import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineStar, AiTwotoneStar } from "react-icons/ai";


export function Favorite(props) {
  const [selected, setSelected] = useState(false)

  useEffect(()=>{
    setSelected(props.selected)
  }, [props.selected])
  if (!props.upload) {
    return (
      <Tooltip title={ selected === true? "Remove from favorite":"Add file to favorite list"} >
        <IconButton
          onClick={(e)=>{
            e.preventDefault();
            e.stopPropagation();
            props.setSelected(!selected);
            setSelected(!selected);
          }}
          aria-label="unfavorite"
          id="unfavorite-btn"
        >
          { selected === false?<AiOutlineStar size={22} style={{color: "#333232"}}/>:
          <AiTwotoneStar size={22} color={"#333232"}/>
        }
        </IconButton>
      </Tooltip>
    )
  } else {
    return null
  }
}