import {AUDIO_VIEW_APP, DOC_VIEW_APP, EXCEL_VIEW_APP, IMAGE_VIEW_APP, PDF_VIEW_APP, TARGET_PLATFORM, VIDEO_VIEW_APP } from "../../utils/consts";

export class ResolverParams {
  parentDir = ""
  objectID = ""
}

export class ResolverObject {
  url = ""
  accesses = []
  application = ""
}

function getApplicationUrl(applicationName, resolverParams) {
  switch(applicationName) {
    case "DirectoryView": {
      return ""
    }
    case "ImageView": {
      return IMAGE_VIEW_APP + `/${resolverParams.objectID}/${resolverParams.parentDir}/`
    }
    case "VideoView": {
      return VIDEO_VIEW_APP + `/${resolverParams.objectID}/${resolverParams.parentDir}/`
    }
    case "PdfView": {
      return PDF_VIEW_APP + `/${resolverParams.objectID}/${resolverParams.parentDir}/`
    }
    case "AudioView": {
      return AUDIO_VIEW_APP + `/${resolverParams.objectID}/${resolverParams.parentDir}/`
    }
    case "ExcelView": {
      return PDF_VIEW_APP + `/${resolverParams.objectID}/${resolverParams.parentDir}/`
    }
    case "DocView": {
      return PDF_VIEW_APP + `/${resolverParams.objectID}/${resolverParams.parentDir}/`
    }
    default: {
      return ""
    }
  }
}

export function getApplication(applications_control, applicationName) {
  for (var i = 0; i < applications_control?.applications.length; i++) {
    if (applications_control.applications[i].name === applicationName) {
      return applications_control.applications[i]
    }
  }
  return null
}

export function resolveObjectType(applications_control, resolverParams, objectType) {
  for (var i = 0; i < applications_control.applications.length; i++) {
    const application = applications_control.applications[i];
    const extensions = application.extensions;
    if (extensions.indexOf(objectType) === -1) {
      continue
    }
    const platform_access_control = applications_control.access[0]
    const accesses = platform_access_control[TARGET_PLATFORM]
    var resolverObject = new ResolverObject();
    resolverObject.url = getApplicationUrl(application.name, resolverParams);
    resolverObject.accesses = accesses;
    resolverObject.application = application;
    return resolverObject
  }
  return null
}

export function getFileObjectByID(fileObjects, objectID) {
  for (var i = 0;i < fileObjects.length; i++) {
    const fileObject = fileObjects[i];
    if (fileObject.id === objectID) {
      return fileObject
    }
  }
  return null
}

export function getSuitableFileObjects(application, fileObjects) {
  var ids = []
  for (var i = 0;i < fileObjects.length; i++) {
    const fileObject = fileObjects[i];
    if (application?.extensions.indexOf(fileObject.file_type) !== -1) {
      ids.push(fileObject.id)
    }
  }
  return ids;
}

export function getSuitableFileObjectsList(application, fileObjects) {
  var objects = []
  for (var i = 0;i < fileObjects.length; i++) {
    const fileObject = fileObjects[i];
    if (application?.extensions.indexOf(fileObject.file_type) !== -1) {
      objects.push(fileObject)
    }
  }
  return objects;
}