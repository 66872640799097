import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { MdDeleteOutline } from "react-icons/md";
import { cutLongString, randomString } from "../../../utils/helper";
import { BIN_PAGE } from "../../../utils/consts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteFileInList, DeleteNotification, OpenNotifications, SetFileList, SetUnsetSelectedRow } from "../../../redux_store/actions";
import { AiFillCloseCircle } from "react-icons/ai";


export function DeleteFile(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box style={{width: '100%'}}
    onClick={(e)=>{
      var selection = window.getSelection();
      if(selection.type !== "Range") {
        dispatch(SetFileList([]))
        navigate(`${BIN_PAGE}/?selected=${props.objectID}&t=${randomString(10)}`)
        dispatch(OpenNotifications(false))
      }
    }}
    className={"clickable-notification"}
    >
      <Stack direction={"column"} style={{width: '92%'}} padding={1}>
        <Box  style={{width: '100%'}}>
          <Stack direction={"row"}>
            <MdDeleteOutline size={24} style={{color: "#4051DA"}}/>
            <Typography style={{fontWeight: 'bold', textAlign: 'center', marginRight: 10, width: '89%', marginTop: 3}}>{props.date}</Typography>
            <Tooltip title={"Close notification"} >
              <IconButton
                size="small"
                style={{position: 'absolute', right: 10, marginTop:1}}
                  onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation()
                    dispatch(DeleteFileInList('notification', props.id))
                  }}
                  aria-label="unfavorite"
                  id="unfavorite-btn"
                >
                <AiFillCloseCircle size={15}/>
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack direction={"column"} style={{}}>
            <Typography style={{maxWidth: '100%', wordWrap: "break-word"}}>
              User <span style={{fontWeight: 'bold'}}>{cutLongString(props.srcUserEmail, 40)}</span> added to bin {props.objectType} 
              <span style={{fontWeight: 'bold'}}> {props.name} </span> from <span style={{fontWeight: 'bold'}}>{cutLongString(props.location, 200)} </span> 
              location.
            </Typography>
          </Stack>
          
        </Box>
        
      </Stack>
    </Box>
  )
}