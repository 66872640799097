import React, { useEffect } from "react";
import { Container, CircularProgress, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "react-oidc-context";
import { HOME_PAGE, TARGET_PLATFORM } from "src/utils/consts";
import { LogOut } from "src/redux_store/actions";

export function Logout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth()
  useEffect(() => {
    auth.removeUser();
    auth.signoutSilent({silentRequestTimeoutInSeconds: 1}).then(() => {
      dispatch(LogOut())
      sessionStorage.removeItem("profile")
      setTimeout(()=>{
        if (TARGET_PLATFORM === 'web') {
          navigate("/")
        } else {
          navigate(HOME_PAGE)
        }
        console.log("LOGOUT2")
        window.location.reload();
    })});
  }, [])

  return (
    <div style={{background: '#EAF3FC'}}>
      <Container style={{height: '100vh', background: '#EAF3FC'}}>
      <div className="logout-div">
        <CircularProgress size={60} />
        <Typography variant="caption" component="div" color="text.secondary" style={{fontSize: 20, marginRight: 5}}>
          Logging out...
        </Typography>
      </div>
      </Container>
    </div>
  )
}