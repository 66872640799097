import { BsFilePdfFill, BsFiletypeDoc, BsFiletypeDocx, BsFiletypeXls, BsFiletypeXlsx } from "react-icons/bs";
import { AiFillFileExclamation, AiFillFolder, AiOutlineFileWord } from "react-icons/ai";
import { BsFiletypeMp3 } from "react-icons/bs";
import { LiaFileAudio } from "react-icons/lia";
import { API, FILTER_FAVORITE_FILES, GROUP_BY_DATE, GROUP_BY_DELETED_DATE, GROUP_BY_FAVORITE, GROUP_BY_NAME, GROUP_BY_SIZE, GROUP_BY_TIME_LEFT, GROUP_BY_TYPE, ORDER_ASC, ObjectType, TARGET_PLATFORM } from "./consts";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { UserInfo } from "src/objects/user";
import { SetListObjectPreviewSizeFormat, UpdatePreview } from "src/redux_store/actions";

export function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function create_back_file_object(id) {
  return  {
    'created': -1,
    'favorite': false,
    'file_mimetype': null,
    'file_type': null,
    'id': id,
    'medium_preview': {},
    'meta_data': {},
    'name': '',
    'object_type': 'folder',
    'original_name': '',
    'owner': '',
    'parent_dir': null,
    'size': 0,
    'small_preview': {},
    'total_size': 0,
    'updated': null,
    'user_permissions': [],
    'version': 0
  }
}

export function headerWrapper(headers) {
  var innerHeaders = {};
  innerHeaders['jf_os'] = TARGET_PLATFORM;
  innerHeaders['jf_device'] = randomString(15);
  innerHeaders = {...innerHeaders, ...headers};
  return innerHeaders;
}

export function getUserInfoFromProfileData(data) {
  if (!data || Object.keys(data).length === 0) {
    return {};
  }
  var _userInfo = new UserInfo();
  _userInfo.userEmail = data.email;
  _userInfo.userName = data.first_name;
  _userInfo.userToken = data.access_token;
  _userInfo.lastName = data.last_name;
  _userInfo.avatarUrl = data.avatar_url + '?'+(Date.now());
  _userInfo.avatarID = data.avatar_id;
  _userInfo.userID = data.external_user_id;
  _userInfo.root = data.root;
  _userInfo.share_permissions = data.share_permissions;
  _userInfo.userTotalSpace = extractUserTotalSpace(data?.user_product?.product?.resources);
  _userInfo.userUsedSpace = extractUserSpaceUsage(data?.usage);
  _userInfo.userMaxUploads = extractMaxUploads(data?.user_product?.product?.resources);
  _userInfo.userCurrentUploads = extractUserUploads(data?.usage);
  _userInfo.userMaxUploadBytes = extractMaxUploadBytes(data?.user_product?.product?.resources);
  _userInfo.userCurrentUploadBytes = extractUserUploadsBytes(data?.usage);
  _userInfo.userMaxDownloadBytes = extractMaxDownloadsBytes(data?.user_product?.product?.resources);
  _userInfo.userCurrentDownloadBytes = extractUserDownloadsBytes(data?.usage);
  _userInfo.userMaxCretedFolders = extractMaxCreatedFolders(data?.user_product?.product?.resources);
  _userInfo.userCurrentCretedFolders = extractUserCreatedFolders(data?.usage);
  _userInfo.userMaxShares = extractMaxShares(data?.user_product?.product?.resources);
  _userInfo.userCurrentShares = extractUserShares(data?.usage);
  _userInfo.applicationsControl = data?.applications_control;
  _userInfo.actionRead = data?.actions?.read;
  _userInfo.activeSessions = data?.sessions;
  _userInfo.totalSessions = data?.user_product?.product?.active_sessions;
  _userInfo.loginHistory = data?.login_history;
  _userInfo.userProduct = data?.user_product;
  _userInfo.billing = data?.billing;
  _userInfo.abr = data?.abr;
  if (_userInfo.avatarSSoUrl) {
    _userInfo.avatarSSoUrl = data.avatar_sso_url+'?'+(Date.now());
  }
  return _userInfo;
}

export function secondsToFormatedTime(seconds) {
  if (!seconds) return "00:00"
  if (seconds > 3600) {
    var date =  new Date(seconds * 1000).toISOString().substring(11, 19);
    var hours = date.slice(0, 2);
    var minutes = date.slice(3, 5);
    var _seconds = date.slice(6, 8);
    var totalMinuts = parseInt(hours) * 60 + parseInt(minutes)
    return totalMinuts.toString() + ':' + _seconds;
  } else {
    return new Date(seconds * 1000).toISOString().substring(14, 19);
  }
}

export function savePublicLinkPassword(publicToken, password) {
  var publicLinkPassword = sessionStorage.getItem('publicLinkPassword');
  if (publicLinkPassword) {
    publicLinkPassword = JSON.parse(publicLinkPassword);
  } else {
    publicLinkPassword = {}
  }
  publicLinkPassword[publicToken] = password;
  sessionStorage.setItem('publicLinkPassword', JSON.stringify(publicLinkPassword))
}

export function saveLayoutType(layoutType) {
  sessionStorage.setItem('layoutType',layoutType);
}

export function getLayoutType() {
  return sessionStorage.getItem('layoutType');
}

export function setFavorites(accessToken, resources, favorite) {
  const headers = {
    'Content-Type': 'application/json',
    AuthToken: accessToken,
  };
  return headerWrapper(headers).then(newHeader => {
    return fetch(API + '/favorites/', {headers: newHeader, 
      method: 'POST', body: JSON.stringify({
      resources: resources,
      favorite: favorite,
    }),})
    .then(resp => resp.json())
    .then(data => {
      console.log("FAV RESP: ", data, resources);
      return data;
    });
  });
}

export function dateStringToGMTDAteString(dateString) {
  var offset = new Date().getTimezoneOffset()*60;
  var dateSeconds = parseInt(new Date(dateString).getTime() / 1000 - offset);
  var date = new Date(dateSeconds * 1000);
  // Get the components of the date
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Construct the date string in the specified format
  const _dateString = `${month}/${day}/${year} ${hours}:${minutes}`;

  return _dateString;
}

export function getOrderedFileList(fileStruct, key) {
  const groupBy = fileStruct.groupBy;
  const order = fileStruct.order;
  if (!groupBy || !order) {
    return fileStruct[key]?.fileList
  }
  var fileList = fileStruct[key]?.fileList;
  if (!fileList || fileList.length === 0) {
    return []
  }
  switch(groupBy) {
    case GROUP_BY_NAME: {
      if (order === ORDER_ASC) {
        fileList.sort((a, b) => {
          if(a.original_name < b.original_name) { return -1; }
          if(a.original_name > b.original_name) { return 1; }
          return 0;
        })
      } else {
        fileList.sort((a, b) => {
          if(a.original_name > b.original_name) { return -1; }
          if(a.original_name < b.original_name) { return 1; }
          return 0;
        })
      }
      break;
    }
    case GROUP_BY_TIME_LEFT: {
      if (order === ORDER_ASC) {
        fileList.sort((a, b) => {
          if(a.days_left < b.days_left) { return -1; }
          if(a.days_left > b.days_left) { return 1; }
          return 0;
        })
      } else {
        fileList.sort((a, b) => {
          if(a.days_left > b.days_left) { return -1; }
          if(a.days_left < b.days_left) { return 1; }
          return 0;
        })
      }
      break;
    }
    case GROUP_BY_DATE: {
      if (order === ORDER_ASC) {
        fileList.sort((a, b) => {
          if(Date.parse(a.created) - Date.parse(b.created) < 0) { return -1; }
          if((Date.parse(a.created) - Date.parse(b.created)) > 0) { return 1; }
          return 0;
        })
      } else {
        fileList.sort((a, b) => {
          if(Date.parse(a.created) - Date.parse(b.created) > 0) { return -1; }
          if((Date.parse(a.created) - Date.parse(b.created)) < 0) { return 1; }
          return 0;
        })
      }
      break;
    }
    case GROUP_BY_DELETED_DATE: {
      if (order === ORDER_ASC) {
        fileList.sort((a, b) => {
          if(Date.parse(a.deleted_date) - Date.parse(b.deleted_date) < 0) { return -1; }
          if((Date.parse(a.deleted_date) - Date.parse(b.deleted_date)) > 0) { return 1; }
          return 0;
        })
      } else {
        fileList.sort((a, b) => {
          if(Date.parse(a.deleted_date) - Date.parse(b.deleted_date) > 0) { return -1; }
          if((Date.parse(a.deleted_date) - Date.parse(b.deleted_date)) < 0) { return 1; }
          return 0;
        })
      }
      break;
    }
    case GROUP_BY_TYPE: {
      if (order === ORDER_ASC) {
        fileList.sort((a, b) => {
          if (!a.file_type) return 1;
          if(a.file_type < b.file_type) { return -1; }
          if(a.file_type > b.file_type) { return 1; }
          return 0;
        })
      } else {
        fileList.sort((a, b) => {
          if (!a.file_type) return -1;
          if(a.file_type > b.file_type) { return -1; }
          if(a.file_type < b.file_type) { return 1; }
          return 0;
        })
      }
      break;
    }
    case GROUP_BY_SIZE: {
      if (order === ORDER_ASC) {
        fileList.sort((a, b) => {
          if (a.size === undefined) return 1;
          if(a.size - b.size < 0) { return -1; }
          if(a.size - b.size > 0) { return 1; }
          return 0;
        })
      } else {
        fileList.sort((a, b) => {
          if (a.size === undefined) return -1;
          if(a.size - b.size > 0) { return -1; }
          if(a.size - b.size < 0) { return 1; }
          return 0;
        })
      }
      break;
    }
    case GROUP_BY_FAVORITE: {
      if (order === ORDER_ASC) {
       fileList.sort((a, b) => {
          return (a.favorite === b.favorite)? 0 : a.favorite? -1 : 1;
        })
      } else {
        fileList.sort((a, b) => {
          return (a.favorite === b.favorite)? 0 : !a.favorite? -1 : 1;
        })
      }
      break;
    }
    default: {
      return fileList;
    }
  }
  return fileList;
}

export function getObjectListByIds(parentID, ids, fileStruct) {
  var items = fileStruct[parentID]?.fileList;
  var res = [];
  if (!items) {
    return []
  }
  for (var i = 0; i < items.length; i++) {
    if (ids.indexOf(items[i]?.id) !== -1) {
      res.push(items[i]);
    }
  }
  return res;
}

export function getFileBinIDS(fileObjects) {
  var res = [];
  for (var i = 0; i < fileObjects.length; i++) {
    const fileObject = fileObjects[i];
    if (fileObject.object_type === 'file') {
      res.push(fileObject.object_id)
    }
  }
  return res;
}

export function uniqFast(a) {
  var seen = {};
  var out = [];
  var len = a.length;
  var j = 0;
  for(var i = 0; i < len; i++) {
       var item = a[i];
       if(seen[item] !== 1) {
             seen[item] = 1;
             out[j++] = item;
       }
  }
  return out;
}

export function getFileIDS(fileObjects) {
  var res = [];
  for (var i = 0; i < fileObjects.length; i++) {
    const fileObject = fileObjects[i];
    if (fileObject.object_type === 'file') {
      res.push(fileObject.id)
    }
  }
  return res;
}

export function createBreadCrumb(rootFolderID, serverBreadCrumb, userInfo, sharePage) {
  var breadCrumbs = []
  if (userInfo?.userToken) {
    if (sharePage) {
      breadCrumbs = [{original_name: 'My shares', id: ''}];
    } else {
      breadCrumbs = [{original_name: 'My files', id: rootFolderID}];
    }
    
    return breadCrumbs.concat(serverBreadCrumb)
  }
  return serverBreadCrumb;
}


export function getPublicLinkPasswrods() {
  var publicLinkPassword = sessionStorage.getItem('publicLinkPassword');
  if (publicLinkPassword) {
    publicLinkPassword = JSON.parse(publicLinkPassword);
  } else {
    publicLinkPassword = {}
  }
  return publicLinkPassword;
}

export function checkout(productID, userToken) {
  return fetch(API + '/checkout/', {
    method: 'POST',
    body: JSON.stringify({ 'product_id': productID }),
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
  }).then(resp => {
    return resp.json()
  }) 
}

export function getProducts() {
  return fetch(API + '/products/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(resp => {
    return resp.json()
  }) 
}

export function getProduct(id) {
  return fetch(API + `/product/${id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(resp => {
    return resp.json()
  }) 
}


export function getUserSession() {
  var sessionProfile = sessionStorage.getItem('profile');
  if (sessionProfile) {
    sessionProfile = JSON.parse(sessionProfile);
  } else {
    sessionProfile = {}
  }
  return sessionProfile
}

const readEntriesPromise = async (directoryReader) => {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.error(err);
  }
};

const readAllDirectoryEntries = async (directoryReader) => {
  let entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
};

const readEntryContentAsync = async (entry) => {
  return new Promise((resolve, reject) => {
    let reading = 0;
    const contents = [];
    reading++;
    if (entry.isDirectory) {
      reading--;
      contents.push(entry);
      if (reading === 0) {
        resolve(contents);
      }
    } else {
      entry.file(async (file) => {
        reading--;
        const rawFile = file;
        rawFile.path = entry.fullPath;
        contents.push(rawFile);
        if (reading === 0) {
          resolve(contents);
        }
      });
    }
  });
};

export const getAllFileEntries = async (dataTransferItemList) => {
  let fileEntries = [];
  // Use BFS to traverse entire directory/file structure
  let queue = [];
  // Unfortunately dataTransferItemList is not iterable i.e. no forEach
  for (let i = 0; i < dataTransferItemList.length; i++) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    let entry = queue.shift();
    if (entry && entry.isFile) {
      fileEntries.push(entry);
    } else if (entry && entry.isDirectory) {
      fileEntries.push(entry);
      let reader = entry.createReader();
      queue.push(...(await readAllDirectoryEntries(reader)));
    } else {
      continue
    }
  }
  // return fileEntries;
  return Promise.all(
    fileEntries.map((entry) => readEntryContentAsync(entry))
  );
};

export function downloadSelected(objectIDs, userToken, deviceID) {
  return fetch(API + `/fileobjects/?deviceID=${deviceID}`, {
    method: 'POST',
    body: JSON.stringify({ 'file_object_ids': objectIDs }),
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    return resp.json()
  }).then(respJson => {
    return respJson
  })
}

function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export function statePreviewWrapper(fileIDS, previewFormat, authToken, cb) {
  const idChunks = chunks(fileIDS, 40);
  [...idChunks].map((ids, index) => {
    return getPreviewList(fileIDS, previewFormat, authToken).then(previews => {
      cb(previews)
    }) 
  })
}

export function search(data, userToken) {
  return fetch(API+`/search/?q=${data}`,  {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
    },
  }).then(resp =>{
    return resp.json()
  })
}

export function reduxPreviewWrapper(fileIDS, appName, previewFormat, authToken, dispatch, binPreview) {
  const idChunks = chunks(fileIDS, 40);
  [...idChunks].map((ids, index) => {
    return getPreviewList(fileIDS, previewFormat, authToken).then(previews => {
      dispatch(UpdatePreview(appName, previews, previewFormat, binPreview))
      dispatch(SetListObjectPreviewSizeFormat(appName, previewFormat));
    }) 
  })
}

export function getAccesses(objectID, userToken) {
  return fetch(API + '/accesses/' + objectID + '/', {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
  }).then(resp => {
    return resp.json()
  })
}

export function createFolder(folder, folder_name, userToken) {
  return fetch(API + '/create-folder/' + folder + '/', {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      folder_name: folder_name
    })
  }).then(resp => {
    return resp.json()
  })
}

export function getTmpUrl(objectID, userToken, ex) {
  if (!ex) {
    ex = 15;
  }
  return fetch(API + `/tmp-file-object-url/${objectID}/?ex=${ex}`, {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(response =>{
    return response.json()
  })
}

export function downloadBrowserArchive(userToken, fileName) {
  var headers = {
    'AuthToken': userToken,
  };
  return fetch(API + '/download-archive/', 
  { headers }).then(response => {
    return response.blob()
})
.then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
})
}

export function downloadBrowserData(objectID, userToken, fileName) {
  var headers = {
    'AuthToken': userToken,
  };
  return fetch(API + '/fileobject/' + objectID + "/?download=2", 
  { headers }).then(response => {
    return response.blob()
})
.then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
})
}

export function getApplicationControls() {
  var headers = {
    'Content-Type': 'application/json',
  };
  return fetch(`${API}/application-controls/`, { headers })
  .then(resp =>{
    return resp.json();
  }).then(jsonData=>{
    return jsonData;
  });
}

export function getFolderList(objectID, userToken, page, windowSize) {
  var headers = {
    AuthToken: userToken,
    'Content-Type': 'application/json',
  };
  return fetch(`${API}/folder-list/${objectID}/?page=${page}&window=${windowSize}`, { headers })
  .then(resp =>{
    return resp.json();
  }).then(jsonData=>{
    return jsonData;
  });
}

export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);

  }
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return prompt("Copy to clipboard: Ctrl+C, Enter", text);
      }
      finally {
          document.body.removeChild(textarea);
      }
  }
}

export function deleteObject(objectIDs, userToken) {
  return fetch(API + "/add-bin-objects/", {
    method: 'POST',
    body: JSON.stringify({ 'file_object_ids': objectIDs }),
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    return resp.json()
  })
}

export function share(resources, access, targetUser, userToken) {
  return fetch(API + '/share/', {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      resources: resources,
      access_id: access,
      target_user: targetUser
    })
  }).then(resp => {
    return resp.json()
  })
}

export function getBreadCrumb(folderID, userToken) {
  return fetch(API + '/path/' + folderID + '/', {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }}).then(resp => {
      return resp.json()
    })
}

export function copy(dstrFolder, itemsForCopy, userToken) {
  return fetch(API + '/copy/' + dstrFolder + '/', {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      source_objects: itemsForCopy
    })
  }).then(resp => {
    return resp.json()
  })
}

export function getSimpleFileList(objectID, userToken, fileTypes, 
  window, page, selected, preview) {
  var url = `${API}/s-file-list/${objectID}/?window=${window}&page=${page}&file_types=${fileTypes.join(',')}`
  if (selected) {
    url += `&selected=`+selected.join(',');
  }
  if (preview) {
    url += `&previews=${preview}`;
  }
  return fetch(url, {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(response =>{
    return response.json()
  })
}

export function move(dstrFolder, itemsForMove, userToken) {
  return fetch(API + '/move/' + dstrFolder + '/', {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      source_objects: itemsForMove
    })
  }).then(resp => {
    return resp.json()
  })
}

export function deleteLink(userToken, objectID, publicToken) {
  return fetch(API + `/delete-link/${publicToken}/${objectID}`, {
    method: 'DELETE',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    return resp.json()
  })
}

export function createLinkSettings(userToken, publicToken, password, ttl, expireAt) {
  return fetch(API + '/create-link-settings/' + publicToken + '/', {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: password,
      ttl: ttl,
      expire_at: expireAt
    })
  }).then(resp => {
    return resp.json()
  })
}

export function getLinkSettings(userToken, publicToken) {
  return fetch(API + '/link-settings/' + publicToken + '/', {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    return resp.json()
  })
}

export function getLink(userToken, objectID, actionID) {
  return fetch(API + '/create-link/', {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      object_id: objectID,
      action_id: actionID
    })
  }).then(resp => {
    return resp.json()
  })
}

export function getDefaultObjectMenu(menuOptions) {
  var res = [];
  for (var i = 0; i < menuOptions.length; i++) {
    if (!menuOptions[i].key) {
      res.push(menuOptions[i])
    }
  }
  return res;
}

export function removeShare(objects, userToken) {
  return fetch(API+'/remove-share/',  {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(objects)
  }).then(resp =>{
    return resp.json()
  })
}

export function getFileListObjectMenu(fileObject, menuOptions) {
  var res = [];
  for (var i = 0; i < menuOptions.length; i++) {
    var menuOption = menuOptions[i];
    if (TARGET_PLATFORM !== 'web') {
      if (menuOption.id === 9) {
        if (!fileObject.synced && fileObject.object_type === 'folder') {
          res.push(menuOption)
        } else {
          continue
        }
      } else if (menuOption.id === 10) {
        if (fileObject.synced && fileObject.object_type === 'folder') {
          res.push(menuOption)
        } else {
          continue
        }
      } else if (menuOption.targetPage !== 'sharePage') {
        res.push(menuOption)
      }
    } else if (menuOption.targetPage !== 'sharePage') {
      res.push(menuOption)
    }
  }
  return res;
}

export function getFileObjectMenu(userInfo, fileObjectAccesses, menuOptions) {
  var newMenuOptions = [];
  const accesses = fileObjectAccesses?.accesses;
  const sharePermissions = userInfo?.share_permissions;
  const externalUserId = userInfo?.userID;
  var access;
  var listOfPermissions = [];
  for (var i = 0; i < accesses.length; i++) {
    var accessObject = accesses[i];
    if (accessObject.user.id === externalUserId) {
      access = accessObject.access;
      break;
    }
  }
  console.log("DATA: ", accesses, externalUserId)
  if (!access) {
    return getDefaultObjectMenu(menuOptions);
  }
  const accessName = access.name;
  if (accessName === 'Owner') {
    return menuOptions;
  }
  for (var i = 0; i < sharePermissions.length; i++) {
    const sharePermission = sharePermissions[i];
    if (sharePermission.name === accessName) {
      listOfPermissions = sharePermission.description.split(',');
      break;
    }
  }
  console.log("NEW OPTIONS: ", listOfPermissions, sharePermissions, accessName)
  if (!listOfPermissions) {
    return getDefaultObjectMenu(menuOptions);
  }
  for (var i = 0; i < menuOptions.length; i++) {
    const menuOption = menuOptions[i];
    if (listOfPermissions.indexOf(menuOption.key) !== -1 || !menuOption.key) {
      newMenuOptions.push(menuOption)
    }
  }
  
  return newMenuOptions;
}

export function getPreviewList(objectIDs, previewFormat, userToken) {
  if (!objectIDs || objectIDs.length === 0) {
    return new Promise(() => {
      return []
    })
  }
  var headers = {
    'AuthToken': userToken,
    'Content-Type': 'application/json'
  }
  var url = API+'/preview-list/?ids=' + objectIDs.join(',')
  if (previewFormat) {
    url += '&preview='+previewFormat
  }
  return fetch(url, { headers }).then(resp =>{
    return resp.json()
  }).then(jsonData=>{
    return jsonData
  })
}

export function getEnv() {
  const searchParams = new URLSearchParams(window.location.search);
  var env = searchParams.get('env');
  if (!env) {
    env = 1;
  } else {
    env = parseInt(env);
  }
  return env;
}

export function getFileList(folder, userToken, page, windowSize, selected, selectedEnv) {
  var headers = {
    'AuthToken': userToken,
    'Content-Type': 'application/json'
  }
  if (!selectedEnv) {
    selectedEnv = 1;
  }
  var url = API+'/file-list/'+folder+'/?page='+page+'&window='+windowSize+'&preview=small&with_share=0'
  if (selected) {
    url += '&selected='+selected
  }
  url += `&env=${selectedEnv}`
  return fetch(url, { headers }).then(resp =>{
    return resp.json()
  }).then(jsonData=>{
    return jsonData
  })
}

export function getMainRootFromPath(path) {
  if (!path) {
    return "/"
  }
  var tmpPath = path.slice(1, path.length);
  var index = tmpPath.indexOf("/");
  if (index === -1 && tmpPath) {
    return "/"+tmpPath
  } else if (index === -1) {
    return "/"
  }
  return "/"+tmpPath.slice(0, index)
}

function removeDuplicates(arr) {
  return Array.from(new Set(arr.map(JSON.stringify)), JSON.parse)
}

export function rename(objectID, userToken, newName) {
  return fetch(API + "/rename/" + objectID + '/', {
      method: 'POST',
      headers: {
        'AuthToken': userToken,
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify({name: newName})
  }).then(resp=>{
    return resp.json()
  })
}

export function createDirTree(etries) {
  var res = [];
  for (var i =0; i < etries.length; i++){
    const splitedPath = etries[i].fullPath.split('/')
    for (var j = 1;j < splitedPath.length; j++) {
      res.push(splitedPath.slice(1, j+1))
    }
  }
  return removeDuplicates(res);
}

export function createDirTree_v2(etries) {
  var res = [];
  for (var i =0; i < etries.length; i++){
    const splitedPath = etries[i].split('/')
    for (var j = 1;j < splitedPath.length; j++) {
      res.push(splitedPath.slice(1, j+1))
    }
  }
  return removeDuplicates(res);
}

export function getFileDirPath(relativePath) {
  if (!relativePath) {
    return "/"
  }
  var path = relativePath.slice(0, relativePath.lastIndexOf("/"))
  if (!path) {
    return "/"
  }
  return path
}

export function getMainRootObject(main_roots) {
  var tmp_name_to_id = {}
  
  for (var i = 0; i < main_roots.length; i++) {
    tmp_name_to_id[main_roots[i].relative_path] = main_roots[i].id;
  }
  return tmp_name_to_id;
}

export function craeteUploadStructure(files, created_folders, main_roots) {
  var upload_structure = {};
  var tmp_name_to_id = {}
  if (!main_roots) {
    return upload_structure
  }
  for (var i = 0; i < main_roots.length; i++) {
    upload_structure[main_roots[i].id] = {'main_root_path': main_roots[i].relative_path, folders: {}, files: {}};
    tmp_name_to_id[main_roots[i].relative_path] = main_roots[i].id;
  }

  for (const [key, value] of Object.entries(created_folders)) {
    const tmpMainRoot = getMainRootFromPath(key);

    const tmpID = tmp_name_to_id[tmpMainRoot];
    console.log("DA: ", tmpID, upload_structure, tmpMainRoot)
    upload_structure[tmpID].folders[value] = {
      relative_path: key, 
      type: 'folder',
      main_root: tmpID
    };
  }
  for (var i = 0; i < files.length; i++) {
    const _file = files[i];
    const tmpMainRoot = getMainRootFromPath(_file.relative_path_parent_dir);
    
    const tmpID = tmp_name_to_id[tmpMainRoot];
    upload_structure[tmpID].files[_file.id] = {
      relative_path: _file.relative_path, 
      full_path: _file.full_path,
      type: 'file',
      main_root: tmpID
    };
  }
  console.log("RELATIVE: ", upload_structure)
  return upload_structure;
}

export function createFolders(folderID, folders, userToken) {
  return fetch(API+"/create-folders/"+folderID+"/", {
      method: 'POST',
      body: JSON.stringify(folders),
      headers: {
        'AuthToken': userToken,
        'Content-Type': 'application/json', 
      }
  }).then(resp=>{
    return resp.json()
  }).then(respJson=>{
    return respJson
  })
}

export function GetListObjectIcon(listObject) {
  //const [iconSize, setIconSize] = useState(35)
  const [color, setColor] = useState('black')
  const [folderSize, setFolderSize] = useState(35);
  const [previewB64, setPreviewB64] = useState('')
  const [mimeType, setMimeType] = useState('')
  var props = listObject.props;
  const iconSize = listObject.iconSize ? listObject.iconSize : 35;
  useEffect(()=>{
    if (listObject.color) {
      setColor(listObject.color)
    }
  }, [])

  useEffect(() => {
    if (listObject.previewFormat && listObject.previewFormat === 'small') {
      setPreviewB64(props?.small_preview?.data)
      setMimeType(props?.small_preview?.file_mimetype)
    } else if (listObject.previewFormat && listObject.previewFormat === 'medium') {
      setPreviewB64(props?.medium_preview?.data)
      setMimeType(props?.medium_preview?.file_mimetype)
    }
  }, [listObject.previewFormat, props?.small_preview, props?.medium_preview])

  /* useEffect(() => {
    if (listObject.iconSize) {
      setIconSize(listObject.iconSize)
    }
  }, [listObject.iconSize]) */

  /* useEffect(() => {
    if (listObject.folderSize) {
      setFolderSize(listObject.folderSize)
    }
  }, [listObject.folderSize]) */
  
  if (props.object_type === "folder") {
    return <AiFillFolder size={listObject.folderSize} color="#1c85d4"/>
  }
  if (previewB64) {
    if (listObject.previewFormat === 'small') {
      return <Box style={{width: iconSize, height: iconSize}}>
        <img src={`data:${mimeType};base64,${previewB64}`} style={{width: `${iconSize}px`, height: `${iconSize}px`, borderRadius: 5}} />
      </Box>
    } else if (listObject.previewFormat === 'medium') {
      return <Box 
        alignItems={"center"}
        justifyContent={"center"}
        style={{
        width: '100%', 
        height: 200, 
        borderRadius: 7,
        overflow: 'hidden'
        }}>
      <img 
        src={`data:${mimeType};base64,${previewB64}`} 
        style={{width: `${listObject.imageSize}px`, height: `${listObject.imageSize}px`, objectFit: 'cover'}} 
      />
    </Box>
    }
  }
  switch(props.file_type) {
    case 'pdf': {
      return <BsFilePdfFill size={iconSize-8} color={color} />
    }
    case 'mp3': {
      return <BsFiletypeMp3 size={iconSize-8} color={color} />
    }
    case 'ogg': {
      return <LiaFileAudio size={iconSize-8} color={color} />
    }
    case 'flac': {
      return <LiaFileAudio size={iconSize-8} color={color} />
    }
    case 'xlsm':
    case 'xls': {
      return <BsFiletypeXls size={iconSize-8} color={color} />
    }
    case 'xlsx': {
      return <BsFiletypeXlsx size={iconSize-8} color={color} />
    }
    case 'docx': {
      return <BsFiletypeDocx size={iconSize-8} color={color} />
    }
    case 'doc': {
      return <BsFiletypeDoc size={iconSize-8} color={color} />
    }
    default: {
      return <AiFillFileExclamation size={iconSize-8} color={color} />
    }
  }
}

export function covertDate(dateObject) {
  var monthValue = (dateObject.getMonth()+1).toString();
  var dayValue = (dateObject.getDate()).toString()
  var hours = (dateObject.getHours()).toString()
  var minutes = (dateObject.getMinutes()).toString()
  if (monthValue.length === 1) {
    monthValue = "0" + monthValue
  }

  if (dayValue.length === 1) {
    dayValue = "0" + dayValue
  }

  if (hours.length === 1) {
    hours = "0" + hours
  }

  if (minutes.length === 1) {
    minutes = "0" + minutes
  }
  var datestring = monthValue + "/" + dayValue  + "/" + dateObject.getFullYear() + " " +
  hours + ":" + minutes;
  return datestring
}

export function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  if (!duration) {
    return ""
  }
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

export function getIndexOfFoundObject(fileList, objectID) {
  for (var i = 0; i < fileList.length; i++) {
    if (fileList[i].id === objectID) {
      return i;
    }
  }
  return -1;
}

export function valueBytesToString(valueBytes) {
  
  if (!valueBytes) return "0 Bytes"
  valueBytes = parseInt(valueBytes)
  var valueTmpStr = valueBytes.toString();
  const valueLen = valueTmpStr.length;
  const kBytes = 1024;
  const mBytes = 1048576;
  const gBytes = 1073741824;
  const tBytes = 1099511627776;
  switch(true) {
    case 3 > valueLen && valueLen >= 0: {
      return  valueTmpStr+" Bytes"
    }
    case 6 >= valueLen && valueLen > 3: {
      return  (Math.round(valueBytes/kBytes)).toString()+" Kb"
    }
    case 9 >= valueLen && valueLen > 6: {
      return  (Math.round(valueBytes/mBytes)).toString()+" Mb"
    }
    case 12 >= valueLen && valueLen > 9: {
      return  (Math.round(valueBytes/gBytes)).toString()+" Gb"
    }
    case 15 >= valueLen && valueLen> 12: {
      return  (Math.round(valueBytes/tBytes)).toString()+" Tb"
    }
    default:
      return  valueTmpStr+" Bytes"
  }
}


export function cutLongString(longString, n) {
  if (longString && longString.length > n) {
    return longString.slice(0, n) + '...'
  }
  return longString
}

export function getRandomMaxValue(n) {
  const len = Math.floor(Math.random() * n) + 1
  var valStr = "";
  for (var i = 0; i < len; i++) {
    valStr += "9"
  }
  return parseInt(valStr)
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function setFavorite(objectID, userToken) {
  fetch(API + "/favorite/" + objectID + '/', {
      method: 'POST',
      headers: {
        'AuthToken': userToken,
      }
  }).then(resp=>{
    return resp.json()
  }).then(respJson=>{
  })
}

export function getFiltered(filters, fileObject) {
  for (var i = 0; i < filters.length; i++) {
    const filter = filters[i];
    if (filter === FILTER_FAVORITE_FILES) {
      if (fileObject.favorite)
       return true;
      else
       return false;
    }
  }
  return true;
}

export function extractUserTotalSpace(resources) {
  for (var i = 0; i < resources?.length; i++) {
    if (resources[i]?.resource_group.name === "user_space_size") {
      return resources[i]?.value
    }
  }
  return 0
}

export function extractMaxUploads(resources) {
  for (var i = 0; i < resources?.length; i++) {
    if (resources[i]?.resource_group.name === "max_upload_per_day") {
      return resources[i]?.value
    }
  }
  return 0
}

export function extractUserUploads(usages) {
  for (var i = 0; i < usages?.length; i++) {
    if (usages[i]?.resource_group === "max_upload_per_day") {
      return usages[i]?.value
    }
  }
  return 0
}

export function extractMaxUploadBytes(resources) {
  for (var i = 0; i < resources?.length; i++) {
    if (resources[i]?.resource_group.name === "upload_bytes_per_day") {
      return resources[i]?.value
    }
  }
  return 0
}

export function extractUserUploadsBytes(usages) {
  for (var i = 0; i < usages?.length; i++) {
    if (usages[i]?.resource_group === "upload_bytes_per_day") {
      return usages[i]?.value
    }
  }
  return 0
}

export function extractMaxDownloadsBytes(resources) {
  for (var i = 0; i < resources?.length; i++) {
    if (resources[i]?.resource_group.name === "download_bytes_per_day") {
      return resources[i]?.value
    }
  }
  return 0
}

export function extractUserDownloadsBytes(usages) {
  for (var i = 0; i < usages?.length; i++) {
    if (usages[i]?.resource_group === "download_bytes_per_day") {
      return usages[i]?.value
    }
  }
  return 0
}

export function getToken(userInfo, p) {
  if (userInfo && userInfo?.userToken) {
    return userInfo?.userToken;
  }
  var publicLinkPasswords = getPublicLinkPasswrods();
  if (p && publicLinkPasswords[p]) {
    return `${p},${publicLinkPasswords[p]}`
  }
  return p;
}

export function extractMaxCreatedFolders(resources) {
  for (var i = 0; i < resources?.length; i++) {
    if (resources[i]?.resource_group.name === "max_create_folders_per_day") {
      return resources[i]?.value
    }
  }
  return 0
}

export function extractUserCreatedFolders(usages) {
  for (var i = 0; i < usages?.length; i++) {
    if (usages[i]?.resource_group === "max_create_folders_per_day") {
      return usages[i]?.value
    }
  }
  return 0
}

export function extractMaxShares(resources) {
  for (var i = 0; i < resources?.length; i++) {
    if (resources[i]?.resource_group.name === "share_per_day") {
      return resources[i]?.value
    }
  }
  return 0
}

export function extractUserShares(usages) {
  for (var i = 0; i < usages?.length; i++) {
    if (usages[i]?.resource_group === "share_per_day") {
      return usages[i]?.value
    }
  }
  return 0
}

export function extractUserSpaceUsage(usages) {
  for (var i = 0; i < usages?.length; i++) {
    if (usages[i]?.resource_group === "user_space_size") {
      return usages[i]?.value
    }
  }
  return 0
}

export function getContactList(userToken) {
  return fetch(API + '/contact-list/', {
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    return resp.json()
  }).then(respJson => {
    return respJson
  })
}

export function getJarFlowObject(objectID, userToken) {
  return fetch(API + '/jarflow-objects/', {
    method: 'POST',
    body: JSON.stringify({ 'file_object_ids': [objectID] }),
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    return resp.json()
  }).then(respJson => {
    return respJson
  })
}