import { Checkbox, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { CancelActiveAxiosUpload, DeleteFileInList, DeleteUploadObject, RemoveActiveDirectoryUpload, RemoveActiveUploads, RemoveUploadEntity, SetUnsetSelectedRow, ShowShareModal } from "../../redux_store/actions";
import { UploadingAnimation } from "./uploading_animation";
import { SmartProgress } from "./smart_progress";
import { RiCheckboxBlankLine } from "react-icons/ri";
import { RiCheckboxFill } from "react-icons/ri";
import { TARGET_PLATFORM } from "../../utils/consts";
import { invoke } from "@tauri-apps/api";
import { SmartDownload } from "./smart_download";
import { PiShareFatBold } from "react-icons/pi";
import { HiDotsVertical } from "react-icons/hi";


function RowSmartPanelInner(props) {

  const {onCheckBoxLeave, shortPanel} = props;


  if (shortPanel && props.show === 1 && !props.progress) {
    return (<Stack direction="row" sx={{width: '100%'}} alignItems={"end"} justifyContent={"end"}>
      <IconButton
        aria-label="more-btn"
        id="row-menu-btn"
        aria-controls={props.open ? 'row-menu-btn' : undefined}
        aria-expanded={props.open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e)=>{
          e.stopPropagation();
          props.handleClick(e)
          props.onMenuOpen()
        }}
        >
        <HiDotsVertical size={22} style={{width: '24px'}}/>
      </IconButton>
    </Stack>)
  }
  if (props.totalUploadItems && props.value?.object_type === 'folder') {
    return (
      <>
        <Stack direction="row" sx={{width: '130px'}}>
          <Stack direction="column" sx={{width: '100%'}}>
            <UploadingAnimation
              currentUploaded={props.currentUploaded}
              totalUploadItems={props.totalUploadItems}
              show={props.totalUploadItems > 0?true:false}
            />
          </Stack>
          
        </Stack>
        <Tooltip title="Cancel upload" >
          <IconButton
            aria-label="more"
            style={{marginLeft: 20}}
            onClick={()=>{
              props.dispatch(RemoveUploadEntity(props.value.id, 'folder', props.value.main_root_id));
            }} // Place for action 'CancelUpload'
            >
            <CgClose size={22} color="black" style={{width: '22px'}}/>
          </IconButton>
        </Tooltip>
      </>
    )
  }
  if (props.value?.upload && props.progress !== 1) {
    return (
      <>
        <Stack direction="row" sx={{width: '130px'}}>
          <SmartProgress
            value={props.value}
            progress={props.progress}
            setProgress={props.setProgress}
          />
        </Stack>
        <Tooltip title="Cancel upload" >
          <IconButton
            aria-label="more"
            style={{marginLeft: 20}}
            onClick={()=>{
              /* if (TARGET_PLATFORM === "linux" || TARGET_PLATFORM === "macos" || TARGET_PLATFORM === "windows") {
                invoke("stop_active_uploading", {
                  id: props.value.id
                });
              }
              else {
                props.dispatch(CancelActiveAxiosUpload(props.value.id))
              } */
              props.dispatch(CancelActiveAxiosUpload(props.value.id))
              props.dispatch(RemoveUploadEntity(props.value.id, 'file', props.value.main_root_id));
              props.dispatch(DeleteFileInList(props.value.id));
              props.dispatch(RemoveActiveUploads(props.value.id));
              props.dispatch(DeleteUploadObject(props.value.id));
              props.dispatch(RemoveActiveDirectoryUpload(props.value.parent_id, props.value.id));
            }} // Place for action 'CancelUpload'
            >
            <CgClose size={22} color="black" style={{width: '22px'}}/>
          </IconButton>
        </Tooltip>
      </>
    )
  }
  if (props.show === 1 && (!props.progress || props.progress === -1)) {
    return (
    <Stack direction={"row"} style={{width: '100%'}}>
      <Stack direction="row" sx={{width: 110}}>
        <SmartDownload
          dispatch={props.dispatch}
          setProgress={props.setProgress}
          value={props.value}
          progress={props.progress}
          userToken={props.userToken}
        />
        
        <Tooltip title={props.value.object_type === 'folder'? "Share folder": "Share file"}>
          <IconButton
            aria-label="share"
            id="share-btn"
            onClick={()=>{props.showShareDialogFromBtn(props.value)}}
          >
            <PiShareFatBold size={22} style={{width: '24px'}}/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Menu">
          <IconButton
            aria-label="more-btn"
            id="row-menu-btn"
            aria-controls={props.open ? 'row-menu-btn' : undefined}
            aria-expanded={props.open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(e)=>{
              e.stopPropagation();
              props.handleClick(e)
              props.onMenuOpen()
            }}
            >
            <HiDotsVertical size={22} style={{width: '24px'}}/>
          </IconButton>
        </Tooltip>
      
    </Stack>
  </Stack>
  )
  } else if (props.show === 1 && props.progress) {
    return (
      <Stack direction={"row"} style={{width: '100%'}} onMouseLeave={() => onCheckBoxLeave(0)}>
        <Stack direction="row" sx={{width: '130px'}}>
          <SmartProgress
            value={props.value}
            progress={props.progress}
            setProgress={props.setProgress}
          />
          <Tooltip title={props.value.object_type === 'folder'? "Share folder": "Share file"}>
            <IconButton
              aria-label="share"
              id="share-btn"
              onClick={()=>{props.dispatch(ShowShareModal([props.value.id]))}}
            >
              <PiShareFatBold size={22} style={{width: '24px'}}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Menu">
            <IconButton
              aria-label="more-btn"
              id="row-menu-btn"
              aria-controls={props.open ? 'row-menu-btn' : undefined}
              aria-expanded={props.open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={(e)=>{
                e.stopPropagation();
                props.handleClick(e)
                props.onMenuOpen()
              }}
              >
              <HiDotsVertical size={22} style={{width: '24px'}}/>
            </IconButton>
          </Tooltip>
      </Stack>
    </Stack>)
  } else if (props.progress) {
    if (props.selectedRow) {
      return (<>
      <Stack direction="row" sx={{width: '130px'}} onMouseLeave={() => onCheckBoxLeave(0)}>
        <SmartProgress
          value={props.value}
          progress={props.progress}
          setProgress={props.setProgress}
        />
      </Stack>
      </>)
    } 
    return (
      <Stack direction="row" sx={{width: '130px'}}>
        <SmartProgress
          value={props.value}
          progress={props.progress}
          setProgress={props.setProgress}
        />
      </Stack>
    )
  } else {
    return (
      <Stack direction="row" sx={{width: '100%'}}></Stack>
    );
  }
}

export const RowSmartPanel = React.memo(RowSmartPanelInner, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
})