export class UserInfo {
  userName = ""
  userToken = ""
  userEmail = ""
  lastName = ""
  avatarUrl = ""
  userID = ""
  avatarID = ""
  avatarSSoUrl = ""
  root = ""
  share_permissions = []
  userTotalSpace = 0
  userUsedSpace = 0
  userMaxUploads = 0
  userCurrentUploads = 0
  userMaxUploadBytes = 0
  userCurrentUploadBytes = 0
  userMaxDownloadBytes = 0
  userCurrentDownloadBytes = 0
  userMaxCretedFolders = 0
  userCurrentCretedFolders = 0
  userMaxShares= 0
  userCurrentShares = 0
  applicationsControl = {}
}