import { Box, Divider, Drawer, IconButton, LinearProgress, List, ListItem, ListItemText, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tauri } from '@tauri-apps/api';
import { AiFillCloseCircle, AiFillFolder } from "react-icons/ai";
import downloads from "../../managers/downloads";
import { AddDownloads, DeleteDownloads, DeleteUploads, SetSeenDownloads, SetSeenUploads } from "../../redux_store/actions";
import { cutLongString, valueBytesToString } from "../../utils/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import { TARGET_PLATFORM } from "src/utils/consts";


function LinearProgressWithLabel(props) {
  return (
    <Stack sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop:1 }}>
      <Box sx={{ minWidth: "30%" }}>
        <Typography variant="body2" color="text.secondary" style={{fontWeight: 'bold'}}>{props.actionLabel} {props.speed}</Typography>
      </Box>
      <Box sx={{ width: '98%'}}>
        <LinearProgress variant="determinate" value={props.value} style={{width: '100%'}}/>
      </Box>
    </Stack>
  );
}


function DownloadListItem(props) {
  const dispatch = useDispatch();

  /* const onOpenDir = async (downloadDir, fileName) => {
    var pathTmp = await join(downloadDir, fileName)
    console.log("DATA: ", pathTmp)
    const selected = await tauri.invoke('show_in_folder', {path: pathTmp});
    
  }; */

  return (
    <>
    <ListItem key={props.id} disablePadding className="downloadsItem" style={{height: '60px'}}>
      <Box style={{width: '100%'}}  ml={1}>
        {props.downloadDir?
        <Tooltip title={"Open the file location"} >
          <IconButton
            component="div"
            size="small"
            disabled={props.progress !==1}
            style={{position: 'absolute', right: 30, top: 5}}
              onClick={async ()=>{
                if (TARGET_PLATFORM !== 'web') {
                  await tauri.invoke('show_in_folder', {path: props.downloadDir})
                }
                
                //dispatch(SetFileFavorite(value.id))
              // onOpenDir(props.downloadDir, props.name)
              }}
              aria-label="unfavorite"
              id="unfavorite-btn"
            >
            <AiFillFolder size={15}/>
          </IconButton>
        </Tooltip>:null
        }
        {props.progress === 1?
        <Tooltip title={"Close notification"} >
          <IconButton
            size="small"
            style={{position: 'absolute', right: 5, top: 5}}
              onClick={()=>{
                if (props.actionName === "download") {
                  dispatch(DeleteDownloads(props.index))
                  props.onDeleteItem(props.index)
                } else {
                  props.onDeleteItem(props.index)
                  dispatch(DeleteUploads(props.index))
                }
                
              }}
              aria-label="unfavorite"
              id="unfavorite-btn"
            >
            <AiFillCloseCircle size={15}/>
          </IconButton>
          
        </Tooltip>:null}
        <Tooltip title={props.name} >
          <Typography style={{fontWeight: 'bold', marginTop: 5}}>
            {cutLongString(props.name, 25)}
          </Typography>
        </Tooltip>
        {props.progress === 1?
          <ListItemText primary={props.actionName==="download"?"Downloaded " + props.date:"Uploaded " + props.date} />:
          <LinearProgressWithLabel value={props.progress*100} speed={valueBytesToString(props.speed) + " / sec"} actionLabel={props.actionName==="download"?"Downloading... ":"Uploading... "}/>
        }
      </Box>
    </ListItem>
    <Divider />
    </>
  )
}

const DownloadListItemMemo = React.memo(DownloadListItem, (prevProps, nextProps) => {
  return prevProps.progress === nextProps.progress &&
  prevProps.id === nextProps.id &&
  prevProps.index === nextProps.index
})


function FileObjectList(props) {
  const [hasMore, setHashMore] = useState(true);
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)

  const compRef = useRef(null)
  compRef.current = {page, setPage, setHashMore, setItems}

  function loadMore() {
    if (props.objectList.length > page * 40) {
      setHashMore(true)
    } else {
      setHashMore(false)
    }
    setItems([...items,...props.objectList.slice((page - 1)* 40, page*40)])
    setPage(page+1)
    console.log("PG1: ", page, hasMore, props.objectList.length)
  }

  useEffect(()=>{
    setItems([...props.objectList.slice(0, 40)])
    setPage(2)
  }, [])

  function onDeleteItem(index) {
    setItems([...items.slice(0, index), ...items.slice(index+1, items.length)])
  }

  return (
    <Box
    role="tabpanel"
    style={{backgroundColor: "#EAF3FC"}}
    hidden={props.value !== props.tabName}
    sx={{width: 300, height: '100%'}}
    id={`tab-${props.tabName}`}
    >
      <Stack
        id={`ii-${props.tabName}`}
        direction="column"
        style={{backgroundColor: "#EAF3FC", height: '96%'}}
      >
        
      <List
        style={{width: '100%', height: '100%'}}
      >
        
        {items.map((obj, index)=>{
          return (<DownloadListItemMemo
            name={props.actionName==="download"?obj.fileName:obj.original_name}
            id={obj.id}
            date={props.actionName==="download"?obj.date:obj.created}
            progress={obj.progress}
            downloadDir={obj.directoryPath}
            key={index}
            index={index}
            speed={obj.speed}
            actionName={props.actionName}
            onDeleteItem={onDeleteItem}
          />)
        })}
      </List>
      <InfiniteScroll
        dataLength={items.length}
        next={loadMore}
        hasMore={hasMore}
        style={{width: '100%'}}
        scrollableTarget={"ii-1"}
        />
      
    </Stack>
    </Box>
  )
}


export function Downloads(props) {

  const downloadsList = useSelector(state => state.downloadsReducer.downloadsList)
  const uploadList = useSelector(state => state.uploadsReducer.uploadList)
  const [openDownloads, setOpenDownloads] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(()=>{
    setOpenDownloads(props.open)
    if (props.open && downloadsList.length === 0) {
      var downloadsObject = downloads.dataObject;
      for (var i = 0; i < downloadsObject.downloads.length; i++) {
        const obj = downloadsObject.downloads[i];
        dispatch(AddDownloads(obj.id, obj.date, obj.directoryPath, obj.name))
      }
    }
    if (props.open) {
      if (tabValue === 1) {
        dispatch(SetSeenUploads())
      } else {
        dispatch(SetSeenDownloads())
      }
    }
  }, [props.open])

  function onClose() {
    props.onClose()
    if (tabValue === 1) {
      dispatch(SetSeenUploads())
    } else {
      setOpenDownloads(false)
    }
  }


  function a11yProps(tabName) {
    return {
      id: `tab-${tabName}`,
      'aria-controls': `tab-${tabName}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      dispatch(SetSeenUploads())
    }
  };
  

  return (
    <div>
      <React.Fragment key={'left'}>
        <Drawer
          anchor={'left'}
          open={openDownloads}
          onClose={onClose}
        >
          <Box sx={{ position: 'fixed', bgcolor: 'background.paper', height: '100%' }}>
            <Tabs value={tabValue} onChange={handleChange} className="file-action-tabs" centered position="sticky">
              <Tab label="Downloads" {...a11yProps(0)} />
              <Tab label="Uploads" {...a11yProps(1)} />
            </Tabs>
            <FileObjectList objectList={downloadsList} value={tabValue} tabName={0} _setOpen={setOpenDownloads} actionName={"download"}/>
            <FileObjectList objectList={uploadList} value={tabValue} tabName={1} _setOpen={setOpenDownloads} actionName={"upload"}/>
          </Box>
        </Drawer>
      </React.Fragment>
  </div>
  )
}