import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddBreadCrumbFolder, ClearSelectedRows, HasMore, SetAlertData, SetCurrentFolderID, SetCurrentPgPage, SetFileList, UpdateFileInList } from "../../redux_store/actions";
import { API, AlertType, FILE_EXPLORER_PAGE, SHARES } from "../../utils/consts";
import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import { AlertData } from "../../objects/alert";
import { cutLongString, getApplicationControls } from "../../utils/helper";
import { ResolverParams, resolveObjectType } from "../applications/resolver";


function rename(objectID, userToken, newName) {
  return fetch(API+"/rename/"+objectID+'/', {
      method: 'POST',
      headers: {
        'AuthToken': userToken,
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify({name: newName})
  }).then(resp=>{
    return resp.json()
  })
}


export function InputWithLabel(props) {

  const [editValue, setEditValue] = useState("")
  const [editFocused, setEditFocused] = useState(false);
  const dispatch = useDispatch()
  const inpRef = useRef(null);
  var valueListObject = props.value;
  const navigate = useNavigate();

  useEffect(()=>{
    setEditValue(props.value.original_name)
    if (props.editName === true) {
      setTimeout(()=>{inpRef?.current?.focus()}, 200)
    } else {
      setEditFocused(false)
    }
  }, [props.editName, props.value.original_name])

  function performFileListAction(listObject, p) {
    dispatch(SetFileList([]))
    dispatch(HasMore(true))
    dispatch(ClearSelectedRows())
    dispatch(AddBreadCrumbFolder(listObject))
    dispatch(SetCurrentFolderID(listObject.id))
    dispatch(SetCurrentPgPage(2))
    var url = `${FILE_EXPLORER_PAGE}/${listObject.id}/?env=${props.env}`;
    if (props.p) {
      url += '?p='+props.p;
    }
    navigate(url)
  }

  if (props.editName) {
    if (valueListObject.path) {
      return (
        <Stack direction={"column"} style={{width: '100%'}} sx={{ cursor: 'pointer' }}>
          <TextField 
            inputRef = { inpRef }
            className="file-name-textinput"
            onMouseEnter={(e)=>{
              e.stopPropagation()
              e.preventDefault()
            }}
            onClick={(e)=>{
              e.stopPropagation()
              e.preventDefault()
              if(e.detail === 2){
                //dispatch(SetFileList([]));
              }
            }}
            onKeyDown={(event)=>{
              if (event.key === "Enter") {
                rename(valueListObject.id, props.userToken, editValue).then(data=>{
                  if (data.msg || data.detail) {
                    var alertData = new AlertData();
                    alertData.type = AlertType.ERROR;
                    alertData.message = alertData.message = data.msg || data.detail
                    dispatch(SetAlertData(alertData))
                    setEditValue(props.value.original_name)
                  } else {
                    const name = data.name;
                    valueListObject.original_name = name;
                    setEditValue(name);
                    dispatch(UpdateFileInList(valueListObject.parent_dir, valueListObject));
                    alertData = new AlertData();
                    alertData.type = AlertType.INFO;
                    alertData.message = `Renamed to "${name}"`
                    dispatch(SetAlertData(alertData))
                    props.setEditName(false)
                  }
                });
                
              }
            }}
            focused={editFocused}
            placeholder={valueListObject.original_name} 
            variant="standard" 
            value={editValue}
            onChange={(e)=>{setEditValue(e.target.value)}}
            onMouseLeave={(e)=>{
              props.setHoveredRow(1)
              var alertData = new AlertData();
              alertData.type = AlertType.ERROR;
              alertData.message = "The renaming was canceled."
              dispatch(SetAlertData(alertData))
            }
            }
          />
          <Typography style={{fontSize: 13, width: '100%', textAlign: 'left'}}>
            {cutLongString(valueListObject.path, 30)}
          </Typography>
        </Stack>
      ) 
    } else {
      return (
        <Stack direction={"column"} style={{width: '100%'}} sx={{ cursor: 'pointer' }}>
          <TextField 
            inputRef = { inpRef }
            className="file-name-textinput"
            onMouseEnter={(e)=>{
              e.stopPropagation()
              e.preventDefault()
            }}    
            onClick={(e)=>{
              e.stopPropagation()
              e.preventDefault()
              if(e.detail === 2){
                //dispatch(SetFileList([]));
              }
            }}
            onKeyDown={(event)=>{
              if (event.key === "Enter") {
                
                rename(valueListObject.id, props.userToken, editValue).then(data=>{
                  if (data.msg || data.detail) {
                    var alertData = new AlertData();
                    alertData.type = AlertType.ERROR;
                    alertData.message = alertData.message = data.msg || data.detail
                    dispatch(SetAlertData(alertData))
                    setEditValue(props.value.original_name)
                  } else {
                    const name = data.name;
                    valueListObject.original_name = name;
                    setEditValue(name);
                    dispatch(UpdateFileInList(valueListObject.parent_dir, valueListObject));
                    var alertData = new AlertData();
                    alertData.type = AlertType.INFO;
                    alertData.message = `Renamed to "${name}"`
                    dispatch(SetAlertData(alertData))
                    props.setEditName(false)
                  }
                });
                
              }
            }}
            focused={editFocused}
            placeholder={valueListObject.original_name} 
            variant="standard" 
            value={editValue}
            onChange={(e)=>{setEditValue(e.target.value)}}
            onMouseLeave={(e)=>{
              props.setHoveredRow(1)
              var alertData = new AlertData();
              alertData.type = AlertType.ERROR;
              alertData.message = "The renaming was canceled."
              dispatch(SetAlertData(alertData))
            }
            }
          />
        </Stack>
      ) 
    }
  } else {
    if (valueListObject.path) {
      return (
        <Stack direction={"column"} style={{width: '100%'}} sx={{ cursor: 'pointer' }}>
          <Tooltip title={props.value.original_name} placement="bottom-start">
            <Typography 
              sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
              }}
              onClick={()=>{
                if (props.value.object_type === 'folder') {
                  if (props.value.created === -1 && props.value.loadedStatus === 0) {
                    return
                  }
                  dispatch(SetFileList([]));
                  dispatch(ClearSelectedRows());
                  performFileListAction(valueListObject, props.p)
                } else {
                  if (props.value.id.length === 15) {
                    return 
                  }
                  var resolverParams = new ResolverParams();
                  resolverParams.objectID = props.value.external_id;
                  resolverParams.parentDir = props.value.parent_dir;
                  const resolverObject = resolveObjectType(props.applicationsControl, resolverParams, props.value.file_type);
                  if (resolverObject && resolverObject.url) {
                    var url = resolverObject.url+'?file_type='+props.value.file_type;
                    if (props.p) {
                      url += '&p='+props.p;
                    }
                    navigate(url)
                  } else {
                    var alertData = new AlertData();
                    alertData.type = AlertType.ERROR;
                    alertData.message = "Didn't find a suitable application to open this file."
                    dispatch(SetAlertData(alertData))
                  }
                }
              }}
                className="file-name-typo">
              {valueListObject.original_name}
            </Typography>
          </Tooltip>
          <Typography 
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
            }}
            style={{fontSize: 13, width: '100%', textAlign: 'left'}}>
            {valueListObject.path}
          </Typography>
        </Stack>
      )
    } else {
      return (
        <Stack style={{width: '100%'}} sx={{ cursor: 'pointer'}} flexDirection={"row"} >
          <Typography 
            onClick={(e)=>{
              if (props.value.object_type === 'folder') {
                if (props.value.created === -1 && props.value.loadedStatus === 0) {  // Blocks back folder click until all data wil be loaded
                  return
                }
                dispatch(SetFileList([]));
                dispatch(ClearSelectedRows());
                performFileListAction(valueListObject, props.p)
              } else if (props.value.object_type === 'file') {
                if (props.value.id.length === 15) {
                  return 
                }
                var resolverParams = new ResolverParams();
                resolverParams.objectID = props.value.id;
                resolverParams.parentDir = props.value.parent_dir;
                getApplicationControls().then(data => {
                  const resolverObject = resolveObjectType(data.applications_control, resolverParams, props.value.file_type);
                  if (resolverObject && resolverObject.url) {
                    var url = resolverObject.url+'?file_type='+props.value.file_type;
                    if (props.p) {
                      url += '&p='+props.p;
                    }
                    navigate(url)
                  } else {
                    var alertData = new AlertData();
                    alertData.type = AlertType.ERROR;
                    alertData.message = "Didn't find any suitable application to open this file."
                    dispatch(SetAlertData(alertData))
                  }
                })
                
              }
            }}
              className="file-name-typo">
            {valueListObject.original_name}
          </Typography>
        </Stack>
      )
    }
  }
}