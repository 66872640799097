import { ACTIVE_DOWNLOADS, ADD_ACTIVE_DOWNLOADS, ADD_DOWNLOADS, CLEAN_DOWNLOADED_OBJECT_PROGRESS, CLEAN_DOWNLOADS, DELETE_DOWNLOADS, REMOVE_ACTIVE_DOWNLOADS, SET_SEEN_DOWNLOADS, UPDATE_DOWNLOADS, UPDATE_DOWNLOADS_PROGRESS } from "../utils/consts"


const initialState = {
  downloadsList: [],
  filesForDownloadUpdate: {},
  activeDownloads: []
}

export default function downloadsReducer(state = initialState, action) {
  switch(action.type) {
    case ADD_ACTIVE_DOWNLOADS: {
      const objectID = action.payload;
      return {
        ...state,
        activeDownloads: [...state.activeDownloads, objectID]
      }
    }
    case REMOVE_ACTIVE_DOWNLOADS: {
      const objectID = action.payload;
      const index = state.activeDownloads.indexOf(objectID)
      return {
        ...state,
        activeDownloads: [...state.activeDownloads.slice(0, index), ...state.activeDownloads.slice(index+1, state.activeDownloads.length)]
      }
    }
    case SET_SEEN_DOWNLOADS: {
      var downloadsList = state.downloadsList;
      for (var i = 0; i < downloadsList.length; i++) {
        downloadsList[i].seen = true;
      }
      return {
        ...state,
        downloadsList: [...downloadsList]
      }
    }
    case ADD_DOWNLOADS: {
      const payload = action.payload;
      return {
        ...state,
        downloadsList:[payload, ...state.downloadsList]
      }
    }
    case CLEAN_DOWNLOADED_OBJECT_PROGRESS: {
      const objectID = action.payload;
      var downloadsList = state.downloadsList;
      var filesForDownloadUpdate = state.filesForDownloadUpdate;
      for (var i = 0;i < downloadsList.length; i++) {
        var obj = downloadsList[i];
        if (obj.id === objectID) {
          if (filesForDownloadUpdate[obj.id]) {
            filesForDownloadUpdate[obj.id]['progress'] = -1
          }
          return {
            ...state,
            filesForDownloadUpdate: {...filesForDownloadUpdate}
          }
        }
      }
      return {
        ...state
      }
    }
    case UPDATE_DOWNLOADS_PROGRESS: {
      const payload = action.payload;
      var downloadsList = state.downloadsList;
      var filesForDownloadUpdate = state.filesForDownloadUpdate;
      for (var i = 0;i < downloadsList.length; i++) {
        var obj = downloadsList[i];
        if (obj.id === payload.id) {
          downloadsList[i].progress = payload.progress
          downloadsList[i].speed = payload.speed
          downloadsList[i].directoryPath = payload.filePath
          downloadsList[i].fileName = payload.fileName
          filesForDownloadUpdate[obj.id] = {id: obj.id, progress: payload.progress, filePath: payload.filePath}
          return {
            ...state,
            downloadsList: [...downloadsList],
            filesForDownloadUpdate: {...filesForDownloadUpdate}
          }
        }
      }
      return {
        ...state,
      }
    }
    case DELETE_DOWNLOADS: {
      const index = action.payload;
      var downloadsList = state.downloadsList;
      downloadsList.splice(index, 1)
      return {
        ...state,
        downloadsList: [...downloadsList]
      }
    }
    case CLEAN_DOWNLOADS: {
      return {
        ...state,
        downloadsList: []
      }
    }
    default:
      return {
        ...state
      }
  }
}