import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { cutLongString, randomString } from "../../../utils/helper";
import { FILE_EXPLORER_PAGE } from "../../../utils/consts";
import { useNavigate } from "react-router-dom";
import { DeleteFileInList, DeleteNotification, OpenNotifications, SetFileList, SetSelectedAllFiles, SetUnsetSelectedRow } from "../../../redux_store/actions";
import { useDispatch } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";


export function RenameFile(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box style={{width: '100%'}}
      onClick={(e)=>{
        var selection = window.getSelection();
        if(selection.type !== "Range") {
          dispatch(SetFileList([]))
          navigate(`${FILE_EXPLORER_PAGE}/${props.sourceDirID}/?selected=${props.objectID}&t=${randomString(10)}`)
          dispatch(OpenNotifications(false))
        }
      }}
      className={"clickable-notification"}
    >
      <Stack direction={"column"} style={{width: '92%'}} padding={1}>
        <Stack direction={"row"}>
          <MdDriveFileRenameOutline size={24} style={{color: "#4051DA"}}/>
          <Typography style={{fontWeight: 'bold', textAlign: 'center', marginRight: 10, width: '89%', marginTop: 3}}>{props.date}</Typography>
          <Tooltip title={"Close notification"} >
            <IconButton
              size="small"
              style={{position: 'absolute', right: 10, marginTop:1}}
                onClick={(e)=>{
                  e.preventDefault();
                  e.stopPropagation()
                  dispatch(DeleteFileInList('notification', props.id))
                }}
                aria-label="unfavorite"
                id="unfavorite-btn"
              >
              <AiFillCloseCircle size={15}/>
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography style={{width: '100%'}}>
          User <span style={{fontWeight: 'bold'}}>{cutLongString(props.userEmail, 40)}</span> renamed {props.objectType} 
          <span style={{fontWeight: 'bold'}}> {cutLongString(props.oldName, 40)}</span> to <span style={{fontWeight: 'bold'}}>{cutLongString(props.newName, 40)}</span> in 
          <span style={{fontWeight: 'bold'}}> {cutLongString(props.source, 40)}</span> folder
        </Typography>
      </Stack>
    </Box>
  )
}