import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Button} from "@mui/material";
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, CardElement, PaymentElement, Elements} from '@stripe/react-stripe-js';
import { TARGET_PLATFORM } from 'src/utils/consts';


const appearance = {
  theme: 'stripe',

  variables: {
    colorPrimary: '#0570de',
    colorBackground: '#ffffff',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: 'Ideal Sans, system-ui, sans-serif',
    spacingUnit: '2px',
    borderRadius: '4px',
    // See all possible variables below
  }
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <label>
      Card details
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
};

function CheckoutFormInner(props) {
  const {secret: clientSecret} = props;
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const stripe = useStripe();
  const elements = useElements();

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }
  

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const {error} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'http://localhost:3000/file-explorer',
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button variant="contained" type="submit" disabled={!stripe || loading}>
        Submit Payment
      </Button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}


export function CheckoutForm(props) {
  const clientSecret = props.secret;
  const stripePromise = loadStripe('pk_test_51OuePo04oEKUf2umceN3Qf2hqULiRxulMGJKqZtj8o1ovs8lBvl1k6WoTmw2DWmvTWo2pK6nj2THyWHzI3fyPxWn00dihRIWZi');
  var options = {clientSecret, appearance}
  return <Elements options={options} stripe={stripePromise}>
    <CheckoutFormInner secret={props.secret} />
  </Elements>
}