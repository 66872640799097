import { Box, Button, Checkbox, Chip, Grid, IconButton, 
  TableSortLabel, Paper, Stack, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Tooltip, Typography,
  Menu, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import visuallyHidden from '@mui/utils/visuallyHidden';
import { lightStyle } from "../style/light_style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdRestore } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { RiDeleteBin2Line } from "react-icons/ri";
import { API, AlertType, DEFAULT_COLOR, FILE_EXPLORER_PAGE, HOVERED_COLOR, HOVERED_COLOR2, ITEM_HEIGHT, SELECTED_ROW_COLOR } from "../utils/consts";
import { FiArrowLeft } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { AddFileInBinList, AddFilesInList, ClearSelectedBinRows, ClearSelectedRows, DeleteBinObject, DeleteFileInList, HasMoreInBin, SelectUsingShift, SetActiveDeletedObjects, SetAlertData, SetBinFileList, SetBinItemsForAction, SetCurrentBinPgPage, SetFileList, SetListObjectPreviewSizeFormat, SetOrdering, SetSelectedItems, SetUnsetSelectedBinRow, SetUnsetSelectedRow, UpdatePreview } from "../redux_store/actions";
import { GetListObjectIcon, cutLongString, getFileBinIDS, getFileIDS, getLayoutType, getOrderedFileList, getPreviewList, reduxPreviewWrapper, saveLayoutType, valueBytesToString } from "../utils/helper";
import { AlertData } from "../objects/alert";
import { DEFAULT_BUTTON_BACKGROUND_COLOR, DEFAULT_LIGHT_TEXT_COLOR } from "src/style/global";
import { TopComponent } from "./base/top_component";
import { HiDotsVertical } from "react-icons/hi";
import { IoGridOutline } from "react-icons/io5";
import { PiListDashes, PiMinusLight } from "react-icons/pi";

const headCellsWide = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 150,
  },
  {
    id: 'deletedDate',
    align: 'center',
    disablePadding: false,
    label: 'Date deleted',
    width: 100,
  },
  {
    id: 'type',
    align: 'center',
    disablePadding: false,
    label: 'Type',
    width: 60
  },
  {
    id: 'size',
    align: 'center',
    disablePadding: false,
    label: 'Size',
    width: 60
  },
  {
    id: 'timeLeft',
    align: 'center',
    disablePadding: false,
    label: 'Time left',
    width: 100
  },
  {
    id: 'location',
    align: 'left',
    disablePadding: false,
    label: 'Location',
    width: 120
  }
];

const headCellsMediumWide = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 150,
  },
  {
    id: 'deletedDate',
    align: 'center',
    disablePadding: false,
    label: 'Date deleted',
    width: 100,
  },
  {
    id: 'timeLeft',
    align: 'center',
    disablePadding: false,
    label: 'Time left',
    width: 100
  },
  {
    id: 'location',
    align: 'left',
    disablePadding: false,
    label: 'Location',
    width: 120
  }
];

const headCellsMedium = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 150,
  },
  {
    id: 'deletedDate',
    align: 'center',
    disablePadding: false,
    label: 'Date deleted',
    width: 100,
  },
  {
    id: 'location',
    align: 'left',
    disablePadding: false,
    label: 'Location',
    width: 120
  }
];

const headCellsShort = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 150,
  },
];

function getGridItemWidth(minItemWidth, currentWindowWidth) {
  var n = currentWindowWidth / minItemWidth;
  var n1 = Math.floor(n)
  if ((n % 1) > 0.8) {
    n1 = n1 + 1;
    n = n + 1;
  }
  var newSize = (currentWindowWidth - (n1 + 1) * 8) / n1 + 1;
  if (newSize> 410) {
    return 400
  }
  return newSize
}


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, 
    onRequestSort, headCells, userInfo } =
    props;
  const dispatch = useDispatch();
  const createSortHandler = (property) => (event) => {
    console.log("BIN: ", property)
    dispatch(SetOrdering(property, order))
    onRequestSort(event, property);
  };
  

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === 'name') {
            return <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{width: headCell?.width}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{fontSize: 17, fontWeight: 700, fontFamily: "Lato", textAlign: 'center', width:'100%'}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          } else if (headCell.id === 'location') {
            return <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={'normal'}
            style={{width: headCell?.width, fontWeight: 'bold', fontSize: 16}}
          >
              {headCell.label}
            </TableCell>
          } else {
            return <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{width: headCell?.width}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{fontSize: 17, fontWeight: 700, fontFamily: "Lato", textAlign: 'center', winth:'100%', paddingLeft: 25}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          }
        })}
        {userInfo?.userToken ?
        <TableCell 
          style={{width: 90}}
          padding="checkbox"
          >
          <Stack style={{width: '100%'}} alignItems={'flex-end'}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </Stack>
        </TableCell> : null}
      </TableRow>
    </TableHead>
  );
}

function deleteAllObjectRequest(userToken) {
  var headers = {
    'AuthToken': userToken,
    'Content-Type': 'application/json'
  }
  return fetch(
    API + '/delete-all-bin-objects/',{
      method: 'POST',
      headers: headers,
    },
  ).then(resp => {
    return resp.json()
  }).then(jsonData => {
    return jsonData
  })
}

function restoreRequest(userToken, objectIDs) {
  var headers = {
    'AuthToken': userToken,
    'Content-Type': 'application/json'
  }
  return fetch(
    API + '/restore-bin-objects/',{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        bin_object_ids: objectIDs
      })
    },
  ).then(resp => {
    return resp.json()
  }).then(jsonData => {
    return jsonData
  })
}

function deleteRequest(userToken, objectIDs) {
  var headers = {
    'AuthToken': userToken,
    'Content-Type': 'application/json'
  }
  return fetch(
    API + '/delete-bin-objects/',{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        bin_object_ids: objectIDs
      })
    },
  ).then(resp => {
    return resp.json()
  }).then(jsonData => {
    return jsonData
  })
}


function getBinObjects(userToken, page, windowSize, selected) {
  var headers = {
    'AuthToken': userToken,
    'Content-Type': 'application/json'
  }
  var url = API + '/bin-objects/?page=' + page + '&window=' + windowSize + "&preview=small";
  if (selected) {
    url += '&selected='+selected
  }
  return fetch(
    url,
    { headers }
  ).then(resp => {
    return resp.json()
  })
}

function InputWithLabel(props) {

  var valueListObject = props.value;
  return (
    <Stack direction={"column"} style={{width: '100%'}} sx={{ cursor: 'pointer', height: 40 }} justifyContent={"center"}>
      <Tooltip title={props.value.original_name}>
        <Typography
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
        }}
          onClick={() => {
          }}>
          {valueListObject.original_name}
        </Typography>
      </Tooltip>
    </Stack>)
}

function SmartCheckbox(props) {
  const {hovered, selected, dispatch, id, parentID} = props;
  if ((hovered === 1 && selected) || selected) {
    return (
      <Checkbox
        size={'small'}
        color="primary"
        checked={true}
        style={{}}
        onClick={()=>{
          dispatch(SetUnsetSelectedRow(parentID, id))
        }}
      />
    )
  } else if (hovered === 1) {
    return (<Checkbox
      size={'small'}
      color="primary"
      checked={false}
      style={{}}
      onClick={()=>{
        dispatch(SetUnsetSelectedRow(parentID, id))
      }}
    />)
  } else {
    return null;
  }
}

const MemoSelectedRow = React.memo(SmartCheckbox, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected &&
  prevProps.id === nextProps.id &&
  prevProps.parentID === nextProps.parentID &&
  prevProps.hovered === nextProps.hovered
})


function RowSmartPanel(props) {

  const {shortPanel, onShortMenuClick} = props;

  if (props.hoveredRow === 0 && props.selectedRow) {
    return (
      <Stack direction="row" sx={{width: '100%'}}>

      </Stack>
    )
  } else if (props.hoveredRow === 1 && props.selectedRow) {
    if (shortPanel) {
      return (
        <Stack direction="row" sx={{width: '100%'}} alignItems={"end"} justifyContent={"end"}>
          <IconButton
            onClick={(e) => { onShortMenuClick(e) }}
          >
            <HiDotsVertical size={22} style={{width: '24px'}}/>
          </IconButton>
        </Stack>
      )
    } else {
      return (
        <Stack direction="row" sx={{width: '100%'}}>
          <Tooltip title={props.value.object_type === 'folder' ? "Restore folder" : "Restore file"}>
            <IconButton
              onClick={() => { props.restoreObject() }}
            >
              <MdRestore size={22} style={{ width: '24px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete forever">
            <IconButton
              onClick={() => {props.deleteObject()}}
            >
              <RiDeleteBin2Line size={22} style={{ width: '24px' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    }
    
  } else if (props.hoveredRow === 1 && !props.selectedRow) {
    if (shortPanel) {
      return (
        <Stack direction="row" sx={{width: '100%'}} alignItems={"end"} justifyContent={"end"}>
          <IconButton
            aria-label="share"
            id="share-btn"
            onClick={(e) => { onShortMenuClick(e) }}
          >
            <HiDotsVertical size={22} style={{width: '24px'}}/>
          </IconButton>
        </Stack>
      )
    } else {
      return (
        <Stack direction="row" sx={{width: '100%'}}>
          <Tooltip title={props.value.object_type === 'folder' ? "Restore folder" : "Restore file"}>
            <IconButton
              aria-label="share"
              id="share-btn"
              onClick={() => { props.restoreObject() }}
            >
              <MdRestore size={22} style={{ width: '24px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete forever">
            <IconButton
              onClick={() => {props.deleteObject()}}
            >
              <RiDeleteBin2Line size={22} style={{ width: '24px' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    }
    
  } 
   else return (
    <Stack direction="row" sx={{width: '100%'}}></Stack>
  );
}

function FileTableRow({ value, index, windowWidth, selectedRow, userToken, isItemSelected }) {

  const [background, setBackground] = useState(DEFAULT_COLOR);
  const [hoveredRow, setHoveredRow] = useState(0);
  const [anchorActions, setAnchorActions] = useState(false);
  const openActionsMenu = Boolean(anchorActions);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  function handleMouseEnter() {
    if (!selectedRow) {
      setBackground(HOVERED_COLOR)
    }
    setHoveredRow(1)
  }

  function handleMouseLeave() {
    if (!selectedRow) {
      setHoveredRow(0)
      setBackground(DEFAULT_COLOR)
    } else {
      setHoveredRow(0)
    }
  }
  useEffect(()=>{
    if (selectedRow)
      setBackground(SELECTED_ROW_COLOR)
    else
      setBackground(DEFAULT_COLOR)
  }, [selectedRow])

  function handleDoubleClickRow(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.shiftKey) {
      dispatch(SelectUsingShift('bin', value.id))
    } else if (e.ctrlKey ) {
      dispatch(SetUnsetSelectedRow('bin', value.id))
    }
  }

  function handleClick() {

  }

  function restoreObject() {
    restoreRequest(userToken, [value.id]).then(data=>{
      dispatch(DeleteFileInList('bin', value.id))
    })
  }

  

  function deleteObject() {
    deleteRequest(userToken, [value.id]).then(data=>{
      dispatch(DeleteFileInList('bin', value.id))
    })
  }

  function handleActionsMenu(id) {
    setAnchorActions(null)
  }

  return (
    <TableRow
      hover
      ref={tableRef}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={value.id}
      selected={isItemSelected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleDoubleClickRow}
      style={{userSelect: 'none'}}
    >
      <TableCell 
        scope="row"
        padding="none"
        >
        <GetListObjectIcon props={value} previewFormat="small" folderSize={35} />
      </TableCell>
      <TableCell scope="row" padding="none">
        <InputWithLabel
          value={value}
          userToken={userToken}
        />
      </TableCell>
      {windowWidth > 890 ? <TableCell align="center" sx={{ minWidth: '120px' }}><Typography>{value.deleted_date}</Typography></TableCell> : null }
      {windowWidth > 1200 ? <TableCell align="center" sx={{ width: '60px' }}>{value.file_type === null ? '' : value.file_type}</TableCell> : null}
      {windowWidth > 1200 ? <TableCell align="center" sx={{ width: '60px' }}>{value.object_type === "file" ? valueBytesToString(value.size) : <PiMinusLight size={27} />}</TableCell> : null}
      {windowWidth > 890 ? <TableCell align="center" sx={{ width: '100px' }}>{value.days_left}</TableCell> : null}
      {windowWidth > 900 ? <TableCell align="center" sx={{ width: 120 }}>
        <Stack direction="row" justifyContent="start">
          <Tooltip title={value.location}>
            <Chip label={cutLongString(value.location, 37)}>  
            </Chip>
          </Tooltip>
        </Stack>
        </TableCell> : null}
      <TableCell align="center" style={{ width: 120 }}>
        <Stack direction="row">
          <RowSmartPanel
            dispatch={dispatch}
            value={value}
            userToken={userToken}
            handleClick={handleClick}
            selectedRow={selectedRow}
            hoveredRow={hoveredRow}
            restoreObject={restoreObject}
            deleteObject={deleteObject}
            shortPanel={windowWidth < 1090}
            onShortMenuClick={(e) => setAnchorActions(e.currentTarget)}
          />
          <Stack direction={"row"} style={{flex: 1}} alignItems={"end"}>
            <MemoSelectedRow 
              key={index}
              selected={isItemSelected} 
              hovered={hoveredRow} 
              dispatch={dispatch} 
              id={value.id}
              parentID={'bin'}
            />
          </Stack> 
        </Stack>
      </TableCell>
      <Menu
        id="bin-item-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorActions}
        open={openActionsMenu}
        onClose={() => {
          setAnchorActions(null);
          setHoveredRow(0);
        }}
        className="account-menu"
        anchorOrigin={{
          vertical: 0,
          horizontal: 10,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: -2, marginLeft: 20 }}
        paper={{
          style: {
            maxHeight: ITEM_HEIGHT * 4,
            width: '50ch',
          },
        }}
      >
        <MenuItem key={0} onClick={() => {
          restoreObject();
          setAnchorActions(null);
          }} style={{ width: 180 }}>
          <Stack direction="row" spacing={1} style={{ width: '100%' }}>
            <Grid item xs={3}><MdRestore size={25} /></Grid>
            <Grid item xs={7}>{value.object_type === 'folder' ? "Restore folder" : "Restore file"}</Grid>
          </Stack>
        </MenuItem>
        <MenuItem key={1} onClick={() => {
          deleteObject();
          setAnchorActions(null);
          }} style={{ width: 180 }}>
          <Stack direction="row" spacing={1} style={{ width: '100%' }}>
            <Grid item xs={3}><RiDeleteBin2Line size={25} /></Grid>
            <Grid item xs={7}>Delete forever</Grid>
          </Stack>
        </MenuItem>
      </Menu>
    </TableRow>
  )
}


const MemoFileTableRow = React.memo(FileTableRow, (prevProps, nextProps) => {
  return prevProps.value.id === nextProps.value.id &&
    prevProps.value.size === nextProps.value.size &&
    prevProps.windowWidth === nextProps.windowWidth &&
    prevProps.isItemSelected === nextProps.isItemSelected &&
    prevProps.userToken === nextProps.userToken &&
    prevProps.value.small_preview === undefined &&
    prevProps.value.medium_preview === undefined
});

function renderRow(value, index, windowWidth, selectedRow, userToken) {
  return (
    <MemoFileTableRow
      key={index}
      value={value}
      index={index}
      windowWidth={windowWidth}
      isItemSelected={selectedRow}
      userToken={userToken}
    />
  );
}

function BinGridItem(props) {
  const {isItemSelected, gridWidth, item, userInfo} = props;
  const [anchorActions, setAnchorActions] = useState(false);
  const openActionsMenu = Boolean(anchorActions);
  const [bgColor, setBgColor] = useState('#d2dae2');
  const dispatch = useDispatch();

  function restoreObject() {
    restoreRequest(userInfo?.userToken, [item.id]).then(data=>{
      dispatch(DeleteFileInList('bin', item.id))
    })
  }

  function deleteObject() {
    deleteRequest(userInfo?.userToken, [item.id]).then(data=>{
      dispatch(DeleteFileInList('bin', item.id))
    })
  }

  useEffect(() => {
    if (isItemSelected) {
      setBgColor('#75ace4')
    } else {
      setBgColor('#d2dae2')
    }
  }, [isItemSelected])

  return (
    <Stack 
      padding={1}
      onMouseEnter={() => {
        if (!isItemSelected) {
          setBgColor(HOVERED_COLOR2)
        }
      }}
      onMouseLeave={() => {
        if (!isItemSelected) {
          setBgColor('#d2dae2')
        }
      }}
      style={{
        background: bgColor, 
        width: gridWidth, 
        height: 260, 
        display: 'flex', 
        borderRadius: 10,
        userSelect: 'none',
      }}
      onClick={(e) => {
        if (e.shiftKey) {
          dispatch(SelectUsingShift('bin', item.id))
        } else if (e.ctrlKey ) {
          dispatch(SetUnsetSelectedRow('bin', item.id))
        }
      }}
    >
      <Stack 
        style={{height: 50, background: bgColor, width: '100%', cursor: 'pointer'}} 
        direction={"row"}
        onClick={(e) => {
        }}
        >
        <Grid container>
          <Grid item xs={10}>
            <Stack 
              direction={"row"} 
              justifyContent={"start"} 
              alignItems={"start"} 
              style={{height: '100%', width: '100%'}}>
              <Tooltip title={item.original_name} placement="bottom-start">
                <Typography 
                  pt={1}
                  style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis',
                  fontSize: 16,
                  width: '100%', 
                  textAlign: 'left',
                }}>{item?.original_name}
                </Typography>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-label="more-btn"
              id="row-menu-btn"
              aria-controls={props.open ? 'row-menu-btn' : undefined}
              aria-expanded={props.open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={(e)=>{
                e.stopPropagation();
                setAnchorActions(e.currentTarget)
              }}
              >
              <HiDotsVertical size={20}/>
            </IconButton>
          </Grid>
        </Grid>
      </Stack>
      <Stack 
        style={{flex: 1, background: bgColor, width: '100%'}} 
        justifyContent={"center"} 
        alignItems={"center"}
      >
        <GetListObjectIcon 
          props={item} 
          iconSize={150} 
          folderSize={200}
          imageSize={gridWidth} 
          previewFormat={'medium'} />
      </Stack>
      <Menu
        id="bin-item-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorActions}
        open={openActionsMenu}
        onClose={() => {
          setAnchorActions(null);
        }}
        className="account-menu"
        anchorOrigin={{
          vertical: 0,
          horizontal: 10,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: -2, marginLeft: 20 }}
        paper={{
          style: {
            maxHeight: ITEM_HEIGHT * 4,
            width: '50ch',
          },
        }}
      >
        <MenuItem key={0} onClick={() => {
          restoreObject();
          setAnchorActions(null);
          }} style={{ width: 180 }}>
          <Stack direction="row" spacing={1} style={{ width: '100%' }}>
            <Grid item xs={3}><MdRestore size={25} /></Grid>
            <Grid item xs={7}>{item.object_type === 'folder' ? "Restore folder" : "Restore file"}</Grid>
          </Stack>
        </MenuItem>
        <MenuItem key={1} onClick={() => {
          deleteObject();
          setAnchorActions(null);
          }} style={{ width: 180 }}>
          <Stack direction="row" spacing={1} style={{ width: '100%' }}>
            <Grid item xs={3}><RiDeleteBin2Line size={25} /></Grid>
            <Grid item xs={7}>Delete forever</Grid>
          </Stack>
        </MenuItem>
      </Menu>
    </Stack>
  )
}

function BinGridLayout(props) {
  const [gridWidth, setGridWidth] = useState(300);
  const ref = useRef()

  const {fileStruct, userInfo,  isSelected} = props;
  
    useEffect(() => {
      const val = getGridItemWidth(300, ref.current?.offsetWidth);
      setGridWidth(val)
      window.addEventListener("resize", () => {
        const val = getGridItemWidth(300, ref.current?.offsetWidth);
        setGridWidth(val)
      });
    }, [])
  return (
    <Stack 
      ref={ref}
      style={{width: '100%'}} 
      direction={"row"} 
      spacing={1}
      useFlexGap 
      flexWrap="wrap">
        {fileStruct['bin']?.fileList?.map((item, index) => {
          const isItemSelected = isSelected(item.id);
          return <BinGridItem 
            item={item} 
            key={index}
            gridWidth={gridWidth}
            userInfo={userInfo}
            isItemSelected={isItemSelected}
            />
        })}
    </Stack>
  )
}

function BinListLayout(props) {
  const {userInfo, selected, order, orderBy, handleSelectAllClick, selectedRows, windowWidth,
    handleRequestSort, fileStruct, headCells} = props;
  const scrollReturnRef = useRef();
  return (
    <TableContainer style={{ overflowX: "initial" }} ref={scrollReturnRef}>
      <Table 
        sx={{ minWidth: 200, height: '100%', tableLayout: 'fixed' }}
        aria-label="sticky table"
        stickyHeader>
        <EnhancedTableHead
          userInfo={userInfo}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={fileStruct['bin']?.fileList?.length}
          headCells={headCells}
        />
        <TableBody>
          {getOrderedFileList(fileStruct, 'bin')?.map((value, index) => {
            const pos = selectedRows.indexOf(value.id)
            return renderRow(value, index, windowWidth, pos !== -1, userInfo.userToken)
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}


export function Bin(props) {
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  const fileStruct = useSelector(state => state.fileListReducer.fileStruct);
  const selectedAllFiles = useSelector(state => state.fileListReducer.selectedAllFiles)
  const selectedRows = useSelector(state => state.fileListReducer.selectedRows)
  const hasMore = useSelector(state => state.binReducer.hasMore)
  const [layoutType, setLayoutType] = useState(getLayoutType() ? getLayoutType() : 'list')
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const [selected, setSelected] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('calories');
  const [order, setOrder] = React.useState('asc');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pgPage, setPgPage] = useState(1)
  const [headCells, setHeadCells] = useState([])

  let query = useQuery();
  let t = query.get("t")
  let { id } = useParams();
  const navigate = useNavigate();
  const scroller = useRef();
  const localRef = useRef();
  localRef.current = {fileStruct}
  const dispatch = useDispatch();
  const windowSize = 40;

  useEffect(() => {
    setSelected([...selectedRows])
    console.log("SELECTED: ", selectedRows)
  }, [selectedRows])


  function windowChangeWidth() {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 900 && window.innerWidth < 1200) {
      if (userInfo?.userToken) {
        setHeadCells(headCellsMediumWide);
      }
    } else if (window.innerWidth > 890 && window.innerWidth < 900) {
      if (userInfo?.userToken) {
        setHeadCells(headCellsMedium);
      }
    } else if (window.innerWidth < 890) {
      setHeadCells(headCellsShort);
    } else {
      if (userInfo?.userToken) {
        setHeadCells(headCellsWide);
      }
    }
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 900 && window.innerWidth < 1200) {
      if (userInfo?.userToken) {
        setHeadCells(headCellsMediumWide);
      }
    } else if (window.innerWidth > 890 && window.innerWidth < 900) {
      if (userInfo?.userToken) {
        setHeadCells(headCellsMedium);
      }
    } else if (window.innerWidth < 890) {
      setHeadCells(headCellsShort);
    } else {
      if (userInfo?.userToken) {
        setHeadCells(headCellsWide);
      }
    }
    var listener = window.addEventListener("resize", windowChangeWidth);
    dispatch(ClearSelectedRows())
  }, [])

  function restoreSelected() {
    restoreRequest(userInfo.userToken, selectedRows).then(data=>{
      selectedRows.map((item, index)=>{
        dispatch(DeleteBinObject(item))
      })
      dispatch(ClearSelectedRows())
    })
  }

  function deleteSelected() {
    deleteRequest(userInfo.userToken, selectedRows).then(data=>{
      selectedRows.map((item, index)=>{
        dispatch(DeleteBinObject(item))
      })
      dispatch(ClearSelectedRows())
    })
  }

  function loadMore(first) {
    const selected = query.get("selected");
    if (first) {
      dispatch(SetCurrentBinPgPage(1))
    }
    if (userInfo) {
      getBinObjects(userInfo.userToken, localRef.current.fileStruct['bin'].page, 40, selected).then(data => {
        if (data.msg || data.detail) {
          var alertData = new AlertData();
          alertData.type = AlertType.ERROR;
          alertData.message = data.msg || data.detail;
          dispatch(SetAlertData(alertData))
        } else if (data.files.constructor.name === "Array") {
          if (selected) {
            const splitedSelected = selected.split(',');
            var newOrder = [];
            var notSelected = []
            for (var i = 0; i < data.files.length; i++) {
              const _f = data.files[i];
              if (splitedSelected.indexOf(_f.object_id) !== -1) {
                newOrder.push(_f)
              } else {
                notSelected.push(_f)
              }
            }
            var newItems = newOrder.concat(notSelected)
            dispatch(AddFilesInList('bin', newItems, true));
            dispatch(ClearSelectedRows())
          } else {
            dispatch(AddFilesInList('bin', data.files, true));
          }
          
          dispatch(HasMoreInBin(data.has_more))
          const fileIDS = getFileBinIDS(data.files);
          if (fileIDS.length > 0) {
            const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
            reduxPreviewWrapper(fileIDS, 'bin', previewFormat, userInfo.userToken, dispatch, true)
          }
        }
      })
      setPgPage(pgPage+1)
      //dispatch(SetCurrentBinPgPage(currentPgPage + 1))
    }
  }

  useEffect(()=>{
    dispatch(SetCurrentBinPgPage(1))
  }, [])

  useEffect(() => {
    const selected = query.get("selected");
    dispatch(SetActiveDeletedObjects(-1))
    getBinObjects(userInfo.userToken, 1, 40, selected).then(data => {
      if (data.msg || data.detail) {
        var alertData = new AlertData();
        alertData.type = AlertType.ERROR;
        alertData.message = data.msg || data.detail;
        dispatch(SetAlertData(alertData))
      } else if (data.files.constructor.name === "Array") {
        if (selected) {
          const splitedSelected = selected.split(',');
          var newOrder = [];
          var notSelected = []
          for (var i = 0; i < data.files.length; i++) {
            const _f = data.files[i];
            if (splitedSelected.indexOf(_f.object_id) !== -1) {
              newOrder.push(_f)
            } else {
              notSelected.push(_f)
            }
          }
          var newItems = newOrder.concat(notSelected)
          dispatch(SetFileList(newItems, 'bin', windowSize, true));
          dispatch(ClearSelectedRows())
        } else {
          dispatch(SetFileList(data.files, 'bin', windowSize, true));
        }
        
        dispatch(HasMoreInBin(data.has_more))
        const fileIDS = getFileBinIDS(data.files);
        if (fileIDS.length > 0) {
          const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
          reduxPreviewWrapper(fileIDS, 'bin', previewFormat, userInfo.userToken, dispatch, true)
        }
      }
    })
    window.addEventListener("resize", windowChangeWidth);
  }, [id, t])

  function deleteAllObject() {
    deleteAllObjectRequest(userInfo.userToken).then(data=>{
      dispatch(ClearSelectedRows())
      dispatch(SetCurrentBinPgPage(1))
      dispatch(SetFileList([], 'bin', windowSize, false))
      dispatch(HasMoreInBin(true))
      if (data.msg) {
        var alertData = new AlertData();
        alertData.type = AlertType.ERROR;
        alertData.message = data.msg || data.detail;
        dispatch(SetAlertData(alertData))
      }
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (selectedRows.length > 1) {
      dispatch(ClearSelectedRows())
      return;
    }
    var ids = [];
    var fileList = fileStruct['bin']?.fileList;
    for (var i = 0; i < fileList.length; i++) {
      ids.push(fileList[i].id);
    }
    dispatch(SetSelectedItems(ids));
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;
  

  return (
    <TopComponent>
      <Box
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        style={lightStyle.mainBackgroud}
      >
        <Box style={{width: '100%', height: '100%'}}>
          <Grid container padding={1} style={{height: 70}}>
            <Grid item xs={1} sm={1} md={1} lg={4} xl={4} alignItems={"flex-start"} justifyContent={"center"} padding={1}>
              <Stack style={{width: '100%', height: '100%'}} direction={"row"} justifyContent={"start"} alignItems={"start"}>
                <Tooltip title="Go back to files" >
                  <IconButton
                    aria-label="more"
                    onClick={() => {
                      if (backUrl) {
                        navigate(backUrl);
                        dispatch(SetBinFileList([]))
                      } else {
                        navigate(`${FILE_EXPLORER_PAGE}/${userInfo?.root}/`)
                      }
                    }}
                    style={{backgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR}}
                    id="shares-btn">
                      <FiArrowLeft size={22} color={DEFAULT_LIGHT_TEXT_COLOR}/>
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={1} sm={3} md={4} lg={4} xl={4}>
              <Stack direction="row" style={{ width: '100%'}} pt={1.5} justifyContent={"center"} alignItems={"center"}>
                {windowWidth < 690 ? null : <Chip label="Files and folders will be deleted after 7 days"/>}
              </Stack>
            </Grid>
            <Grid item xs={10} sm={8} md={7} lg={4} xl={4}>
              <Stack direction="row" style={{width: '100%'}} justifyContent={"end"} alignItems={"end"} spacing={1}>
                
                {windowWidth < 490 && selectedRows.length !== 0 ? null : 
                <Stack style={{width: 'auto'}} justifyContent={"end"} alignItems={"end"} direction="row" pt={1.4} pr={3.5}>
                  {fileStruct['bin']?.fileList.length === 0?
                  <Button
                    variant="contained"
                    className="create-btn"
                    onClick={deleteAllObject}
                    disabled={fileStruct['bin']?.fileList.length === 0}>
                    Empty bin
                  </Button>:
                  <Tooltip title="Delete all files and folders in the bin forever" >
                    <Button
                      variant="contained"
                      className="create-btn"
                      onClick={deleteAllObject}>
                      Empty bin
                    </Button>
                  </Tooltip>
                  }
                </Stack>}
              </Stack>
            </Grid>
          </Grid>
          <Stack 
            direction={"row"} 
            justifyContent={"end"} 
            alignItems={"end"} style={{height: 30, marginBottom: 10, marginTop: 5}} pr={3.7}>
          {selectedAllFiles || selectedRows.length > 0?
            <Stack flexDirection={"row"} alignItems={"end"} justifyContent={"end"}  style={{flex: 1}}>
              <Typography style={{fontWeight: 'bold', fontSize: 17, height: '100%'}} mb={0.8} mr={1}>
                Selected: {selectedRows.length}
              </Typography>
              <Tooltip title="Cancel selection" >
                <IconButton
                  aria-label="more"
                  onClick={()=>{dispatch(ClearSelectedRows())}}
                  id="notification-btn">
                  <CgClose size={23} color="black"/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Restore" >
                <IconButton
                  aria-label="more"
                  id="notification-btn"
                  onClick={restoreSelected}
                  >
                  <MdRestore size={23} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete forever" >
                <IconButton
                  aria-label="more"
                  id="notification-btn"
                  onClick={deleteSelected}
                  >
                  <RiDeleteBin2Line size={23} />
                </IconButton>
              </Tooltip>
            </Stack> : <Stack> 
            <IconButton
              aria-label="more"
              style={{marginBottom: -1}}
              onClick={() => { 
                if (layoutType === 'list') {
                  setLayoutType('grid');
                  const fileIDS = getFileBinIDS(fileStruct['bin']?.fileList);
                  reduxPreviewWrapper(fileIDS, 'bin', 'medium', userInfo.userToken, dispatch, true)
                  saveLayoutType("grid")
                } else {
                  const fileIDS = getFileBinIDS(fileStruct['bin']?.fileList);
                  reduxPreviewWrapper(fileIDS, 'bin', 'small', userInfo.userToken, dispatch, true)
                  setLayoutType('list');
                  saveLayoutType("list");
                }
              }}
              id="grid-btn">
                {layoutType === 'grid' ? <PiListDashes size={23} color="black"/> : 
                <IoGridOutline size={23} color="black"/>}
            </IconButton>
            </Stack> }
          </Stack>
          <Box sm={12} paddingLeft={2} paddingRight={2} className="table-container-bin" id="scrollableDivBin" ref={scroller}>
          {layoutType === 'list' ? <BinListLayout 
              userInfo={userInfo}
              selected={selected}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              selectedRows={selectedRows}
              windowWidth={windowWidth}
              handleRequestSort={handleRequestSort}
              fileStruct={fileStruct}
              headCells={headCells}
            /> :
            <BinGridLayout 
              isSelected={isSelected}
              fileStruct={fileStruct}
              userInfo={userInfo}
              fsKey={'bin'}
            />}
          </Box>
          <InfiniteScroll
            dataLength={fileStruct['bin']?.fileList ? fileStruct['bin']?.fileList.length : 0}
            next={loadMore}
            hasMore={hasMore}
            style={{ zIndex: 999, width: '100%' }}
            scrollableTarget="scrollableDivBin"
          />
        </Box>
      </Box>
    </TopComponent>
  )
}