import React, { useCallback, useEffect, useRef, useState } from 'react'

import logo from './logo.svg';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { AuthProvider, useAuth } from "react-oidc-context";
import './App.css';
import { Home } from './components/home';
import { FileExplorer } from './components/file_explorer';
import { AlertType, API, APPLOOKUP, AUDIO_VIEW_APP, BIN_PAGE, CHECKOUT, DOC_VIEW_APP, EXCEL_VIEW_APP, FILE_EXPLORER_PAGE, FS_EVENT_SYNC_ENDED, GOOGLE_CLIENT_ID, HOME_PAGE, IMAGE_VIEW_APP, JARFLOW_ACCESS_CHANGE, JARFLOW_ACCESS_REMOVE, JARFLOW_COPY, JARFLOW_CREATE, JARFLOW_DELETE, JARFLOW_DOWNLOAD_ARCHIVE, JARFLOW_FAVORITES, JARFLOW_MOVE, JARFLOW_RENAME, JARFLOW_SHARE, JARFLOW_UPDATE, JARFLOW_UPDATE_PREVIEW, LOGOUT, MAX_CURRENT_DOWNLOADS, MAX_CURRENT_UPLOADS, ObjectType, PDF_VIEW_APP, PLANS_PAGE, REDIRECT_RUL, REDIRECT_URL, SEARCH_FILELIST, SETTINGS_PAGE, SHARES, TARGET_PLATFORM, VIDEO_VIEW_APP } from './utils/consts';
import { Provider, useDispatch, useSelector } from 'react-redux'
import { _store } from './redux_store/store'
import { Settings } from './components/settings';
import config from './managers/config'
import { Plans } from './components/plans';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AddActiveDirectoryUpload, AddActiveDownloads, AddActiveUploads, AddCancelToken, AddFileInList, AddFilesInList, AddFolderInList, AddForceObjectInList, AddNotification, AddPendingPreview, AddTmpUploadFileObject, AddUploads, AddUploadStructure, CleanTmpPathsFromRust, CleanTmpUploadFileObject, DeleteFileInList, DeleteFileObject, DeletePendingPreview, IntremenetNewContent, LogOut, RemoveActiveDirectoryUpload, RemoveActiveDownloads, RemoveActiveUploads, RemoveCancelToken, RemoveOldFileStruct, RemoveTmpUploadFileObject, RemoveUploadEntity, ReplaceUploadedFile, SetAccessToken, SetAlertData, SetFileFavorites, SetListObjectPreviewSizeFormat, SetSyncedFolder, SetTmpPathsFromRust, SetUserInfo, UpdateDownloadsProgress, UpdateFileInList, UpdatePreview, UpdateUploadsProgress } from './redux_store/actions';
import axios from 'axios';
import { Bin } from './components/bin';
import { invoke } from '@tauri-apps/api';
import { covertDate, craeteUploadStructure, createFolders, downloadBrowserArchive, downloadSelected, extractUserSpaceUsage, extractUserTotalSpace, getEnv, getFileList, getJarFlowObject, getMainRootFromPath, getMainRootObject, getPreviewList, getRandomInt, getUserInfoFromProfileData, getUserSession, randomString, reduxPreviewWrapper } from './utils/helper';
import { RenameFile } from './components/base/notifications/rename';
import { AccessChange } from './components/base/notifications/access_change';
import { DeleteFile } from './components/base/notifications/delete_file';
import { ShareRemove } from './components/base/notifications/share_remove';
import { UserShare } from './components/base/notifications/user_share';
import { SearchFileExplorer } from './components/search_file_explorer';
import { ImageApp } from './components/applications/image_view/image_app';
import { VideoApp } from './components/applications/video_view/video_app';
import { PdfApp } from './components/applications/pdf_view/pdf_app';
import { AudioApp } from './components/applications/audio_view/audio_app';
import { ExcelApp } from './components/applications/excel_view/excel_app';
import { AlertData } from './objects/alert';
import { Logout } from './components/logout';
import { WebHome } from './components/web_home';
import { Login } from './components/login';
import { AppLookUp } from './components/app_look_up';
import { Purchase } from './components/purchase';
import { InitPage } from './components/init_page';
const tauriApi = require('./managers/tauri_imports');


const oidcConfig = {
  authority: "https://api-auth.jarflow.com/realms/jarflow-dev/",
  client_id: "account",
  redirect_uri: REDIRECT_URL,
  scope: 'openid profile email',
  automaticSilentRenew: true,
};
var routersList = [
  {
    path: "/authorized/",
    element: <Home />,
  },
  {
    path: HOME_PAGE,
    element: <Home />,
  },
  {
    path: FILE_EXPLORER_PAGE,
    element: <FileExplorer />,
  },
  {
    path: FILE_EXPLORER_PAGE+"/:id/",
    element: <FileExplorer />,
  },
  {
    path: SETTINGS_PAGE,
    element: <Settings />
  },
  {
    path: PLANS_PAGE,
    element: <Plans />
  },
  {
    path: PLANS_PAGE+"/:id/purchase/",
    element: <Purchase />
  },
  {
    path: BIN_PAGE,
    element: <Bin />
  },
  {
    path: LOGOUT,
    element: <Logout />
  },
  {
    path: SHARES+"/:id/",
    element: <FileExplorer sharePage={true} />
  },
  {
    path: SHARES,
    element: <FileExplorer sharePage={true} />
  },
  {
    path: SEARCH_FILELIST,
    element: <SearchFileExplorer />
  },
  {
    path: APPLOOKUP+"/:id",
    element: <AppLookUp />
  },
  {
    path: IMAGE_VIEW_APP + "/:fileObjectID/:parentDir/",
    element: <ImageApp />
  },
  {
    path: VIDEO_VIEW_APP + "/:fileObjectID/:parentDir/",
    element: <VideoApp />
  },
  {
    path: PDF_VIEW_APP + "/:fileObjectID/:parentDir/",
    element: <PdfApp />
  },
  {
    path: AUDIO_VIEW_APP + "/:fileObjectID/:parentDir/",
    element: <AudioApp />
  },
  {
    path: EXCEL_VIEW_APP + "/:fileObjectID/:parentDir/",
    element: <ExcelApp />
  },
  {
    path: DOC_VIEW_APP + "/:fileObjectID/:parentDir/",
    element: <PdfApp />
  }
]


if (TARGET_PLATFORM === 'web') {
  routersList.push({
    path: "/",
    element: <WebHome />,
  })
  routersList.push({
    path: "/login",
    element: <Login />,
  })
  routersList.push({
    path: "/register",
    element: <Login />,
  })
} else {
  routersList.push({
    path: "/",
    element: <InitPage />,
  })
  routersList.push({
    path: "/auth",
    element: <Home />,
  })
}

const router = createBrowserRouter(routersList);

function Updater(props) {
  const uploadList = useSelector(state => state.uploadsReducer.uploadList)
  const downloadsList = useSelector(state => state.downloadsReducer.downloadsList)
  const activeDownloads = useSelector(state => state.downloadsReducer.activeDownloads)
  const activeUploads = useSelector(state => state.uploadsReducer.activeUploads)
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const tmpFileObjects = useSelector(state => state.uploadsReducer.tmpFileObjects)
  const currentFolderID = useSelector(state => state.fileListReducer.currentFolderID)
  const pendingPreviews = useSelector(state => state.uploadsReducer.pendingPreviews)
  const syncedFolders = useSelector(state => state.fileListReducer.syncedFolders)
  const dispatch = useDispatch();
  const auth = useAuth();
  
  
  const ref = useRef(null);
  ref.current = {
    uploadList, activeUploads, userInfo, currentFolderID, downloadsList, activeDownloads,
    tmpFileObjects, pendingPreviews, syncedFolders
  }

  const addExpListener = useCallback(() => {
    auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent().then(user=>{
        if (user) {
          var _userinfo = ref.current.userInfo;
          _userinfo.userToken = user.access_token;
          const access_token = user?.access_token;
          const refresh_token = user?.refresh_token;
          var userSession = getUserSession();
          userSession.access_token = user.access_token;
          sessionStorage.setItem("profile", JSON.stringify(userSession));
          dispatch(SetUserInfo(_userinfo));
          dispatch(SetAccessToken(access_token, refresh_token))
          if (TARGET_PLATFORM !== "web") {
            tauriApi.invoke("set_auth_token", {
              authToken: access_token,
              rootId: _userinfo.root
            })
          }
        } else {
          auth.revokeTokens();
        }
      });
    })
  }, []);

  useEffect(()=>{
    addExpListener();
  }, [addExpListener])

  function uploadDroppedFile(file, folderID, fileID, mainRootID) {
    var formData = new FormData()
    formData.append('file', file)
    var uploadedParts = 0;
    const CancelToken = axios.CancelToken;
    var startUploading = Date.now() / 1000;
    return axios.post(API + '/upload-file/' + folderID + "/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'AuthToken': ref.current.userInfo.userToken
      },
      cancelToken: new CancelToken(function executor(c) {
        dispatch(AddCancelToken(fileID, c));
      }),
      onUploadProgress: data => {
        const currentType = Date.now() / 1000;
        var speed = 0;
        const timeDelta = currentType - startUploading;
        if (timeDelta > 1) {
          speed = data.loaded / timeDelta;
        }
        const tmpStep = data.loaded / data.total;
        if (tmpStep - uploadedParts >= 0.1) {
          uploadedParts = tmpStep
          dispatch(UpdateUploadsProgress(fileID, uploadedParts, speed))
        } else if (data.loaded === data.total) {
          uploadedParts = tmpStep
          dispatch(UpdateUploadsProgress(fileID, uploadedParts, speed))
        }
      },
    })
    .then(resp => {
      dispatch(RemoveUploadEntity(fileID, 'file', mainRootID));
      dispatch(RemoveActiveUploads(fileID));
      dispatch(RemoveCancelToken(fileID));
      return resp.data
    }).catch(err => { 
        if(axios.isCancel(err)) {
          console.log("CANCEL ERROR ", err)
        }
      }
    )
  }

  function downloadFolder(downloadPath, objectID, userToken) {
    downloadSelected([objectID], userToken, config.deviceID)
    /* return invoke("downloadFiles", 
      {
        downloadDir: downloadPath,
        tmpId: objectID,
        fileUrl: API+'/fileobjects/',
        fileObjectIds: [objectID],
        userToken: userToken,
        archiveName: 'jarflow-download'
    }) */
  }

  function downloadFileObject(downloadPath, objectID, fileName, userToken) {
    return tauriApi.invoke("downloadFile",
      {
        downloadDir: downloadPath,
        fileUrl: API + '/fileobject/' + objectID + "/?download=1",
        fileName: fileName,
        fileObjectId: objectID,
        userToken: userToken
      })
  }

  function startUploadData() {
    var uploadsCounter = ref.current.activeUploads.length;
    const _uploadList = ref.current.uploadList;
    
    for (var i = 0; i < _uploadList.length; i++) {
      if (!ref.current?.userInfo?.userToken) {
        window.location.reload();
      }
      if (uploadsCounter < MAX_CURRENT_UPLOADS && _uploadList.length > 0) {
        const uploadObject = _uploadList[i];
        const index = ref.current.activeUploads.indexOf(uploadObject.id)
        if (index === -1 && uploadObject.progress !== 1) {
          dispatch(AddFilesInList(uploadObject.parent_dir, [uploadObject]))
          dispatch(AddActiveUploads(uploadObject.id))
          dispatch(AddActiveDirectoryUpload(uploadObject.parent_dir, uploadObject.id))
          uploadsCounter++;
          dispatch(RemoveTmpUploadFileObject(uploadObject.id))
          uploadDroppedFile(uploadObject.file, uploadObject.parent_dir, uploadObject.id, uploadObject.main_root_id).then(jsonData => {
            if (jsonData && jsonData.id) {
              dispatch(ReplaceUploadedFile(uploadObject.parent_dir, uploadObject, jsonData))
              dispatch(RemoveActiveDirectoryUpload(jsonData.parent_dir, uploadObject.id))
            }
          })
        }
      }
    }
  }

  function startDownloadData() {
    var activeDownloadItems = ref.current.activeDownloads;
    const _downloadList = ref.current.downloadsList;
    var downloadPath = "";
    if (TARGET_PLATFORM === "windows") {
      downloadPath = config?.configObject?.syncPath + "\\Downloads";
    } else if (TARGET_PLATFORM === "macos" || TARGET_PLATFORM === "linux") {
      downloadPath = config?.configObject?.syncPath + "/Downloads";
    }
    for (var i = 0; i < _downloadList.length; i++) {
      if (ref.current.activeDownloads.length < MAX_CURRENT_DOWNLOADS && _downloadList.length > 0) {
        const downloadObject = _downloadList[i];
        const index = activeDownloadItems.indexOf(downloadObject.id)
        if (index === -1 && downloadObject.progress !== 1) {
          dispatch(AddActiveDownloads(downloadObject.id))
          if (downloadObject.objectType === 'folder') {
            downloadFolder(downloadPath, downloadObject.id, ref.current.userInfo.userToken)
          } else {
            downloadFileObject(downloadPath, downloadObject.id, downloadObject.name, ref.current.userInfo.userToken)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('profile') && (!userInfo || Object.keys(userInfo).length === 0)) {
      var data = JSON.parse(sessionStorage.getItem('profile'));
      const _userInfo = getUserInfoFromProfileData(data);
      dispatch(SetUserInfo(_userInfo));
    }
    var unlisten = null;
    var uploaderListener = null;
    if (TARGET_PLATFORM !== 'web') {
      unlisten = tauriApi.listen('fileDownload', (e) => {
        if (e.payload.fileObjectId) {
          var _activeDownloads = ref.current.activeDownloads;
          const index = _activeDownloads.indexOf(e.payload.fileObjectId)
          if (index !== -1 && e.payload.downloadedParts === 1) {
            dispatch(RemoveActiveDownloads(e.payload.fileObjectId))
          }
          dispatch(UpdateDownloadsProgress(e.payload.fileObjectId, e.payload.downloadedParts, e.payload.filePath, e.payload.speed, e.payload.fileName))
        }
      })
      unlisten = tauriApi.listen('backError', (e) => {
        if (e.payload) {
          var alertData = new AlertData();
          alertData.type = AlertType.ERROR;
          alertData.message = e.payload.msg;
          dispatch(SetAlertData(alertData))
          }
      })
      unlisten = tauriApi.listen('fs_events', (e) => {
        if (e.payload && e.payload.event_type === FS_EVENT_SYNC_ENDED) {
            tauriApi.invoke("watch_the_folder_windows", {
              folderPath: config.configObject.syncPath
            }).then(_ => {
              
            })
          }
      })
    }
    if (TARGET_PLATFORM !== 'web') {
      uploaderListener = tauriApi.listen('fileUploader', (e) => {
        switch(e.payload.action_type) {
          case "USER_NOTIFY": {
            dispatch(SetTmpPathsFromRust(e.payload.files, e.payload.folders))
            break;
          }
          case "CREATE_FOLDERS": {
            createFolders(ref.current.currentFolderID, e.payload.folders, ref.current.userInfo.userToken).then(data=>{
              var upload_structure = craeteUploadStructure(e.payload.files, data.created_folders, data.main_roots);
              for (var i = 0; i < data.root_folders.length; i++) {
                dispatch(AddFolderInList(data.root_folders[i]));
              }
              
              var tmp_main_root_object = getMainRootObject(data.main_roots);
              dispatch(AddUploadStructure(upload_structure))
              for (var i = 0; i < e.payload.files.length; i++) {
                const _file = e.payload.files[i];
                const main_root = getMainRootFromPath(_file.relative_path);
                const relative_path_parent_dir = _file.relative_path_parent_dir;
                var tmpObject = {
                  original_name: _file.name,
                  id: _file.id,
                  created: covertDate(new Date()),
                  updated: 0,
                  size: 0,
                  favorite: false,
                  object_type: 'file',
                  progress: 0.001,
                  upload: true,
                  parent_dir: data.created_folders[relative_path_parent_dir],
                  full_path: _file.full_path,
                  main_root_id: tmp_main_root_object[main_root]
                };
                dispatch(AddTmpUploadFileObject(tmpObject))
                dispatch(AddUploads(tmpObject))
              }
            })
            //dispatch(CleanTmpPathsFromRust())
            break;
          }
          case "ADD_TMP_FILE": {
            dispatch(AddFileInList(e.payload))
            dispatch(AddUploads(e.payload))
            break;
          }
          case "UPDATE_UPLOAD_STATUS": {
            dispatch(UpdateUploadsProgress(e.payload.id, e.payload.progress, e.payload.speed))
            break;
          }
          case "FINISH_UPLOAD": {
            dispatch(ReplaceUploadedFile(e.payload.current_object, e.payload.server_object))
            dispatch(RemoveActiveUploads(e.payload.current_object.id))
          }
          default: {
  
          }
        }
      })
    }
    
    function updateUserUsage(data) {
      var _userInfo = ref.current.userInfo;
      _userInfo.userUsedSpace = extractUserSpaceUsage(data);
      dispatch(SetUserInfo(_userInfo));
    }

    const eventUpdate = setInterval(() => {
      
      if (ref.current.userInfo && ref.current.userInfo.userToken && Object.keys(ref.current.pendingPreviews).length > 0) {
        var processedKeys = [];
        var grouppedByParent = {};
        for (var previewKey in ref.current.pendingPreviews) {
          var previewObject = ref.current.pendingPreviews[previewKey];
          processedKeys.push(previewKey);
          if (grouppedByParent[previewObject.parentID]) {
            grouppedByParent[previewObject.parentID].push(previewObject.objectID)
          } else {
            grouppedByParent[previewObject.parentID] = [previewObject.objectID]
          }
        }
        for (var parentKey in grouppedByParent) {
          var ids = grouppedByParent[parentKey];
          reduxPreviewWrapper(ids, parentKey, 'small', ref.current.userInfo.userToken, dispatch)
          reduxPreviewWrapper(ids, parentKey, 'medium', ref.current.userInfo.userToken, dispatch)
        }
        dispatch(DeletePendingPreview(processedKeys));
      }
      if (ref.current.userInfo && ref.current.userInfo.userToken && ref.current.currentFolderID) {
        fetch(API + '/notifications/',
        {
          headers: {
            'AuthToken': ref.current.userInfo.userToken,
          },
        }
        ).then(
          data=> {
            return data.json()
          }
        ).then(jsonData => {
          var notifications = jsonData.notifications;
          for (var i = 0; i < notifications?.length; i++) {
            const notification = notifications[i];
            var notificationComponent = null;
            switch(notification.event) {
              case JARFLOW_DOWNLOAD_ARCHIVE: {
                const status = JSON.parse(notification.status);
                const deviceID = notification.device_id;
                if (deviceID !== config.deviceID) {
                  continue
                }
                switch (status) {
                  case 1: {
                    var alertData = new AlertData();
                    alertData.type = AlertType.INFO;
                    alertData.message = notification.message;
                    dispatch(SetAlertData(alertData))
                    break;
                  }
                  case 0: {
                    var alertData = new AlertData();
                    alertData.type = AlertType.ERROR;
                    alertData.message = notification.message;
                    dispatch(SetAlertData(alertData))
                    break;
                  }
                  case 2: {
                    var alertData = new AlertData();
                    alertData.type = AlertType.INFO;
                    alertData.message = notification.message;
                    dispatch(SetAlertData(alertData));
                    const rndSuffix = randomString(4);
                    if (TARGET_PLATFORM === "linux" || TARGET_PLATFORM === "macos" || TARGET_PLATFORM === "windows") {
                      const tmpID = randomString(10);
                      
                      invoke("downloadFiles", 
                          {
                            downloadDir: config.configObject.downloadPath,
                            tmpId: tmpID,
                            fileUrl: API + '/download-archive/',
                            userToken: ref.current.userInfo.userToken,
                            archiveName: `jarflow-download-${rndSuffix}`
                      })
                    } else {
                      downloadBrowserArchive(ref.current.userInfo.userToken, `jarflow-download-${rndSuffix}`)
                      console.log("Download using browser downloader")
                    }
                    break;
                  }
                  default: {}
                }
                
                break;
              }
              case JARFLOW_RENAME: {
                const metaData = JSON.parse(notification.meta_data);
                notificationComponent = <RenameFile 
                  userEmail={metaData.src_user}
                  objectType={metaData.object_type}
                  oldName={metaData.old_name}
                  newName={metaData.new_name}
                  source={metaData.parent_dir}
                  date={metaData.created}
                  id={notification.external_id}
                  objectID={metaData.object_id}
                  sourceDirID={metaData.source_dir_id}
                  idNotif={notification.external_id}
                />
                var obj = {'id': notification.external_id, obj: notificationComponent}
                dispatch(AddForceObjectInList('notification', obj))
                break
              }
              case JARFLOW_ACCESS_CHANGE: {
                const metaData = JSON.parse(notification.meta_data);
                notificationComponent = <AccessChange
                srcUserEmail={metaData.src_user}
                srcFirstName={metaData.src_user_first_name}
                date={metaData.created}
                resource={metaData.resource}
                oldAccessName={metaData.old_access_name}
                newAccessName={metaData.new_access_name}
                sourceDirID={metaData.source_dir_id}
                objectID={metaData.object_id}
                objectType={metaData.object_type}
                idNotif={notification.external_id}
                id={notification.external_id}
                />
                var obj = {'id': notification.external_id, obj: notificationComponent}
                dispatch(AddForceObjectInList('notification', obj))
                break
              }
              case JARFLOW_DELETE: {
                const metaData = JSON.parse(notification.meta_data);
                notificationComponent = <DeleteFile
                srcUserEmail={metaData.src_user}
                date={metaData.created}
                location={metaData.location}
                objectID={metaData.object_id}
                objectType={metaData.object_type}
                name={metaData.name}
                idNotif={notification.external_id}
                id={notification.external_id}
                />
                var obj = {'id': notification.external_id, obj: notificationComponent}
                dispatch(AddForceObjectInList('notification', obj))
                break
              }
              case JARFLOW_ACCESS_REMOVE: {
                const metaData = JSON.parse(notification.meta_data);
                notificationComponent = <ShareRemove
                srcUserEmail={metaData.src_user}
                date={metaData.created}
                objectID={metaData.object_id}
                objectType={metaData.object_type}
                name={metaData.name}
                idNotif={notification.external_id}
                id={notification.external_id}
                />
                var obj = {'id': notification.external_id, obj: notificationComponent}
                dispatch(AddForceObjectInList('notification', obj))
                break
              }
              case JARFLOW_SHARE: {
                const metaData = JSON.parse(notification.meta_data);
                notificationComponent = <UserShare
                srcUserEmail={metaData.src_user}
                date={metaData.created}
                objectID={metaData.object_id}
                objectType={metaData.object_type}
                name={metaData.name}
                sourceDirID={metaData.source_dir_id}
                idNotif={notification.external_id}
                id={notification.external_id}
                />
                var obj = {'id': notification.external_id, obj: notificationComponent}
                dispatch(AddForceObjectInList('notification', obj))
                break
              }
              default: {
                break;
              }
            }
          }
          //console.log("NOTIFICATIONS", notifications)
        }).catch(err => {
          console.log("notify err")
        })
        fetch(API + '/event-update/?euids=' + ref.current.currentFolderID, {
          method: 'GET',
          headers: {
            'AuthToken': ref.current.userInfo.userToken,
            'Content-Type': 'application/json',
          }
        }).then(resp => {
          return resp.json()
        }).then(respJson => {
          if (respJson && respJson.constructor.name !== "Array") {
            console.log("ERROR: ", respJson)
            return;
          }
          updateUserUsage(respJson[0].usage)
          const folderUpdate = respJson[0].folder_update;
          if (!folderUpdate) {
            return
          }
          for (var i = 0; i < folderUpdate?.length; i++) {
            const updateObject = folderUpdate[i];
            switch (updateObject.event) {
              case JARFLOW_UPDATE_PREVIEW: {
                if (updateObject.meta_data) {
                  const meta = JSON.parse(updateObject.meta_data)
                  if (Object.keys(meta).length > 0) {
                    const objectID = meta.object_id;
                    var parentID = meta.parent_id;
                    if (!updateObject.my) {
                      parentID = `4_${parentID}`;
                    }
                    var previewObj = {};
                    previewObj[randomString(12)] = {
                      parentID: parentID,
                      objectID: objectID
                    }
                    dispatch(AddPendingPreview(previewObj));
                  }
                }
                break;
              }
              case JARFLOW_COPY: {
                if (updateObject.fs_object.id === ref.current.currentFolderID) {
                  getFileList(ref.current.currentFolderID, ref.current.userInfo.userToken, 1, 40).then(data=>{
                    if (data.files.constructor.name === "Array") {
                      var env = getEnv();
                      for (var j = 0; j < data.files.length; j++) {
                        const id = data[j].parent_dir;
                        var _fsKey = `${env}_${id}`;
                        if (env === 1 || !env) {
                          _fsKey = id;
                        }
                        dispatch(AddFileInList(_fsKey, data.files[j], true))
                      }
                    }
                  })
                }
                break;
              }
              case JARFLOW_UPDATE: {
                if (updateObject.meta_data) {
                  const meta = JSON.parse(updateObject.meta_data)
                  const id = meta.object.parent_dir;
                  for (var s = 1; s < 5; s++) {
                    var _fsKey = `${s}_${id}`;
                    if (s === 1) {
                      _fsKey = id;
                    }
                    dispatch(UpdateFileInList(_fsKey, meta.object));
                  }
                }
                break;
              }
              case JARFLOW_FAVORITES: {
                if (updateObject.meta_data) {
                  const meta = JSON.parse(updateObject.meta_data);
                  if (Object.keys(meta).length > 0) {
                    const parent_dir = meta.parent_dir;
                    const resources = meta.resources;
                    const favorite = meta.favorite;
                    var _fsKey = parent_dir;
                    if (!updateObject.my) {
                      _fsKey = `4_${parent_dir}`;
                    }
                    dispatch(SetFileFavorites(_fsKey, resources, favorite));
                  }
                }
                break;
              }
              case JARFLOW_CREATE: {
                if (updateObject.meta_data) {
                  const meta = JSON.parse(updateObject.meta_data)
                  if (Object.keys(meta).length > 0) {
                    var env = getEnv();
                    if (meta.object.object_type === 'folder') {
                      const id = meta.object.parent_dir;
                      var _fsKey = id;
                      if (!updateObject.my) {
                        _fsKey = `4_${id}`;
                      }
                      if (meta)
                          dispatch(AddFileInList(_fsKey, meta.object, true));
                      if (TARGET_PLATFORM !== "web") {
                        if (ref.current.syncedFolders[meta.object.parent_dir]) {
                          dispatch(SetSyncedFolder(meta.object.id, true))
                        }
                        tauriApi.invoke("create_folder", {
                          objectId: meta.object.id,
                          parentDir: meta.object.parent_dir,
                          folderName: meta.object.original_name
                        })
                      }
                    } else {
                      const id = meta.object.parent_dir;
                      var _fsKey = id;
                      if (!updateObject.my) {
                        _fsKey = `4_${id}`;
                      }
                      if (meta)
                          dispatch(AddFileInList(_fsKey, meta.object, true));
                      if (TARGET_PLATFORM !== "web") {
                        if (ref.current.syncedFolders[meta.object.parent_dir]) {
                          tauriApi.invoke("create_file_from_ui", {
                            objectId: meta.object.id,
                            parentDir: meta.object.parent_dir,
                            fileName: meta.object.original_name
                          })
                        }
                      }
                    }
                  }
                }
                break;
              }
              case JARFLOW_DELETE: {
                if (updateObject.meta_data) {
                  const meta = JSON.parse(updateObject.meta_data)
                  var env = getEnv();
                  if (Object.keys(meta).length > 0) {
                    const id = meta.parent_dir;
                    var _fsKey = `${env}_${id}`;
                    if (env === 1 || !env) {
                      _fsKey = id;
                    }
                    dispatch(DeleteFileInList(_fsKey, meta.object_id));
                    if (TARGET_PLATFORM !== "web") {
                      if (meta.object_id) {
                        tauriApi.invoke("delete_file_object", {
                          objectId: meta.object_id,
                        })
                      }
                    }
                  }
                }
                break;
              }
              case JARFLOW_ACCESS_REMOVE: {
                if (updateObject.meta_data) {
                  const meta = JSON.parse(updateObject.meta_data)
                  if (Object.keys(meta).length > 0) {
                    dispatch(IntremenetNewContent('share'));
                    var env = getEnv();
                    if (meta.object.parent_dir) {
                      const id = meta.object.parent_dir;
                      var _fsKey = `${env}_${id}`;
                      if (env === 1 || !env) {
                        _fsKey = id;
                      }
                      dispatch(DeleteFileInList(_fsKey, meta.object.object_id));
                    } else {
                      const id = ref.current.userInfo.root;
                      var _fsKey = `${env}_${id}`;
                      if (env === 1 || !env) {
                        _fsKey = id;
                      }
                      dispatch(DeleteFileInList(_fsKey, meta.object.object_id));
                    }
                    if (TARGET_PLATFORM !== "web") {
                      tauriApi.invoke("delete_file_object", {
                        objectId: meta.object.object_id,
                      })
                    }
                  }
                }
                break;
              }
              case JARFLOW_MOVE: {
                if (updateObject.meta_data) {
                  var env = getEnv();
                  const meta = JSON.parse(updateObject.meta_data)
                  const old_id = meta.old_parent_dir;
                  const new_id = meta.new_parent_dir;
                  var _fsKey_old = `${env}_${old_id}`;
                  var _fsKey_new = `${env}_${new_id}`;
                  if (env === 1 || !env) {
                    _fsKey_old = meta.old_parent_dir;
                  }
                  if (env === 1 || !env) {
                    _fsKey_new = meta.new_parent_dir;
                  }
                  dispatch(DeleteFileInList(_fsKey_old, meta.object_id));
                  dispatch(AddFileInList(_fsKey_new, meta.object, true));
                  if (TARGET_PLATFORM !== "web") {
                    tauriApi.invoke("move_file_object", {
                      srcObjectId: meta.object_id,
                      dstObjectId: meta.new_parent_dir,
                    })
                  }
                }
                break;
              }
              case JARFLOW_SHARE: {
                if (updateObject.meta_data) {
                  const meta = JSON.parse(updateObject.meta_data)
                  if (Object.keys(meta).length > 0) {
                    var id = meta.object.parent_dir;
                    if (updateObject['as_root_object']) {
                      id = ref.userInfo.root;
                    }
                    var _fsKey = `4_${id}`;
                    if (meta)
                        dispatch(AddFileInList(_fsKey, meta.object, true));
                    if (TARGET_PLATFORM !== "web") {
                      if (ref.current.syncedFolders[meta.object.parent_dir]) {
                        dispatch(SetSyncedFolder(meta.object.id, true))
                      }
                      tauriApi.invoke("create_folder_share", {
                        objectId: meta.object.id,
                        parentDir: meta.object.parent_dir,
                        folderName: meta.object.original_name
                      })
                    } else {
                      if (meta)
                          dispatch(AddFileInList(_fsKey, meta.object, true));
                      if (TARGET_PLATFORM !== "web") {
                        if (ref.current.syncedFolders[meta.object.parent_dir]) {
                          tauriApi.invoke("create_file_from_ui_share", {
                            objectId: meta.object.id,
                            parentDir: meta.object.parent_dir,
                            fileName: meta.object.original_name
                          })
                        }
                      }
                    }
                  }
                }
                break;
              }
              case JARFLOW_RENAME: {
                if (updateObject.meta_data) {
                  var env = getEnv();
                  const meta = JSON.parse(updateObject.meta_data)
                  const id = meta.object.parent_dir;
                  var _fsKey = `${env}_${id}`;
                  if (env === 1 || !env) {
                    _fsKey = id;
                  }
                  dispatch(UpdateFileInList(_fsKey, meta.object));
                  if (TARGET_PLATFORM !== "web") {
                    tauriApi.invoke("rename_file_object", {
                      objectId: meta.object.id,
                      newName: meta.object.original_name
                    })
                  }
                }
                break;
              }
              default: {
              }
            }
          }
        }).catch(err =>{
          console.log("err: ", err)
          //dispatch(LogOut())
        });
      }
    }, 10000)
    const removeOldFileStruct = setInterval(() => {
      dispatch(RemoveOldFileStruct())
    }, 600000);
    const uploadInterval = setInterval(startUploadData, 2000);

    const downloadInterval = setInterval(startDownloadData, 2000);
    return () => {
      unlisten.then(f => f());
      uploaderListener.then(f=>f())
      clearInterval(downloadInterval);
      clearInterval(uploadInterval);
      clearInterval(eventUpdate);
      clearInterval(removeOldFileStruct);
    }
  }, [])
  return (<></>)
}

function App() {

  return (
    <AuthProvider {...oidcConfig}>
      <Provider store={_store}>
        <div className="App" style={{ flex: 1, backgroundColor: 'black' }} /* onContextMenu={(e)=> e.preventDefault()} */>
          <DndProvider backend={HTML5Backend}>
            <Updater />
            <RouterProvider router={router} />
          </DndProvider>
        </div>
      </Provider>
    </AuthProvider>
  );
}

export default App;
