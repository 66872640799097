import { API, FILE_EXPLORER_PAGE, TARGET_PLATFORM } from "../utils/consts";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useAuth } from "react-oidc-context";
import { InitBreadCrumb, SetAccessToken, SetCurrentFolderID, SetSyncedFolder, SetUserInfo } from "../redux_store/actions";
import { UserInfo } from "../objects/user";
import { extractUserSpaceUsage, extractUserTotalSpace, getUserInfoFromProfileData } from "../utils/helper";
import config from "src/managers/config";
const tauriApi = require('../managers/tauri_imports');

export function Home(props) {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{ 
    if (auth.isAuthenticated) {
      const access_token = auth.user?.access_token;
      const refresh_token = auth.user?.refresh_token;
      var headers = { 
        'Content-Type': 'application/json', 
        'AuthToken': access_token }
      dispatch(SetAccessToken(access_token, refresh_token))
      fetch(API+'/user-profile/', { headers }).then(response => response.json()).then(data=>{
        data.access_token = access_token;
        const _userInfo = getUserInfoFromProfileData(data);
        dispatch(SetUserInfo(_userInfo));
        sessionStorage.setItem("profile", JSON.stringify(data))
        if (TARGET_PLATFORM !== "web") {
          tauriApi.invoke("set_auth_token", {
            authToken: access_token,
            rootId: _userInfo.root
          })
          setTimeout(() => {
            tauriApi.invoke("check_sync_folder", {
              folderPath: config.configObject.syncPath
            }).then(_ => {
              
            })
          }, 2000);
        }
        navigate(`${FILE_EXPLORER_PAGE}/${data.root}/`)
      }).catch((err) => {
        console.log("HOME PROFILE ERROR ", err)
      })
      
      
    }
  }, [auth.isAuthenticated])

  function initProfile(userToken) {
    const headers = { 
      'Content-Type': 'application/json', 
      'AuthToken': userToken }
    return fetch(API+'/user-profile/', { headers })
      .then(response => response.json())
      .then(data => {
        data.access_token = userToken;
        const _userInfo = getUserInfoFromProfileData(data);
        dispatch(SetUserInfo(_userInfo));
        return data;
      }).catch((err) => {
        console.log("HOME PROFILE ERROR ", err)
      })
      ;
  }

  if (auth.isLoading) {
      return <div>Loading...</div>;
  }

  if (auth.error) {
      return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect().then(user =>{
    })
  }
  return (<></>);
}