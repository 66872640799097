import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { rename } from 'src/utils/helper';
import { RenameFileObject } from 'src/redux_store/actions';
import { useDispatch } from 'react-redux';

export default function RenameDialog(props) {
  
  const {objectType, objectID, oldName, open, userToken, onCloseRename, parentID} = props;
  const [value, setValue] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenDialog(false);
    onCloseRename()
  };

  useEffect(() => {
    setOpenDialog(open);
    if (oldName !== undefined)
      setValue(oldName);
  }, [open, oldName])

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        maxWidth={'md'}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Rename {objectType}</DialogTitle>
        <DialogContent style={{width: 400}}>
          <TextField
            autoFocus
            required
            margin="dense"
            label={`Set new ${objectType} name`}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained"
            onClick={() => {
              rename(objectID, userToken, value).then(_ => {
                dispatch(RenameFileObject(value, parentID, objectID))
              });
              onCloseRename()
            }}>Rename</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}