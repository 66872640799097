import React, { useEffect, useRef, useState, 
  useCallback, useLayoutEffect, 
  useTransition} from "react";
import { useDispatch, useSelector } from "react-redux";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { IoNotificationsOutline, IoPersonOutline, IoAddOutline, IoPeopleOutline, IoGridOutline } from "react-icons/io5";
import { GrNext, GrUpgrade } from "react-icons/gr";
import { HiMenu, HiOutlineLibrary, HiOutlineTicket } from "react-icons/hi";
import { IoTrashBinOutline } from "react-icons/io5";
import { SlCloudDownload } from "react-icons/sl";
import { lightStyle } from "src/style/light_style";
import { Box, Grid, Button, Stack, IconButton, 
  Badge, Avatar, Breadcrumbs, Typography, Link, 
  Menu, MenuItem, Divider, Tooltip, 
  Chip,
  Autocomplete} from "@mui/material";
import { Search } from "./search";
import InfiniteScroll from "react-infinite-scroll-component";
import { AddDownloads, AddFolderInList, AddTmpUploadFileObject, AddUploadStructure, AddUploads, CleanSyncedFolders, ClearSelectedRows, DeleteFileInList, OpenNotifications, ProcessMsg, SetAlertData, SetBackURL, SetBreadCrumb, SetCurrentFolderID, SetCurrentPgPage, SetCurrentPublicToken, SetFileList, SetFileListLoadedStatus, SetManagePermissionFileObject, SetModalCopyFileObject, SetModalMoveFileObject, SetNewContent, SetSelectedItems, SetShareFileObject, SetShowContainerHeaderUpload, SetSyncedFolder, SetTmpPathsFromRust, SetUnsetSelectedRow, SetUserInfo, SetitemsForAction, UpdatePreview } from "../../src/redux_store/actions";
import { copy, covertDate, craeteUploadStructure, createBreadCrumb, createDirTree, createDirTree_v2, createFolders, deleteObject, downloadSelected, getAllFileEntries, getApplicationControls, getBreadCrumb, getFileDirPath, getFileIDS, getFileList, getMainRootFromPath, getMainRootObject, getPreviewList, getRandomInt, getTmpUrl, getToken, move, randomString, reduxPreviewWrapper, removeShare, search, setFavorite, share, statePreviewWrapper, valueBytesToString } from "src/utils/helper";
import { ALERT_TYPE_ERROR, ALERT_TYPE_INFO, API, AlertType, BIN_PAGE, FILE_EXPLORER_PAGE, ITEM_HEIGHT, LOGOUT, ORDER_ASC, ORDER_DESC, PLANS_PAGE, SETTINGS_PAGE, SHARES, TARGET_PLATFORM } from "src/utils/consts";
import { AlertData } from "src/objects/alert";
import { NativeTypes } from "react-dnd-html5-backend";
import RenameDialog from "./base/rename_dialog";
import { CopyFileListDialog } from "./base/dialogs/copy_filelist_dialog";
import { MoveFileListDialog } from "./base/dialogs/move_filelist_dialog";
import ShareDialog from "./base/dialogs/share_dialog";
import { ManageAccessDialog } from "./base/dialogs/manage_access_dialog";
import { MoveToBin } from "./base/dialogs/move_to_bin_dialog";
import { ManageLinkAccessDialog } from "./base/dialogs/manage_link_access_dialog";
import { TopComponent } from "./base/top_component";
import { AiFillFolderAdd, AiFillSetting, AiOutlineDownload, AiOutlineUpload } from "react-icons/ai";
import { ImFolderUpload } from "react-icons/im";
import CreateFolderDialog from "./base/create_folder_dialog";
import { RiTeamLine } from "react-icons/ri";
import { FiArrowLeft, FiCopy, FiLogOut } from "react-icons/fi";
import { LinearProgressWithLabel } from "./base/liniar_progress_with_label";
import { useDrop } from "react-dnd";
import { HeaderContainerUpload } from "./base/header_container_upload";
import { Notifications } from "./base/notifications";
import { PiListDashes, PiShareFatBold } from "react-icons/pi";
import { MdDeleteOutline } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { DEFAULT_BUTTON_BACKGROUND_COLOR, DEFAULT_LIGHT_TEXT_COLOR } from "src/style/global";
import { FileExplorerList } from "./layouts/file_explorer_list";
import { FileExplorerGrid } from "./layouts/file_explorer_grid";
import { AddFileInList, AddFilesInList, SetFileListBgColor, SetListObjectPreviewSizeFormat } from "../redux_store/actions";
import { cutLongString, getLayoutType, getObjectListByIds, getUserInfoFromProfileData, getUserSession, saveLayoutType } from "../utils/helper";
import { DND_DEFAULT_BG_COLOR, DND_HOVERED } from "../utils/consts";
import { BiMove } from "react-icons/bi";
import BgEmptyListImage from '../static/images/squirrel_6_300x300.png';
import ReactCurvedText from 'react-curved-text';
import config from "src/managers/config";
import { Downloads } from "./base/downloads";
import { IoIosArrowDown } from "react-icons/io";
import { ChipDropDown } from "./base/dialogs/chip_dropdown";
const tauriApiDialog = require('@tauri-apps/api/dialog');
const tauriApi = require('src/managers/tauri_imports');



var accoutOptions = [
  {
    id: 0,
    name: 'General access',
    iconObject: <RiTeamLine size={22} />
  },
  {
    id: 1,
    name: 'Setting',
    iconObject: <AiFillSetting size={22} />
  },
  {
    id: 2,
    name: 'Pricing',
    iconObject: <GrUpgrade size={22} />
  },
  {
    id: 3,
    name: 'Promo code',
    iconObject: <HiOutlineTicket size={22} />
  },
  {
    id: 4,
    name: 'Logout',
    iconObject: <FiLogOut size={22} />
  },
]


const headCellsWide = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 170,
  },
  {
    id: 'date',
    align: 'center',
    disablePadding: false,
    label: 'Date',
    width: 100,
  },
  {
    id: 'type',
    align: 'center',
    disablePadding: false,
    label: 'Type',
    width: 40
  },
  {
    id: 'size',
    align: 'center',
    disablePadding: false,
    label: 'Size',
    width: 60
  },
  {
    id: 'favorite',
    align: 'center',
    disablePadding: false,
    label: 'Favorite',
    width: 40
  }
];

const headCellsWideNoAuth = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 170,
  },
  {
    id: 'date',
    align: 'center',
    disablePadding: false,
    label: 'Date',
    width: 100,
  },
  {
    id: 'type',
    align: 'center',
    disablePadding: false,
    label: 'Type',
    width: 40
  },
  {
    id: 'size',
    align: 'center',
    disablePadding: false,
    label: 'Size',
    width: 60
  },
];

const headCellsMediumWide = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 170,
  },
  {
    id: 'date',
    align: 'center',
    disablePadding: false,
    label: 'Date',
    width: 100,
  },
  {
    id: 'type',
    align: 'center',
    disablePadding: false,
    label: 'Type',
    width: 50
  },
  {
    id: 'favorite',
    align: 'center',
    disablePadding: false,
    label: 'Favorite',
    width: 80
  }
];

const headCellsMediumWideNoAuth = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 170,
  },
  {
    id: 'date',
    align: 'center',
    disablePadding: false,
    label: 'Date',
    width: 100,
  },
  {
    id: 'type',
    align: 'center',
    disablePadding: false,
    label: 'Type',
    width: 40
  }
];

const headCellsMedium = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 170,
  },
  {
    id: 'date',
    align: 'center',
    disablePadding: false,
    label: 'Date',
    width: 100,
  },
  {
    id: 'favorite',
    align: 'center',
    disablePadding: false,
    label: 'Favorite',
    width: 80
  }
];

const headCellsMediumNoAuth = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 170,
  },
  {
    id: 'date',
    align: 'center',
    disablePadding: false,
    label: 'Date',
    width: 100,
  }
];

const headCellsShort = [
  {
    id: 'image',
    disablePadding: true,
    label: '',
    width: 40
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 170,
  },
];

const envOptions = [
  {
    title: "My files",
    id: 1,
  },
  {
    title: "My shares",
    id: 2,
  },
  {
    title: "My public links",
    id: 3,
  },
  {
    title: "Shared with me",
    id: 4,
  },
]

export const useIsScrollable = (dependencies) => {
  const [node, setNode] = useState();
  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  const [isScrollable, setIsScrollable] = useState(false);

  useLayoutEffect(() => {
    if (!node) return;

    setIsScrollable(node.scrollHeight > node.clientHeight);
  }, [...dependencies, node]);

  useLayoutEffect(() => {
    if (!node) return;

    const handleWindowResize = () => {
      setIsScrollable(node.scrollHeight > node.clientHeight);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [node]);

  return [isScrollable, ref, node];
};


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CustomBreadCrumb(props) {
  var {breadCrumbs, env, disabled} = props;
  if (env) {
    env = envOptions[parseInt(env)-1];
  } else {
    env = envOptions[0];
  }
  const [selectedItem, setSelectedItem] = useState(env);
  const navigate = useNavigate();

  const ref = useRef();
  ref.current = {selectedItem};

  function goToSelectedEnv(id, _env) {
    var url = `${FILE_EXPLORER_PAGE}/${id}/`;
    url += `?env=${_env}`;
    navigate(url);
  }

  function itemSelected(item) {
    setSelectedItem(item);
    goToSelectedEnv(breadCrumbs[0].id, item.id);
  }

  if (breadCrumbs?.length === 1) {
    return (
      <Breadcrumbs 
        maxItems={7} 
        itemsAfterCollapse={3}
        itemsBeforeCollapse={3}
        separator={<GrNext size={17} color='black'/>}
        aria-label="breadcrumb">
        <ChipDropDown
          items={envOptions}
          selectedItem={selectedItem}
          onChanged={itemSelected}
          clickedItem={() => {
            goToSelectedEnv(breadCrumbs[0].id, ref.current.selectedItem.id)
          }}
        />
      </Breadcrumbs>
    )
  } else {
    return (
      <Breadcrumbs 
        maxItems={7} 
        itemsAfterCollapse={2}
        itemsBeforeCollapse={3}
        separator={<GrNext size={17} color='black'/>}
        aria-label="breadcrumb">
          <ChipDropDown
            items={envOptions}
            selectedItem={selectedItem}
            onChanged={itemSelected}
            clickedItem={() => {
              goToSelectedEnv(breadCrumbs[0].id, ref.current.selectedItem.id)
            }}
          />
          {breadCrumbs?.slice(1, breadCrumbs.length-1).map((obj, index) => {
          return (
          <Link 
            key={index}
            component="button"
            underline="none"
            color="inherit" 
            style={{fontSize: 18,display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
          }}
            disabled={disabled}
            onClick={() => {
              goToSelectedEnv(breadCrumbs[0].id, ref.current.selectedItem.id)
          }}>
            {cutLongString(obj.original_name, 20)}
          </Link>)
        })}
        <Typography color="text.primary" style={{
          fontWeight: 700, fontSize:18,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          overflow: 'hidden', 
          textOverflow: 'ellipsis',
          maxWidth: 300,
          }}>
          {breadCrumbs[breadCrumbs.length-1]?.original_name}
        </Typography>
      </Breadcrumbs>)
  }
}


export function EmptyFolderLabel(props) {
  const {show} = props;
  if (show) {
    return <Stack justifyContent={"center"} alignItems={"center"}>
      <img className="not-druggable" src={require('../static/images/squirrel_6_300x300.png')} alt={"squirrel"} style={{objectFit: 'contain', width: 300, height: 300}} />
        <ReactCurvedText width={300}
          height={50}
          cx={150}
          cy={0}
          rx={200}
          ry={40}
          startOffset={90}
          reversed={false}
          text="Drop your files here"
          textProps={{ style: { fontSize: 25, fontWeight: 'bold' } }}
          textPathProps={null}
          tspanProps={null}
          ellipseProps={null}
          svgProps={null} />
    </Stack>
  } else {
    return null;
  }
  
}


export function FileExplorer(props) {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo);
  const backUrl = useSelector(state => state.userProfileReducer.backUrl);
  const fileStruct = useSelector(state => state.fileListReducer.fileStruct);
  const showContainerHeaderUpload = useSelector(state => state.fileListReducer.showContainerHeaderUpload);
  const selectedRows = useSelector(state => state.fileListReducer.selectedRows);
  const activeDeletedObjects = useSelector(state => state.binReducer.activeDeletedObjects);
  const mainListBreadCrumb = useSelector(state => state.fileListReducer.mainListBreadCrumb);
  const currentFolderID = useSelector(state => state.fileListReducer.currentFolderID);
  const inFolder = useSelector(state => state.fileListReducer.inFolder);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showManageAccessDialog, setShowManageAccessDialog] = useState(false);
  const [order, setOrder] = React.useState(ORDER_ASC);
  const [orderBy, setOrderBy] = React.useState('calories');
  const [anchorActions, setAnchorActions] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  
  const [renameObjectData, setRenameObjectData] = useState(null);
  const [itemsForCopy, setItemsForCopy] = useState([]);
  const [itemsForMove, setItemsForMove] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showManageLinkDialog, setShowManageLinkDialog] = useState(false);
  const [headCells, setHeadCells] = useState([])
  const [page, setPage] = React.useState(0);
  const [updateListView, setUpdateListView] = useState(0);
  const [manageAccessFileObject, setManageAccessFileObject] = useState();
  const [anchorAccoutMenu, setAnchorAccoutMenu] = useState(false);
  const [openDownloads, setOpenDownloads] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [layoutType, setLayoutType] = useState(getLayoutType() ? getLayoutType() : 'list')
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [publicAccess, setPublicAccess] = useState(null)
  const [manageLinkResource, setManageLinkResource] = useState();
  const [localSelected, setLocalSelected] = useState({})
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [tableContainerBackgroundColor, setTableContainerBackgroundColor] = useState("rgb(234, 243, 252)")
  const [deleteFileObjects, setDeleteFileObjects] = useState([])
  const [shareItems, setShareItems] = useState([])
  const [dataLoading, setDataLoding] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [fsKey, setFsKey] = useState('');
  const [unseenDownloads, setUnseenDownloads] = useState(0);
  const [applicationControls, setApplicationControls] = useState();
  const folderSelectedRef = useRef();
  const fileSelectedRef = useRef();
  const openActionsMenu = Boolean(anchorActions);
  const openAccoutMenu = Boolean(anchorAccoutMenu);
  const [isScrollable, scrollRef, node] = useIsScrollable([]);
  let { id } = useParams();
  let query = useQuery();
  const p = query.get('p');
  var q = query.get('q');
  var env = query.get('env');
  const windowSize = 40;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!env) {
    env = 1;
  } else {
    env = parseInt(env);
  }
  const {sharePage} = props;
  var localRef = useRef();
  localRef.current = {mainListBreadCrumb, currentFolderID, inFolder, fileStruct, userInfo, env}

  const [{ canDrop, isOver }, dropRefHeader] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {

      },
      canDrop(item) {
        return true
      },
      hover(item) {
        if (!sharePage) {
          dispatch(SetShowContainerHeaderUpload(true))
        }
      },
      collect: (monitor) => {
        const item = monitor.getItem()
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    })
  )

  const [{ canDropOnTable, isOverTable }, dropTableRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (localRef.current.inFolder) {
          return
        }
        
        if (item && item.dataTransfer) {
          var items = item.dataTransfer.items;

          getAllFileEntries(items).then(files => {
            const flattenFiles = files.reduce((acc, val) => acc.concat(val), []);
            var onlyDirs = [];
            var onlyFiles = [];
            var folders = []
            for (var i = 0; i < flattenFiles.length; i++) {

              if (flattenFiles[i].isDirectory) {
                onlyDirs.push(flattenFiles[i]);
                folders.push(flattenFiles[i].fullPath);
              } else if (flattenFiles[i].path) {
                onlyFiles.push(flattenFiles[i])
              }
            }
            if (onlyFiles.length > 2000 && TARGET_PLATFORM !== 'web') {
              dispatch(SetTmpPathsFromRust(onlyFiles, folders))
            } else {
              var parsed_files = [];
              for (let i = 0; i < onlyFiles.length; i++) {

                const f = onlyFiles[i];
                var lastIndexOfSlash = f.path.lastIndexOf("/");

                var filePath = "";
                if (lastIndexOfSlash === -1) {
                  continue
                }
                if (lastIndexOfSlash === 0) {
                  filePath = '/'
                } else {
                  filePath = f.path.slice(0, lastIndexOfSlash)
                }
                folders.push(getFileDirPath(filePath));
                parsed_files.push({
                  full_path: f.path,
                  relative_path: f.path,
                  id: randomString(15),
                  name: f.name,
                  size: f.size,
                  file_object: f,
                  relative_path_parent_dir: getFileDirPath(f.path)
                })
              }
              var dirTree = createDirTree(onlyDirs);

              createFolders(localRef.current.currentFolderID, dirTree, userInfo?.userToken).then(data => {

                if (data.msg || data.detail) {
                  var alertData = new AlertData();
                  alertData.type = AlertType.ERROR;
                  alertData.message = data.msg || data.detail;
                  dispatch(SetAlertData(alertData))
                  return;
                }
                var createdFolders = data.created_folders;
                if (!createdFolders) {
                  createdFolders[localRef.current.currentFolderID] = { files: [], folders: [] }
                }

                var upload_structure = craeteUploadStructure(parsed_files, createdFolders, data.main_roots);

                for (var i = 0; i < data.root_folders.length; i++) {
                  dispatch(AddFolderInList(data.root_folders[i]));
                }
                var tmp_main_root_object = getMainRootObject(data.main_roots);
                dispatch(AddUploadStructure(upload_structure))
                for (var i = 0; i < parsed_files.length; i++) {
                  var fileObject = parsed_files[i];
                  const relative_path = fileObject.relative_path;
                  const relative_path_parent_dir = fileObject.relative_path_parent_dir;
                  var parentID = data.created_folders[relative_path_parent_dir]
                  const main_root = getMainRootFromPath(relative_path_parent_dir);

                  if (parentID) {
                    var tmpObject = {
                      original_name: fileObject.name,
                      id: fileObject.id,
                      created: covertDate(new Date()),
                      updated: 0,
                      size: fileObject.size,
                      favorite: false,
                      object_type: 'file',
                      progress: 0.001,
                      upload: true,
                      parent_dir: parentID,
                      main_root_id: tmp_main_root_object[main_root],
                      file: fileObject.file_object
                    };
                    dispatch(AddTmpUploadFileObject(tmpObject))
                    dispatch(AddUploads(tmpObject))
                  }
                }
              }).catch((err) => {
                var alertData = new AlertData();
                  var msg = '';
                  if (err.detail) {
                    msg = err.detail;
                  } else if (err.msg) {
                    msg = err.msg;
                  } else {
                    msg = 'cannot created imported folders'
                  }
                  alertData.type = AlertType.ERROR;
                  alertData.message = msg;
                  dispatch(SetAlertData(alertData))
              })
            }
          });
        }
      },
      canDrop(item) {
        return true
      },
      hover(item) {
        
        var obj = createBreadCrumb(userInfo?.root, localRef.current.mainListBreadCrumb, userInfo, sharePage).slice(-1)[0]
        var items = item.dataTransfer.items;
        var length = items.length;
        if (length > 0) {
          var itemString = length > 1 ? length + " items in " : length + " item in "
          var alertData = new AlertData();
          alertData.type = AlertType.INFO;
          alertData.message = "Upload " + itemString + obj.original_name
          dispatch(SetAlertData(alertData))
        }
      },
      collect: (monitor) => {
        const item = monitor.getItem()
        return {
          isOverTable: monitor.isOver({shallow: true}),
          canDropOnTable: monitor.canDrop(),
        }
      },
    })
  )

  useEffect(() => {
    if (!isScrollable && node && !firstLoad) {
      setTimeout(() => {
        loadMore(localRef.current.env)
      }, 2000);
    }
  }, [isScrollable, node, id, firstLoad])

  useEffect(() => {
    if (!isOverTable) {
      setTableContainerBackgroundColor(DND_DEFAULT_BG_COLOR);
    } else {
      setTableContainerBackgroundColor(DND_HOVERED);
      //dispatch(SetFileListBgColor(DND_HOVERED))
    }
  }, [isOverTable])

  useEffect(() => {
    if (!isOver)
      dispatch(SetShowContainerHeaderUpload(false))
  }, [isOver])

  function windowChangeWidth() {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 1000 && window.innerWidth < 1200) {
      if (userInfo?.userToken) {
        setHeadCells(headCellsMediumWide);
      } else {
        setHeadCells(headCellsMediumWideNoAuth);
      }
    } else if (window.innerWidth > 890 && window.innerWidth < 1000) {
      if (userInfo?.userToken) {
        setHeadCells(headCellsMedium);
      } else {
        setHeadCells(headCellsMediumNoAuth);
      }
    } else if (window.innerWidth < 890) {
      setHeadCells(headCellsShort);
    } else {
      if (userInfo?.userToken) {
        setHeadCells(headCellsWide);
      } else {
        setHeadCells(headCellsWideNoAuth);
      }
    }
  }


  function handleRenamePressed(fileObject) {
    setRenameObjectData({
      name: fileObject?.original_name,
      objectType: fileObject?.object_type,
      id: fileObject?.id,
    })
  }

  function showShareDialogFromBtn(obj) {
    setShowShareDialog(true);
    setShareItems([obj])
  }

  function handleActionsMenu(id) {
    setAnchorActions(null)
  }
  
  function onMenuItemSelect(_id, fileObject, userToken) {
    switch(_id) {
      case 0: {
        setItemsForCopy([fileObject.id])
        setShowCopyDialog(true)
        //dispatch(SetModalCopyFileObject(fileObject))
        break;
      }
      case 1: {
        handleRenamePressed(fileObject);
        break;
      }
      case 2: {
        //dispatch(ShowShareModal([fileObject.id]))
        setShowShareDialog(true);
        setShareItems([fileObject])
        break;
      }
      case 3: {
        //dispatch(SetManagePermissionFileObject(fileObject))
        setShowManageAccessDialog(true);
        setManageAccessFileObject(fileObject);
        break;
      }
      case 4: {
        var alertData = new AlertData();
        alertData.type = AlertType.INFO;
        alertData.message = "Starting to download " + fileObject.original_name
        dispatch(SetAlertData(alertData))
        if (TARGET_PLATFORM === 'web') {
          if (fileObject.object_type === 'folder') {
            downloadSelected([fileObject.id], userToken, config.deviceID)
          } else {
            getTmpUrl(fileObject.id, getToken(userInfo, p)).then(data=>{
              if (!data.msg) {
                const tmpID = data.id;
                const tmpURL = API + `/tmp-file-object-content/${tmpID}?download=2`;
                document.getElementById('magic-frame').src = tmpURL;
              }
            })
          }
        } else {
          const zip = fileObject.object_type === 'folder' ? true : false;
          dispatch(AddDownloads(
            fileObject.id, 
            (new Date()).toLocaleString(), 
            config.configObject.downloadPath, 
            fileObject.original_name, 
            zip, 
            fileObject.object_type ))
        }
            /* invoke("downalodFile", 
            {
              downloadDir: config.configObject.downloadPath,
              fileUrl: API+'/fileobject/'+fileObject.id+"/",
              fileName: fileObject.original_name,
              fileObjectId: fileObject.id,
              userToken: userTOken
          }) */
        break;
      }
      case 5: {
        //dispatch(SetCopyLinkFileObject(fileObject))
        setShowManageLinkDialog(true);
        setManageLinkResource(fileObject)
        setPublicAccess(fileObject?.public_access)
        break;
      }
      case 6: {
        setItemsForMove([fileObject])
        setShowMoveDialog(true)
        //dispatch(SetModalMoveFileObject(fileObject))
        break;
      }
      case 7: {
        setShowDeleteDialog(true);
        setDeleteFileObjects([fileObject])
        //dispatch(DeleteFileObject(fileObject))
        //dispatch(DeleteFileInList(fileObjectID));
        break;
      }
      case 8: {
        var forRemove = [{
          target_user: userInfo?.userID,
          resource: fileObject.id
        }];
        removeShare(forRemove, userToken).then(jsonData =>{
          var _fsKey = `${userInfo.root}_share`;
          if (sharePage) {
            if (id) {
              _fsKey = `${id}_share`;
            }
          }
          dispatch(DeleteFileInList(_fsKey, fileObject.id))
        })
        break;
      }
      case 9: {
        tauriApi.invoke("set_synced_folder", {
          objectId: fileObject.id
        }).then(_ => {
          dispatch(SetSyncedFolder(fileObject.id, true))
        })
        break;
      }
      case 10: {
        dispatch(CleanSyncedFolders());
        tauriApi.invoke("remove_synced_folder", {
          objectId: fileObject.id
        }).then(_ => {
          tauriApi.invoke("get_synced_folders", {
          }).then((syncedFolders)=> {
            syncedFolders.map((obj, _) => {
              dispatch(SetSyncedFolder(obj, true))
            })
          })
        })
        break;
      }
      default: {
        
      }
    }
    setAnchorEl(null);
  };

  function handleOnCLickActions(event) {
    setAnchorActions(event.currentTarget);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === ORDER_ASC;
    setOrder(isAsc ? ORDER_DESC : ORDER_ASC);
    setOrderBy(property);
  };

  function handleAccountClick(event) {
    setAnchorAccoutMenu(event.currentTarget);
  };

  function loadRootFiles() {
    getFileList(localRef.current?.userInfo?.root, localRef.current?.userInfo?.userToken, 1, 
        windowSize, env).then(data => {
      if (!data.msg && !data.detail && id === localRef.current?.currentFolderID) {
        dispatch(SetBreadCrumb(data.bread_crumb))
        dispatch(SetFileList(data.files, localRef.current?.userInfo?.root, windowSize, false))
        dispatch(ClearSelectedRows())
        const fileIDS = getFileIDS(data.files);
        const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
        reduxPreviewWrapper(fileIDS, localRef.current?.userInfo?.root, previewFormat, 
          getToken(localRef.current?.userInfo, p), dispatch)
      }
    })
    dispatch(SetCurrentPgPage(2))
    //dispatch(HasMore(true))
    dispatch(SetCurrentFolderID(id))

  }

  function loadMore(env) {
    var id = localRef.current?.currentFolderID;
    var _fsKey = `${env}_${id}`;
    if (env === 1 || !env) {
      _fsKey = id;
    }
    var _currentPage = localRef.current?.fileStruct[_fsKey]?.page;
    var currID = localRef.current?.currentFolderID;
    if (_currentPage === undefined) {
      _currentPage = 1
    }
    getFileList(localRef.current?.currentFolderID, getToken(localRef.current?.userInfo, p), 
      _currentPage, windowSize, undefined, env).then(data => {
      if (!data.msg && !data.detail && currID === localRef.current?.currentFolderID) {
        createBreadCrumb(localRef.current?.userInfo?.root, data.bread_crumb, localRef.current?.userInfo, true)
        dispatch(AddFilesInList(_fsKey, data.files, true))
        //dispatch(ClearSelectedRows())
        //setUpdateListView(getRandomInt(100000))
        const fileIDS = getFileIDS(data.files);
        const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
        reduxPreviewWrapper(fileIDS, _fsKey, previewFormat, 
          getToken(localRef.current?.userInfo, p), dispatch)
      }
    })
  }

  function updatePreview(_fsKey) {
    if ( layoutType === 'grid' && fileStruct[_fsKey] && !fileStruct[_fsKey].previewMedium) {
      const fileIDS = getFileIDS(fileStruct[_fsKey].fileList);
      reduxPreviewWrapper(fileIDS, _fsKey, 'medium', getToken(userInfo, p), dispatch)
    } else if (layoutType === 'list' && fileStruct[_fsKey] && !fileStruct[_fsKey].previewSmall ) {
      const fileIDS = getFileIDS(fileStruct[_fsKey].fileList);
      reduxPreviewWrapper(fileIDS, _fsKey, 'small', getToken(userInfo, p), dispatch)
    }
  }

  function handleDeleteItems(_selectedRows) {
    setShowDeleteDialog(false)
    _selectedRows.map((item, index) => {
      dispatch(DeleteFileInList(currentFolderID, item.id))
      //dispatch(ClearSelectedRows())
    })
    //dispatch(SetActiveDeletedObjects(_deleteFileObjects.length))
    //dispatch(DeleteFileObject(null))
    var ids = [];
    for (var i = 0; i < _selectedRows.length; i++) {
      ids.push(_selectedRows[i].id)
    }
    deleteObject(ids, localRef.current?.userInfo?.userToken)
  }

  useEffect(() => {
    setSelected([...selectedRows])
  }, [selectedRows])

  useEffect(() => {
    const selected = query.get("selected");
    
    dispatch(ClearSelectedRows())
    if (localRef.current?.userInfo?.userToken) {
      setApplicationControls(localRef.current?.userInfo?.applicationsControl)
    } else {
      getApplicationControls().then(data => {
        setApplicationControls(data.applications_control)
      })
    }
    if (!getToken(localRef.current?.userInfo, p)) {
      return 
    }
    if (windowWidth > 1000 && windowWidth < 1200) {
      if (localRef.current?.userInfo?.userToken) {
        setHeadCells(headCellsMediumWide);
      } else {
        setHeadCells(headCellsMediumWideNoAuth);
      }
    } else if (windowWidth > 890 && windowWidth < 1000) {
      setHeadCells(headCellsMedium);
    } else if (windowWidth < 890) {
      setHeadCells(headCellsShort);
    } else {
      if (localRef.current?.userInfo?.userToken) {
        setHeadCells(headCellsWide);
      } else {
        setHeadCells(headCellsWideNoAuth);
      }
    }
    if (TARGET_PLATFORM !== 'web') {
      tauriApi.invoke("get_synced_folders", {
      }).then((syncedFolders)=> {
        syncedFolders.map((obj, _) => {
          return dispatch(SetSyncedFolder(obj, true))
        })
      })
    }
    dispatch(SetAlertData(null));
    dispatch(SetFileListLoadedStatus(0))
    if (!id && !q) {
      id = localRef.current?.userInfo?.root
      const headers = { 
        'Content-Type': 'application/json', 
        'AuthToken': localRef.current?.userInfo?.userToken}
      fetch(API+'/user-profile/', { headers })
      .then(response => response.json())
      .then(data => {
        data.access_token = localRef.current?.userInfo?.userToken;
        const _userInfo = getUserInfoFromProfileData(data);
        dispatch(SetUserInfo(_userInfo));
        sessionStorage.setItem("profile", JSON.stringify(data))
      }).catch(err => {
        console.log("ERR: ", err)
      });
      navigate(`${FILE_EXPLORER_PAGE}/${id}/`)
      return;
    }
    if (!id) {
      id = localRef.current?.userInfo?.root
    }
    var currID = id;
    dispatch(SetCurrentFolderID(id));
    var _fsKey = id;
    getBreadCrumb(id, getToken(localRef.current?.userInfo, p)).then(resp => {
      const serverBreadCrumb = resp.bread_crumb;
      dispatch(SetBreadCrumb(serverBreadCrumb))
    }).catch((err) => {
    })
    if (q && q !== null) {
      _fsKey = `${id}_search`;
    }
    if (env !== 1 && env) {
      _fsKey = `${env}_${_fsKey}`;
    }
    
    setFsKey(_fsKey)
    console.log("FSKEY: ", _fsKey)
    if (!sharePage && !q) {
      dispatch(SetBackURL(`${FILE_EXPLORER_PAGE}/${id}/`))
    }
    if (!fileStruct[_fsKey]) {
      setDataLoding(true)
      if (q && q !== null) {
        search(q, userInfo?.userToken).then(data=>{
          dispatch(SetFileList(data.files, _fsKey, windowSize, false))
          const fileIDS = getFileIDS(data.files);
          const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
          reduxPreviewWrapper(fileIDS, _fsKey, previewFormat, getToken(localRef.current?.userInfo, p), 
                  dispatch)
          setDataLoding(false)
        }).catch((err) => {
          console.log("ERR", err)
          setDataLoding(false)
        })
      } else {
        getFileList(id, getToken(localRef.current?.userInfo, p), 1, 
          windowSize, selected, env).then(data => {
          // Set back url for handling back click in another windows
         if (data.msg || data.detail) {
           if (data.detail === 'A password is required for access to the public link') {
             dispatch(SetCurrentPublicToken(p));
           }
           if (data.detail === 'Unauthorized') {
             navigate('/');
           }
           var alertData = new AlertData();
           alertData.type = AlertType.ERROR;
           alertData.message = data.msg || data.detail;
           dispatch(SetAlertData(alertData))
           if (localRef.current?.userInfo?.root) {
             loadRootFiles()
           }
         } else if (data.files && data.files.constructor.name === "Array") {
           dispatch(SetBackURL(`${FILE_EXPLORER_PAGE}/${id}/`))
           //dispatch(HasMore(data.has_more))
           dispatch(SetCurrentFolderID(id))
           if (selected) {
             const splitedSelected = selected.split(',');
             var newOrder = [];
             var notSelected = []
             for (var i = 0; i < data.files.length; i++) {
               const _f = data.files[i];
               if (splitedSelected.indexOf(_f.id) !== -1) {
                 newOrder.push(_f)
               } else {
                 notSelected.push(_f)
               }
             }
             var newItems;
             if (notSelected && notSelected.length > 0) {
              newItems = [notSelected[0], ...newOrder.concat(notSelected.slice(1))]
             } else {
              newItems = [...newOrder.concat(notSelected.slice(1))]
             }
             if (localRef.current.mainListBreadCrumb.length === 0) {
              newItems = newOrder.concat(notSelected);
             }
             if (currID === localRef.current?.currentFolderID) {
              dispatch(SetFileList([...newItems], _fsKey, windowSize, false))
              const fileIDS = getFileIDS(newItems);
              if (fileIDS.length > 0) {
                  const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
                  reduxPreviewWrapper(fileIDS, _fsKey, previewFormat, getToken(localRef.current?.userInfo, p), 
                    dispatch)
                }
              dispatch(ClearSelectedRows())
              splitedSelected.map((item, index) => {
                dispatch(SetUnsetSelectedRow(_fsKey, item))
              })
            }
           } else if (currID === localRef.current?.currentFolderID) {
              dispatch(SetFileList(data.files, _fsKey, windowSize, false))
              const fileIDS = getFileIDS(data.files);
              if (fileIDS.length > 0) {
                  const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
                  reduxPreviewWrapper(fileIDS, _fsKey, previewFormat, getToken(localRef.current?.userInfo, p), 
                    dispatch)
              }
           }
         }
         setFirstLoad(false);
         dispatch(SetFileListLoadedStatus(1))
         setDataLoding(false)
       }).catch(err => {
        setDataLoding(false)
        //navigate("/auth");
       })
      } } else {
        if (!id) {
          id = userInfo.root
        }
        var _fsKey = id;
        if (q) {
          _fsKey = `${id}_search`;
        } else if (env !== 1) {
          _fsKey = `${env}_${id}`;
        }

        updatePreview(_fsKey)
        if (selected) {
          var data = fileStruct[_fsKey]
          const splitedSelected = selected.split(',');
          var newOrder = [];
          var notSelected = []
          for (var i = 0; i < data.fileList.length; i++) {
            const _f = data.fileList[i];
            if (splitedSelected.indexOf(_f.id) !== -1) {
              newOrder.push(_f)
            } else {
              notSelected.push(_f)
            }
          }
          var newItems;
          if (notSelected && notSelected.length > 0) {
          newItems = [notSelected[0], ...newOrder.concat(notSelected.slice(1))]
          } else {
          newItems = [...newOrder.concat(notSelected.slice(1))]
          }
          if (localRef.current.mainListBreadCrumb.length > 1 && id === localRef.current?.currentFolderID) {
            dispatch(SetFileList([...newItems], 
              id, windowSize, false))
          } else if (id === localRef.current?.currentFolderID) {
            if (notSelected[0].created === -1) {
              dispatch(SetFileList([...newItems], 
               id, windowSize, false))
            } else {
              dispatch(SetFileList(newOrder.concat(notSelected), id, windowSize, false))
            }
          }
          dispatch(ClearSelectedRows())
          splitedSelected.map((item, index) => {
            dispatch(SetUnsetSelectedRow(id, item))
          })
        }
        if (q && q !== null) {
          search(q, userInfo?.userToken).then(data=>{
            dispatch(SetFileList(data.files, _fsKey, windowSize, false))
            const fileIDS = getFileIDS(data.files);
            const previewFormat = layoutType === 'grid' ? 'medium' : 'small';
            reduxPreviewWrapper(fileIDS, _fsKey, previewFormat, getToken(localRef.current?.userInfo, p), 
                    dispatch)
          }).catch((err) => {
            console.log("ERR", err)
          })
        }
        setDataLoding(false);
      }
  }, [id, q, env]);

  useEffect(() => {
    window.addEventListener("resize", windowChangeWidth);
  }, [])


  function handleCancel() {
    setShowDeleteDialog(false);
  }

 
  const handleSelectAllClick = (event) => {
    if (selectedRows.length > 1) {
      dispatch(ClearSelectedRows())
      return;
    }
    var ids = [];
    var fileList = fileStruct[fsKey]?.fileList;
    for (var i = 0; i < fileList.length; i++) {
      ids.push(fileList[i].id);
    }
    dispatch(SetSelectedItems(ids));
  };

  const onUploadFolder = async () => {
    if (["windows", "linux", "macos"].indexOf(TARGET_PLATFORM) !== -1) {
      const selected = await tauriApiDialog.open({
        directory: true,
        multiple: false,
      });
      if (selected) {
        tauriApi.invoke("uploadFolder", {
          path: selected
        }).then(data => {
        })
      }
    } else {
      folderSelectedRef.current.click()
    }
  }

  function handleCreate(id) {
    switch (id) {
      case 0: {
        setOpenCreateFolder(true)
        break;
      }
      case 1: {
        fileSelectedRef.current.click();
        break;
      }
      case 2: {
        onUploadFolder()
        break;
      }
      default:
        break;
    }
    setAnchorActions(null)
  }

  const onDirSelect = async (dirTree, parsed_files, currentDirID) => {
    var _currentFolderID = currentFolderID;
    if (currentDirID) {
      _currentFolderID = currentDirID;
    }

    createFolders(_currentFolderID, dirTree, userInfo.userToken).then(data => {
      if (data.msg) {
        var alertData = new AlertData();
        alertData.type = AlertType.ERROR;
        alertData.message = data.msg;
        dispatch(SetAlertData(alertData))
        return;
      }
      var upload_structure = craeteUploadStructure(parsed_files, data.created_folders, data.main_roots);

      for (var i = 0; i < data.root_folders.length; i++) {
        dispatch(AddFolderInList(currentFolderID, data.root_folders[i]));
      }
      var tmp_main_root_object = getMainRootObject(data.main_roots);
      dispatch(AddUploadStructure(upload_structure))
      for (var i = 0; i < parsed_files.length; i++) {
        const _file = parsed_files[i];
        const relative_path = _file.relative_path;
        const relative_path_parent_dir = _file.relative_path_parent_dir;
        const main_root = getMainRootFromPath(relative_path);
        const tmpObject = {
          original_name: _file.name,
          id: _file.id,
          created: covertDate(new Date()),
          updated: 0,
          size: _file.size,
          favorite: false,
          object_type: 'file',
          progress: 0.001,
          upload: true,
          parent_dir: data.created_folders[relative_path_parent_dir],
          main_root_id: tmp_main_root_object[main_root],
          file: _file.file_object
        };
        dispatch(AddTmpUploadFileObject(tmpObject))
        dispatch(AddUploads(tmpObject))
      }
    })
  };

  function processFileUpoad(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      var item = e.target.files[i];
      const fileObject = {
        original_name: item.name,
        id: randomString(10),
        created: covertDate(new Date()),
        updated: 0,
        size: item.size,
        favorite: false,
        object_type: 'file',
        progress: 0.001,
        upload: true,
        parent_dir: currentFolderID,
        main_root_id: currentFolderID,
        file: item
      };
      dispatch(AddTmpUploadFileObject(fileObject))
      dispatch(AddUploads(fileObject))
    }
  }

  async function handleCloseAccout(id) {
    switch (id) {
      case 0: {
        navigate(SETTINGS_PAGE)
        break;
      }
      case 1: {
        navigate(SETTINGS_PAGE)
        break;
      }
      case 2: {
        navigate(PLANS_PAGE)
        break;
      }
      case 3: {
        break;
      }
      case 4: {
        //googleLogout()
        navigate(LOGOUT)
        break;
      }
      default:
        break;
    }
    setAnchorAccoutMenu(null)
  }

  useEffect(() => {
    var obj = Object.fromEntries(selectedRows.map((el, index) => [el, 1]));
    setLocalSelected({...obj});
  }, [selectedRows])

  
  function isSelected(id) {
    return localSelected[id] === 1;
  }
  return (
    <TopComponent
      openMobileMenu={openMobileMenu}
      sharePage={sharePage}
      onCloseMobileMenu={() => {setOpenMobileMenu(false)}}
      >
      <input type='file' id='file' ref={fileSelectedRef} style={{ display: 'none' }} onChange={processFileUpoad} multiple />
      <input type='file' id='file' directory="" webkitdirectory="" mozdirectory="" ref={folderSelectedRef} style={{ display: 'none' }} onChange={(e) => {
        var files = e.target.files || e.dataTransfer.files;
        var folders = []
        var parsed_files = []
        for (let i = 0; i < files.length; i++) {

          const f = files[i];
          folders.push(getFileDirPath("/" + f.webkitRelativePath));
          parsed_files.push({
            full_path: "/" + f.webkitRelativePath,
            relative_path: "/" + f.webkitRelativePath,
            id: randomString(15),
            name: f.name,
            size: f.size,
            file_object: f,
            relative_path_parent_dir: getFileDirPath("/" + f.webkitRelativePath)
          })
        }
        var dirTree = createDirTree_v2(folders);
        onDirSelect(dirTree, parsed_files)
        e.target.value = '';
      }
      } />
      <Box
       ref={dropRefHeader}
        alignItems="center"
        spacing={0}
        style={lightStyle.mainBackgroud}
      >
        <Box style={{width: '100%', height: '100%'}}>
        { windowWidth > 800 ? 
          <Grid 
            container padding={1} style={{height: 65}}>
            <Grid item xs={3} md={2} sm={2} lg={3} xl={3} alignItems={"flex-start"} justifyContent={"center"} padding={1}>
              <Stack flexDirection={"column"} style={{width: '100%', height: '100%'}} justifyContent={"start"} alignItems={"start"}>
                {userInfo?.userToken && (sharePage || q)? 
                  <Box>
                    <Tooltip title="Go back to files" >
                      <IconButton
                        aria-label="more"
                        onClick={() => {
                          if (backUrl) {
                            navigate(backUrl);
                          } else {
                            navigate(`${FILE_EXPLORER_PAGE}/${userInfo?.root}/`)
                          }
                        }}
                        style={{backgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR}}
                        id="shares-btn">
                          <FiArrowLeft size={22} color={DEFAULT_LIGHT_TEXT_COLOR}/>
                      </IconButton>
                    </Tooltip>
                  </Box>
                   : null}
                {userInfo?.userToken && !sharePage && !q? <Button
                  variant="contained"
                  className="create-btn"
                  onClick={handleOnCLickActions}
                  aria-controls={openActionsMenu ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openActionsMenu ? 'true' : undefined}
                  style={{width: 130}}
                  startIcon={<IoAddOutline size={22} color={"green"} style={{ color: 'white' }} />}>
                  Create
                </Button> : null}
              </Stack>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6} xl={6} padding={1}>
              <Stack style={{width: '100%'}}>
                <Search
                  userToken={userInfo?.userToken}
                  userID={userInfo?.userID}
                  activateSearchWindow={true}
                />
              </Stack>
            </Grid>
            <Grid item xs={3} md={4} sm={4} lg={3} xl={3} justifyContent={"center"} padding={1} alignItems={"flex-end"}>
              <Stack flexDirection={"row"} alignItems={"flex-end"} justifyContent={"flex-end"} style={{flex: 1}} mr={1}>
              {userInfo?.userToken ? 
                <React.Fragment>
                {TARGET_PLATFORM === 'web' ? null : <IconButton
                  aria-label="more"
                  onClick={() => setOpenDownloads(true)}
                  id="downloads-btn">
                  <Badge badgeContent={unseenDownloads} color="secondary">
                    <SlCloudDownload size={27} />
                  </Badge>
                </IconButton>}
                <IconButton
                  aria-label="more"
                  onClick={() => dispatch(OpenNotifications(true))}
                  id="notification-btn">
                  <Tooltip title="Notifications" >
                    <Badge badgeContent={fileStruct['notification']?.fileList.length} color="secondary">
                      <IoNotificationsOutline size={27} />
                    </Badge>
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="bin"
                  onClick={() => navigate(BIN_PAGE + `/?t=${getRandomInt(10000)}`)}
                  id="bin-btn">
                  <Tooltip title="Bin" >
                    <Badge badgeContent={activeDeletedObjects} color="secondary">
                      <IoTrashBinOutline size={27} />
                    </Badge>
                  </Tooltip>
                </IconButton>
                {userInfo && (userInfo.avatarSSoUrl || userInfo.avatarUrl) ?
                  <IconButton onClick={handleAccountClick}>
                    <Tooltip title="Profile" >
                      <Avatar
                        sx={{ width: 30, height: 30, fontSize: 17 }}
                        src={userInfo.avatarSSoUrl !== "" ? userInfo.avatarSSoUrl : userInfo.avatarUrl}
                      >{userInfo.abr}</Avatar>
                    </Tooltip>
                  </IconButton> : <IconButton
                    aria-label="more"
                    aria-controls={openAccoutMenu ? 'long-menu' : undefined}
                    aria-expanded={openAccoutMenu ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleAccountClick}
                    id="account-btn">
                    <Tooltip title="Profile" >
                      <IoPersonOutline size={27} />
                    </Tooltip>
                  </IconButton>
                }
                </React.Fragment> : null}
              </Stack>
            </Grid>
          </Grid> : 
          <Grid container padding={1} style={{height: 70}}>
            <Grid item xs={9} sm={9} md={9} 
              justifyContent={"center"} 
              padding={1} 
              alignItems={"flex-start"}>
              <Stack alignItems={"flex-start"}>
                <Search
                  userToken={userInfo?.userToken}
                  userID={userInfo?.userID}
                  activateSearchWindow={true}
                />
              </Stack>
            </Grid>
            <Grid item xs={3} sm={3} md={3} padding={1} justifyContent={"center"}>
              <Stack style={{width: '100%'}} alignItems={"flex-end"}>
                <IconButton
                  aria-label="more"
                  onClick={() => setOpenMobileMenu(true)}
                  id="himenu-btn">
                  <Badge badgeContent={unseenDownloads} color="secondary">
                    <HiMenu size={27} />
                  </Badge>
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
          }
          {selected.length > 0 ? 
          <Grid container padding={1} style={{height: 50}}>
            <Stack direction="row" spacing={1} mr={2.5} 
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
              style={{
              marginBottom: 0, 
              marginTop: -5,
              width: '100%'
              }}>
              <Typography style={{
                fontWeight: 'bold',
                marginLeft: 10,
                fontSize: 18,
                marginBottom: 5
                }}>Selected: {selectedRows.length}</Typography>
                <Tooltip title="Cancel selection" >
                  <IconButton
                    aria-label="cancel"
                    onClick={() => { dispatch(ClearSelectedRows()) }}
                    >
                    <CgClose size={23} color="black" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy selected" >
                  <IconButton
                    aria-label="copy"
                    onClick={() => { 
                      setItemsForCopy(selectedRows.map((item, index) => {var obj = {}; obj['id'] = item; return obj}))
                      setShowCopyDialog(true)
                    }}
                    >
                    <FiCopy size={23} color="black" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Move selected" >
                  <IconButton
                    aria-label="more"
                    onClick={() => {
                      setItemsForMove(selectedRows.map((item, index) => {var obj = {}; obj['id'] = item; return obj}))
                      setShowMoveDialog(true) 
                    }}
                    >
                    <BiMove size={23} color="black" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download" >
                  <IconButton
                    aria-label="download"
                    id="download-btn"
                    onClick={() => {
                      //const tmpID = randomString(10);
                      //dispatch(AddDownloads(tmpID, (new Date()).toLocaleString(), config.configObject.downloadPath, 'downloaded_files.zip', true ))
                      //setProgress(0.001)
                      downloadSelected(selectedRows, userInfo.userToken, config.deviceID)
                    }}
                  >
                    <AiOutlineDownload size={23} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Share" >
                  <IconButton
                    aria-label="share"
                    id="share-btn"
                    onClick={() => {
                      setShowShareDialog(true);
                    }}
                  >
                    <PiShareFatBold size={23} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Move to bin" >
                  <IconButton
                    aria-label="more_to_bin"
                    onClick={() => {
                      setShowDeleteDialog(true)
                      //dispatch(DeleteFileObjects(selectedRows))
                    }}
                  >
                    <MdDeleteOutline size={23} />
                  </IconButton>
                </Tooltip>
            </Stack>
          </Grid> : 
          <Grid container style={{height: 50}}>
            <Grid item xs={11}>
              <Stack  marginLeft={2} 
                flexDirection={"column"} 
                alignItems={"flex-start"} 
                style={{width: '100%', height: '100%', justifyContent: 'center'}}>
                <CustomBreadCrumb 
                  disabled={dataLoading}
                  env={env}
                  breadCrumbs={createBreadCrumb(userInfo?.root, mainListBreadCrumb, userInfo, sharePage)}
                  onItemPress={(_id, env) => {
                    if (sharePage && !_id) {
                      navigate(SHARES);
                    } else if (sharePage && _id) {
                      var url = `${SHARES}/${_id}/`;
                      navigate(url)
                    } else {
                      var url = `${FILE_EXPLORER_PAGE}/${_id}/`;
                      if (p) {
                        url += '?p='+p + `&env=${env}`;
                      }
                      navigate(url)
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack direction={"row"} alignItems={"end"} justifyContent={"end"} pt={0.3}>
                <IconButton
                  aria-label="more"
                  style={{marginRight: 30}}
                  onClick={() => { 
                    var currentFsID;
                    if (q) {
                      currentFsID = `${userInfo.root}_search`;
                    } else {
                      currentFsID = sharePage ? `${userInfo.root}_share` : currentFolderID;
                    }

                    if (layoutType === 'list') {
                      setLayoutType('grid');
                      const fileIDS = getFileIDS(fileStruct[currentFsID]?.fileList);
                      reduxPreviewWrapper(fileIDS, currentFsID, 'medium', getToken(userInfo, p), dispatch)
                      saveLayoutType("grid")
                    } else {
                      const fileIDS = getFileIDS(fileStruct[currentFsID]?.fileList);
                      reduxPreviewWrapper(fileIDS, currentFsID, 'small', getToken(userInfo, p), dispatch)
                      setLayoutType('list');
                      saveLayoutType("list");
                    }
                  }}
                  id="notification-btn">
                    {layoutType === 'grid' ? <PiListDashes size={23} color="black"/> : 
                    <IoGridOutline size={23} color="black"/>}
                </IconButton>
              </Stack>
              
            </Grid>
          </Grid>}
            <Box 
              ref={(el) => {
                scrollRef(el)
                dropTableRef(el)
              }}
              sm={12} 
              paddingLeft={2} 
              paddingRight={2}
              className="table-container" 
              id="scrollableDiv"

              style={{
                backgroundColor: tableContainerBackgroundColor, 
              }}
              >
                {layoutType === 'list' ? <FileExplorerList 
                fileStruct={fileStruct}
                userInfo={userInfo}
                selected={selected}
                order={order}
                orderBy={orderBy} 
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                fsKey={fsKey}
                headCells={headCells}
                tableContainerBackgroundColor={tableContainerBackgroundColor}
                mainListBreadCrumb={mainListBreadCrumb}
                sharePage={sharePage}
                q={q}
                updateListView={updateListView}
                windowWidth={windowWidth}
                dataLoading={dataLoading}
                onMenuItemSelect={onMenuItemSelect}
                applicationControls={applicationControls}
                showShareDialogFromBtn={showShareDialogFromBtn}
                isSelected={isSelected}
                p={p}
                env={localRef.current.env}
              /> : 
              <FileExplorerGrid 
                fileStruct={fileStruct}
                userInfo={userInfo}
                selected={selected}
                order={order}
                orderBy={orderBy} 
                handleSelectAllClick={handleSelectAllClick}
                handleRequestSort={handleRequestSort}
                fsKey={fsKey}
                headCells={headCells}
                tableContainerBackgroundColor={tableContainerBackgroundColor}
                mainListBreadCrumb={mainListBreadCrumb}
                sharePage={sharePage}
                windowWidth={windowWidth}
                dataLoading={dataLoading}
                q={q}
                onMenuItemSelect={onMenuItemSelect}
                applicationControls={applicationControls}
                showShareDialogFromBtn={showShareDialogFromBtn}
                isSelected={isSelected}
                p={p}
                env={localRef.current.env}
              />}
              <div 
                style={{flex: "0 1 100%"}}>
                <Stack justifyContent={"center"} alignItems={"center"} style={{width: '100%'}}>
                  <EmptyFolderLabel 
                      show={fileStruct[fsKey]?.fileList.length === 0 && 
                      !dataLoading ? true : false} />
                </Stack>
              </div>
            </Box>
        </Box>
        {userInfo.userToken ? 
        <React.Fragment>
          <Menu
            id="user-account-menu"
            MenuListProps={{
              'aria-labelledby': 'user-account-menu',
            }}
            anchorEl={anchorAccoutMenu}
            open={openAccoutMenu}
            onClose={handleCloseAccout}
            className="user-account-menu"
            paper={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '45ch',
              },
            }}
          >
            {accoutOptions.map((option) => (
              <MenuItem key={option.id} onClick={() => handleCloseAccout(option.id)} style={{ width: 188 }}>
                <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                  <Grid item xs={2.3}>{option.iconObject}</Grid>
                  <Grid item xs={7}>{option.name}</Grid>
                </Stack>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              style={{ pointerEvents: 'none', height: 50 }}
            >
              <Box style={{ width: '100%', marginBottom: 10 }}>
                <LinearProgressWithLabel
                  value={userInfo && userInfo?.userUsedSpace && userInfo?.userTotalSpace ? 100 * userInfo.userUsedSpace / userInfo.userTotalSpace : 0}
                  actualSize={valueBytesToString(userInfo ? userInfo.userUsedSpace : 0)}
                  userSpaceSize={valueBytesToString(userInfo ? userInfo.userTotalSpace : 0)}
                />
              </Box>
            </MenuItem>
          </Menu>
          <Menu
            id="create-item-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorActions}
            open={openActionsMenu}
            onClose={handleActionsMenu}
            className="account-menu"
            anchorOrigin={{
              vertical: 0,
              horizontal: 10,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{ marginTop: -2, marginLeft: 20 }}
            paper={{
              style: {
                maxHeight: ITEM_HEIGHT * 4,
                width: '50ch',
              },
            }}
          >
            <MenuItem key={0} onClick={() => handleCreate(0)} style={{ width: 180 }}>
              <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                <Grid item xs={3}><AiFillFolderAdd size={25} /></Grid>
                <Grid item xs={7}>Create Folder</Grid>
              </Stack>
            </MenuItem>
            <MenuItem key={1} onClick={() => handleCreate(1)} style={{ width: 180 }}>
              <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                <Grid item xs={3}><AiOutlineUpload size={25} /></Grid>
                <Grid item xs={7}>Upload File</Grid>
              </Stack>
            </MenuItem>
            <MenuItem key={2} onClick={() => handleCreate(2)} style={{ width: 180 }}>
              <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                <Grid item xs={3}><ImFolderUpload size={21} /></Grid>
                <Grid item xs={7}>Upload Folder</Grid>
              </Stack>
            </MenuItem>
          </Menu>
          <InfiniteScroll
            dataLength={fileStruct[currentFolderID]?.fileList.length > 0 ? fileStruct[currentFolderID]?.fileList.length : 0}
            next={()=> loadMore(localRef.current.env)}
            hasMore={fileStruct[currentFolderID]?.hasMore}
            style={{ zIndex: 999, width: '100%' }}
            scrollableTarget="scrollableDiv"
            hasChildren={true}
          />
          <HeaderContainerUpload
            value={createBreadCrumb(userInfo?.root, mainListBreadCrumb, userInfo, sharePage)}
            show={showContainerHeaderUpload}
            setShowContainerHeaderUpload={SetShowContainerHeaderUpload}
            userInfo={userInfo}
            currentFolderID={currentFolderID}
          />
          <CopyFileListDialog 
            open={showCopyDialog}
            onCancel={() => setShowCopyDialog(false)}
            userToken={userInfo?.userToken}
            initialFolderID={userInfo?.root}
            itemsForCopy={itemsForCopy}
            onSubmit={(folderID) => {
              copy(folderID, itemsForCopy, userInfo?.userToken)
            }}
          />
          <MoveFileListDialog 
            open={showMoveDialog}
            onCancel={() => setShowMoveDialog(false)}
            userToken={userInfo?.userToken}
            initialFolderID={userInfo?.root}
            itemsForMove={itemsForMove}
            onSubmit={(folderID) => {
              var ids = [];
              console.log("SELECTED: ", selectedRows)
              for (var i = 0 ;i < itemsForMove.length; i++) {
                ids.push(itemsForMove[i].id)
              }
              move(folderID, ids, userInfo?.userToken).then(data => {
                dispatch(ProcessMsg(data, ALERT_TYPE_ERROR))
              })
              for (var i = 0 ;i < ids.length; i++) {
                dispatch(DeleteFileInList(currentFolderID, ids[i]))
              }
            }}
          />
          <RenameDialog 
            open={renameObjectData ? true : false}
            objectType={renameObjectData?.objectType}
            objectID={renameObjectData?.id}
            oldName={renameObjectData?.name}
            userToken={userInfo?.userToken}
            onCloseRename={() => setRenameObjectData(null)}
            parentID={currentFolderID}
          />
          <ShareDialog 
            open={showShareDialog}
            onCancel={() => {
              setShowShareDialog(false);
              setShareItems([])
            }}
            share_permissions={userInfo?.share_permissions}
            onSubmit={(access, targetUser) => {
              var ids = [];
              for (var i = 0; i < shareItems.length; i++) {
                ids.push(shareItems[i].id)
              }
              const targetEmail = targetUser.target_user ? targetUser.target_user.email : targetUser;
              share(ids, access, targetEmail, userInfo?.userToken)
            }}
            selected={selected}
            shareName={shareItems ? shareItems[0]?.original_name : ''}
            userInfo={userInfo}
          />
          <ManageAccessDialog 
            open={showManageAccessDialog}
            onCancel={() => setShowManageAccessDialog(false)}
            manageAccessFileObject={manageAccessFileObject}
            userToken={userInfo?.userToken}
            userInfo={userInfo}
          />
          <ManageLinkAccessDialog 
            showManageLinkDialog={showManageLinkDialog}
            manageLinkResource={manageLinkResource}
            handleCancel={() => {
              setShowManageLinkDialog(false)
            }}
            publicAccess={publicAccess}
            userInfo={userInfo}
          />
          <CreateFolderDialog 
            parentObjectID={currentFolderID}
            open={openCreateFolder}
            userToken={userInfo?.userToken}
            setOpenCreateFolde={setOpenCreateFolder}
          />
          <Downloads
            onClose={() => setOpenDownloads(false)}
            open={openDownloads} />
          <MoveToBin 
            showDeleteDialog={showDeleteDialog}
            handleCancel={handleCancel}
            selectedRows={deleteFileObjects.length > 0 ? deleteFileObjects : getObjectListByIds(currentFolderID, selectedRows, fileStruct)}
            handleDeleteItems={handleDeleteItems}
          />
        </React.Fragment> : null}
      </Box>
  </TopComponent>
  );
}