import { Box, Fab, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import 'react-data-grid/lib/styles.css';
import DataGrid, { textEditor, Column } from "react-data-grid";
import { lightStyle } from "../../../style/light_style";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, FILE_EXPLORER_PAGE } from "../../../utils/consts";
import { FiArrowLeft } from "react-icons/fi";
import { cutLongString } from "../../../utils/helper";


function getFileObject(tmpID, userToken) {
  return fetch(API + `/fileobject/${tmpID}`, {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(response =>{
    return response.arrayBuffer()
  })
}

export function ExcelApp() {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  const fileList = useSelector(state => state.fileListReducer.fileList)
  const [gridData, setGridData] = useState({});
  const [excelName, setExcelName] = useState("")
  let { fileObjectID, parentDir } = useParams();
  const navigate = useNavigate();

  useEffect(()=>{
    getFileObject(fileObjectID, userInfo.userToken).then(data=>{
      const userDoc = XLSX.read(data);
      const ws = userDoc.Sheets[userDoc.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(ws, { header:1 });
      const range = XLSX.utils.decode_range(ws["!ref"]||"A1");
      const columns = Array.from({ length: range.e.c + 1 }, (_, i) => ({
        key: String(i), // RDG will access row["0"], row["1"], etc
        name: XLSX.utils.encode_col(i), // the column labels will be A, B, etc
        editor: textEditor // enable cell editing
      }));
      setGridData({rows: rows, columns: columns})
      for (var i = 0;i < fileList.length; i++) {
        if (fileList[i].id === fileObjectID) {
          setExcelName(fileList[i].original_name)
        }
      }
    })
  }, [])

  return (
    <Box 
      style={lightStyle.mainBackgroud}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Fab 
        onClick={()=>{
          navigate(backUrl)
        }}
        color="primary" 
        size={"medium"}
        style={{position: 'fixed', top: '2%', left: '2%'}}>
        <FiArrowLeft size={18}/>
      </Fab>
      <Stack direction={"row"} style={{width: '100%',background: '#ECEEF0', height: 50}}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} lg={3}>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            {excelName?
              <Typography style={{width: '100%', marginTop: 12, fontSize: 16, fontStyle: 'italic'}} color="text.secondary">
                {cutLongString(excelName, 100)}
              </Typography>:null}
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Stack direction={"row-reverse"}>
              <Box style={{marginTop: 5}}>
              </Box>
              <Box style={{marginTop: 5}}>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {gridData.columns && gridData.rows?<DataGrid className="rdg-light"
      style={{width: '100%', height: '100%', backgroundColor: 'white'}}
      background="white"
      columns={gridData.columns} rows={gridData.rows} />:null}
      
    </Box>
  )
}