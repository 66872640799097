import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, LinearProgress } from "@mui/material";

export function LinearProgressWithLabel(props) {

  const [backgroundColor, setBackgroundColor] = useState('rgba(25, 118, 210, 0.3)')
  const [progressColor, setProgressColor] = useState('rgb(25, 118, 210)')

  useEffect(() => {
    var defaultColor = [25, 118, 210];
    /* var r = props.value * 255 / 100 ;
    if (25 + r < 255) {
      r += 25;
    } else {
      r = 255;
    }
    defaultColor[0] = r;
    console.log("R: ", r , props.value) */
    if (props.value > 90) {
      defaultColor[0] = 255;
      defaultColor[1] = 60;
      defaultColor[2] = 56;
    }
    setBackgroundColor(`rgba(${defaultColor}, 0.3)`)
    setProgressColor(`rgba(${defaultColor})`)
  }, [])

  return (
    <Stack sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 2 }}>
      <Box sx={{ minWidth: "30%" }}>
        <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>Used</Typography>
      </Box>
      <Box sx={{ width: '98%', mr: 1 }}>
        <LinearProgress variant="determinate" value={props.value} sx={{backgroundColor: backgroundColor,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: progressColor
                  }}}/>
      </Box>
      <Box sx={{ minWidth: "30%" }}>
        <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>{props.actualSize + " of " + props.userSpaceSize}</Typography>
      </Box>
    </Stack>
  );
}