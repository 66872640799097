import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { AlertData } from "../../objects/alert";
import { API, AlertType, TARGET_PLATFORM } from "../../utils/consts";
import { AddDownloads, SetAlertData } from "../../redux_store/actions";
import config from "../../managers/config";
import { AiOutlineDownload } from "react-icons/ai";
import { downloadSelected, getTmpUrl, getToken } from "src/utils/helper";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function SmartDownload(props) {
  let query = useQuery();
  const p = query.get('p');
  if (!props.progress || props.progress === -1) {
    return (
      <Tooltip title="Download file">
        <IconButton
          aria-label="more"
          onClick={()=>{
            var alertData = new AlertData();
            alertData.type = AlertType.INFO;
            alertData.message = "Startign to download " + props.value.original_name
            props.dispatch(SetAlertData(alertData))
            if (TARGET_PLATFORM === 'web') {
              if (props.value.object_type === 'folder') {
                downloadSelected([props.value.id], props.userToken, config.deviceID)
              } else {
                var userInfo = {};
                userInfo.userToken = props.userToken;
                getTmpUrl(props.value.id, getToken(userInfo, p)).then(data=>{
                  if (!data.msg) {
                    const tmpID = data.id;
                    const tmpURL = API + `/tmp-file-object-content/${tmpID}?download=2`;
                    document.getElementById('magic-frame').src = tmpURL;
                  }
                })
              }
            } else {
              props.dispatch(AddDownloads(
                props.value.id, 
                (new Date()).toLocaleString(), 
                config.configObject.downloadPath, 
                props.value.original_name, 
                false, 
                props.value.object_type)
              )
              props.setProgress(0.001)
            }
            /* invoke("downalodFile", 
            {
              downloadDir: config.configObject.downloadPath,
              fileUrl: API+'/fileobject/'+props.value.id+"/",
              fileName: props.value.original_name,
              fileObjectId: props.value.id,
              userToken: props.userToken
          }) */
          }}
          id="download-btn">
          <AiOutlineDownload size={22}/>
        </IconButton>
    </Tooltip>
    )
  } else {
    return null;
  }
}