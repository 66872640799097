import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NativeTypes } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import {Typography, Box, Stack, Grid, IconButton, Menu, MenuItem, Tooltip} from '@mui/material'
import { HiDotsVertical } from "react-icons/hi";
import { GetListObjectIcon, covertDate, craeteUploadStructure, createDirTree, createFolders, getAccesses, getAllFileEntries, getDefaultObjectMenu, getFileDirPath, getFileListObjectMenu, getFileObjectMenu, getMainRootFromPath, getMainRootObject, randomString, setFavorite } from "../../utils/helper";
import { API, AlertType, DND_DEFAULT_BG_COLOR, DND_HOVERED, FILE_EXPLORER_PAGE, HOVERED_COLOR2, ITEM_HEIGHT, SELECTED_ROW_COLOR, SHARES, options } from "../../utils/consts";
import { AddFolderInList, AddTmpUploadFileObject, AddUploadStructure, AddUploads, CancelActiveAxiosUpload, DeleteFileInList, DeleteUploadObject, RemoveActiveDirectoryUpload, RemoveActiveUploads, RemoveUploadEntity, SelectUsingShift, SetAlertData, SetFileListBgColor, SetFileUploadInFolder, SetTmpPathsFromRust, SetUnsetSelectedRow, SetitemsForAction } from "../../redux_store/actions";
import { Favorite } from "../base/favorite";
import { ResolverParams, resolveObjectType } from "../applications/resolver";
import { AlertData } from "../../objects/alert";
import { useDrag, useDrop } from "react-dnd";
import { SmartProgress } from "../base/smart_progress";
import { CgClose } from "react-icons/cg";
import { ClassNames } from "@emotion/react";
import { FaSync } from "react-icons/fa";


function getGridItemWidth(minItemWidth, currentWindowWidth) {
  var n = currentWindowWidth / minItemWidth;
  var n1 = Math.floor(n)
  if ((n % 1) > 0.8) {
    n1 = n1 + 1;
    n = n + 1;
  }
  var newSize = (currentWindowWidth - (n1 + 1) * 8) / n1 + 1;
  if (newSize> 410) {
    return 400
  }
  return newSize
}


function GridItem(props) {

  const [anchorEl, setAnchorEl] = useState(false);
  const [containerClassName, setContainerClassName] = useState('list-fade-item');
  const syncedFolders = useSelector(state => state.fileListReducer.syncedFolders)
  const [menuOptions, setMenuOptions] = useState([]);
  const [bgColor, setBgColor] = useState('#d2dae2');
  const selectedRows = useSelector(state => state.fileListReducer.selectedRows)
  const filesForUploadUpdate = useSelector(state => state.uploadsReducer.filesForUploadUpdate)
  const inFolder = useSelector(state => state.fileListReducer.inFolder);
  const [ progress, setProgress ] = useState(undefined);
  const open = Boolean(anchorEl);

  const {item, gridWidth, sharePage, userInfo, p, q,
    isItemSelected, onMenuItemSelect, applicationControls} = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemRef = useRef();
  var useDropFunction = {};
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleSelectedFav() {
    setFavorite(item.id, userInfo?.userToken);
  }
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'box',
      item: item ,
      end(_item, monitor) {
        const dropResult = monitor.getDropResult()
        if (_item && dropResult) {
          console.log(dropResult)
        }
      },
      canDrag(monitor) {
        if (item.id.length === 15) {
          return false;
        }
        if (item.created === -1) {
          return false;
        }
        return true
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [item.original_name],
  )

  if (item.object_type === 'folder' && userInfo) {
    useDropFunction = {
    accept: [NativeTypes.FILE, 'box'],
    drop(_item) {
      if (_item.object_type) {
        var selectedIds = []
        for ( var i = 0; i < selectedRows.length; i++) {
          selectedIds.push(selectedRows[i])
        }
        selectedIds.push(_item.id)
        fetch(API+'/move/'+item.id+'/',  {
          method: 'POST',
          headers: {
            'AuthToken': userInfo?.userToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            source_objects: selectedIds
          })
        }).then(resp =>{
          return resp.json()
        }).then(respJson => {
          if (respJson.msg || respJson.detail) {
            var alertData = new AlertData();
            alertData.type = AlertType.ERROR;
            alertData.message = respJson.msg || respJson.detail;
            dispatch(SetAlertData(alertData))
          } else {
            selectedIds.map((item, index) => {
              dispatch(DeleteFileInList(_item.parent_dir, item))
            })
          }
        })
      } else if (_item && _item.dataTransfer) {
        var items = _item.dataTransfer.items;
        getAllFileEntries(items).then(files=>{
          const flattenFiles = files.reduce((acc, val) => acc.concat(val), []);
          var onlyDirs = [];
          var onlyFiles = [];
          var folders = []
          for (var i = 0;i < flattenFiles.length; i++) {
            if (flattenFiles[i].isDirectory) {
              onlyDirs.push(flattenFiles[i]);
              folders.push(flattenFiles[i].fullPath);
            } else if (flattenFiles[i].path) {
              onlyFiles.push(flattenFiles[i])
            }
          }
          if (onlyFiles.length > 2000) {
            var meta = {
              currentFolderID: item.id
            }
            dispatch(SetTmpPathsFromRust(onlyFiles, folders, meta));
          } else {
            var parsed_files = [];
            for (let i = 0; i < onlyFiles.length; i++) {
              
              const f = onlyFiles[i];
              var lastIndexOfSlash = f.path.lastIndexOf("/");
              
              var filePath = "";
              if (lastIndexOfSlash === -1) {
                continue
              }
              if (lastIndexOfSlash === 0) {
                filePath = '/'
              } else {
                filePath = f.path.slice(0, lastIndexOfSlash)
              }
              folders.push(getFileDirPath(filePath));
              parsed_files.push({
                full_path: f.path,
                relative_path: f.path,
                id: randomString(15),
                name: f.name,
                size: f.size,
                file_object: f,
                relative_path_parent_dir: getFileDirPath(f.path)
              })
            }
            var dirTree = createDirTree(onlyDirs);
            
            createFolders(item.id, dirTree, userInfo?.userToken).then(data=>{
              
              if (data.msg) {
                var alertData = new AlertData();
                alertData.type = AlertType.ERROR;
                alertData.message = data.msg;
                dispatch(SetAlertData(alertData))
                return ;
              }
              
              var upload_structure = craeteUploadStructure(parsed_files, data.created_folders, data.main_roots);
    
              for (var i = 0; i < data.root_folders.length; i++) {
                dispatch(AddFolderInList(data.root_folders[i]));
              }
              var tmp_main_root_object = getMainRootObject(data.main_roots);
              dispatch(AddUploadStructure(upload_structure))
              for (var i = 0; i < parsed_files.length; i++) {
                var fileObject = parsed_files[i];
                const relative_path_parent_dir = fileObject.relative_path_parent_dir;
                var parentID = data.created_folders[relative_path_parent_dir]
                const main_root = getMainRootFromPath(relative_path_parent_dir);
                if (parentID) {
                  var tmpObject = {
                    original_name: fileObject.name,
                    id: fileObject.id,
                    created: covertDate(new Date()),
                    updated: 0,
                    size: fileObject.size,
                    favorite: false,
                    object_type: 'file',
                    progress: 0.001,
                    upload: true,
                    parent_dir: parentID,
                    main_root_id: tmp_main_root_object[main_root],
                    file: fileObject.file_object
                  };
                  dispatch(AddTmpUploadFileObject(tmpObject))
                  dispatch(AddUploads(tmpObject))
                }
              }
            })
          }});
      }
    },
    canDrop(_item, monitor) {
      if (item.id === _item.id) {
        return false;
      }
      if (item.object_type === 'folder') {
        return true
      }
      return false
    },
    hover(_item) {
      if (item.object_type === 'folder') {
        if (!inFolder) {
          dispatch(SetFileUploadInFolder(true))
          if (_item && _item.dataTransfer) {
            var items = _item.dataTransfer.items;
            var length = items.length;
            if (length > 0) {
              var itemString = length > 1?length+" items in ":length+" item in "
              var alertData = new AlertData();
              alertData.type = AlertType.INFO;
              alertData.message = "Upload " + itemString + item.original_name
              dispatch(SetAlertData(alertData))
            }
          } else if (_item.object_type) {
            var message = `Move ${_item.original_name} in ${item.original_name}`
            if (selectedRows.length > 0) {
              message = `Move selected items in ${item.original_name}`
            }
            var alertData = new AlertData();
            alertData.type = AlertType.INFO;
            alertData.message = message
            dispatch(SetAlertData(alertData))
          }
        }
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver({shallow: false}),
        canDrop: monitor.canDrop(),
      }
    },
    }
  } else if (userInfo) {
    useDropFunction = {
      accept: ['cc'],
      canDrop(_item, monitor) {
        return false
      }
    }
  }

  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => (useDropFunction), [selectedRows]
  )

  useEffect(()=>{
    if (isOver === false) {
      if (!isItemSelected) {
        setBgColor('#d2dae2')
      } else {
        setBgColor("#75ace4")
      }
      dispatch(SetFileUploadInFolder(false))
    } else {
      if (!isItemSelected) {
        setBgColor(HOVERED_COLOR2)
      } else {
        setBgColor("#75ace4")
      }
    }
  }, [isOver]) 

  useEffect(() => {
    setTimeout(() => {
      setContainerClassName('')
    }, 2000)
    
  }, [])

  useEffect(() => {
    if (isItemSelected) {
      setBgColor('#75ace4')
    } else {
      setBgColor('#d2dae2')
    }
  }, [isItemSelected])

  const onMenuOpen = () => {
    if (sharePage || q) {
      setMenuOptions(getDefaultObjectMenu(options));
      getAccesses(item.id, userInfo?.userToken).then((data)=> {
        setMenuOptions(getFileObjectMenu(userInfo, data, options))
      })
    } else {
      item.synced = syncedFolders[item.id];
      console.log("SYNC: ", syncedFolders, syncedFolders[item.id]);
      setMenuOptions(getFileListObjectMenu(item, options));
    }
    if (isItemSelected) {
      dispatch(SetitemsForAction([-1]))
    } else {
      dispatch(SetitemsForAction([item.id]))
    }
  }

  if (itemRef.current) {
    dragRef(dropRef(itemRef))
  }


  return (
    <Stack 
      ref={itemRef}
      padding={1}
      className={containerClassName}
      onMouseEnter={() => {
        if (!isItemSelected) {
          setBgColor(HOVERED_COLOR2)
        }
      }}
      onMouseLeave={() => {
        if (!isItemSelected) {
          setBgColor('#d2dae2')
        }
      }}
      style={{
        background: bgColor, 
        width: gridWidth, 
        height: 260, 
        display: 'flex', 
        borderRadius: 10,
        userSelect: 'none',
      }}
      onClick={(e) => {
        if (e.shiftKey) {
          dispatch(SelectUsingShift(item.parent_dir, item.id))
        } else if (e.ctrlKey ) {
          dispatch(SetUnsetSelectedRow(item.parent_dir, item.id))
        } else if (e.detail === 2) {
          e.preventDefault();
          if (item.object_type === 'folder') {
            var url = '';
            if (sharePage) {
              url = `${SHARES}/${item.id}/`;
            } else {
              url = `${FILE_EXPLORER_PAGE}/${item.id}/`;
            }
            if (p) {
              url += '?p='+p;
            }
            navigate(url)
          } else {
            var resolverParams = new ResolverParams();
            resolverParams.objectID = item.id;
            resolverParams.parentDir = item.parent_dir;
            const resolverObject = resolveObjectType(applicationControls, resolverParams, item.file_type);
            if (resolverObject && resolverObject.url) {
              var url = resolverObject.url+'?file_type='+item.file_type;
              if (p) {
                url += '&p='+p;
              }
              navigate(url)
            } else {
              var alertData = new AlertData();
              alertData.type = AlertType.ERROR;
              alertData.message = "Didn't find a suitable application to open this file."
              dispatch(SetAlertData(alertData))
            }
          }
        }
      }}
    >
      <Stack 
        style={{height: 50, background: bgColor, width: '100%', cursor: 'pointer'}} 
        direction={"row"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (item.object_type === 'folder') {
            var url = '';
            if (sharePage) {
              url = `${SHARES}/${item.id}/`;
            } else {
              url = `${FILE_EXPLORER_PAGE}/${item.id}/`;
            }
            if (p) {
              url += '?p='+p;
            }
            navigate(url)
          } else {
            var resolverParams = new ResolverParams();
            resolverParams.objectID = item.id;
            resolverParams.parentDir = item.parent_dir;
            const resolverObject = resolveObjectType(applicationControls, resolverParams, item.file_type);
            if (resolverObject && resolverObject.url) {
              var url = resolverObject.url+'?file_type='+item.file_type;
              if (p) {
                url += '&p='+p;
              }
              navigate(url)
            } else {
              var alertData = new AlertData();
              alertData.type = AlertType.ERROR;
              alertData.message = "Didn't find a suitable application to open this file."
              dispatch(SetAlertData(alertData))
            }
          }
        }}
        >
        <Grid container>
          <Grid item xs={2}>
            <Stack 
              direction={"row"} 
              justifyContent={"start"} 
              alignItems={"start"} 
              style={{height: '100%', width: '100%'}}>
              {
                userInfo?.userToken ? <Favorite
                upload={item.upload}
                selected={item.favorite}
                setSelected={handleSelectedFav}
              /> : null}
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Stack 
              direction={"row"} 
              justifyContent={"start"} 
              alignItems={"start"} 
              style={{height: '100%', width: '100%'}}>
              <Tooltip title={item.original_name} placement="bottom-start">
                <Typography 
                  pt={1}
                  style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis',
                  fontSize: 16,
                  width: '100%', 
                  textAlign: 'left',
                }}>{item?.original_name}
                </Typography>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction={"row"} alignItems={"end"} justifyContent={"end"}>
              {filesForUploadUpdate[item.id]?.progress ? <Tooltip title="Cancel upload" >
                <IconButton
                  aria-label="more"
                  style={{marginLeft: 20}}
                  onClick={(e)=>{
                    console.log("ITEM: ", item)
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(RemoveUploadEntity(item.id, 'file', item.main_root_id));
                    dispatch(DeleteFileInList(item.parent_dir, item.id));
                    dispatch(RemoveActiveUploads(item.id));
                    dispatch(DeleteUploadObject(item.id));
                    dispatch(RemoveActiveDirectoryUpload(item.parent_dir, item.id));
                    dispatch(CancelActiveAxiosUpload(item.id))
                  }} // Place for action 'CancelUpload'
                  >
                  <CgClose size={22} color="black" style={{width: '22px'}}/>
                </IconButton>
              </Tooltip> : <IconButton
                aria-label="more-btn"
                id="row-menu-btn"
                aria-controls={props.open ? 'row-menu-btn' : undefined}
                aria-expanded={props.open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e)=>{
                  e.stopPropagation();
                  handleMenuClick(e)
                  onMenuOpen()
                }}
                >
                <HiDotsVertical size={20}/>
              </IconButton>}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack 
        style={{flex: 1, background: bgColor, width: '100%'}} 
        justifyContent={"center"} 
        alignItems={"center"}
      >
        {filesForUploadUpdate[item.id]?.progress ? <Stack 
          direction={"row"} style={{marginBottom: -35, width: '100%'}} alignItems={"end"} justifyContent={"end"}>
          <SmartProgress
            value={item}
            progress={filesForUploadUpdate[item.id]?.progress}
            setProgress={setProgress}
          />   
        </Stack> : null}
        {syncedFolders[item.id] ? <Stack direction={"row"} style={{flex: 1, position: 'relative'}} justifyContent={"end"}>
         <GetListObjectIcon 
          props={item} 
          iconSize={150} 
          folderSize={200}
          imageSize={gridWidth} 
          previewFormat={'medium'} />
          <FaSync size={30} style={{position: 'absolute', color: "#537188", fontWeight: "bold"}} />
        </Stack>
        : <GetListObjectIcon 
        props={item} 
        iconSize={150} 
        folderSize={200}
        imageSize={gridWidth} 
        previewFormat={'medium'} />}
        
      </Stack>
      <Menu
        id="row-menu"
        MenuListProps={{
          'aria-labelledby': 'row-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
          setBgColor('#d2dae2')
        }}
        className="row-menu"
        paper={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
      {menuOptions.map((option) => (
        <MenuItem 
          key={option.id} 
          disabled={option.id===4 && (progress !== undefined && progress !== 1)} 
          onClick={()=>{
            onMenuItemSelect(option.id, item, userInfo?.userToken);
            setAnchorEl(null);
            }}>
          <Stack direction="row"  spacing={2}>
            <Grid item xs={4}>{option.iconObject}</Grid>
            <Grid item xs={8}><Typography>{option.name}</Typography></Grid>
          </Stack>
        </MenuItem>
      ))}
    </Menu>
    </Stack>
  )
}

export function FileExplorerGrid(props) {
  const [gridWidth, setGridWidth] = useState(300);
  const [items, setItems] = useState([])
  const ref = useRef()

  const {fileStruct, userInfo, fsKey, sharePage, onMenuItemSelect, applicationControls,
    isSelected, p, q} = props;
  
    useEffect(() => {
      const val = getGridItemWidth(300, ref.current?.offsetWidth);
      setGridWidth(val)
      window.addEventListener("resize", () => {
        const val = getGridItemWidth(300, ref.current?.offsetWidth);
        setGridWidth(val)
      });
    }, [])

    useEffect(() => {
      //setItems([])
      if (fileStruct[fsKey]?.fileList) {
        setTimeout(() => {
          setItems(fileStruct[fsKey]?.fileList)
        }, 50);
      }
    }, [fileStruct[fsKey]?.fileList])

    useEffect(() => {
      setItems([])
    }, [fsKey])

  return (
    <Stack 
      ref={ref}
      style={{width: '100%'}} 
      direction={"row"} 
      spacing={1}
      useFlexGap 
      flexWrap="wrap">
        {items.map((item, index) => {
          const isItemSelected = isSelected(item.id);
          return <GridItem 
            item={item} 
            key={index}
            gridWidth={gridWidth}
            sharePage={sharePage}
            userInfo={userInfo}
            isItemSelected={isItemSelected}
            onMenuItemSelect={onMenuItemSelect}
            applicationControls={applicationControls}
            q={q}
            p={p}
            />
        })}
    </Stack>
  )
}

/*

<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}></Grid>
*/