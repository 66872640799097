import { Box, Link, Checkbox, IconButton, Paper, Stack, Table, 
  TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, TableBody } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { lightStyle } from "../style/light_style";
import { getApplicationControls, getFiltered } from "../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { RowFileObject } from "./base/row_fileobject";
import { useLocation, useNavigate } from "react-router-dom";
import { AddFileInList, ClearSelectedRows, FilterFavoriteFiles, HasMore, SetBreadCrumb, SetCurrentPgPage, SetFileList, SetUnsetSelectedRow } from "../redux_store/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { API, FILE_EXPLORER_PAGE } from "../utils/consts";
import { FiArrowLeft } from "react-icons/fi";
import { Search } from "./search";
import { DEFAULT_BUTTON_BACKGROUND_COLOR, DEFAULT_LIGHT_TEXT_COLOR } from "src/style/global";
import { TopComponent } from "./base/top_component";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function search(data, userToken, page) {
  var requestQ = API+`/search/?q=${data}`
  if (page !== undefined) {
    requestQ = API+`/search/?q=${data}&page=${page}`
  }
  return fetch(requestQ,  {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
    },
  }).then(resp =>{
    return resp.json()
  })
}


const MemoFileTableRow = React.memo(RowFileObject, (prevProps, nextProps) => {
  return prevProps.windowWidth === nextProps.windowWidth &&
  prevProps.isItemSelected === nextProps.isItemSelected && 
  prevProps.row === nextProps.row
  });


function RenderRow(value, index, windowWidth, selectedRow, applicationControls, userInfo, currentUploaded, totalUploadItems) {

  return (
    <MemoFileTableRow 
      key={index} 
      row={value} 
      index={index} 
      windowWidth={windowWidth} 
      selectedRow={selectedRow}
      isItemSelected={selectedRow} 
      userInfo={userInfo}
      parentFolder={false}
      applicationControls={applicationControls}
      />
  );
}


export function SearchFileExplorer(props) {
  const activeFilters = useSelector(state => state.fileListReducer.activeFilters)
  const selectedRows = useSelector(state => state.fileListReducer.selectedRows)
  const backUrl = useSelector(state => state.userProfileReducer.backUrl);
  const fileStruct = useSelector(state => state.fileListReducer.fileStruct);
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const fileList = useSelector(state => state.fileListReducer.fileList)
  const filesForDownloadUpdate = useSelector(state => state.downloadsReducer.filesForDownloadUpdate)
  const filesForUploadUpdate = useSelector(state => state.uploadsReducer.filesForUploadUpdate)
  const currentPgPage = useSelector(state => state.fileListReducer.currentPgPage)
  const hasMore = useSelector(state => state.fileListReducer.hasMore)
  const selectedAllFiles = useSelector(state => state.fileListReducer.selectedAllFiles)
  const rootUploadCounter = useSelector(state => state.uploadsReducer.rootUploadCounter)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [applicationControls, setApplicationControls] = useState();

  const scroller = useRef();
  const scrollReturnRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let query = useQuery();
  let q = query.get("q")
  let windowSize = 40;
  
  function windowChangeWidth() {
    setWindowWidth(window.innerWidth)
  }

  function _loadList(_q) {
    search(_q, userInfo.userToken).then(data=>{
      if (data.files?.constructor.name === "Array") {
        dispatch(SetFileList(data.files, 'search', windowSize, false))
        dispatch(HasMore(data.has_more))
      }
    })
  }

  useEffect(() => {
    getApplicationControls().then(data => {
      setApplicationControls(data.applications_control)
    })
  }, [])

  useEffect(()=>{
    _loadList(q)
    dispatch(SetCurrentPgPage(2))
    window.addEventListener("resize", windowChangeWidth);
  }, [])

  function loadMore() {
    if (userInfo && q) {
      search(q, userInfo.userToken, currentPgPage).then(data=>{
        if (data.files.constructor.name === "Array") {
          for (var i = 0; i < data.files.length; i++) {
            dispatch(AddFileInList('search', data.files[i], false, true))
          }
          dispatch(HasMore(data.has_more))
        }
      })
      dispatch(SetCurrentPgPage(currentPgPage+1))
    }
  }


  return (
    <TopComponent
      >
      <Box
        justifyContent="center"
        alignItems="center"
        spacing={0}
        style={lightStyle.mainBackgroud}
      >
          {userInfo?.userToken ? 
            <Box>
              <Tooltip title="Go back to files" >
                <IconButton
                  aria-label="more"
                  onClick={() => {
                    if (backUrl) {
                      navigate(backUrl);
                    } else {
                      navigate(`${FILE_EXPLORER_PAGE}/${userInfo?.root}/`)
                    }
                  }}
                  style={{backgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR}}
                  id="shares-btn">
                    <FiArrowLeft size={22} color={DEFAULT_LIGHT_TEXT_COLOR}/>
                </IconButton>
              </Tooltip>
            </Box>
            : null}
        <Stack style={{position: 'absolute', width: 350, top: 15, transform: `translate(-50%, 0)`, left: '50%', zIndex:102}}>
          <Search 
            userToken={userInfo?userInfo.userToken:null}
            userID={userInfo?userInfo.userID:null}
            activateSearchWindow={false}
            onSearchPageList={(_val)=>{
              _loadList(_val)
              dispatch(SetCurrentPgPage(2))
            }}
          />
        </Stack>
        <Stack direction="column" sx={{ width: '100%', marginTop: 10 }} className="table-container" id="scrollableDivSearch"  ref={scroller}>
          <TableContainer component={Paper} style={{ overflowX: "initial", background: 'transparent', width: '100%', alignSelf: 'center'}} ref={scrollReturnRef}>
            <Table stickyHeader >
              <TableHead className="tb-head">
                <TableRow>
                  <TableCell color="primary" component="th" align="center" style={{ width: '50px' }}></TableCell>
                  <TableCell color="primary" component="th" align="left" style={{fontWeight: 'bold', width: '250px'}}>
                    <Typography style={{fontWeight: 'bold'}}>File name</Typography>
                  </TableCell>
                  <TableCell color="primary" component="th" align="center" style={{fontWeight: 'bold', width: '100px'}}>
                    <Typography style={{fontWeight: 'bold'}}>Created</Typography>
                  </TableCell>
                  {windowWidth > 1000 ? <TableCell color="primary" component="th" align="center" style={{fontWeight: 'bold', width: '60px'}}>
                    <Typography style={{fontWeight: 'bold'}}>Type</Typography>
                  </TableCell>: null}
                  {windowWidth > 1000 ? <TableCell color="primary" component="th" align="center" style={{fontWeight: 'bold', width: '70px'}}>
                    <Typography style={{fontWeight: 'bold'}}>Size</Typography>
                  </TableCell>: null}
                  <TableCell color="primary" component="th" align="center" style={{fontWeight: 'bold', width: '60px'}}>
                    <Link 
                    underline="none" 
                    onClick={()=>{dispatch(FilterFavoriteFiles())}}
                    href="#" 
                    color="text.primary"
                    >
                      <Typography style={{fontWeight: 'bold', marginTop: 0}}>Favorite</Typography>
                    </Link>

                  </TableCell>
                  <TableCell sx={{width: '200px'}}>
                    <Tooltip title={selectedAllFiles?"Cancel selection":"Select all"} >
                      <Checkbox
                        color="primary"
                        checked={selectedAllFiles}
                        style={{marginLeft: '157px', width: '45px'}}
                        onChange={()=>{
                          if (!selectedAllFiles) {
                            dispatch(ClearSelectedRows())
                            for (var i = 0; i < fileList.length; i ++) {
                              dispatch(SetUnsetSelectedRow(fileList[i].id))
                            }
                          } else {
                            dispatch(ClearSelectedRows())
                          }
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{width: '100%'}}>
                {fileStruct['search']?.fileList?.map((value, index) => {

                  if (!getFiltered(activeFilters, value)) {
                    return null
                  }
                  const pos = selectedRows.indexOf(value.id)
                  if (userInfo && filesForDownloadUpdate[value.id]) {
                    return RenderRow(value, index, windowWidth, pos !== -1, applicationControls, userInfo)
                  } else if (userInfo && filesForUploadUpdate[value.id]) {
                    return RenderRow(value, index, windowWidth, pos !== -1, applicationControls, userInfo)
                  } else if (userInfo) {
                    return RenderRow(value, index, windowWidth, pos !== -1, applicationControls, userInfo, rootUploadCounter[value.id]?.counter, rootUploadCounter[value.id]?.total);
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <InfiniteScroll
          dataLength={fileStruct['search']?.fileList?.length > 0 ? fileStruct['search']?.fileList?.length : 0}
          next={loadMore}
          hasMore={hasMore}
          style={{zIndex: 999, width: '100%'}}
          scrollableTarget="scrollableDivSearch"
          />
      </Box>
    </TopComponent>
  )
}