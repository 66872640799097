import { Box, Typography, CircularProgress, Grid, Paper, Stack, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Lightbox, { LoadingIcon, SLIDE_STATUS_PLACEHOLDER, hasWindow, makeComposePrefix, useEvents } from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { lightStyle } from "../../../style/light_style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API, AlertType, FILE_EXPLORER_PAGE } from "../../../utils/consts";
import { SLIDE_STATUS_LOADING } from "yet-another-react-lightbox";
import { useTimeouts } from "yet-another-react-lightbox";
import { activeSlideStatus } from "yet-another-react-lightbox";
import { useEventCallback } from "yet-another-react-lightbox";
import { SLIDE_STATUS_COMPLETE } from "yet-another-react-lightbox";
import { SLIDE_STATUS_ERROR } from "yet-another-react-lightbox";
import { isImageFitCover } from "yet-another-react-lightbox";
import { cssClass } from "yet-another-react-lightbox";
import { clsx } from "yet-another-react-lightbox";
import { ELEMENT_ICON } from "yet-another-react-lightbox";
import { ResolverParams, resolveObjectType } from "../resolver";
import { getApplicationControls, getSimpleFileList, getTmpUrl, getToken } from "src/utils/helper";
import { AddFilesInList, SetFileList } from "src/redux_store/actions";
import { TopComponent } from "src/components/base/top_component";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import { TfiClose } from "react-icons/tfi";
import { APPLICATION_HEADER_TITLE_FONT_COLOR, APPLICATION_HEADER_TITLE_FONT_SIZE } from "src/style/global";
import { getJarFlowObject } from "../../../utils/helper";
import { AlertData } from "../../../objects/alert";
import { SetAlertData } from "../../../redux_store/actions";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const slideImagePrefix = makeComposePrefix("slide_image");
const slidePrefix = makeComposePrefix("slide");

export default function Settings({ children }) {
  return (
    <Paper variant="outlined" sx={{ p: 2, textAlign: "left" }}>
      <Grid container rowSpacing={2} columnSpacing={4}>
        {children}
      </Grid>
    </Paper>
  );
}

function ImageSlide({ slide: image, offset, render, rect, imageFit, imageProps, onClick, onLoad, style, userInfo, p}) {
  var _a, _b, _c, _d, _e, _f, _g;
  const [status, setStatus] = React.useState(SLIDE_STATUS_ERROR);
  const [largePreview, setLargePreview] = useState("");
  const { publish } = useEvents();
  const { setTimeout } = useTimeouts();
  const imageRef = React.useRef(null);

  React.useEffect(() => {
      if (offset === 0) {
          publish(activeSlideStatus(status));
      }
      if (status === SLIDE_STATUS_ERROR) {
        getTmpUrl(image.src, getToken(userInfo, p)).then(data=>{
          if (!data.msg) {
            const tmpID = data.id;
            const tmpURL = API + `/tmp-file-object-content/${tmpID}?size=1200`
            setLargePreview(tmpURL)
            publish(activeSlideStatus(status))
          }
        })
      }
      
  }, []);
  const handleLoading = useEventCallback((img) => {
      ("decode" in img ? img.decode() : Promise.resolve())
          .catch(() => { })
          .then(() => {
          if (!img.parentNode) {
              return;
          }
          setStatus(SLIDE_STATUS_COMPLETE);
          setTimeout(() => {
              onLoad === null || onLoad === void 0 ? void 0 : onLoad(img);
          }, 0);
      });
  });
  const setImageRef = React.useCallback((img) => {
      imageRef.current = img;
      if (img === null || img === void 0 ? void 0 : img.complete) {
          handleLoading(img);
      }
  }, [handleLoading]);
  const handleOnLoad = React.useCallback((event) => {
      handleLoading(event.currentTarget);
  }, [handleLoading]);
  const onError = React.useCallback(() => {
      setStatus(SLIDE_STATUS_ERROR);
  }, []);
  const cover = isImageFitCover(image, imageFit);
  const nonInfinite = (value, fallback) => (Number.isFinite(value) ? value : fallback);
  const maxWidth = nonInfinite(Math.max(...((_b = (_a = image.srcSet) === null || _a === void 0 ? void 0 : _a.map((x) => x.width)) !== null && _b !== void 0 ? _b : []).concat(image.width ? [image.width] : []).filter(Boolean)), ((_c = imageRef.current) === null || _c === void 0 ? void 0 : _c.naturalWidth) || 0);
  const maxHeight = nonInfinite(Math.max(...((_e = (_d = image.srcSet) === null || _d === void 0 ? void 0 : _d.map((x) => x.height)) !== null && _e !== void 0 ? _e : []).concat(image.height ? [image.height] : []).filter(Boolean)), ((_f = imageRef.current) === null || _f === void 0 ? void 0 : _f.naturalHeight) || 0);
  const defaultStyle = maxWidth && maxHeight
      ? {
          maxWidth: `min(${maxWidth}px, 100%)`,
          maxHeight: `min(${maxHeight}px, 100%)`,
      }
      : {
          maxWidth: "100%",
          maxHeight: "100%",
      };
  const srcSet = (_g = image.srcSet) === null || _g === void 0 ? void 0 : _g.sort((a, b) => a.width - b.width).map((item) => `${item.src} ${item.width}w`).join(", ");
  const estimateActualWidth = () => rect && !cover && image.width && image.height ? (rect.height / image.height) * image.width : Number.MAX_VALUE;
  const sizes = srcSet && rect && hasWindow() ? `${Math.round(Math.min(estimateActualWidth(), rect.width))}px` : undefined;
  const { style: imagePropsStyle, className: imagePropsClassName, ...restImageProps } = imageProps || {};
  return (React.createElement(React.Fragment, null,
      React.createElement("img", { ref: setImageRef, onLoad: handleOnLoad, onError: onError, onClick: onClick, draggable: false, className: clsx(cssClass(slideImagePrefix()), cover && cssClass(slideImagePrefix("cover")), status !== SLIDE_STATUS_COMPLETE && cssClass(slideImagePrefix("loading")), imagePropsClassName), style: { ...defaultStyle, ...style, ...imagePropsStyle }, ...restImageProps, alt: image.alt, sizes: sizes, srcSet: srcSet, src: largePreview }),
      status !== SLIDE_STATUS_COMPLETE && (React.createElement("div", { className: cssClass(slidePrefix(SLIDE_STATUS_PLACEHOLDER)) },
          status === SLIDE_STATUS_LOADING &&
              ((render === null || render === void 0 ? void 0 : render.iconLoading) ? (render.iconLoading()) : (React.createElement(LoadingIcon, { className: clsx(cssClass(ELEMENT_ICON), cssClass(slidePrefix(SLIDE_STATUS_LOADING))) }))),
          status === SLIDE_STATUS_ERROR &&
          <CircularProgress size={50}/>))));
}


export function ImageApp() {
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const fileStruct = useSelector(state => state.fileListReducer.fileStruct)
  const [applicationControls, setApplicationControls] = useState();
  const [currentImageName, setCurrentImageName] = useState('');
  let { fileObjectID, parentDir } = useParams();
  const [slides, setSlides] = useState()
  const [index, setIndex] = useState();
  const localRef = useRef();
  const fullscreenRef = React.useRef(null);
  const zoomRef = React.useRef();
  const [open, setOpen] = useState(false);
  localRef.current = {currentImageName, fileObjectID, parentDir};

  const navigate = useNavigate();
  let query = useQuery();
  const p = query.get('p');
  const windowSize = 40;
  const dispatch = useDispatch();

  useEffect(()=>{
    setOpen(true)
    if (userInfo?.userToken) {
      setApplicationControls(userInfo?.applicationsControl)
    } else {
      getApplicationControls().then(data => {
        setApplicationControls(data.applications_control)
      })
    }

    return () => {
      dispatch(SetFileList([], "image_app", windowSize, false))
    }
  }, [])

  function addSelectedToFileList(files, selected) {
    const selectedID = selected[0].id;
    var found = false;
    for (var i = 0; i < files.length; i++) {
      if (files[i].id === selectedID) {
        found = true;
      }
    }
    if (!found) {
      return selected.concat(files)
    }
    return files;
  }

  useEffect(() => {
    if (applicationControls) {
      let file_type = query.get("file_type");
      var resolverParams = new ResolverParams();
      resolverParams.objectID = localRef.current.fileObjectID;
      resolverParams.parentDir = localRef.current.parentDir;
      const resolverObject = resolveObjectType(applicationControls, 
        resolverParams, file_type);
      getSimpleFileList(
        localRef.current.parentDir,
        getToken(userInfo, p),
        resolverObject.application.extensions,
        windowSize,
        1,
        [localRef.current.fileObjectID],
        1,
      ).then(res => {
        dispatch(SetFileList(addSelectedToFileList(res.files, res.selected), "image_app", windowSize, false))
      }).catch((error) => {
        getJarFlowObject(localRef.current.fileObjectID, getToken(userInfo, p)).then(data => {
          if (data.detail) {
            var alertData = new AlertData();
            alertData.type = AlertType.ERROR;
            alertData.message = data.detail
            dispatch(SetAlertData(alertData))
          } else {
            var obj = data[0];
            dispatch(SetFileList([obj], "image_app", windowSize, false))
          }
        })
      })
    }
  }, [applicationControls])

  useEffect(() => {
    var slides = [];
    if (fileStruct["image_app"]?.fileList) {
      const fileList = fileStruct["image_app"].fileList;
      var ids = []
      for (var i = 0; i < fileList.length; i++) {
        ids.push(fileList[i].id)
      }
      //const ids = getSuitableFileObjects(application, fileList)
      
      const index = ids.indexOf(localRef.current.fileObjectID)
      for (var i = 0;i < ids.length; i++) {
        slides.push({src: ids[i], userToken: getToken(userInfo, p), width: 1200, height: 1000})
      }
      setSlides([...slides])
      console.log("IMAGES: ", slides)
      setIndex(index)
    }
    
  }, [fileStruct])

  function renderImage(_props) {
    var param = {userInfo: userInfo, p: p, ..._props};
    return <ImageSlide {...param} />
  }

  return (
    <TopComponent>
      <Stack 
        style={lightStyle.applicationContainer}
        direction="column"
      >
        <Lightbox
            open={open}
            styles={{
            }}
            animation={{ fade: 600, swipe: 500 }}
            carousel={{
              finite: false,
              preload: 0,
            }}
            zoom={{
              ref: zoomRef,
              scrollToZoom: true,
              maxZoomPixelRatio:5,
              maxZoom: 10
            }}
            plugins={[Zoom]}
            noScroll={{disabled: true}}
            portal={() => {<div></div>}}
            maxZoom={10}
            render={{
              maxZoom: 10,
              iconError: () => <CircularProgress size={50}/>,
              slide: renderImage,
              buttonZoom: () => null,
              controls: () => (
                <Stack direction={"row"} style={{width: '100%',height: 50, position: 'absolute', top: 0}} sx={{zIndex: 10000}}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      
                    </Grid>
                    <Grid item xs={8}>
                      <Stack style={{width: '100%'}} alignItems={"center"} justifyContent="center">
                        <Typography style={{
                          fontStyle:'italic', 
                          fontSize: APPLICATION_HEADER_TITLE_FONT_SIZE, 
                          marginTop: 15, 
                          color: APPLICATION_HEADER_TITLE_FONT_COLOR}}>{localRef.current.currentImageName}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack direction={"row"} alignItems={"flex-end"} padding={1} justifyContent={"flex-end"} style={{width: '100%', height: '100%', zIndex:9999}}>
                        <IconButton 
                            onClick={()=>{
                              zoomRef.current.zoomOut()
                          }}>
                            <BsZoomOut size={25} style={{color: '#EAEAF3'}}/>
                        </IconButton>
                        <IconButton onClick={()=>{
                          zoomRef.current.zoomIn()
                        }}>
                          <BsZoomIn size={25} style={{color: '#EAEAF3'}}/>
                        </IconButton>
                        <IconButton onClick={()=>{
                          setOpen(false);
                          navigate(-1)
                        }}>
                          <TfiClose size={25} style={{color: '#EAEAF3'}}/>
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              )
            }}
            //fullscreen={{ ref: fullscreenRef, auto: false }}
            on={{
              click: () => fullscreenRef.current?.enter(),
              view: ({index}) => {
                if ((index + 4 === fileStruct["image_app"]?.fileList.length && fileStruct["image_app"]?.hasMore)
                 || (fileStruct["image_app"]?.hasMore && index - 4  <= 0) ) {
                  let file_type = query.get("file_type");
                  var resolverParams = new ResolverParams();
                  resolverParams.objectID = localRef.current.fileObjectID;
                  resolverParams.parentDir = localRef.current.parentDir;
                  const resolverObject = resolveObjectType(applicationControls, 
                    resolverParams, file_type);
                  getSimpleFileList(
                    localRef.current.parentDir,
                    getToken(userInfo, p),
                    resolverObject.application.extensions,
                    windowSize,
                    fileStruct["image_app"]?.page,
                    [],
                    1,
                  ).then(res => {
                    dispatch(AddFilesInList("image_app", res.files, true))
                  })
                }
                setCurrentImageName(fileStruct["image_app"]?.fileList[index]?.original_name)
              }
            }}
            close={() => {
              navigate(-1)
            }}
            slides={slides}
            index={index===-1?0:index}
            toolbar={{
              buttons: []
            }}
          />
      </Stack>
    </TopComponent>
  )
}