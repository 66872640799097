import { Snackbar } from "@mui/material";
import Slide from '@mui/material/Slide';

export function CreateSnackBar(props) {
  if (props.alertData && props.alertData.type === 'info') {
    return (
      <Snackbar
        ContentProps={{
          className: "info-snackbar"
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={props.openNotification}
        onClose={() => { props.setOpenNotification(false) }}
        autoHideDuration={3000}
        TransitionComponent={Slide}
        message={
          <span>
            <div>{props.alertData?.message}</div>
          </span>
        }
        key={1}>
      </Snackbar>)
  } else if (props.alertData && props.alertData.type === 'error') {
    return (
      <Snackbar
        open={props.openNotification}
        key={1}
        ContentProps={{
          className: "error-snackbar"
        }}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        message={
          <span>
            <div>{props.alertData?.message}</div>
          </span>
        }
        onClose={() => { props.setOpenNotification(false) }}>
      </Snackbar>
    )
  }
}