import React, { Children, useEffect, useRef, useState } from "react"
import { Box, Chip, Grid, Stack, Tooltip, Typography, IconButton, Slider, Divider  } from "@mui/material";
import { LuShuffle } from "react-icons/lu";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaBackward, FaForward, FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { APPLICATION_HEADER_TITLE_FONT_COLOR } from "src/style/global";
import { MdOutlineRepeatOne, MdRepeat } from "react-icons/md";
import { GetListObjectIcon, cutLongString, fancyTimeFormat, getRandomInt, getTmpUrl, getToken, secondsToFormatedTime } from "src/utils/helper";
import { API, DEFAULT_COLOR, HOVERED_COLOR, SELECTED_ROW_COLOR } from "src/utils/consts";
import { TbPointFilled } from "react-icons/tb";


const ON_PLAYING = 1;
const ON_PLAY = 2;
const ON_PAUSE = 3;
const ON_END = 4;
const ON_TACK_CHANGE = 5;

const ORDER_SHUFFLE = 1;
const ORDER_LOOP = 2
const ORDER_SINGLE_LOOP = 3;
const ORDER_DEFAULT = 4;


function Volume(props) {
  const {audioPlayer} = props;
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [mute, setMute] = useState(false);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    if (audioPlayer) {
      setMute(audioPlayer?.getMuted())
      setVolume(Math.floor(audioPlayer?.getVolume() * 100))
    }
  }, [audioPlayer])

  useEffect(() => {
    if (!isNaN(volume)) {
      audioPlayer?.setVolume(volume / 100)
    }
  }, [volume])

  return (
    <Box style={{height: 200, width: '100%'}} justifyContent={"center"} alignItems={'center'}>
      <IconButton 
        style={{marginTop: -1}}
        onMouseEnter={() => {setShowVolumeSlider(true)}}
        onMouseLeave={() => {setShowVolumeSlider(false)}}
        onClick={()=>{
          if (!mute) {
            audioPlayer?.mute()
          } else {
            audioPlayer?.unMute()
          }
          setMute(!mute);
          }}>
        {mute ? <FaVolumeMute size={18} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/> :
         <FaVolumeUp size={18} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>}
      </IconButton>
      {showVolumeSlider ? <Slider
        style={{height: 100, position: 'fixed', transform: 'translate(-110%, -98%)'}}
        sx={{
          '& input[type="range"]': {
            WebkitAppearance: 'slider-vertical'
          },
        }}
        onMouseEnter={() => {
          setShowVolumeSlider(true);
        }}
        onMouseLeave={() => {
          setShowVolumeSlider(false)
        }}
        orientation="vertical"
        defaultValue={0}
        size={"small"}
        value={volume}
        onChange={(e, value) => {
          if (!isNaN(value)) {
            setVolume(value)
            if (value === 0) {
              setMute(true);
            } else {
              setMute(false);
            }
          }
        }}
        onKeyDown={() => {}}
    /> : null}
    </Box>
  )
}


function LoopOrdering(props) {
  const {ordering} = props;
  switch(ordering) {
    case ORDER_LOOP: {
      return <MdRepeat size={22} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
    }
    case ORDER_SINGLE_LOOP: {
      return <MdOutlineRepeatOne size={22} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
    }
    default:
      return <MdRepeat size={22} style={{color: '#757579'}}/>
  }
}


function ControlPanel(props) {
  const {
    currentPlaying, audioPlayer, userInfo, urlUpdater, 
    setAudioSrc, setCurrentPlaying, p} = props;
  const [playingStatus, setPlayingStatus] = useState(false);
  const [currentProgress, setCurrentProgress] = useState({progress: 0});
  const [ordering, setOrdering] = useState(ORDER_DEFAULT)

  audioPlayer?.positionUpdate(setCurrentProgress);
  useEffect(() => {
    audioPlayer?.registerListener((e) => {
      setPlayingStatus(true)
    }, ON_PLAY)
    audioPlayer?.registerListener((e) => {
      setPlayingStatus(true)
    }, ON_PLAYING)
    audioPlayer?.registerListener((e) => {
      setPlayingStatus(false)
    }, ON_END)
    audioPlayer?.registerListener((i) => {
      audioPlayer?.clear();
      console.log("CURERNT INDEX: ", i)
      const song = audioPlayer?.getSongByIndex(i)
      if (song) {
        getTmpUrl(song.id, getToken(userInfo, p), 100000).then(data=>{
          const tmpID = data.id;
          setAudioSrc(API + `/tmp-file-object-content/${tmpID}`)
          setCurrentPlaying(song)
          setTimeout(() => {
            urlUpdater.create(userInfo, song.id, setAudioSrc, 1000000)
          }, 500);
        })
      }
    }, ON_TACK_CHANGE)
  }, [audioPlayer])

  return (
    <Stack sx={{width: '100%', height: 220}} direction={"column"}>
      <Stack sx={{width: '100%', height: 100, display: 'flex'}} direction={"row"} alignItems={"start"} justifyContent={"start"}>
        {currentPlaying ? 
        <Stack style={{width: 90, marginLeft: 5, marginBottom: 10}} direction={"row"}>
          <GetListObjectIcon props={currentPlaying} iconSize={90} color={APPLICATION_HEADER_TITLE_FONT_COLOR} previewFormat="small" /> </Stack> :
          null }
        <Stack spacing={1} padding={2} direction={"column"}>
          <Typography 
            style={{
              color: APPLICATION_HEADER_TITLE_FONT_COLOR, 
              fontSize: 20, 
              fontWeight: 'bold', 
              fontStyle: 'italic', 
              textAlign:"start",
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              overflow: 'hidden', 
              textOverflow: 'ellipsis',
              }}>{currentPlaying?.original_name}
          </Typography>
          <Typography 
            style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR, fontSize: 15, fontStyle: 'italic', textAlign:"start"}}>{currentPlaying?.meta_data?.album}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={"row"} sx={{width: '100%', height: 50, display: 'flex'}} justifyContent={"center"} alignItems={"center"}>
        <Stack direction={"row"} sx={{height: 20, display: 'flex'}}>
          <IconButton onClick={()=>{
            if (ordering === ORDER_SHUFFLE) {
              audioPlayer.setOrdering(ORDER_DEFAULT)
              setOrdering(ORDER_DEFAULT)
            } else {
              audioPlayer.setOrdering(ORDER_SHUFFLE)
              setOrdering(ORDER_SHUFFLE)
            }
          }}>
            {ordering === ORDER_SHUFFLE ? 
              <LuShuffle  size={18} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/> : 
              <LuShuffle  size={18} style={{color: '#757579'}}/>}
            
          </IconButton>
          <IconButton onClick={()=>{
            audioPlayer.rewind();
          }}>
            <FaBackward  size={18} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
          </IconButton>
          <IconButton id="play-btn" onClick={()=>{
            if (playingStatus) {
              audioPlayer.pause();
            } else {
              audioPlayer.playAudio();
            }
            setPlayingStatus(!playingStatus)
          }}>
            {playingStatus ? <FaPause size={18} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/> : <FaPlay  size={18} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>}
          </IconButton>
          <IconButton onClick={()=>{
            audioPlayer.forward();
          }}>
            <FaForward size={18} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
          </IconButton>
          <IconButton onClick={()=>{
            if (ordering === 4 || ordering === 1) {
              setOrdering(ORDER_LOOP);
              audioPlayer.setOrdering(ORDER_LOOP);
            }
            if (ordering > 1 && ordering < 4) {
              const tmpOrder = ordering + 1;
              audioPlayer.setOrdering(tmpOrder);
              setOrdering(tmpOrder);
            }
          }}>
            <LoopOrdering ordering={ordering}/>
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction={"column"} sx={{width: '100%', height: 70}}>
      <Grid container>
          <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
            <Typography style={{
              fontSize: 12, 
              color: APPLICATION_HEADER_TITLE_FONT_COLOR,
              width: '100%', textAlign: 'left'
              }}>{secondsToFormatedTime(currentProgress?.seconds)}</Typography>
          </Grid>
          <Grid item xs={9} xl={9} sm={9} md={9} lg={9}>
          </Grid>
          <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
            <Typography style={{
              fontSize: 12, 
              color: APPLICATION_HEADER_TITLE_FONT_COLOR,
              width: '100%', textAlign: 'right'
              }}>{secondsToFormatedTime(currentProgress?.duration)}</Typography>
          </Grid>
          <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={10} xl={11} sm={11} md={11} lg={11}>
            <Slider
              size="small"
              defaultValue={0}
              valueLabelDisplay={"off"}
              value={currentProgress?.progress}
              disableSwap={true}
              onMouseDown={() => {
                audioPlayer?.pause()
              }}
              onMouseUp={() => {
                audioPlayer?.sliderSeek(currentProgress?.progress)
              }}
              onChange={(e) => {
                setCurrentProgress({...currentProgress, ...{progress: e.target.value}})
              }}
              onChangeCommitted={(e) => {
                audioPlayer?.sliderSeek(currentProgress?.progress)
              }}
              aria-label="Small"
            />
          </Grid>
          <Grid item xs={2} xl={1} sm={1} md={1} lg={1}>
            <Volume 
              audioPlayer={audioPlayer}
              />
          </Grid>
        </Grid>
        
      </Stack>
    </Stack>
  )
}

function MetaData(props) {

  function CreateEl({prefix, text, withPoint, color}) {
    if (!text) {
      return null
    }
    if (withPoint) {
      return (
        <span 
          style={{minWidth: 100, maxWidth: 200, fontSize: 16, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: color}} 
          align="left">
            {prefix+text}<TbPointFilled size={22} style={{marginBottom: -6}}/>
        </span>
      )
    }
    return (
      <span 
        style={{minWidth: 100, maxWidth: 200, fontSize: 16, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: color}} align="left">
        {prefix+text}
      </span>
    )
  }
  if (!props.fullLenght) {
    return (
      <div style={{display: 'block', width: '100%', textAlign: 'left', flex: 1}}>
        <CreateEl color="rgb(100, 100, 100)" prefix={"Album: "} text={cutLongString(props.album, 26)} withPoint={true} />
        <CreateEl color="rgb(100, 100, 100)" prefix={"Artist: "} text={cutLongString(props.artist, 26)} withPoint={true} />
        <CreateEl color="rgb(100, 100, 100)" prefix={"Bitrate: "} text={Math.round(props.bitrate / 1000)} />
      </div>
    )
  } else {
    return (
      <div style={{display: 'block', width: '100%', textAlign: 'left', flex: 1}}>
        <CreateEl color="white" prefix={"Album: "} text={cutLongString(props.album, 300)} withPoint={true} />
        <CreateEl color="white" prefix={"Artist: "} text={cutLongString(props.artist, 300)} withPoint={true} />
        <CreateEl color="white" prefix={"Bitrate: "} text={Math.round(props.bitrate / 1000)} />
      </div>
    )
  }
}


function SongItem(props) {
  const {audioPlayer, urlUpdater, p} = props;
  const defaultItemColo = 'black';
  const hoveredItemColor = '#191919'
  const selectedItem = '#3F004C';

  const [background, setBackground] = useState(defaultItemColo)

  useEffect(()=>{
    if (props.selected) {
      setBackground(selectedItem)
    } else {
      setBackground(defaultItemColo)
    }
  }, [props.selected])

  return (
    <Box 
      key={props.index} style={{width: '100%', minHeight: 70, maxHeight: 100, cursor: 'pointer', background: background}} 
      onMouseEnter={()=>{
        setBackground(hoveredItemColor)
      }}
      onMouseLeave={()=>{
        if (props.selected) {
          setBackground(selectedItem)
        } else {
          setBackground(defaultItemColo)
        }
      }}
      className="song-item">
        <Stack 
          direction="row" 
          spacing={1}   
          style={{width: '100%', alignItems: 'center', minHeight: 70, maxHeight: 100}}
          onClick={(e)=>{
            audioPlayer.clear();
            getTmpUrl(props.obj.id, getToken(props.userInfo, p), 1000000).then(data=>{
              const tmpID = data.id;
              props.setAudioSrc(API + `/tmp-file-object-content/${tmpID}`)
              props.setCurrentPlaying(props.obj)
              setTimeout(() => {
                urlUpdater.create(props.userInfo, props.obj.id, props.setAudioSrc, 1000000)
              }, 500);
            })
          }}
          >
          <Stack style={{width: 50, marginLeft: 5, marginBottom: 10}} direction={"row"}>
            <GetListObjectIcon props={props.obj} iconSize={40} color={APPLICATION_HEADER_TITLE_FONT_COLOR} previewFormat="small" />
          </Stack>
          <Stack direction={"column"} style={{marginLeft: 5, width: "calc(100% - 60px)"}}>
            <Typography 
              align="left"
              style={{
                fontSize: 15, 
                width: '100%', 
                color: APPLICATION_HEADER_TITLE_FONT_COLOR,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
              }} 
              >
              {props.obj.original_name}
            </Typography>
            <MetaData
              album={props.obj.meta_data.album}
              artist={props.obj.meta_data.artist}
              bitrate={props.obj.meta_data.bitrate}
              fullLenght={false}
            />
          </Stack>
          <Stack direction={"column"} style={{position: 'static'}}>
            <Typography style={{fontSize: 13, color: APPLICATION_HEADER_TITLE_FONT_COLOR}}>{fancyTimeFormat(props.obj.meta_data.length)}</Typography>
            <Chip label={props.obj.file_type} size="small" color={"primary"} />
          </Stack>
        </Stack>
      <Divider />
    </Box>
  )
}


function PlayList(props) {
  const {audioFiles, userInfo, setAudioSrc, setCurrentPlaying, hasMore,
    currentPlaying, audioPlayer, urlUpdater, dataLength, loadMore, p} = props;
  return (
  <Stack 
    sx={{width: '100%', height: 'calc(100vh - 265px)'}} 
    direction={"column"}
    >
    <Stack direction={"column"} style={{height: '100%', overflowY: 'auto'}} id="playlistscroll">
      {audioFiles.map((value, index) => {
        return (
          <SongItem 
            key={index}
            index={index}
            obj={value.obj}
            userInfo={userInfo}
            setAudioSrc={setAudioSrc}
            setCurrentPlaying={setCurrentPlaying}
            selected={value?.obj?.id === currentPlaying?.id}
            audioPlayer={audioPlayer}
            urlUpdater={urlUpdater}
            p={p}
          />
        )
      })}
    </Stack>
    
    <InfiniteScroll
      dataLength={dataLength}
      next={loadMore}
      hasMore={hasMore}
      style={{ zIndex: 9999, width: '100%' }}
      scrollableTarget="playlistscroll"
      hasChildren={true}
    />
  </Stack>
  )
}

class AudioControls {

  constructor(audio, src) {
    this.src = src;
    this.audio = audio.current;
    document.getElementById("audio-source").src = src;
   //this.audio.src = src;
    this.currentTime = 0;
    this.ordering = ORDER_DEFAULT;
  }

  _getCurrentSongIndex() {
    
    for (var i = 0; i < this.songList.length; i++) {
      var song = this.songList[i];
      if (song.obj.id === this.currentPlaying.id) {
        return i;
      }
    }
    return -1;
  }

  _getNextSongIndex() {
    var totalSongsLen = this.songList.length;
    const index = this._getCurrentSongIndex();
    switch(this.ordering) {
      case ORDER_DEFAULT: {
        if (index + 1 !== totalSongsLen) {
          return index + 1;
        } else {
          return -1;
        }
      }
      case ORDER_LOOP: {
        if (index + 1 !== totalSongsLen) {
          return index + 1;
        } else {
          return 0;
        }
      }
      case ORDER_SINGLE_LOOP: {
        return index;
      }
      case ORDER_SHUFFLE: {
        return getRandomInt(totalSongsLen)
      }
      default: {
        return -1;
      }
    }
  }

  setCurrentPlayingObject(obj) {
    this.currentPlaying = obj;
  }

  setSongList(songList) {
    this.songList = songList
  }

  _createNewAudioObject(newSrc) {
    var oldAudioElem = document.getElementById("w-audio");
    const currentTime = oldAudioElem.currentTime;
    this.src = newSrc;
    var newAudioElement = new Audio();
    newAudioElement.src = newSrc;
    newAudioElement.id = "w-audio"
    newAudioElement.muted = true;
    newAudioElement.autoPlay = true;
    newAudioElement.onplaying = (e) => this._onPlaying(e);
    newAudioElement.onplay = (e) => this._onPlay(e);
    newAudioElement.onended = (e) => this._onEnded(e);
    newAudioElement.currentTime = currentTime;
    newAudioElement.load()
    newAudioElement.play().catch((err) => console.log(err))
    var parent = oldAudioElem.parentNode;
    parent.replaceChild(newAudioElement, oldAudioElem);
    newAudioElement.muted = false;
    this.audio = newAudioElement;
  }

  setSrc(src) {
    this.src = src;
    this._createNewAudioObject(src)
  }

  playAudio(fromBegin) {
    this.audio.src = this.src;
    //this.audio.src = this.src;
    if (fromBegin) {
      this.audio.currentTime = 0;
      this.currentTime = 0;
    } else {
      this.audio.currentTime = this.currentTime;
    }
    this.audio.load();
    this.audio.play().catch((err) => console.log(err));
  }
 
  seekAndPlay(time) {
    this.audio.currentTime = time;
    this.audio.play().catch((err) => console.log(err));
  }

  sliderSeek(val) {
    var currentTime = Math.floor(this.audio.duration * val / 100);
    if (currentTime) {
      this.audio.currentTime = currentTime;
      this.audio.play().catch((err) => console.log(err));
    }
  }

  positionUpdate(fn) {
    if (this.updatePositionInterval) {
      clearInterval(this.updatePositionInterval);
    }
    this.updatePositionInterval = setInterval(() => {
      if (this.audio.duration) {
        if (this.audio.currentTime !== this.prevSeconds) {
          fn({
            progress: 100 * this.audio.currentTime / this.audio.duration, 
            seconds: this.audio.currentTime,
            duration: this.audio.duration
          })
        }
        this.prevProgress = 100 * this.audio.currentTime / this.audio.duration;
        this.prevDuration = this.audio.duration;
        this.prevSeconds = this.audio.currentTime;
      } else if (this.prevProgress && this.prevDuration && this.prevSeconds) {
        fn({
          progress: this.prevProgress, 
          seconds: this.prevSeconds, 
          duration: this.prevDuration
        })
      } else {
        fn({progress: 0, seconds: 0, duration: 0})
      }
      
    }, 300)
  }

  getOnPlayUpdate(e) {}

  getOnPlayinUpdate(e) {}

  getOnPause(e) {}

  getEnded(e) {}

  getNextTrackIndex(e) {

  }

  _onPlay(e) {
    this.getOnPlayUpdate(e)
  }

  _onPlaying(e) {
    this.getOnPlayinUpdate(e)
  }

  

  _onEnded(e) {
    this.currentTime = 0;
    this.getEnded(e)
    this.getNextTrackIndex(this._getNextSongIndex());
  }

  clear() {
    this.stop()
  }

  registerListener(fn, listenerType) {
    switch(listenerType) {
      case ON_PLAY: {
        this.getOnPlayUpdate = fn;
        break;
      }
      case ON_PLAYING: {
        this.getOnPlayinUpdate = fn;
        break;
      }
      case ON_PAUSE: {
        this.getOnPause = fn;
        break;
      }
      case ON_END: {
        this.getEnded = fn;
        break
      }
      case ON_TACK_CHANGE: {
        this.getNextTrackIndex = fn
        break;
      }
      default : {
      
      }
    }
  }

  pause() {
    this.audio.pause();
    this.currentTime = this.audio.currentTime;
  }

  setVolume(val) {
    
    if (!isNaN(val)) {
      if (val < 0.04) {
        this.audio.volume = 0;
      } else {
        this.audio.volume = val;
      }
    } else {
      this.audio.volume = 0;
    }
  }

  getVolume() {
    return this.audio.volume;
  }

  getMuted() {
    return this.audio.muted
  }

  mute() {
    this.audio.muted = true;
  }

  unMute() {
    this.audio.muted = false;
  }

  stop() {
    this.audio.pause();
    this.audio.currentTime = 0;
    this.currentTime = 0;
  }

  setOrdering(ordering) {
    this.ordering = ordering;
  }

  duration() {
    return this.audio.duration;
  }

  getSongByIndex(index) {
    if (index === -1) {
      return undefined
    }
    return this.songList[index]?.obj
  }

  forward() {
    var step = 1;
    var currentTime = this.audio.currentTime;
    var duration = this.audio.duration;
    if (this.audio.duration > 10) {
      step = duration / 10;
    }
    if (duration > currentTime + step) {
      this.audio.currentTime = currentTime + step;
    } else {
      this.audio.currentTime = duration - 0.1;
    }
    this.currentTime = this.audio.currentTime;
    this.playAudio();
  }

  rewind() {
    var step = 1;
    var currentTime = this.audio.currentTime;
    var duration = this.audio.duration;
    if (this.audio.duration > 10) {
      step = duration / 10;
    }
    if (0 < currentTime - step) {
      this.audio.currentTime = currentTime - step;
    } else {
      this.audio.currentTime = 0;
    }
    this.currentTime = this.audio.currentTime;
    this.playAudio();
  }

  continue() {
    this.audio.play();
  }

  exit() {
    if (this.updatePositionInterval) {
      clearInterval(this.updatePositionInterval);
    }
  }
}

export function AudioPlayer(props) {
  const {audioFiles, userInfo, setAudioSrc, setCurrentPlaying, 
    currentPlaying, audioSrc, autoPlay, urlUpdater, dataLength, 
    loadMore, hasMore, p} = props;
  const [audioPlayer, setAudioPlayer] = useState();
  const audioRef = useRef();
  const [initLoad, setInitLoad] = useState(true);
  const localRef = useRef()
  localRef.current = {initLoad}

  useEffect(() => {
    if (!audioPlayer && audioSrc && audioRef) {
      //var audio = new Audio(audioSrc);
      //audio.autoplay = true;
      var tmpAudioPlayer = new AudioControls(audioRef, audioSrc);
      setAudioPlayer(tmpAudioPlayer)
    }
  }, [audioSrc, audioRef])

  useEffect(() => {
    if (audioPlayer && currentPlaying) {
      audioPlayer.setCurrentPlayingObject(currentPlaying)
    }
  }, [audioPlayer, currentPlaying])

  useEffect(() => {
    if (audioPlayer && audioFiles) {
      audioPlayer.setSongList(audioFiles);
    }
  }, [audioPlayer, audioFiles])

  useEffect(() => {
    if (audioPlayer) {
      if (!initLoad) {
        audioPlayer.setSrc(audioSrc);
      } else {
        setInitLoad(false)
        audioPlayer.playAudio()
        urlUpdater.create(userInfo, currentPlaying.id, setAudioSrc, 1000000) // update url each 1000 seconds
      }
    }
  }, [audioSrc, audioPlayer])

  useEffect(() => {
    return () => {
      urlUpdater.clearUpdateInterval()
    }
  }, [])

  return (
    <Stack sx={{width: '100%',background: 'black', height: '100%'}}>
      <Stack style={{height: 220, flex: '0 1 auto'}}>
        <Grid container direction="row">
          <Grid item xs={1} xl={3} sm={1} md={2} lg={3}></Grid>
          <Grid item xs={10} xl={6} sm={10} md={8} lg={6}>
            <ControlPanel 
              currentPlaying={currentPlaying}
              audioPlayer={audioPlayer}
              urlUpdater={urlUpdater}
              userInfo={userInfo}
              setAudioSrc={setAudioSrc}
              setCurrentPlaying={setCurrentPlaying}
              p={p}
            />
          </Grid>
          <Grid item xs={1} xl={3} sm={1} md={2} lg={3}>
          </Grid>
        </Grid>
      </Stack>
      <Stack style={{}}>
        <Grid container direction="row">
        <Grid item xl={3} sm={1} md={2} lg={3}></Grid>
        <Grid item xs={12} xl={6} sm={10} md={8} lg={6}>
          <PlayList 
            audioFiles={audioFiles}
            userInfo={userInfo}
            setAudioSrc={setAudioSrc}
            setCurrentPlaying={setCurrentPlaying}
            currentPlaying={currentPlaying}
            audioPlayer={audioPlayer}
            urlUpdater={urlUpdater}
            dataLength={dataLength}
            loadMore={loadMore}
            hasMore={hasMore}
            p={p}
            />
          <audio
            id='w-audio'
            controls={false}
            autoPlay={autoPlay}
            muted={false}
            ref={audioRef}
            onPlaying={(e) => audioPlayer?._onPlaying(e)}
            onPlay={(e) => audioPlayer?._onPlay(e)}
            onEnded={(e) => audioPlayer?._onEnded(e)}
          >
            <source id="audio-source" src={audioPlayer?.src} />
          </audio>
        </Grid>
        <Grid item xl={3} sm={1} md={2} lg={3}>
        </Grid>
        </Grid>
      </Stack>
      
    </Stack>
  )
}