import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography } from '@mui/material';
import { TopComponent } from "./base/top_component";
import { lightStyle } from "../style/light_style";
import { getApplicationControls, getJarFlowObject, getToken } from "../utils/helper";
import { ResolverParams, resolveObjectType } from "./applications/resolver";
import { AlertData } from "../objects/alert";
import { AlertType } from "../utils/consts";
import { SetAlertData, SetCurrentPublicToken } from "../redux_store/actions";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export function AppLookUp(props) {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo);
  const dispatch = useDispatch();
  var containerStyle = {};
  containerStyle = {...lightStyle.mainBackgroud};
  let { id } = useParams();
  let query = useQuery();
  const navigate = useNavigate();
  const p = query.get('p');
  

  useEffect(() => {
    getApplicationControls().then(appData => {
      getJarFlowObject(id, getToken(userInfo, p)).then(data => {
        console.log("DATA: ", data)
        if (data.detail) {
          var alertData = new AlertData();
          alertData.type = AlertType.ERROR;
          alertData.message = data.detail
          if (data.detail === 'A password is required for access to the public link') {
            dispatch(SetCurrentPublicToken(p));
          } else {
            dispatch(SetAlertData(alertData))
          }
        } else {
          var obj = data[0];
          var resolverParams = new ResolverParams();
          resolverParams.objectID = id;
          resolverParams.parentDir = data[0].parent_dir;
          const resolverObject = resolveObjectType(appData.applications_control, resolverParams, obj.file_type);
          if (resolverObject && resolverObject.url) {
            var url = resolverObject.url+'?file_type='+obj.file_type;
            if (p) {
              url += '&p='+p;
            }
            navigate(url)
          } else {
            var alertData = new AlertData();
            alertData.type = AlertType.ERROR;
            alertData.message = "Didn't find a suitable application to open this file."
            dispatch(SetAlertData(alertData))
          }
        }
      })
    })
    
  }, [])

  return (
    <TopComponent>
      <Stack style={containerStyle} justifyContent={"center"}>
        <Typography style={{fontSize: 20, color: "black"}}>Getting the application for requested resource...</Typography>
      </Stack>
    </TopComponent>
  )
}