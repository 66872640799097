import React from "react";
import { useDispatch } from "react-redux";
import { CleanDownloadedObjectProgress } from "../../redux_store/actions";
import { CircularProgressWithLabel } from "./circular_progress_with_label";
import { BsCheckLg } from "react-icons/bs";
import { Stack } from "@mui/material";

function DownloadProgress(props) {
  const dispatch = useDispatch()
  if (props.progress === 1) {
    
    setTimeout(()=>{
      props.setProgress(undefined)
      dispatch(CleanDownloadedObjectProgress(props.value.id))
    }, 7000)
  }
  if (!props.progress) {
    return (
      null
    )
  }
  else if (props.progress !== -1 && props.progress !== 1) {
    return (
      <Stack style={{marginTop: 3, width: '35px', marginLeft: 5}}>
        <CircularProgressWithLabel value={props.progress*100}/>
      </Stack>
    )
  } else if (props.progress === 1) {
    return (
      <Stack style={{marginTop: 6, width: '35px', marginLeft: 5}}>
        <BsCheckLg size={28} color={"green"} style={{width: '30px'}}/>
      </Stack>
    )
  }
}


const MemoDownloadProgress = React.memo(DownloadProgress, (prevProps, nextProps) => {
  return prevProps.progress === nextProps.progress
})

const MemoUploadProgress = React.memo(DownloadProgress, (prevProps, nextProps) => {
  return prevProps.progress === nextProps.progress
})

export function SmartProgress(props) {
  if (props.value.upload) {
    return (
        <MemoUploadProgress progress={props.progress} setProgress={props.setProgress} value={props.value}/>
    )
  } else {
      return (
          <MemoDownloadProgress progress={props.progress} setProgress={props.setProgress} value={props.value}/>
      )
  }
}