import React, { useEffect, useState } from 'react'
import { TopComponent } from './base/top_component'
import {
  Link,
  useNavigate,
} from "react-router-dom";
import {Stack, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, Box, Grid, Tooltip, IconButton, CircularProgress} from "@mui/material";
import config from '../managers/config'
import { API, AUTH_PAGE } from 'src/utils/consts';
import { lightStyle } from 'src/style/light_style';
import { FiArrowRight } from 'react-icons/fi';
import { DEFAULT_BUTTON_BACKGROUND_COLOR, DEFAULT_LIGHT_TEXT_COLOR } from 'src/style/global';
const tauriApi = require('../managers/tauri_imports');

export function InitPage(props) {

  const [syncDir, setSyncDir] = useState('')
  const [syncDirInput, setSyncDirInput] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const onDirSelect = async () => {
    const selected = await tauriApi.open({
      directory: true,
      multiple: false,
      defaultPath: await tauriApi.appDir(),
    });
    setSyncDir(selected)
    setSyncDirInput(selected)
  };

  useEffect(() => {
    
    setTimeout(() => {
      if (config && config.configObject.syncPath) {
        tauriApi.invoke("init_client_config", {
          serverAddress: API,
          syncFolder: config.configObject.syncPath,
        })
        navigate(`${AUTH_PAGE}`)
      } else {
        tauriApi.appDir().then(dir => {
          setSyncDirInput(dir)
        })
      }
      setIsLoading(false);
    }, 2000);
  }, [config])

  return (
    <TopComponent>
      <Box
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        style={lightStyle.mainBackgroud}
      >
        {isLoading ? <Stack style={{height: '100%', width: '100%'}} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress size={60} />
        </Stack>
          : 
          <Box style={{width: '100%', height: '100%'}}>
          <Grid container padding={1} style={{height: 70}}>
            <Grid item xs={1} sm={3} md={3} lg={4} xl={4} alignItems={"flex-start"} justifyContent={"center"} padding={1}>
            </Grid>
            <Grid item xs={10} sm={6} md={6} lg={4} xl={4}>

            </Grid>
            <Grid item xs={1} sm={3} md={3} lg={4} xl={4}>
              <Stack direction="row" style={{width: '100%'}} justifyContent={"end"} alignItems={"end"} spacing={1}>
                {syncDirInput ? <Tooltip title="Go back to files" >
                  <IconButton
                    aria-label="more"
                    onClick={() => {
                      config.configObject.syncPath = syncDirInput;
                      config.saveConfig();
                      tauriApi.resourceDir().then(data => {
                        tauriApi.invoke("create_windows_quick_link", {
                          resourcePath: data,
                          syncDir: syncDirInput
                        }).then(_ => {
                          navigate(`${AUTH_PAGE}`);
                        })
                        tauriApi.invoke("watch_the_folder_windows", {
                          folderPath: syncDirInput
                        }).then(_ => {
                          navigate(`${AUTH_PAGE}`);
                        })
                      })
                    }}
                    style={{backgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR}}
                    id="back-btn">
                      <FiArrowRight size={22} color={DEFAULT_LIGHT_TEXT_COLOR}/>
                  </IconButton>
                </Tooltip> : null}
              </Stack>
            </Grid>
          </Grid>
          <Stack direction={"row"} style={lightStyle.mainBackgroud} alignItems={"center"}>
          <Stack justifyContent={"center"} alignItems={"center"} style={{height: 200, marginTop: 50}}>
            <img className="not-druggable" src={require('../static/images/squirrel_welcome.png')} alt={"squirrel-welcome"} style={{objectFit: 'contain', width: 300, height: 300}} />
          </Stack>
          <Stack justifyContent={"start"} alignItems={"center"} style={{flex: 1}} padding={10}>
            <FormControl  variant="outlined">
              <InputLabel htmlFor="directory-outline">Select sync directory</InputLabel>
              <OutlinedInput 
                id="directory-outline" 
                style={{width: 400}}
                label={"Download directory"}
                value={syncDirInput}
                onChange={(e)=>{setSyncDirInput(e.target.value)}}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      aria-label="open directory select"
                      onClick={onDirSelect}
                      style={{width: 80}}
                      variant="contained" 
                    >
                      Open
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Stack>
        </Stack>
        </Box>
        }
        
      </Box>
    </TopComponent>
  )
}