//import { appConfigDir, join, downloadDir } from '@tauri-apps/api/path';
import { randomString } from '../utils/helper';
import { TARGET_PLATFORM } from 'src/utils/consts';
//const tauriApiPath = require('./tauri_imports');
if (TARGET_PLATFORM !== 'web') {
  
}

var defaultConfig = {}

/* if (TARGET_PLATFORM !== 'web') {
  defaultConfig = {
    downloadPath: await tauriApiPath.downloadDir(),
    syncPath: '',
    name: '',
    deviceID: randomString(30),
    secondName: '',
    email: '',
    limits: {
      maxUploadFileSize: 1024*1024*200,
      passwordLink: true,
      publicShare: true,
      maxDownloadsPerLink: 100,
      referalLinksInMonth: 10,
      binItems: 500,
      maxItemsInFolder: 6000,
    }
  }
} */

class Config {

  
  async createConfigFile() {
    /* if (TARGET_PLATFORM !== 'web') {
      this.configPath = await tauriApiPath.join(this.configDir, 'config.json')
    }
    console.log("PATH: ", this.configPath)
    if (!await tauriApiPath.exists(this.configPath)) {
      try {
        await tauriApiPath.writeFile(
          {
            contents: JSON.stringify(defaultConfig),
            path: this.configPath,
          },{
            dir: tauriApiPath.BaseDirectory.AppConfig,
          }
        );
      } catch (e) {
        console.log(e);
      }
    }; */
  }


  configDir = ""
  configPath = ""
  configObject = {}

  async createDataFolder() {
    /* if (TARGET_PLATFORM !== 'web') {
      this.configDir = await tauriApiPath.appConfigDir();
    }
    console.log("PATH: ", this.configDir)
    if (!await tauriApiPath.exists(this.configDir)) {
      await tauriApiPath.createDir("", {
        dir: tauriApiPath.BaseDirectory.AppConfig,
        recursive: true,
      });
    } */
  };


  constructor() {
    (async () => {
      await this.createDataFolder()
      await this.createConfigFile()
      await this.loadConfigObject()
    })();
  }

  updateParam(key, value) {
    this.configObject[key] = value
  }

  async saveConfig() {
    try {
      /* await tauriApiPath.writeFile(
        {
          contents: JSON.stringify(this.configObject),
          path: this.configPath,
        },{
          dir: tauriApiPath.BaseDirectory.AppConfig,
        }
      ); */
    } catch (e) {
      console.log(e);
    }
  }

  async loadConfigObject() {
    /* tauriApiPath.readTextFile(this.configPath).then(str=> {
      this.configObject = JSON.parse(str);
    }) */
  }

}

var config = null;
if (TARGET_PLATFORM !== 'web') {
  config = new Config();
} else {
  config = {};
  config.deviceID = randomString(20);
}
export default config;