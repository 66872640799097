import React, { useEffect } from "react";
import {
  useNavigate,
} from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useAuth } from "react-oidc-context";
import { UserInfo } from "src/objects/user";
import { API, FILE_EXPLORER_PAGE, REDIRECT_URL } from "src/utils/consts";

export function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(()=>{ 
    if (!auth.isAuthenticated) {
      auth.signinRedirect({redirect_uri: REDIRECT_URL}).then(user =>{
        if (!user || user === null) {
          navigate("/")
        } else {
          navigate("/authorized")
        }
      }).catch(errr => {
        console.log("AUTH ERR: ", errr)
      })
    }
  }, [auth.isAuthenticated])


  return (<></>);
}