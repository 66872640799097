import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Drawer, Box, Divider, List, ListItemIcon, Avatar,
  ListItem, ListItemButton, ListItemText, Badge} from '@mui/material';
import { IoNotificationsOutline, IoPeopleOutline, IoPersonOutline, IoTrashBinOutline } from "react-icons/io5";
import { BIN_PAGE, FILE_EXPLORER_PAGE, LOGOUT, SETTINGS_PAGE, SHARES } from "src/utils/consts";
import { LinearProgressWithLabel } from "../liniar_progress_with_label";
import { valueBytesToString } from "src/utils/helper";
import { RiLoginBoxLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { OpenNotifications } from "src/redux_store/actions";
import { DEFAULT_LIGHT_BG_COLOR } from "src/style/global";
import { HiOutlineLibrary } from "react-icons/hi";


export function MobileMenuDrawer(props) {
  const {open, userInfo, onCloseMobileMenu, fileStruct, sharePage} = props;
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenDrawer(open)
  }, [open])

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
    onCloseMobileMenu()
  };

  return (
    <Drawer open={openDrawer} onClose={toggleDrawer(false)} style={{}}>
      <Box sx={{ width: 250, backgroundColor: DEFAULT_LIGHT_BG_COLOR, flex: 1 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {userInfo?.userToken ? 
        <ListItem key={0} disablePadding>
          <ListItemButton onClick={() => {
            navigate(SETTINGS_PAGE)
          }}>
            <ListItemIcon>
              {userInfo && (userInfo.avatarSSoUrl || userInfo.avatarUrl) ?
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={userInfo.avatarSSoUrl !== "" ? userInfo.avatarSSoUrl : userInfo.avatarUrl}
                >H</Avatar> : <IoPersonOutline size={27} />
              }
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
          </ListItemButton>
        </ListItem> : <ListItem key={1} disablePadding>
          <ListItemButton onClick={() => {
            navigate("/register")
          }}>
            <ListItemIcon>
              <RiLoginBoxLine size={27} />
            </ListItemIcon>
            <ListItemText primary={"Login"} />
          </ListItemButton>
        </ListItem>}
        {userInfo?.userToken && !sharePage?
        <ListItem key={2} disablePadding>
          <ListItemButton onClick={() => {
            navigate(SHARES)
          }}>
            <ListItemIcon>
              <Badge badgeContent={fileStruct['share']?.newContent} color="secondary">
                <IoPeopleOutline size={27} />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Shares"} />
          </ListItemButton>
        </ListItem> : null }
        {userInfo?.userToken && sharePage?
        <ListItem key={3} disablePadding>
          <ListItemButton onClick={() => {
            navigate(navigate(`${FILE_EXPLORER_PAGE}/${userInfo?.root}/`))
          }}>
            <ListItemIcon>
              <Badge badgeContent={fileStruct['share']?.newContent} color="secondary">
                <HiOutlineLibrary size={27} />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"My files"} />
          </ListItemButton>
        </ListItem> : null }
        {userInfo?.userToken ?
        <ListItem key={4} disablePadding>
          <ListItemButton onClick={() => {
            dispatch(OpenNotifications(true))
          }}>
            <ListItemIcon>
              <Badge badgeContent={fileStruct['notification']?.newContent} color="secondary">
                <IoNotificationsOutline size={27} />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Notifications"} />
          </ListItemButton>
        </ListItem> : null }
        {userInfo?.userToken ?
        <ListItem key={5} disablePadding>
          <ListItemButton onClick={() => {
            navigate(BIN_PAGE)
          }}>
            <ListItemIcon>
              <Badge badgeContent={fileStruct['bin']?.newContent} color="secondary">
                <IoTrashBinOutline size={27} />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Bin"} />
          </ListItemButton>
        </ListItem> : null }
        {userInfo?.userToken ?
        <ListItem key={6} disablePadding>
          <ListItemButton onClick={() => {
            navigate(LOGOUT)
          }}>
            <ListItemIcon>
              <FiLogOut size={27} />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem> : null }
        {userInfo?.userToken ?
        <React.Fragment>
          <Divider />
          <ListItem key={7}>
            <LinearProgressWithLabel
              value={userInfo && userInfo?.userUsedSpace && userInfo?.userTotalSpace ? 100 * userInfo.userUsedSpace / userInfo.userTotalSpace : 0}
              actualSize={valueBytesToString(userInfo ? userInfo.userUsedSpace : 0)}
              userSpaceSize={valueBytesToString(userInfo ? userInfo.userTotalSpace : 0)}
            />
          </ListItem>
        </React.Fragment> : null}
      </List>
      </Box>
    </Drawer>
  )
}