import React, { useEffect, useRef, useState } from "react";
import FileListDialog from "./filelist_dialog";
import { getFolderList } from "src/utils/helper";

export function MoveFileListDialog(props) {
  const [page, setPage] = useState(1);
  const [fileList, setFileList] = useState([]);
  const windowSize = 40;
  const [hasMore, setHasMore] = useState(true);
  const {open, initialFolderID, userToken, onSubmit, onCancel, itemsForMove} = props;
  const [currentFolderID, setCurrentFolderID] = useState(initialFolderID)
  const [breadCrumbs, setBreadCrumbs] = useState([{original_name: 'My files', id: initialFolderID}])
  const localRef = useRef();
  localRef.current = {page, currentFolderID}

  function createBreadCrumb(serverBreadCrumb) {
    var breadCrumbs = [{original_name: 'My files', id: initialFolderID}];
    return breadCrumbs.concat(serverBreadCrumb)
  }

  function _itemsToDict(items) {
    var res = {};
    for (var i = 0; i < items.length; i++) {
      res[items[i].id] = 1
    }
    return res;
  }

  useEffect(() => {
    if (currentFolderID) {
      const items = _itemsToDict(itemsForMove);
      getFolderList(currentFolderID, userToken, 1, windowSize).then((filelist) => {
        var tmpFileList = [];
        const files = filelist.files;
        for (var i = 0; i < files.length; i++) {
          const fileListObject = files[i];
          if (items[fileListObject.id] !== 1) {
            tmpFileList.push(fileListObject);
          } else {
            continue
          }
        }
        setFileList([...tmpFileList]);
        setBreadCrumbs([...createBreadCrumb(filelist.bread_crumb)])
        if (files.length === windowSize) {
          setPage(2);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
    }
  }, [currentFolderID, itemsForMove])

  function loadMore() {
    getFolderList(localRef.current.currentFolderID, userToken, localRef.current.page, windowSize).then((filelist) => {
      const items = _itemsToDict(itemsForMove);
      var tmpFileList = [];
      const files = filelist.files;
      if (files.length === windowSize) {
        setHasMore(true)
        setPage(localRef.current.page+1);
      } else {
        setHasMore(false)
      }
      for (var i = 0; i < files.length; i++) {
        const fileListObject = files[i];
        if (items[fileListObject.id] !== 1) {
          tmpFileList.push(fileListObject);
        } else {
          continue
        }
      }
      setFileList([...fileList, ...tmpFileList]);
    })
  }

  useEffect(() => {
    // setCurrentFolderID('')
    if (open) {
      setFileList([])
      setBreadCrumbs([{original_name: 'My files', id: initialFolderID}])
      getFolderList(initialFolderID, userToken, 1, windowSize).then((filelist) => {
        const items = _itemsToDict(itemsForMove);
        var tmpFileList = [];
        const files = filelist.files;
        if (files.length === windowSize) {
          setHasMore(true)
        } else {
          setHasMore(false)
        }
        for (var i = 0; i < files.length; i++) {
          const fileListObject = files[i];
          if (items[fileListObject.id] !== 1) {
            tmpFileList.push(fileListObject);
          } else {
            continue
          }
        }
        setFileList([...tmpFileList]);
        setPage(2);
      })
    }
  }, [open, initialFolderID])

  return (
    <FileListDialog 
      fileList={fileList}
      dataLength={fileList.length ? fileList.length : 0}
      loadMore={loadMore}
      hasMore={hasMore}
      open={open}
      dialogTitle={"Move items"}
      cancelButtonTitle={"Cancel"}
      submitButtonTitle={"Move"}
      disableSubmitBtn={false}
      onSubmit={() => {
        onSubmit(localRef.current.currentFolderID);
      }}
      onItemPress={(id) => {
        setCurrentFolderID(id);
        setPage(1);
        setHasMore(true);
      }}
      onCancel={onCancel}
      breadCrumbs={breadCrumbs}
    />
  )
}