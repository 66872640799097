export const lightStyle = {
  mainBackgroud: {
    backgroundColor: '#EAF3FC', 
    width: '100%', 
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    height: '100%'
  },
  applicationContainer: {
    backgroundColor: 'black', 
    width: '100%', 
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    height: '100%'
  },
  homePageAuth: {
    backgroundColor: '#EAF3FC', 
    position: 'fixed', 
    width: '100%', 
    height: '100%',
  },
  fileExplorerList: {
    scrollbarColor:  "#EAF3FC #EAF3FC",
    backgroundColor: 'transparent',
    height: "100%", 
    overflowY:'auto',
  },
  btnSize: {
    backButtonSize: 22
  }
}