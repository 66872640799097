import React, { useEffect } from 'react';
import {List, Dialog, DialogActions, DialogContent, DialogTitle, 
  Button, ListItemButton, ListItemAvatar, ListItemText, Typography, Stack, 
  Breadcrumbs, Link,  } from '@mui/material';
import { GrNext } from "react-icons/gr";
import { GetListObjectIcon, rename } from 'src/utils/helper';
import InfiniteScroll from "react-infinite-scroll-component";
import { cutLongString } from '../../../utils/helper';

function CustomBreadCrumb(props) {
  const {breadCrumbs, onItemPress} = props;
  if (breadCrumbs?.length === 1) {
    return (
      <Breadcrumbs 
        maxItems={7} 
        itemsAfterCollapse={3}
        itemsBeforeCollapse={3}
        separator={<GrNext size={17} color='black'/>}
        aria-label="breadcrumb">
        <Typography color="text.primary" style={{fontWeight: 700}}>
          {breadCrumbs[0].original_name}
        </Typography>
      </Breadcrumbs>
    )
  } else {
    return (
      <Breadcrumbs 
        maxItems={7} 
        itemsAfterCollapse={3}
        itemsBeforeCollapse={3}
        separator={<GrNext size={17} color='black'/>}
        aria-label="breadcrumb">
          {breadCrumbs.slice(0, breadCrumbs.length-1).map((obj, index) => {
          return (
          <Link 
            key={index}
            underline="hover" 
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 200
            }}
            color="inherit" href="#" onClick={() => {
            onItemPress(obj.id)
          }}>
            {cutLongString(obj.original_name, 20)}
          </Link>)
        })}
        <Typography color="text.primary" style={{
          fontWeight: 700, 
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          overflow: 'hidden', 
          textOverflow: 'ellipsis',
          maxWidth: 200
          }}>
          {breadCrumbs[breadCrumbs.length-1].original_name}
        </Typography>
      </Breadcrumbs>)
  }
}

export default function FileListDialog(props) {
  
  const {onItemPress, open, fileList, dialogTitle, 
    submitButtonTitle, cancelButtonTitle, onSubmit, 
    onCancel, disableSubmitBtn, breadCrumbs, dataLength, hasMore, loadMore} = props;
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    onCancel()
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open])

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        maxWidth={'lg'}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle style={{fontWeight: 700}}>{dialogTitle}</DialogTitle>
        <DialogContent style={{width: 650, overflowY: "hidden"}}>
          <Stack direction={"column"} style={{background: 'white', flex: 1}}>
            <CustomBreadCrumb 
              breadCrumbs={breadCrumbs}
              onItemPress={onItemPress}
            />
          </Stack >
          <Stack direction={"column"} style={{height: 600, overflowY: 'auto'}} id="scrolllist">
            <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
              {fileList.map((item, index) => {
                return (
                  <ListItemButton onClick={() => {onItemPress(item.id)}} key={index}>
                    <ListItemAvatar>
                      <GetListObjectIcon props={item} previewFormat={'small'} folderSize={35} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography className="file-name-typo" >
                        {item.original_name}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                )
              })
              }
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancelButtonTitle}</Button>
          <Button 
            type="submit" 
            variant="contained"
            disabled={disableSubmitBtn}
            onClick={onSubmit}
            >{submitButtonTitle}</Button>
        </DialogActions>
        <InfiniteScroll
          dataLength={dataLength}
          next={loadMore}
          hasMore={hasMore}
          style={{ zIndex: 999999, width: '100%' }}
          scrollableTarget="scrolllist"
        />
      </Dialog>
    </React.Fragment>
  );
}