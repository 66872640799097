// TYPES

import { AiOutlineDownload } from "react-icons/ai";
import { BiMove } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { HiOutlineLink } from "react-icons/hi";
import { MdSync, MdSyncDisabled } from "react-icons/md";
import { VscChromeClose } from "react-icons/vsc";
import { MdDeleteOutline, MdDriveFileRenameOutline } from "react-icons/md";
import { PiShareFatBold, PiUsersThree } from "react-icons/pi";

export const ObjectType = {
  File: 0,
  Forlder: 1
}

export const AlertType = {
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
  SUCCESS: "success"
}

export const ITEM_HEIGHT = 48;
export const langs = [
  {
    id: 0,
    label: 'English'
  }
]
export const options = [
  {
    id:0,
    name: 'Copy',
    iconObject: <FiCopy size={22}/>,
    key: 'Copy'
  },
  {
    id:1,
    name: 'Rename',
    iconObject: <MdDriveFileRenameOutline size={22}/>,
    key: 'Rename'
  },
  {
    id:2,
    name: 'Share',
    iconObject: <PiShareFatBold size={22}/>,
    key: 'Share',
  },
  {
    id:3,
    name: 'Manage access',
    iconObject: <PiUsersThree size={22}/>,
    key: ''
  },
  {
    id:4,
    name: 'Download',
    iconObject: <AiOutlineDownload size={22}/>,
    key: ''
  },
  {
    id:5,
    name: 'Manage link access',
    iconObject: <HiOutlineLink size={22}/>,
    key: 'Public link'
  },
  {
    id:6,
    name: 'Move',
    iconObject: <BiMove size={22}/>,
    key: 'Move'
  },
  {
    id:7,
    name: 'Move to bin',
    iconObject: <MdDeleteOutline size={22}/>,
    key: 'Delete Folders'
  },
  {
    id:8,
    name: 'Remove',
    iconObject: <VscChromeClose size={22}/>,
    key: '',
    targetPage: 'sharePage'
  },
  {
    id:9,
    name: 'Sync folder',
    iconObject: <MdSync size={22}/>,
    key: 'Sync',
    targetPage: ''
  },
  {
    id:10,
    name: 'UnSync folder',
    iconObject: <MdSyncDisabled size={22}/>,
    key: 'Sync',
    targetPage: ''
  }
];

// BUILD
export const TARGET_PLATFORM = "web"; //"windows"; // web, windows, mac
export const MAX_CURRENT_UPLOADS = 4;
export const MAX_CURRENT_DOWNLOADS = 4;

//ROUTING

export const HOME_PAGE = "/auth-redirect/";
export const FILE_EXPLORER_PAGE = "/file-explorer"
export const SETTINGS_PAGE = "/settings"
export const PLANS_PAGE = "/plans"
export const BIN_PAGE = "/bin"
export const SEARCH_FILELIST = "/search-file-explorer"
export const IMAGE_VIEW_APP = "/image-preview-app"
export const VIDEO_VIEW_APP = "/video-preview-app"
export const PDF_VIEW_APP = "/document-view-app"
export const AUDIO_VIEW_APP = "/audio-preview-app"
export const EXCEL_VIEW_APP = "/excel-preview-app"
export const DOC_VIEW_APP = "/doc-preview-app"
export const LOGOUT = "/logout"
export const SHARES = "/shares"
export const APPLOOKUP = "/applookup"
export const CHECKOUT = "/checkout"
export const AUTH_PAGE = '/auth'

// EVENTS

export const JARFLOW_MOVE = 1
export const JARFLOW_DELETE = 2
export const JARFLOW_CREATE = 3
export const JARFLOW_UPDATE = 4
export const JARFLOW_SHARE = 5
export const JARFLOW_ACCESS_CHANGE = 6
export const JARFLOW_ACCESS_REMOVE = 7
export const JARFLOW_COPY = 8
export const JARFLOW_UPLOAD = 9
export const JARFLOW_RENAME = 10
export const JARFLOW_DOWNLOAD_ARCHIVE = 11
export const JARFLOW_UPDATE_PREVIEW = 12
export const JARFLOW_FAVORITES = 13;

// CLIENT EVENTS
export const FS_EVENT_SYNC_ENDED = 1;

//AUTH
export const GOOGLE_CLIENT_ID = "202849500744-sph00nubg4c5m60i1vui2oia6t48dfn9.apps.googleusercontent.com";
//API
export const API = "https://jf-api-dev.jarflow.com/api/v1"

//STYLE 
export const SELECTED_ROW_COLOR = "#b3d9ff"
export const DEFAULT_COLOR = "#EAF3FC"
export const HOVERED_COLOR = "#d7e2ec"
export const HOVERED_COLOR2 = "#c1cbd4"
export const DND_HOVERED = '#d2e7fd'
export const DND_DEFAULT_BG_COLOR = '#EAF3FC'

export const DEFAULT_FONT_FOR_PRODUCT_NAMES = "Belgrano, serif";

//ORDERING
export const GROUP_BY_NAME = 'name';
export const GROUP_BY_DATE = 'date';
export const GROUP_BY_TYPE = 'type';
export const GROUP_BY_SIZE = 'size';
export const GROUP_BY_FAVORITE = 'favorite';
export const GROUP_BY_DELETED_DATE = 'deletedDate';
export const GROUP_BY_TIME_LEFT = 'timeLeft';
export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';

//ALERT TYPES
export const ALERT_TYPE_INFO = 1;
export const ALERT_TYPE_ERROR = 2;
export const ALERT_TYPE_SUCCESS = 3;

//ACTIONS
export const SET_CURRENT_FOLDER_ID = 'set_current_folder_id';
export const INIT_BREAD_CRUMB = 'init_bread_crumb';
export const SET_BREAD_CRUMB = 'set_bread_crumb';
export const UPDATE_DOWNLOADS_PROGRESS = 'update_downloads_progress';
export const CLEAN_DOWNLOADED_OBJECT_PROGRESS = 'clean_downloaded_object_progress';
export const ADD_ACTIVE_DOWNLOADS = 'add_active_downloads';
export const SET_NOTIFICATIONS = 'set_notifications';
export const UPDATE_NOTIFICATION_LAST_VISITED = 'update_notification_last_visited';
export const DELETE_NOTIFICATION = 'delete_notification';
export const ADD_NOTIFICATION = 'add_notification';
export const PROCESS_MSG = 'process_msg';
export const ADD_ACTIVE_UPLOADS = 'add_active_uploads';
export const ADD_ACTIVE_DIRECTORY_UPLOAD = 'add_active_directory_upload';
export const ADD_PENDING_PREVIEWS = 'add_pending_previews';
export const DELETE_PENDING_PREVIEWS = 'delete_pending_previews';
export const SET_FILE_LIST_LOADED_STATUS = 'set_file_list_loaded_status';
export const REMOVE_ACTIVE_DIRECTORY_UPLOAD = 'remove_active_directory_upload';
export const TMP_UPLOAD_FILE_OBJECTS = 'tmp_upload_file_objects';
export const CLEAN_TMP_UPLOAD_FILE_OBJECTS = 'clean_tmp_upload_file_objects'
export const REMOVE_TMP_UPLOAD_FILE_OBJECT = 'remove_tmp_upload_file_object'
export const REMOVE_ACTIVE_UPLOADS = 'remove_active_uploads';
export const REMOVE_ACTIVE_DOWNLOADS = 'remove_active_downloads';
export const DELETE_DOWNLOADS = 'delete_downloads';
export const UPDATE_UPLOADS_PROGRESS = 'update_uploads_progress';
export const SET_CURRENT_PG_PAGE = 'set_current_pg_page';
export const SET_HAS_MORE = 'set_has_more';
export const SET_HAS_MORE_IN_BIN = 'set_has_more_in_bin';
export const REPLACE_UPLOADED_FILE = 'replace_uploaded_file';
export const DELETE_UPLOADS = 'delete_uploads';
export const DELETE_UPLOAD_OBJECT = 'delete_upload_object';
export const CLEAN_UPLOADS = 'clean_uploads';
export const CLEAN_DOWNLOADS = 'clean_downalods';
export const ADD_DOWNLOADS = 'add_downloads';
export const ADD_UPLOADS = 'add_uploads';
export const SET_SEEN_UPLOADS = 'set_seen_uploads';
export const SET_SEEN_DOWNLOADS = 'set_seen_downloads';
export const INIT_DOWNLOAD_LISTENER = 'init_download_listenere';
export const SET_FILE_FAVORITES = 'set_file_favorites';
export const SET_MODAL_FILE_MOVE_DATA = 'set_modal_file_move_data';
export const SET_SHARE_FILE_OBJECT = 'set_share_file_object';
export const UPDATE_FILEOBJECT_ACCESS = 'udpate_fileobject_access';
export const SET_MANAGE_PERMISSION_FILE_OBJECT = 'set_manage_permission_file_object';
export const SET_COPY_LINK_FILE_OBJECT = 'set_copy_link_file_object';
export const SET_ALERT_DATA = 'set_alert_data';
export const SET_SYNCED_FOLDERS = 'set_synced_folders';
export const CLEAN_SYNCED_FOLDERS = 'clean_synced_folders';
export const SET_TMP_PATHS_FROM_RUST = 'set_tmp_path_from_rust';
export const ADD_UPLOAD_STRUCTURE = 'add_upload_structure';
export const SHOW_SHARE_MODAL = 'show_share_modal';
export const HIDE_SHARE_MODAL = 'hide_share_modal';
export const REMOVE_UPLOAD_ENTITY = 'remove_upload_entity';
export const ADD_CANCEL_TOKEN = 'add_cancel_token';
export const CANCEL_ACTIVE_AXIOS_UPLOAD = 'cancel_active_axois_upload';
export const REMOVE_CANCEL_TOKEN = 'remove_cancel_token';
export const CLEAN_TMP_PATHS_FROM_RUST = 'clean_tmp_paths_from_rust';
export const SET_UNSET_SELECTED_ROW = 'set_unset_selected_row';
export const SELECT_USING_SHIFT = 'select_using_shift';
export const SET_SELECT_ITEMS = 'set_select_items';
export const SET_UNSET_SELECTED_BIN_ROW = 'set_unset_selected_bin_row';
export const SET_CURRENT_BIN_PG_PAGE = 'set_current_bin_pg_page';
export const CLEAR_SELECTED_ROWS = 'clear_selected_rows';
export const CLEAR_SELECTED_BIN_ROWS = 'clear_selected_bin_rows';
export const ADD_BREAD_CRUMB_FOLDER = 'add_bread_crumb_foler';
export const SET_MODAL_FILE_COPY_DATA = 'set_modal_file_copy_data';
export const SET_FILE_LIST = 'set_file_list';
export const REMOVE_OLD_FILE_STRUCT = 'remove_old_file_struct';
export const SET_BIN_FILE_LIST = 'set_bin_file_list';
export const ADD_FILE_IN_LIST = 'add_file_in_list';
export const ADD_FILES_IN_LIST = 'add_files_in_list';
export const ADD_FILE_IN_BIN_LIST = 'add_file_in_bin_list';
export const ADD_FOLDER_IN_LIST = 'add_folder_in_list';
export const REMOVE_OBJECT_BY_INDEX = 'remove_object_by_index';
export const ADD_FORCE_OBJECT_IN_LIST = 'add_force_object_in_list';
export const FILTER_FAVORITE_FILES = 'filter_favorite_files';
export const SET_SELETECTED_ALL_FILES = 'selected_all_files';
export const SET_SELECTED_ALL_BIN_FILES = 'set_selected_bin_files';
export const SET_FILE_STRUCT_ORDERING = 'set_file_struct_ordering';
export const OPEN_NOTIFICATIONS = 'open_notifications'
export const SET_ACTIVE_DELETED_OBJECTS = 'set_active_deleted_objects';
export const SET_ITEMS_FOR_ACTION = 'set_items_for_action';
export const SET_BIN_ITEMS_FOR_ACTION = 'set_items_for_action';
export const SET_BACK_URL = 'set_back_url'
export const SET_AUTH_TOKENS = 'set_auth_tokens';
export const SET_PUBLIC_LINK_PASSWORD = 'set_public_link_password';
export const SET_CURRENT_PUBLIC_TOKEN = 'current_public_token';
export const SET_SHOW_CONTAINER_HEADER_UPLOAD = 'set_show_container_header_upload';
export const UPDATE_FILE_IN_LIST = 'update_file_in_list';
export const UPDATE_PREVIEW = 'update_preview';
export const INCREMENT_NEW_CONTENT = 'increment_newContent';
export const DECREMENT_NEW_CONTENT = 'decrement_new_content';
export const SET_NEW_CONTENT = 'set_new_content';
export const RENAME_FILE_OBJECT = 'rename_file_object';
export const DELETE_FILE_IN_LIST = 'delete_file_in_list';
export const SET_FILE_UPLOAD_IN_FOLDER = 'set_file_upload_in_folder';
export const DELETE_BIN_OBJECT = 'delete_bin_object';
export const DELETE_FILE_OBJECT = 'delete_file_object';
export const DELETE_FILE_OBJECTS = 'delete_file_object';
export const SET_PREVIEW_SIZE_FORMAT = 'set_preview_size_format';
export const SET_USER_INFO = 'set_user_info';
export const LOG_OUT = 'log_out';

export const RELEASE = 'dev';
var _REDIRECT_URL = '';
if (TARGET_PLATFORM === 'web') {
  if (RELEASE === 'dev') {
    _REDIRECT_URL = "https://dev-cli.jarflow.com/authorized/";
  } else if (RELEASE === 'prod') {
    _REDIRECT_URL = "https://app.jarflow.com/authorized/"
  } else {
    _REDIRECT_URL = "http://localhost:3000/authorized/"
  }
};

export const REDIRECT_URL = _REDIRECT_URL;