import { Box, Button, CircularProgress, Fab, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf'
import { lightStyle } from "../../../style/light_style";
import { API, FILE_EXPLORER_PAGE } from "../../../utils/consts";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import InfiniteScroll from "react-infinite-scroll-component";
import { getFileObjectByID } from "../resolver";
import { TopComponent } from "src/components/base/top_component";
import { cutLongString, getJarFlowObject } from "src/utils/helper";
import { APPLICATION_HEADER_TITLE_FONT_COLOR, APPLICATION_HEADER_TITLE_FONT_SIZE } from "src/style/global";


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


function getFileObject(tmpID, userToken) {
  return fetch(API + `/fileobject/${tmpID}`, {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(response =>{
    return response.blob()
  })
}

function PageWithObserver({ index, pageNumber, scale, renderTextLayer, renderAnnotationLayer, renderMode, setPageHeight }) {

  return <Page 
    key={index}
    pageNumber={pageNumber}
    scale={scale}
    renderTextLayer={renderTextLayer}
    renderAnnotationLayer={renderAnnotationLayer}
    renderMode={renderMode}
    onRenderSuccess={(obj)=>{
      setPageHeight(obj.height)
    }}
    />;
}

function PdfPageList(props) {

  const setPageVisibility = useCallback((pageNumber, isIntersecting) => {
    props.setVisiblePages((prevVisiblePages) => ({
      ...prevVisiblePages,
      [pageNumber]: isIntersecting
    }));
  }, []);

  return (
    <Stack direction="column">
      {Array.from(new Array(props.numPages), (el, index) => (
        <PageWithObserver 
          key={`page_${index + 1}`} 
          index={`page_${index + 1}`}
          pageNumber={index + 1} 
          scale={props.scale}
          renderTextLayer={false} 
          renderAnnotationLayer={false} 
          setPageHeight={props.setPageHeight}
          setPageVisibility={setPageVisibility}
          renderMode="canvas"/>
      ))}
    </Stack>
  )
}


export function PdfApp() {

  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  const [blob, setBlob] = useState();
  const [numPages, setNumPages] = useState(1);
  const [loadedPages, setLoadedPages] = useState(1)
  const [pageHeight, setPageHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [pdfName, setPdfName] = useState("")
  const [hasMore, setHasMore] = useState(true)
  const [scale, setScale] = useState(1)
  const [scrollTop, setScrollTop] = useState(0);
  const [visiblePages, setVisiblePages] = useState({});
  let { fileObjectID, parentDir } = useParams();

  const navigate = useNavigate();
  const pageBatchSize = 20;
  const scaleStep = 0.5
  const maxScale = 3;
  var containerStyle = {};
  containerStyle = {...lightStyle.applicationContainer};
  //containerStyle.backgroundColor = "rgba(0, 0, 0, .8)"

  useEffect(()=>{
    
    getFileObject(fileObjectID, userInfo.userToken).then(data=>{
      setBlob(data)
    })
    getJarFlowObject(fileObjectID, userInfo.userToken).then(data => {
      if (data[0]) {
        setPdfName(data[0].original_name)
      }
    })
  }, [])

  useEffect(()=>{
    if (scrollHeight - scrollTop < 3*pageHeight && hasMore) {
      loadMore()
    }
  }, [scrollHeight, scrollTop])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    if (pageBatchSize > numPages) {
      setLoadedPages(numPages)
      setHasMore(false)
    } else {
      setLoadedPages(pageBatchSize)
    }
  }

  function loadMore() {
    if (loadedPages < numPages) {
      if (loadedPages + pageBatchSize <= numPages) {
        setLoadedPages(loadedPages + pageBatchSize)
      } else {
        setLoadedPages(numPages)
      }
    } else {
      setHasMore(false)
    }
  }

  function displayCurrentPageNumber(visiblePages) {
    var keys = Object.keys(visiblePages)
    var pages = []
    for (var i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (visiblePages[key]) {
        pages.push(key)
      }
    }
    switch(pages.length) {
      case 1: {
        return pages[0]
      }
      case 2: {
        return pages[0]
      }
      case 3: {
        return pages[1]
      }
      default: {
        return pages[0]
      }
    }
  }

  function getScrollData(e) {
    setScrollTop(e.target.scrollTop )
    setScrollHeight(e.target.scrollHeight)
  }

  return (
    <TopComponent>
      <Stack 
        style={containerStyle}
        direction="row"
        alignItems="center"
        spacing={0}
      >
        <Stack direction={"row"} style={{width: '100%',marginBottom: 10, height: 72}}>
          <Grid container spacing={2}>
            <Grid item xs={5} md={4} lg={4}>
              <Box>
                <Typography style={{fontStyle:'italic', 
                fontSize: APPLICATION_HEADER_TITLE_FONT_SIZE, marginTop: 15, color: APPLICATION_HEADER_TITLE_FONT_COLOR}}>{cutLongString(pdfName, 45)}</Typography>
              </Box>
              
            </Grid>
            <Grid item xs={2} md={4} lg={4}>
              {pageHeight !== 0?
              <Typography style={{textAlign: 'center', marginTop: 15, fontSize: 15, 
              color: APPLICATION_HEADER_TITLE_FONT_COLOR}}>{1+ Math.round(scrollTop/ pageHeight) + " / " + numPages}</Typography>:null}
              
            </Grid>
            <Grid item xs={5} md={4} lg={4}>
              <Stack direction={"row"} alignItems={"flex-end"} padding={1} justifyContent={"flex-end"} style={{width: '100%', height: '100%'}}>
                <IconButton 
                    onClick={()=>{
                    if (scale - scaleStep >= 1) {
                      setScale(scale-scaleStep)
                      if (numPages >= pageBatchSize)
                        setLoadedPages(pageBatchSize)
                      else 
                        setLoadedPages(numPages)
                    }
                  }}>
                    <BsZoomOut size={25} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
                </IconButton>
                <IconButton onClick={()=>{
                  if (scale + scaleStep <= maxScale) {
                    setScale(scale+scaleStep)
                    if (numPages >= pageBatchSize)
                      setLoadedPages(pageBatchSize)
                    else 
                      setLoadedPages(numPages)
                  }
                }}>
                  <BsZoomIn size={25} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
                </IconButton>
                <IconButton onClick={()=>{
                  navigate(-1)
                }}>
                  <TfiClose size={25} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
          
        </Stack>
        <Box 
          direction="column"  
          className="all-page-container" 
          id="pdf-stack-scrollable"
          onScroll={getScrollData}
          >
          
          <Document
            file={blob}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Typography style={{fontSize: 18, color: 'white'}}>Loding document ...</Typography>}
            noData={<Stack direction={"row"} style={{width: 500, alignContent: 'center', alignItems: 'center', flex: 1, justifyContent: 'center'}}>
              <Box>
                <Typography style={{fontSize: 18, color: 'white'}}>Loding document ...</Typography>
              </Box>
              <Box>
                <CircularProgress size={20}/>
              </Box></Stack>}
          >
            <PdfPageList 
              numPages={loadedPages} 
              setPageHeight={setPageHeight}
              scale={scale}/>
          </Document>
        </Box>
        
      </Stack>
    </TopComponent>
  )
}