import React, { useEffect, useState } from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, 
  Button, Stack, Grid, TextField, FormControl, InputLabel,
  Select, Autocomplete, Chip, MenuItem, Box, Avatar, Typography} from '@mui/material';
import { getContactList, randomString } from '../../../utils/helper';


export default function ShareDialog(props) {
  
  const {open, onSubmit, selected, shareName,
    onCancel, share_permissions, userInfo} = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [shareTarget, setShareTarget] = useState('');
  const [selectedAccess, setSelectedAccess] = useState(share_permissions ? share_permissions[1] : null);
  const [contactList, setContactList] = useState([]);

  const handleClose = () => {
    setOpenDialog(false);
    onCancel()
  };

  const handleChange = (event) => {
    setSelectedAccess(event.target.value);
  };

  useEffect(() => {
    setOpenDialog(open);
    if (open) {
      var newList = [];
      getContactList(userInfo?.userToken).then(data=> {
        setContactList(data);
      })
    }
  }, [open])

  useEffect(() => {
    setShareTarget('');
  }, [])

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        maxWidth={'lg'}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        {!shareName && selected && selected.length > 0 ? 
        <DialogTitle style={{fontWeight: 700}}>{`Share ${selected.length} items`}</DialogTitle>:
        <DialogTitle style={{fontWeight: 700}}>{`Share ${shareName}`}</DialogTitle>
        }
        <DialogContent style={{width: 650}}>
          <Stack direction={"column"} style={{height: 400, width: '100%'}}>
            <Grid 
              container 
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              >
              <Grid item lg={9} md={9}>
                <Stack direction="row" marginTop={1}>
                  <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300 }}
                    freeSolo
                    options={contactList}
                    autoHighlight
                    getOptionLabel={(option) => {
                      return `${option?.target_user?.email} ${option?.target_user?.name}`
                    }}
                    onChange={(event, newValue) => {
                      setShareTarget(newValue)
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                        <Grid container>
                          <Grid item xs={2}>
                            <Avatar
                              sx={{ width: 30, height: 30, marginTop: 1}}
                              src={option.target_user.avatar}
                            >{option.target_user.email}
                            </Avatar>
                          </Grid>
                          <Grid item xs={10}>
                            <Stack direction={"column"}>
                              <Typography style={{fontSize: 15, fontWeight: 'bold'}}>
                                {option.target_user.name ? option.target_user.name : option.target_user.email} 
                              </Typography>
                              <Typography style={{fontSize: 15}}>
                                {option.target_user.email}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="use user email or user id"
                        size="small"
                        variant="outlined" 
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item sm={3} lg={3} md={3}>
                <FormControl sx={{mt:1, width: '100%' }} size="small">
                  <InputLabel id="access-input-label">Access</InputLabel>
                  <Select
                    labelId="access-input-label"
                    id="daccess-input"
                    value={selectedAccess}
                    label="Access"
                    onChange={handleChange}
                    style={{width: '100%'}}
                    defaultValue={share_permissions ? share_permissions[2] : {}}
                  >
                    {share_permissions?.map((item, index) => {
                      if (item.name !== 'Owner') {
                        return (
                          <MenuItem value={item} key={index}>{item.name}</MenuItem>
                        )
                      } else {
                        return null
                      }
                      
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12}>
                <Stack direction="row" marginTop={2}>
                  <Autocomplete
                    disabled
                    clearIcon={false}
                    options={[]}
                    defaultValue={share_permissions ? share_permissions[0]?.description.split(",") : {}}
                    freeSolo
                    multiple
                    value={selectedAccess?.description.split(",")}
                    renderTags={(value, props) => {
                      return value.map((item, index)=>{
                        if (item) {
                          return (
                            <Chip label={item} key={index}/>
                          )
                        } else {
                          return null
                        }
                        
                      })
                    }}
                    renderInput={(params) => 
                      <TextField 
                        {...params}
                        disabled 
                        label="Permissions"
                        className="permission-input"
                      />}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained"
            disabled={shareTarget.length === 0 ? true : false}
            onClick={() => {
              onSubmit(selectedAccess.id, shareTarget)
            }}
            >Share
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}