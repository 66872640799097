import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { cutLongString } from "src/utils/helper";


function DeleteItemCaption(props) {
  const {selectedRows} = props;

  if (selectedRows && selectedRows.length === 1) {
    return (
      <Typography>Item <strong>{cutLongString(props.selectedRows[0]?.original_name, 100)} </strong> will be moved in the bin.</Typography>
    )
  } else if (selectedRows && selectedRows.length > 1) {
    return (
      <Typography>{props.selectedRows.length} {props.selectedRows.length === 1 ? 'items' : 'items'} will be moved in the bin.</Typography>
    )
  }
}


export function MoveToBin(props) {
  const {showDeleteDialog, handleCancel, selectedRows, handleDeleteItems} = props;
  
  return (
    <Dialog
      open={showDeleteDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {selectedRows.length === 1 ? "Delete item" : "Delete selected items"}
      </DialogTitle>
      <DialogContent>
        <DeleteItemCaption
          selectedRows={selectedRows}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button 
          type="submit" 
          variant="contained"
          onClick={() => {
          handleDeleteItems(selectedRows)
        }}>
          Move to bin
        </Button>
      </DialogActions>
    </Dialog>
  )
}