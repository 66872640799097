import { Box, Chip, Divider, Fab, Grid, IconButton, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { lightStyle } from "../../../style/light_style";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API, FILE_EXPLORER_PAGE } from "../../../utils/consts";
import { getApplicationControls, getFileIDS, getPreviewList, getRandomInt, getSimpleFileList, getTmpUrl, getToken, reduxPreviewWrapper } from "../../../utils/helper";
import { ResolverParams, resolveObjectType } from "../resolver";
import { SetFileList } from "../../../../src/redux_store/actions";
import { AudioPlayer } from "./audio_player";
import { TfiClose } from "react-icons/tfi";
import { AddFilesInList, SetListObjectPreviewSizeFormat, UpdatePreview } from "../../../redux_store/actions";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

class UrlUpdater {
  create(userInfo, objectID, setAudioSrc, intervalDuration) {
    this.clearUpdateInterval();
    this.updateInterval = setInterval(() => {
      getTmpUrl(objectID, userInfo?.userToken, intervalDuration / 1000).then(data => {
        const tmpID = data.id;
        setAudioSrc(API + `/tmp-file-object-content/${tmpID}`);
      })
    }, intervalDuration - 10000)
  }

  clearUpdateInterval() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval)
    }
  }
}

function getNextSong(songList, currentSongID, random) {
  const songListLenght = songList.length;
  if (!random) {
    for (var i = 0; i < songListLenght; i++) {
      if (songList[i].obj.id === currentSongID) {
        if (i + 1 !== songListLenght) {
          return songList[i+1].obj
        }
        return songList[0].obj
      }
    }
  } else {
    return songList[getRandomInt(songListLenght)].obj
  }
  
}

var urlUpdater = new UrlUpdater();

export function AudioApp() {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const fileStruct = useSelector(state => state.fileListReducer.fileStruct)
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  let { fileObjectID, parentDir } = useParams();
  const [applicationControls, setApplicationControls] = useState();
  const [index, setIndex] = useState()
  const [audioSrc, setAudioSrc] = useState("")
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [currentPlaying, setCurrentPlaying] = useState()
  const [audioFiles, setAudioFiles] = useState([])

  const navigate = useNavigate();
  const localRef = useRef();
  let query = useQuery();
  const p = query.get('p');
  const windowSize = 40;
  const dispatch = useDispatch();
  const APP_NAME = 'audio_player_app';
  localRef.current = {userInfo, currentPlaying, audioSrc, page};

  useEffect(()=>{
    if (userInfo?.userToken) {
      setApplicationControls(userInfo?.applicationsControl)
    } else {
      getApplicationControls().then(data => {
        setApplicationControls(data.applications_control)
      })
    }
    return () => {
    }
  }, [])

  function loadMore() {
    let file_type = query.get("file_type");
    var resolverParams = new ResolverParams();
    resolverParams.objectID = fileObjectID;
    resolverParams.parentDir = parentDir;
    const resolverObject = resolveObjectType(applicationControls, 
      resolverParams, file_type);
    getSimpleFileList(
      parentDir,
      getToken(userInfo, p),
      resolverObject.application.extensions,
      windowSize,
      localRef.current.page
    ).then(res => {
      if (!res.msg && !res.detail) {
        dispatch(AddFilesInList(APP_NAME, res.files, true))
        const fileIDS = getFileIDS(res.files);
        reduxPreviewWrapper(fileIDS, APP_NAME, 'small', getToken(userInfo, p), dispatch)
        if (res.files.length === windowSize) {
          setHasMore(true);
          setPage(localRef.current.page+1)
        } else {
          setHasMore(false);
        }
      }
    })
  }

  useEffect(() => {
    if (applicationControls) {
      let file_type = query.get("file_type");
      var resolverParams = new ResolverParams();
      resolverParams.objectID = fileObjectID;
      resolverParams.parentDir = parentDir;
      const resolverObject = resolveObjectType(applicationControls, 
        resolverParams, file_type);
      getSimpleFileList(
        parentDir,
        getToken(userInfo, p),
        resolverObject.application.extensions,
        windowSize,
        1,
        [fileObjectID],
        1,
      ).then(res => {
        if (!res.msg && !res.detail) {
          const selectedID = res.selected[0].id;
          var allFiles = [...res.selected, ...res.files.filter((item) => item.id !== selectedID)]
          const fileIDS = getFileIDS(allFiles);
          dispatch(SetFileList(allFiles, APP_NAME, windowSize, false))
          reduxPreviewWrapper(fileIDS, APP_NAME, 'small', getToken(userInfo, p), dispatch)
          if (res.files.length === windowSize) {
            setHasMore(true);
            setPage(localRef.current.page+1)
          } else {
            setHasMore(false);
          }
        }
      })
    }
  }, [applicationControls])

  useEffect(() => {
    if (fileStruct[APP_NAME]?.fileList) {
      const fileList = fileStruct[APP_NAME].fileList;
      var ids = []
      for (var i = 0; i < fileList.length; i++) {
        ids.push(fileList[i].id)
      }
      //const ids = getSuitableFileObjects(application, fileList)
      
      const index = ids.indexOf(fileObjectID)
      var _audioFiles = [];
      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].id === fileObjectID) {
          setCurrentPlaying(fileList[i]);
          getTmpUrl(fileList[i].id, getToken(userInfo, p), 3600).then(data => {
            const tmpID = data.id;
            if (!audioSrc) {
              setAudioSrc(API + `/tmp-file-object-content/${tmpID}`);
            }
          })
        }
        _audioFiles.push({obj: fileList[i]});
      }
      setAudioFiles([..._audioFiles]);
      setIndex(index);
    }
  }, [fileStruct])

  return (
    <Box 
      style={lightStyle.mainBackgroud}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Stack direction={"column"} style={{width: '100%', height: '100%', display: 'flex', flexFlow: 'column'}}>
        <Stack direction={"row"} style={{width: '100%', height: 50, background: 'black'}}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              
            </Grid>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={2}>
              <Stack direction={"row"} alignItems={"flex-end"} padding={1} justifyContent={"flex-end"} style={{width: '100%', height: '100%', zIndex:9999}}>
                <IconButton onClick={()=>{
                  var url = `${FILE_EXPLORER_PAGE}/${parentDir}/`;
                  if (!userInfo?.userToken && p) {
                    url += '?p=' + p;
                  }
                  navigate(url)
                }}>
                  <TfiClose size={25} style={{color: '#EAEAF3'}}/>
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Box style={{width: '100%'}} justifyContent={"center"} alignItems={"center"}>
          <AudioPlayer 
            audioFiles={audioFiles}
            userInfo={localRef.current.userInfo}
            setAudioSrc={setAudioSrc}
            setCurrentPlaying={setCurrentPlaying}
            currentPlaying={currentPlaying}
            audioSrc={audioSrc}
            autoPlay={true}
            urlUpdater={urlUpdater}
            dataLength={audioFiles.length ? audioFiles.length : 0}
            loadMore={loadMore}
            hasMore={hasMore}
            p={p}
          />
        </Box>
      </Stack>
    </Box>
  )
}
/**
 * <Stack direction={"row"} style={{height: 90}}>
          <button onClick={() => {
            var audio = new Audio(localRef.current.audioSrc);
            audio.play();
            setTimeout(() => {
              audio.src = localRef.current.audioSrc;
              audio.currentTime = 600;
              audio.play();
            }, 30000);
          }}>Play</button>
        </Stack>
 */