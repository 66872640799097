import { List, ListItem, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";


export function UploadingAnimation(props) {
  const [loadingText, setLoadingText] = useState("")
  const [tikCounter, setTikCounter] = useState(0);

  const ref = useRef();
  ref.current = {loadingText, tikCounter, setLoadingText, setTikCounter}

  useEffect(()=>{
    const animInterval = setInterval(() => {
      var tmpCounter = ref.current.tikCounter;
      var text = "Uploading ";
      if (tmpCounter > 3) {
        ref.current.setTikCounter(0)
        tmpCounter = 0;
      }
      for (var i = 0; i < tmpCounter; i++) {
        text += "."
      }
      ref.current.setTikCounter(tmpCounter+1);
      ref.current.setLoadingText(text);
    }, 1000);
    return ()=>{
      clearInterval(animInterval)
    }
  }, [])

  if (props.show) {
    return <Stack direction="row" style={{width: '130px'}} className="anim-loading2-container">
      <List sx={{ width: '130px' }} className="anim-loading2">
        <ListItem
          key={1}
          disableGutters
          sx={{ width: '130px' }}
          className="li-anim"
        >
          <Stack direction="row" style={{width: '130px'}}>
            <Typography style={{fontSize: 15, fontStyle: 'italic', width: '100%'}}>{loadingText}</Typography>
          </Stack>
        </ListItem>
        <ListItem
          key={2}
          disableGutters
          sx={{ width: '130px' }}
          className="li-anim"
        >
          <Stack direction="row" style={{width: '130px'}}>
            <Typography style={{width: '100%', textAlign: 'center', fontSize: 15, fontWeight: 'bold '}}>{props.currentUploaded} from {props.totalUploadItems}</Typography>
          </Stack>
        </ListItem>
      </List>
    </Stack>
  } else {
    return null
  }
}