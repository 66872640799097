import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createFolder } from 'src/utils/helper';
import { AlertData } from 'src/objects/alert';
import { AlertType } from 'src/utils/consts';
import { AddFolderInList, SetAlertData } from 'src/redux_store/actions';

export default function CreateFolderDialog(props) {
  
  const {parentObjectID, open, userToken, setOpenCreateFolde} = props;
  const [value, setValue] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenCreateFolde(false)
    setValue('');
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open])
  

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        maxWidth={'md'}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            setOpenDialog(false)
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Create new folder</DialogTitle>
        <DialogContent style={{width: 400}}>
          <TextField
            autoFocus
            required
            margin="dense"
            label={`Set new folder name`}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained"
            onClick={() => {
              createFolder(parentObjectID, value, userToken).then(data => {
                if (data.msg || data.detail) {
                  var alertData = new AlertData();
                  alertData.type = AlertType.ERROR;
                  alertData.message = data.msg || data.detail
                  dispatch(SetAlertData(alertData))
                } else {
                  dispatch(AddFolderInList(parentObjectID, data))
                }
                setOpenCreateFolde(false)
              });
            }}>Create</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}