import React, { useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import styled from "styled-components";
import { CHECKOUT, PLANS_PAGE } from "src/utils/consts";
import { checkout } from "src/utils/helper";

const PricingList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});


export function PricingCard(props) {
  const navigate = useNavigate();
  const [disablePurchaseButton, setDisablePurchaseButton] = useState(false);
  return (
    <Card className={props.className ? props.className:"pricing-card"}>
      <CardHeader
        title={props.title}
        subheader={props.subheader}
        titleTypographyProps={{ align: "center" }}
        action={props.title}
        subheaderTypographyProps={{
          align: "center",
        }}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[700],
        }}
      />
      <CardContent style={{flex: 1}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            mb: 2,
          }}
        >
          <Typography component="h2" variant="h3" color="text.primary">
            ${props.price}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            /{props.period}
          </Typography>
        </Box>
        <PricingList>
          {props.description?.map((line, index) => {
            if (index === 0) {
              return <Typography
              component="li"
              variant="subtitle1"
              align="center"
              key={index}
              style={{fontWeight: 'bold', fontSize: 19}}
            >
              {line?.description}
            </Typography>
            } else {
              return <Typography
                component="li"
                variant="subtitle1"
                align="center"
                key={index}
                style={{fontSize: 15}}
              >
                {line?.description}
              </Typography>
            }
            
          })}
        </PricingList>
      </CardContent>
      
      <CardActions style={{bottom: 0, position: 'absolute', width: '100%'}}>
        {props.userInfo?.userProduct?.product?.scu === props.scu ? 
        <Button fullWidth variant={props.buttonVariant} disabled>
          In Used
        </Button> : 
        <LoadingButton 
          fullWidth 
          loading={disablePurchaseButton}
          variant={props.buttonVariant} 
          disabled={disablePurchaseButton}
          onClick={() => {
            if (props.checkout) {
              setDisablePurchaseButton(true)
              checkout(props.productID, props.userInfo?.userToken).then(data=> {
                props.onGetSecret(data.secret)
              })
            } else {
              navigate(`${PLANS_PAGE}/${props.id}/purchase/`);
            }
          }}>
          {props.buttonText}
        </LoadingButton>}
      </CardActions>
    </Card>
  )
}