import { getPublicLinkPasswrods, getUserInfoFromProfileData, getUserSession } from "src/utils/helper";
import { LOG_OUT, SET_AUTH_TOKENS, SET_BACK_URL, SET_CURRENT_PUBLIC_TOKEN, SET_PUBLIC_LINK_PASSWORD, SET_USER_INFO } from "../utils/consts";

const initialState = {
  userInfo: getUserInfoFromProfileData(getUserSession()),
  accessToken: "",
  refreshToken: "",
  backUrl: "",
  publicLinkPasswords: getPublicLinkPasswrods(),
  currentPulicToken: ""
}

export default function userProfileReducer(state = initialState, action) {
  switch(action.type) {
    case SET_CURRENT_PUBLIC_TOKEN: {
      const {publicToken} = action.payload;
      return {
        ...state,
        currentPulicToken: publicToken
      }
    }
    case SET_PUBLIC_LINK_PASSWORD: {
      const {publicLinkPassword, publicToken} = action.payload;
      const obj = {};
      obj[publicToken] = publicLinkPassword;
      return {
        ...state,
        publicLinkPassword: {...state.publicLinkPassword, ...obj}
      }
    }
    case SET_AUTH_TOKENS: {
      const payload = action.payload;
      const access = payload.access;
      const refresh = payload.refresh;
      return {
        ...state,
        accessToken: access,
        refreshToken: refresh
      }
    }
    case SET_BACK_URL: {
      const payload = action.payload;
      return {
        ...state,
        backUrl: payload
      }
    }
    case SET_USER_INFO: {
      const userInfo = action.payload;
      return {
        ...state,
        userInfo: userInfo
      }
    }
    case LOG_OUT: {
      return {
        ...state,
        userInfo: {}
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}