import { AlertData } from "src/objects/alert";
import { ADD_NOTIFICATION, ALERT_TYPE_ERROR, ALERT_TYPE_INFO, ALERT_TYPE_SUCCESS, AlertType, DELETE_NOTIFICATION, PROCESS_MSG, SET_NOTIFICATIONS, UPDATE_NOTIFICATION_LAST_VISITED } from "../utils/consts";

const initialState = {
  notificationList: [],
  lastVisited: 0
}

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_NOTIFICATION: {
      const notifID = action.payload;
      var notificationList = state.notificationList;
      var newList = []
      for (var i = 0; i < notificationList.length; i++) {
        if (notificationList[i].props.idNotif !== notifID) {
          newList.push(notificationList[i])
        }
      }
      return {
        ...state,
        notificationList: [...newList]
      }
    }
    case UPDATE_NOTIFICATION_LAST_VISITED: {
      const lastVisited = action.payload;
      return {
        ...state,
        lastVisited: lastVisited
      }
    }
    case SET_NOTIFICATIONS: {
      const notifications = action.payload;
      return {
        ...state,
        notificationList: notifications
      }
    }
    case ADD_NOTIFICATION: {
      const payload = action.payload;
      return {
        ...state,
        notificationList: [payload, ...state.notificationList]
      }
    }
    
    default: {
      return {
        ...state
      }
    }
  }
}