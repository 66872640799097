import { Box, Grid, Stack, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "yet-another-react-lightbox/styles.css";
import { lightStyle } from "../../../style/light_style";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, FILE_EXPLORER_PAGE } from "../../../utils/consts";
import VideoJS from "./videojs";
import { cutLongString, getJarFlowObject } from "src/utils/helper";
import { APPLICATION_HEADER_TITLE_FONT_COLOR, APPLICATION_HEADER_TITLE_FONT_SIZE } from "src/style/global";
import { TfiClose } from "react-icons/tfi";


function getTmpUrl(objectID, userToken) {
  return fetch(API + `/tmp-file-object-url/${objectID}/?ex=600`, {
    method: 'GET',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    }
  }).then(response =>{
    return response.json()
  })
}


export function VideoApp() {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  let { fileObjectID, parentDir } = useParams();
  const [slides, setSlides] = useState({})
  const [videoName, setVideoName] = useState('');
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const playerRef = React.useRef(null);
  useEffect(()=>{
    setOpen(true)
    getJarFlowObject(fileObjectID, userInfo.userToken).then(data => {
      if (data[0]) {
        setVideoName(data[0].original_name)
      }
    })
    getTmpUrl(fileObjectID, userInfo.userToken).then(data=>{
      const tmpID = data.id;
      var mimeType = data.mime;
      if (data.mime === 'video/quicktime') {
        mimeType = 'video/mp4'
      } 
      
      setSlides({...{
        muted: true,
        language: "en",
        preload: "auto",
        fluid: true,
        autoplay: true,
        controls: true,
        responsive: true,
        playsinline: true,
        controlBar: true,
        sources: [
        {
          src: API + `/tmp-file-object-content/${tmpID}`,
          type: mimeType,
        },
        ]
      }})
    })
    document.addEventListener("keydown", function(event) {
      //event.preventDefault();
      //event.stopPropagation();
      if (event.key === "Escape") {
        navigate(backUrl)
      }
    });
    return () => document.removeEventListener("keydown", ()=>{});
  }, [])
  


  const handlePlayerReady = (player) => {
    playerRef.current = player;
  };

  return (
    <Box 
      style={lightStyle.mainBackgroud}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction={"row"} style={{width: '100%',height: 70, background: 'black'}} pt={3}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={4} lg={4}>
            
            
          </Grid>
          <Grid item xs={6} md={4} lg={4}>
            <Box>
              <Typography style={{
                fontStyle:'italic', 
                fontSize: APPLICATION_HEADER_TITLE_FONT_SIZE,
                marginTop: 10,
                color: APPLICATION_HEADER_TITLE_FONT_COLOR}}>{cutLongString(videoName, 42)}
                </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} md={4} lg={4}>
            <Stack direction={"row"} alignItems={"flex-end"} padding={1} justifyContent={"flex-end"} style={{}}>
              <IconButton onClick={()=>{
                navigate(-1)
              }}>
                <TfiClose size={25} style={{color: APPLICATION_HEADER_TITLE_FONT_COLOR}}/>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Box className="container-video-main">
        <Box className="container-video">
          <VideoJS options={slides} onReady={handlePlayerReady} />
        </Box>
      </Box>
      
      
    </Box>
  )
}