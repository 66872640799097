import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PublicLinkPasswordDialog from "./public_link_password_dialog";
import { CreateSnackBar } from "./dialogs/create_snack_bar";
import { OpenNotifications } from "src/redux_store/actions";
import { Notifications } from "./notifications";
import { MobileMenuDrawer } from "./drawers/MobileMenuDrawer";


export function TopComponent(props) {
  const alertData = useSelector(state => state.fileListReducer.alertData)
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const fileStruct = useSelector(state => state.fileListReducer.fileStruct);
  const currentPulicToken = useSelector(state => state.userProfileReducer.currentPulicToken);
  const openNotifications = useSelector(state => state.fileListReducer.openNotifications)
  const [openNotification, setOpenNotification] = useState(false)
  const [currentAlert, setCurrentAlert] = useState('')
  const [alertPool, setAlertPool] = useState([])
  const dispatch = useDispatch();
  const {openMobileMenu, onCloseMobileMenu, sharePage} = props;
  const localRef = useRef();
  localRef.current = {alertPool}
  useEffect(() => {
    if (!alertData || !alertData?.message) {
      setOpenNotification(false)
    } else {
      setAlertPool([...localRef.current.alertPool, alertData])
      setOpenNotification(true)
    }
  }, [alertData])

  useEffect(() => {
    
    const objectForAlert = localRef.current.alertPool[0];
    if (objectForAlert) {
      setCurrentAlert(objectForAlert)
      setAlertPool([...localRef.current.alertPool.slice(1, localRef.current.alertPool.length)])
      setOpenNotification(true)
    }
  }, [openNotification])

  return (
  <React.Fragment>
    {props.children}
    <MobileMenuDrawer 
      open={openMobileMenu}
      userInfo={userInfo}
      onCloseMobileMenu={onCloseMobileMenu}
      fileStruct={fileStruct}
      sharePage={sharePage}
    />
    <PublicLinkPasswordDialog 
      open={currentPulicToken.length > 0 ? true : false}
      publicToken={currentPulicToken}
    />
    <CreateSnackBar
      openNotification={openNotification}
      setOpenNotification={setOpenNotification}
      alertData={currentAlert}
    />
    <Notifications
      onClose={() => dispatch(OpenNotifications(false))}
      notificationList={fileStruct['notification']?.fileList}
      userToken={userInfo ? userInfo.userToken : null}
      open={openNotifications} 
    />
  </React.Fragment>)
}