import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NativeTypes } from "react-dnd-html5-backend";
import {
  Grid, Stack, Typography, TableRow, TableCell, Checkbox, Menu, MenuItem,
  Box
} from "@mui/material";
import { GetListObjectIcon, covertDate, craeteUploadStructure, createDirTree, createFolders, dateStringToGMTDAteString, getAccesses, getAllFileEntries, getDefaultObjectMenu, getFileDirPath, getFileListObjectMenu, getFileObjectMenu, getMainRootFromPath, getMainRootObject, randomString, setFavorite, valueBytesToString } from "src/utils/helper";
import { InputWithLabel } from "./input_with_label";
import { Favorite } from "./favorite";
import { RowSmartPanel } from "./row_smart_panel";
import { AddDownloads, AddFolderInList, AddTmpUploadFileObject, AddUploadStructure, AddUploads, DeleteFileInList, DeleteFileObject, SelectUsingShift, SetAlertData, SetCopyLinkFileObject, SetManagePermissionFileObject, SetModalCopyFileObject, SetModalMoveFileObject, SetShareFileObject, SetTmpPathsFromRust, SetUnsetSelectedRow, SetitemsForAction, ShowShareModal } from "src/redux_store/actions";
import { API, AlertType, DEFAULT_COLOR, FILE_EXPLORER_PAGE, HOVERED_COLOR, ITEM_HEIGHT, SHARES, TARGET_PLATFORM, options } from "src/utils/consts";
import { config } from "localforage";
import { AlertData } from "src/objects/alert";
import { ResolverParams, resolveObjectType } from "../applications/resolver";
import { AiFillFolder } from "react-icons/ai";
import { RxDotsHorizontal } from "react-icons/rx";
import { useDrag, useDrop } from "react-dnd";
import { TfiMinus } from "react-icons/tfi";
import { PiMinusLight } from "react-icons/pi";
import { SetFileUploadInFolder } from "../../redux_store/actions";
import { MdSync } from "react-icons/md";
import { FaSync } from "react-icons/fa";


function SmartCheckbox(props) {
  const {hovered, selected, dispatch, id, parentID} = props;

  if ((hovered === 1 && selected) || selected) {
    return (
      <Checkbox
        size={'small'}
        color="primary"
        checked={true}
        onChange={()=>{
          dispatch(SetUnsetSelectedRow(parentID, id))
        }}
      />
    )
  } else if (hovered === 1){
    return (<Checkbox
      size={'small'}
      color="primary"
      checked={false}
      onChange={()=>{
        dispatch(SetUnsetSelectedRow(parentID, id))
      }}
    />)
  } else {
    return null;
  }
}

function SmartIconObject(props) {
  const {synced, row} = props;
  if (synced) {
    return <Stack direction={"row"} style={{height: '100%', position: 'relative'}}>
      <FaSync size={10} style={{ left: 20, "color": "#537188", fontWeight: "bold", position: "absolute"}} />
      <GetListObjectIcon props={row} previewFormat={"small"} folderSize={35} />
    </Stack>
  }
  return <Stack direction={"row"} style={{height: '100%', position: 'relative'}}>
      <GetListObjectIcon props={row} previewFormat={"small"} folderSize={35} />
    </Stack>
}

const MemoSelectedRow = React.memo(SmartCheckbox, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected &&
  prevProps.id === nextProps.id &&
  prevProps.parentID === nextProps.parentID &&
  prevProps.hovered === nextProps.hovered
})


export function RowFileObject(props) {
  const {isItemSelected, row, index, applicationControls, sharePage, q,
    userInfo, windowWidth, onMenuItemSelect, p, parentFolder, disabled, shortPanel, env} = props;
  const [hoveredRow, setHoveredRow] = useState(0);
  const filesForUploadUpdate = useSelector(state => state.uploadsReducer.filesForUploadUpdate)
  const selectedRows = useSelector(state => state.fileListReducer.selectedRows)
  const syncedFolders = useSelector(state => state.fileListReducer.syncedFolders)
  const [ progress, setProgress ] = useState(undefined);
  const [containerClassName, setContainerClassName] = useState('list-fade-item');
  const [menuOptions, setMenuOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);
  const [background, setBackground] = useState({backgroundColor: '#EAF3FC'});

  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const labelId = `enhanced-table-checkbox-${index}`;
  var useDropFunction = {};
  function handleSelectedFav(val) {
    setFavorite(row.id, userInfo?.userToken);
    //setSelected([row]);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setHoveredRow(1);
  };
  useEffect(() => {
    if (!hoveredRow) {
      setAnchorEl(null);
    }
  }, [hoveredRow])

  useEffect(() => {
    if (!anchorEl) {
      setHoveredRow(0);
    }
  }, [anchorEl])

  useEffect(() => {
    setProgress(filesForUploadUpdate[row.id]?.progress)
  }, [filesForUploadUpdate[row.id]?.progress])

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'box',
      item: row ,
      end(item, monitor) {
        const dropResult = monitor.getDropResult()
        if (item && dropResult) {
          console.log(dropResult)
        }
      },
      canDrag(monitor) {
        if (row.id.length === 15) {
          return false;
        }
        if (row.created === -1) {
          return false;
        }
        return true
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [row.original_name],
  )

  if ((row.object_type === 'folder' && userInfo) || (parentFolder && userInfo)) {
    useDropFunction = {
    accept: [NativeTypes.FILE, 'box'],
    drop(item) {
      if (item.object_type) {
        var selectedIds = []
        for ( var i = 0; i < selectedRows.length; i++) {
          selectedIds.push(selectedRows[i])
        }
        selectedIds.push(item.id)
        fetch(API+'/move/'+row.id+'/',  {
            method: 'POST',
            headers: {
              'AuthToken': userInfo?.userToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              source_objects: selectedIds
            })
          }).then(resp =>{
            return resp.json()
          }).then(respJson => {
            if (respJson.msg || respJson.detail) {
              var alertData = new AlertData();
              alertData.type = AlertType.ERROR;
              alertData.message = respJson.msg || respJson.detail;
              dispatch(SetAlertData(alertData))
            } else {
              selectedIds.map((_item, index) => {
                dispatch(DeleteFileInList(row.parent_dir, _item))
              })
              
            }
          })
      } else if (item && item.dataTransfer) {
        var items = item.dataTransfer.items;
        getAllFileEntries(items).then(files=>{
          const flattenFiles = files.reduce((acc, val) => acc.concat(val), []);
          var onlyDirs = [];
          var onlyFiles = [];
          var folders = []
          for (var i = 0;i < flattenFiles.length; i++) {
            
            if (flattenFiles[i].isDirectory) {
              onlyDirs.push(flattenFiles[i]);
              folders.push(flattenFiles[i].fullPath);
            } else if (flattenFiles[i].path) {
              onlyFiles.push(flattenFiles[i])
            }
          }
          if (onlyFiles.length > 2000) {
            var meta = {
              currentFolderID: row.id
            }
            dispatch(SetTmpPathsFromRust(onlyFiles, folders, meta));
          } else {
            var parsed_files = [];
            for (let i = 0; i < onlyFiles.length; i++) {
              
              const f = onlyFiles[i];
              var lastIndexOfSlash = f.path.lastIndexOf("/");
              
              var filePath = "";
              if (lastIndexOfSlash === -1) {
                continue
              }
              if (lastIndexOfSlash === 0) {
                filePath = '/'
              } else {
                filePath = f.path.slice(0, lastIndexOfSlash)
              }
              folders.push(getFileDirPath(filePath));
              parsed_files.push({
                full_path: f.path,
                relative_path: f.path,
                id: randomString(15),
                name: f.name,
                size: f.size,
                file_object: f,
                relative_path_parent_dir: getFileDirPath(f.path)
              })
            }
            var dirTree = createDirTree(onlyDirs);
            
            createFolders(row.id, dirTree, userInfo?.userToken).then(data=>{
              if (data.msg || data.detail) {
                var alertData = new AlertData();
                alertData.type = AlertType.ERROR;
                alertData.message = data.msg || data.detail;
                dispatch(SetAlertData(alertData))
                return ;
              }
              
              var upload_structure = craeteUploadStructure(parsed_files, data.created_folders, data.main_roots);
    
              for (var i = 0; i < data.root_folders.length; i++) {
                dispatch(AddFolderInList(data.root_folders[i]));
              }
              var tmp_main_root_object = getMainRootObject(data.main_roots);
              dispatch(AddUploadStructure(upload_structure))
              for (var i = 0; i < parsed_files.length; i++) {
                var fileObject = parsed_files[i];
                const relative_path_parent_dir = fileObject.relative_path_parent_dir;
                var parentID = data.created_folders[relative_path_parent_dir]
                const main_root = getMainRootFromPath(relative_path_parent_dir);
                if (parentID) {
                  var tmpObject = {
                    original_name: fileObject.name,
                    id: fileObject.id,
                    created: covertDate(new Date()),
                    updated: 0,
                    size: fileObject.size,
                    favorite: false,
                    object_type: 'file',
                    progress: 0.001,
                    upload: true,
                    parent_dir: parentID,
                    main_root_id: tmp_main_root_object[main_root],
                    file: fileObject.file_object
                  };
                  dispatch(AddTmpUploadFileObject(tmpObject))
                  dispatch(AddUploads(tmpObject))
                }
              }
            })
          }});
      }
    },
    canDrop(item, monitor) {
      if (row.id === item.id) {
        return false;
      }
      if (row.object_type === 'folder' || (parentFolder && userInfo)) {
        return true
      }
      return false
    },
    hover(item) {
      if (row.object_type === 'folder' || parentFolder) {
        dispatch(SetFileUploadInFolder(true))
        if (item && item.dataTransfer) {
          var items = item.dataTransfer.items;
          var length = items.length;
          if (length > 0) {
            var itemString = length > 1?length+" items in ":length+" item in "
            var alertData = new AlertData();
            alertData.type = AlertType.INFO;
            alertData.message = "Upload " + itemString + row.original_name
            dispatch(SetAlertData(alertData))
          }
        } else if (item.object_type) {
          var message = `Move ${item.original_name} in ${row.original_name}`
          if (selectedRows.length > 0) {
            message = `Move selected items in ${row.original_name}`
          }
          var alertData = new AlertData();
          alertData.type = AlertType.INFO;
          alertData.message = message
          dispatch(SetAlertData(alertData))
        }
        setBackground({backgroundColor: HOVERED_COLOR})
    }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver({shallow: false}),
        canDrop: monitor.canDrop(),
      }
    },
    }
  } else if (userInfo) {
    useDropFunction = {
      accept: ['cc'],
      canDrop(item, monitor) {
        return false
      }
    }
  }
  
  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => (useDropFunction), [selectedRows]
  )

  const cbDispatch = useCallback((obj) => {
    return dispatch(obj)
  }, [])

  const cbSetProgress = useCallback((obj) => {
    return setProgress(obj)
  }, [])

  const cbHandleClick = useCallback((obj) => {
    return handleClick(obj)
  }, [])

  const cbSetHoveredRow = useCallback((obj) => {
    return setHoveredRow(obj)
  }, [])

  useEffect(()=>{
    if (!isOver) {
      setBackground({backgroundColor: '#EAF3FC'})
    } else {
      setBackground({backgroundColor: HOVERED_COLOR})
    }
  }, [isOver]) 

  useEffect(() => {
    setTimeout(() => {
      setContainerClassName('')
    }, 2000);
  }, [])
  
  if (tableRef.current) {
    dragRef(dropRef(tableRef))
  }

  if (parentFolder) {
    return (
      <TableRow
        hover
        ref={tableRef}
        sx={background}
        className={'list-fade-item'}
        onClick={(e) => {
          if (!disabled) {
            if (e.detail === 2) {
              var url = `${FILE_EXPLORER_PAGE}/${row.id}/?env=${env}`;
              if (p) {
                url += '?p='+p;
              }
              navigate(url);
            }
          }
        }}
        onMouseEnter={() => setHoveredRow(1)}
        onMouseLeave={() => {
          setHoveredRow(0);
          setAnchorEl(null);
        }}
      >
        <TableCell
          scope="row"
          padding="none"
        >
          <AiFillFolder size={35} color="#1c85d4"/>
        </TableCell>
        <TableCell
          scope="row"
          padding="none"
        >
          <Stack 
            onClick={() => {
              if (!disabled) {
                var url = '';
                url = `${FILE_EXPLORER_PAGE}/${row.id}/?env=${env}`;
                if (p) {
                  url += '?p='+p;
                }
                navigate(url);
              }
            }}
            style={{
            cursor: 'pointer',
            width: '100%', 
            height: '100%', 
            justifyContent: 'flex-end', 
            alignItems: 'flex-start'}}
          >
            <RxDotsHorizontal size={27} style={{color: 'black'}} />
          </Stack>
        </TableCell>
        {windowWidth > 890 ? <TableCell align="center" sx={{ width: '80px' }} /> : null}
        {windowWidth > 1000 ? <TableCell align="center" sx={{ width: '60px' }} /> : null}
        {windowWidth > 1200 ? <TableCell align="center" sx={{ width: '70px' }} /> : null}
        {userInfo?.userToken ? <React.Fragment>{windowWidth > 890 ? 
          <TableCell align="center" sx={{ width: '60px' }} /> : null}
          <TableCell align="center" style={{ width: '200px' }} />
        </React.Fragment>: null}
      </TableRow>
    )
  }
  return (
    <TableRow
      hover
      ref={tableRef}
      className={'list-fade-item'}
      onClick={(e) => {
        if (e.shiftKey) {
          dispatch(SelectUsingShift(row.parent_dir, row.id))
        } else if (e.ctrlKey ) {
          dispatch(SetUnsetSelectedRow(row.parent_dir, row.id))
        } else if (e.detail === 2) {
          if (row.object_type === 'folder') {
            var url = `${FILE_EXPLORER_PAGE}/${row.id}/?env=${env}`;
            if (p) {
              url += '?p='+p;
            }
            navigate(url)
          } else {
            var resolverParams = new ResolverParams();
            resolverParams.objectID = row.id;
            resolverParams.parentDir = row.parent_dir;
            const resolverObject = resolveObjectType(applicationControls, resolverParams, row.file_type);
            if (resolverObject && resolverObject.url) {
              var url = resolverObject.url+'?file_type='+row.file_type;
              if (p) {
                url += '&p='+p;
              }
              navigate(url)
            } else {
              var alertData = new AlertData();
              alertData.type = AlertType.ERROR;
              alertData.message = "Didn't find a suitable application to open this file."
              dispatch(SetAlertData(alertData))
            }
          }
        }
      }}
      onMouseEnter={() => setHoveredRow(1)}
      onMouseLeave={() => {
        setHoveredRow(0);
      }}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
      sx={background}
    >
      <TableCell
        scope="row"
        padding="none"
      >
        <SmartIconObject 
          synced={syncedFolders[row.id]}
          row={row}
        />
        
      </TableCell>
      <TableCell
        id={labelId}
        scope="row"
        padding="none"
      >
        <InputWithLabel
          value={row}
          editName={false}
          setEditName={() => { }}
          setHoveredRow={() => { }}
          userToken={userInfo?.userToken}
          applicationsControl={applicationControls}
          sharePage={sharePage}
          p={p}
          env={env}
        />
      </TableCell>
      {windowWidth > 890 ? <TableCell align="center" sx={{ width: '80px' }}><Stack justifyContent={"center"} alignItems={"center"} style={{width: '100%'}}>{row.created !== -1 ? <Typography style={{ width: '100%' }}>{dateStringToGMTDAteString(row.created)}</Typography> : <PiMinusLight size={27} />}</Stack></TableCell> : null}
      {windowWidth > 1000 ? <TableCell align="center" sx={{ width: '60px' }}><Stack justifyContent={"center"} alignItems={"center"} style={{width: '100%'}}>{!row.file_type ? <PiMinusLight size={27} /> : row.file_type}</Stack></TableCell> : null}
      {windowWidth > 1200 ? <TableCell align="center" sx={{ width: '70px' }}><Stack justifyContent={"center"} alignItems={"center"} style={{width: '100%'}}>{row.object_type === "file" ? valueBytesToString(row.size) : <PiMinusLight size={27} />}</Stack></TableCell> : null}
      {userInfo?.userToken ? <React.Fragment>{windowWidth > 890 ? <TableCell align="center" sx={{ width: '60px' }}>
        {row.created !== -1 ?
          <Favorite
            upload={row.upload}
            selected={row.favorite}
            setSelected={handleSelectedFav}
          /> : null
        }
      </TableCell> : null}
      <TableCell align="center" style={{ width: 120 }}>
        {row.created !== -1 ?
          <Stack direction="row" spacing={1} marginLeft={1}>
            <RowSmartPanel
              dispatch={cbDispatch}
              setProgress={cbSetProgress}
              value={row}
              userToken={userInfo?.userToken}
              progress={progress}
              SetShareFileObject={SetShareFileObject}
              handleClick={cbHandleClick}
              open={open}
              show={hoveredRow}
              onCheckBoxLeave={cbSetHoveredRow}
              selectedRow={isItemSelected}
              upload={row.upload}
              showShareDialogFromBtn={props.showShareDialogFromBtn}
              shortPanel={shortPanel}
              //currentUploaded={currentUploaded}
              //totalUploadItems={totalUploadItems}
              onMenuOpen={() => {
                if (sharePage || q) {
                  setMenuOptions(getDefaultObjectMenu(options));
                  getAccesses(row.id, userInfo?.userToken).then((data)=> {
                    setMenuOptions(getFileObjectMenu(userInfo, data, options))
                  })
                } else {
                  row.synced = syncedFolders[row.id];
                  setMenuOptions(getFileListObjectMenu(row, options));
                }
                if (isItemSelected) {
                  dispatch(SetitemsForAction([-1]))
                } else {
                  dispatch(SetitemsForAction([row.id]))
                }
              }}
            />
            {!progress || progress === -1 ? <Stack direction={"row"} style={{flex: 1}} alignItems={"end"}>
              <MemoSelectedRow 
                key={index}
                selected={isItemSelected} 
                hovered={hoveredRow} 
                dispatch={dispatch} 
                id={row.id}
                parentID={row.parent_dir}
              />
            </Stack>  : null}
            
          </Stack> : null
        }
      </TableCell> 
      </React.Fragment>: null}
        <Menu
            id="row-menu"
            MenuListProps={{
              'aria-labelledby': 'row-menu',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
              setHoveredRow(0);
            }}
            className="row-menu"
            paper={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}
          >
          {menuOptions.map((option) => (
            <MenuItem 
              key={option.id} 
              disabled={option.id===4 && (progress !== undefined && progress !== 1)} 
              onClick={()=>{
                onMenuItemSelect(option.id, row, userInfo?.userToken);
                setAnchorEl(null);
                }}>
              <Stack direction="row"  spacing={2}>
                <Grid item xs={4}>{option.iconObject}</Grid>
                <Grid item xs={8}><Typography>{option.name}</Typography></Grid>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
    </TableRow>
  )
}

export const MemoRow = React.memo(RowFileObject, (prevProps, nextProps) => {
  return prevProps.windowWidth === nextProps.windowWidth &&
  prevProps.isItemSelected === nextProps.isItemSelected && 
  prevProps.row === nextProps.row &&
  prevProps.sharePage === nextProps.sharePage &&
  prevProps.row.small_preview === undefined &&
  prevProps.row.medium_preview === undefined
})