import { ADD_FILE_IN_BIN_LIST, CLEAR_SELECTED_BIN_ROWS, DELETE_BIN_OBJECT, SET_ACTIVE_DELETED_OBJECTS, SET_BIN_FILE_LIST, SET_BIN_ITEMS_FOR_ACTION, SET_CURRENT_BIN_PG_PAGE, SET_HAS_MORE_IN_BIN, SET_SELECTED_ALL_BIN_FILES, SET_UNSET_SELECTED_BIN_ROW } from "../utils/consts";

const initialState = {
  selectedAllFiles: false,
  fileList: [],
  selectedRows: [],
  currentPgPage: 1,
  hasMore: true,
  itemsForAction: [],
  activeDeletedObjects: 0
}

export default function binReducer(state = initialState, action) {
  switch(action.type) {
    case SET_ACTIVE_DELETED_OBJECTS: {
      const activeDeletedObjects = action.payload;
      if (activeDeletedObjects === -1) {
        return {
          ...state,
          activeDeletedObjects: 0
        }
      }
      return {
        ...state,
        activeDeletedObjects: state.activeDeletedObjects+activeDeletedObjects
      }
    }
    case SET_SELECTED_ALL_BIN_FILES: {
      return {
        ...state,
        selectedAllFiles: !state.selectedAllFiles
      }
    }
    case SET_BIN_FILE_LIST: {
      const fileList = action.payload;
      return {
        ...state,
        fileList: [...fileList]
      }
    }
    case SET_BIN_ITEMS_FOR_ACTION: {
      const itemsForAction = action.payload;
      if (itemsForAction.length === 0) {
        return {
          ...state,
          itemsForAction: [],
        }
      } else if (itemsForAction[0] === -1) {
        return {
          ...state,
          itemsForAction: [...state.selectedRows],
        }
      } else {
        return {
          ...state,
          itemsForAction: [...itemsForAction],
        }
      }
    }
    case CLEAR_SELECTED_BIN_ROWS: {
      return {
        ...state,
        selectedRows: [],
        selectedAllFiles: false,
      }
    }
    case SET_UNSET_SELECTED_BIN_ROW: {
      const selectedRowID = action.payload;
      const pos = state.selectedRows.indexOf(selectedRowID);
      if (pos !== -1) {
        return {
          ...state,
          selectedAllFiles: false,
          selectedRows: [...state.selectedRows.slice(0, pos), ...state.selectedRows.slice(pos+1, state.selectedRows.length)]
        }
      }
      if (state.fileList.length === state.selectedRows.length+1) {
        return {
          ...state,
          selectedAllFiles: true,
          selectedRows: [...state.selectedRows, selectedRowID]
        }
      }
      return {
        ...state,
        selectedAllFiles: false,
        selectedRows: [...state.selectedRows, selectedRowID]
      }
    }
    case SET_CURRENT_BIN_PG_PAGE: {
      const currentPgPage = action.payload;
      return {
        ...state,
        currentPgPage: currentPgPage
      }
    }
    case SET_HAS_MORE_IN_BIN: {
      const hasMore = action.payload;
      return {
        ...state,
        hasMore: hasMore
      }
    }
    case ADD_FILE_IN_BIN_LIST: {
      const fileObject = action.payload;
      const first = action.first;
      if (first) {
        return {
          ...state,
        selectedAllFiles: false,
        fileList: [fileObject, ...state.fileList]
        }
      }
      return {
        ...state,
        selectedAllFiles: false,
        fileList: [...state.fileList, fileObject]
      }
    }
    case DELETE_BIN_OBJECT: {
      const fileObjectID = action.payload;
      var currentFileList = state.fileList;
      for (var i = 0; i < currentFileList.length; i++) {
        var obj = currentFileList[i];
        if (obj.id === fileObjectID) {
          var index = state.selectedRows.indexOf(obj.id);
          if ( index !== -1) {
            return {
              ...state,
              fileList: [...currentFileList.slice(0, i), ...currentFileList.slice(i+1, currentFileList.length)],
              selectedRows: [...state.selectedRows.slice(0, index), ...state.selectedRows.slice(index+1, state.selectedRows.length)]
            }
          }
          return {
            ...state,
            fileList: [...currentFileList.slice(0, i), ...currentFileList.slice(i+1, currentFileList.length)],
          }
        }
      }
      return {
        ...state
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}