import { Chip, Divider, Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";


export const ChipDropDown = ({ items, selectedItem, onChanged, clickedItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (item) => {
    onChanged(item);
    setAnchorEl(null);
  };
  return (
    <div>
      <Chip
        className="bread-crumb-chip"
        label={selectedItem.title}
        onClick={clickedItem}
        onDelete={(e) => setAnchorEl(e.currentTarget)}
        deleteIcon={
        <Stack direction={"row"}>
          <Divider orientation="vertical" flexItem className="bt1-devider"/>
          <IoIosArrowDown />
        </Stack>}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => setAnchorEl(null)}
      >
        {items.map((item) => (
          <MenuItem key={item.id} onClick={(e) => handleClick(item)} selected={selectedItem.id===item.id ? true : false}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};