import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import visuallyHidden from '@mui/utils/visuallyHidden';
import {TableHead, TableRow, TableSortLabel, Box, TableCell,
  Table, TableContainer, TableBody, Stack, Checkbox} from '@mui/material'
import { MemoRow, RowFileObject } from '../base/row_fileobject';
import { createBreadCrumb, getOrderedFileList } from 'src/utils/helper';
import { ORDER_ASC, ORDER_DESC } from 'src/utils/consts';
import { SetOrdering } from 'src/redux_store/actions';
import { EmptyFolderLabel } from '../file_explorer';

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, 
    onRequestSort, headCells, userInfo } =
    props;
  
  const dispatch = useDispatch();

  const createSortHandler = (property) => (event) => {
    dispatch(SetOrdering(property, order))
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === 'name') {
            return <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{width: headCell?.width}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : ORDER_ASC}
              onClick={createSortHandler(headCell.id)}
              style={{fontSize: 17, fontWeight: 700, fontFamily: "Lato", textAlign: 'center', winth:'100%'}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === ORDER_DESC ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          } else {
            return <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{width: headCell?.width}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : ORDER_ASC}
              onClick={createSortHandler(headCell.id)}
              style={{
                fontSize: 17, fontWeight: 700, 
                fontFamily: "Lato", textAlign: 'center', winth:'100%', paddingLeft: 25}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === ORDER_DESC ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          }
        })}
        {userInfo?.userToken ?
        <TableCell 
          style={{width: 120}}
          padding="checkbox"
          >
             <Stack style={{width: '100%'}}  alignItems={'flex-end'}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </Stack>
        </TableCell> : null}
      </TableRow>
    </TableHead>
  );
}


export function FileExplorerList(props) {

  const {fileStruct, userInfo, selected, order, orderBy, q,
    handleSelectAllClick, handleRequestSort, fsKey, headCells, tableContainerBackgroundColor,
    mainListBreadCrumb, sharePage, windowWidth, dataLoading, onMenuItemSelect, applicationControls,
    showShareDialogFromBtn, isSelected, p, updateListView, env} = props;
  const [items, setItems] = useState([])

  useEffect(() => {
    if (fileStruct[fsKey]?.fileList) {
      //setItems([])
      var tmpItems = getOrderedFileList(fileStruct, fsKey);
      if (tmpItems) {
        setTimeout(() => {
          setItems([...tmpItems])
        }, 50);
      }
    }
    
  }, [order, orderBy, fileStruct[fsKey]?.fileList, updateListView])

  useEffect(() => {
    setItems([])
  }, [fsKey])

  return (
    <TableContainer sx={{ overflowX: "initial" }}>
      <Table
        sx={{ minWidth: 200, height: '100%', tableLayout: 'fixed' }}
        aria-labelledby="tableTitle"
        stickyHeader 
        
        aria-label="sticky table"
      >
        <EnhancedTableHead
          userInfo={userInfo}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={fileStruct[fsKey]?.fileList?.length}
          headCells={headCells}
        />
        <TableBody style={{backgroundColor: tableContainerBackgroundColor}}>
          {createBreadCrumb(userInfo?.root, mainListBreadCrumb, userInfo, sharePage).length > 1? 
            <RowFileObject 
              windowWidth={windowWidth}
              userInfo={userInfo}
              disabled={dataLoading}
              index={-1}
              row={createBreadCrumb(userInfo?.root, mainListBreadCrumb, userInfo, sharePage)[createBreadCrumb(userInfo?.root, mainListBreadCrumb, userInfo, sharePage).length-2]}
              parentFolder={true}
              onMenuItemSelect={onMenuItemSelect}
              key={-1}
              p={p}
              isItemSelected={false} 
              applicationControls={applicationControls}
              showShareDialogFromBtn={showShareDialogFromBtn}
              sharePage={sharePage}
              env={env}
            />
            : null}
          {items.map((item, index) => {
            const _isSelected = isSelected(item?.id);
            return <MemoRow 
              windowWidth={windowWidth}
              userInfo={userInfo}
              index={index}
              row={item}
              onMenuItemSelect={onMenuItemSelect}
              key={item?.id}
              p={p}
              q={q}
              isItemSelected={_isSelected} 
              parentFolder={false}
              applicationControls={applicationControls}
              showShareDialogFromBtn={showShareDialogFromBtn}
              sharePage={sharePage}
              shortPanel={windowWidth < 1090}
              env={env}
            />})}
        </TableBody>
      </Table>
    </TableContainer>
  )
}