import { join, appDataDir } from '@tauri-apps/api/path';
//import { createDir, writeFile, BaseDirectory, exists, readTextFile } from '@tauri-apps/api/fs';
import { TARGET_PLATFORM } from 'src/utils/consts';


const downloadsDefault = {
  'downloads': []
}


class Downloads {


  /* async createDownloads() {
    if (TARGET_PLATFORM !== 'web') {
      this.downloadsPath = await join(this.dataDir, 'downloads.json')
      if (!await exists(this.downloadsPath)) {
        try {
          await writeFile(
            {
              contents: JSON.stringify(downloadsDefault),
              path: this.downloadsPath,
            },
            {
              dir: BaseDirectory.AppData,
            }
          );
        } catch (e) {
          console.log(e);
        }
      };
    }
  } */


  dataDir = ""
  downloadsPath = ""
  dataObject = {}

  /* async createDataFolder() {
    if (TARGET_PLATFORM !== 'web') {
      this.dataDir = await appDataDir();
      console.log("PATH: ", this.dataDir)
      if (!await exists(this.dataDir)) {
        return await createDir("", {
          dir: BaseDirectory.AppConfig,
          recursive: true,
        });
      } 
    }
  }; */


  constructor() {
    (async () => {
      /* await this.createDataFolder()
      await this.createDownloads()
      await this.loadDataObject() */
    })();
  }

  addDownload(downloadObject) {
    this.dataObject.downloads.push(downloadObject)
  }

  updateParam(key, value) {
    this.dataObject[key] = value
  }

  /* async saveDownalods() {
    try {
      await writeFile(
        {
          contents: JSON.stringify(this.dataObject),
          path: this.downloadsPath,
        },{
          dir: BaseDirectory.AppData,
        }
      );
    } catch (e) {
      console.log(e);
    }
  } */

  /* async loadDataObject() {
    if (TARGET_PLATFORM !== 'web') {
      readTextFile(this.downloadsPath).then(str=> {
        this.dataObject = JSON.parse(str);
      })
    }
  } */
}

var downloads = null;
if (TARGET_PLATFORM !== 'web') {
  downloads = new Downloads();
}
export default downloads;