import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Dialog, DialogTitle, DialogContent, AccordionDetails, Grid, FormControl,
  DialogActions, Button, Typography, Accordion, AccordionSummary, InputLabel, Popover,
  InputAdornment, IconButton, Input, Box, Stack
} from "@mui/material";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdContentCopy } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { copyToClipboard, createLinkSettings, deleteLink, getLink, getLinkSettings } from "src/utils/helper";
import { DeleteFileInList } from "src/redux_store/actions";
import { useDispatch } from "react-redux";


export function ManageLinkAccessDialog(props) {

  const { 
    showManageLinkDialog, 
    handleCancel, manageLinkResource, userInfo, publicAccess, objectID } = props;
  const [publicLink, setPublicLink] = useState('');
  const [openInfo, setOpenInfo] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [password, setPassword] = useState('');
  const [ttl, setTTL] = useState(0);
  const [expAt, setExpAt] = useState('');
  const [serverExpAt, setServerExpAt] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [expand, setExpand] = React.useState(false);
  const [publicToken, setPublicToken] = useState('');
  const dispatch = useDispatch();
  function generateLink() {
    getLink(userInfo?.userToken, manageLinkResource.id, userInfo?.actionRead).then((res) => {
      setPublicLink(res?.public_url)
      setPublicToken(res?.public_token)
    })
  }

  const toggleAccordion = () => {
    setExpand(!expand);
  };

  function _deleteLink() {
    setExpand(false);
    
    deleteLink(userInfo?.userToken, manageLinkResource.id, publicToken).then((res) => {
      setPublicLink("");
      var _fsKey = `${3}_${manageLinkResource.parent_dir}`;
      dispatch(DeleteFileInList(_fsKey, manageLinkResource.id));
    })
  }

  useEffect(() => {
    if (showManageLinkDialog && publicAccess?.public_token) {
      var d = new Date();
      
      getLinkSettings(userInfo?.userToken, publicAccess?.public_token).then(data => {
        var clientDate = new Date(data.expire_at - (d.getTimezoneOffset() * 60 * 1000));
        if (data.password) {
          setPassword(data.password)
        }
        if (data.ttl) {
          setTTL(data.ttl)
        }
        if (data.expire_at) {
          setServerExpAt(data.expire_at)
          setExpAt(clientDate)
        }
      })
    }
    
  }, [showManageLinkDialog])

  useEffect(() => {
    if (showManageLinkDialog && publicAccess && Object.keys(publicAccess).length > 0) {
      setPublicLink(publicAccess?.url)
      setPublicToken(publicAccess?.public_token)
    } else {
      setExpand(false);
      setPublicLink('')
      setPublicToken('')
    }
  }, [showManageLinkDialog, publicAccess])

  function handleSaveSettings() {
    var tmpExpDate = 0;
    if (serverExpAt) {
      tmpExpDate = serverExpAt;
    }
    createLinkSettings(userInfo?.userToken, publicToken, password, ttl, 
      tmpExpDate).then(() =>{
      handleCancel()
    })
  }

  const MUInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      id="standard-adornment-exp-at"
      type={'text'}
      style={{marginTop: 15}}
      value={value}
      onClick={onClick}
      ref={ref}
      sx={{ width: 400}}
    />
  ));

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'lg'}
        open={showManageLinkDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Manage link access
        </DialogTitle>
        <DialogContent style={{ minWidth: 350, maxWidth: 650 }}>
          <Grid
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            container>
            <Grid item xs={3} justifyContent={"center"}>
              <Button
                variant="contained"
                onClick={() => {
                  if (publicLink.length > 0) {
                    _deleteLink();
                  } else {
                    generateLink();
                  }
                }}>
                {publicLink.length > 0 ? "Delete link" : "Get link"}
              </Button>
            </Grid>
            <Grid item xs={9} justifyContent={"center"}>
              <FormControl sx={{width: '100%'}} variant="outlined">
                <Input
                  id="outlined-adornment-link"
                  type='text'
                  disabled
                  value={publicLink}
                  style={{height: 30}}
                  className="link-input"
                  endAdornment={
                    <InputAdornment position="end">
                      <CopyToClipboard text={publicLink}>
                        <IconButton
                          disabled={publicLink.length === 0 ? true : false}
                          onClick={() => {
                            if (publicLink) {
                              console.log("LINK: ", publicLink)
                              copyToClipboard(publicLink);
                            }
                          }}
                          edge="end"
                        >
                          <MdContentCopy size={17} />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  }
                  label="Public Link"
                />
              </FormControl>
            </Grid>
          <Grid item xs={12} marginTop={3}>
            <Accordion 
              expanded={expand}
              disabled={publicLink.length === 0 ? true : false}>
              <AccordionSummary
                expandIcon={<IoMdArrowDropdown />}
                aria-controls="panel2-content"
                id="panel2-header"
                onClick={toggleAccordion}
              >
                <Typography>Advanced settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl sx={{ m: 1, width: '100%', justifyContent: 'center' }} variant="standard">
                  <Grid
                    container
                  >
                    <Grid item xs={11} justifyContent={"center"}>
                      <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={'text'}
                        style={{width: '100%'}}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={1} justifyContent={"center"}>
                      <IconButton
                        style={{justifyContent: 'center', height: '100%', width: '100%'}}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setInfoText("Use password to protect public link.")
                          setOpenInfo(true);
                        }}
                        edge="end"
                      >
                        <FaInfoCircle size={17} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl sx={{ m: 1, width: '100%', justifyContent: 'center' }} variant="standard">
                  <Grid
                    container
                  >
                    <Grid item xs={11} justifyContent={"center"}>
                      <InputLabel htmlFor="standard-adornment-ttl">Time to live</InputLabel>
                      <Input
                        id="standard-adornment-ttl"
                        type={'number'}
                        style={{width: '100%'}}
                        value={ttl}
                        onChange={(e) => {
                          setTTL(e.target.value)
                          setExpAt(null);
                          setServerExpAt(0);
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} justifyContent={"center"}>
                      <IconButton
                        style={{justifyContent: 'center', height: '100%', width: '100%'}}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setInfoText("Set time to live for public url. Time to live calculated in minutes and should not be gretaer than 10000.")
                          setOpenInfo(true);
                        }}
                        edge="end"
                      >
                        <FaInfoCircle size={17} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl sx={{ m: 1, width: '100%', justifyContent: 'center' }} variant="standard">
                  <Grid
                    container
                  >
                    <Grid item xs={11} justifyContent={"center"}>
                      <InputLabel htmlFor="standard-adornment-exp-at">Expire at</InputLabel>
                      <DatePicker 
                        className="public-url-datepicker"
                        selected={expAt} 
                        showTimeSelect
                        dateFormat="Pp"
                        portalId="datepicker-portal"
                        customInput={<MUInput />}
                        includeDateIntervals={[
                          { start: new Date(), end: new Date().setDate(new Date().getDate() + 30) },
                        ]}
                        onChange={(date) => {
                        var d = new Date();
                        setExpAt(date);
                        setTTL(0);
                        setServerExpAt(Date.parse(date) + (d.getTimezoneOffset() * 60 * 1000))
                        console.log("PARSED DATE: ", Date.parse(date))
                        }} />
                    </Grid>
                    <Grid item xs={1} justifyContent={"center"}>
                      <IconButton
                        style={{justifyContent: 'center', height: '100%', width: '100%'}}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setInfoText("Set time to live for public url. Time to live calculated in minutes and should not be gretaer than 10000.")
                          setOpenInfo(true);
                        }}
                        edge="end"
                      >
                        <FaInfoCircle size={17} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>
                <DialogActions>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      handleSaveSettings(manageLinkResource)
                    }}>
                    Save
                  </Button>
                </DialogActions>
              </AccordionDetails>
            </Accordion>
          </Grid>
          </Grid>
          {!expand ?
            <Stack direction={"row"} style={{marginTop: 18, width: '100%', justifyContent: 'flex-end'}}>
              <Button 
                variant="contained"
                onClick={handleCancel}>Close</Button>
            </Stack> : null}
          
        </DialogContent>
      </Dialog>
      <Popover 
        onClose={() => {
          setInfoText('');
          setOpenInfo(false);
        }}
        id={"info-pop"} 
        open={openInfo} 
        anchorEl={anchorEl}
        style={{minWidth: 100}}
        >
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', width: '100%' }}>
          <Typography>{infoText}</Typography>
        </Box>
      </Popover>
    </React.Fragment>
  )
}