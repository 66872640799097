import { invoke } from "@tauri-apps/api";
import { ADD_ACTIVE_DIRECTORY_UPLOAD, ADD_ACTIVE_UPLOADS, ADD_CANCEL_TOKEN, ADD_PENDING_PREVIEWS, ADD_UPLOADS, ADD_UPLOAD_STRUCTURE, CANCEL_ACTIVE_AXIOS_UPLOAD, CLEAN_TMP_PATHS_FROM_RUST, CLEAN_TMP_UPLOAD_FILE_OBJECTS, CLEAN_UPLOADS, DELETE_PENDING_PREVIEWS, DELETE_UPLOADS, DELETE_UPLOAD_OBJECT, REMOVE_ACTIVE_DIRECTORY_UPLOAD, REMOVE_ACTIVE_UPLOADS, REMOVE_CANCEL_TOKEN, REMOVE_TMP_UPLOAD_FILE_OBJECT, REMOVE_UPLOAD_ENTITY, SET_SEEN_UPLOADS, SET_TMP_PATHS_FROM_RUST, TARGET_PLATFORM, TMP_UPLOAD_FILE_OBJECTS, UPDATE_UPLOADS_PROGRESS } from "../utils/consts"

const initialState = {
  uploadList: [],
  filesForUploadUpdate: {},
  activeUploads: [],
  tmpFileObjects: [],
  tmpRustFilePaths: {},
  uploadStructure: {},
  rootUploadCounter: {},
  axiosCancelTokens: {},
  activeDirectoryUploads: {},
  pendingPreviews: {}
}

export default function uploadsReducer(state = initialState, action) {
  switch(action.type) {
    case ADD_PENDING_PREVIEWS: {
      const {pendingPreview} = action.payload;
      var pendingPreviews = state.pendingPreviews;
      pendingPreviews = Object.assign({}, pendingPreviews, pendingPreview)
      return {
        ...state,
        pendingPreviews: {...pendingPreviews}
      }
    }
    case DELETE_PENDING_PREVIEWS: {
      const {pendingPreviewIDS} = action.payload;
      var pendingPreviews = state.pendingPreviews;
      for (var i = 0; i < pendingPreviewIDS.length; i++) {
        var _id = pendingPreviewIDS[i];
        if (pendingPreviews[_id]) {
          delete pendingPreviews[_id]
        }
      }
      
      return {
        ...state,
        pendingPreviews: {...pendingPreviews}
      }
    }
    case ADD_ACTIVE_DIRECTORY_UPLOAD: {
      const payload = action.payload;
      const parentID = payload.parentID;
      const objectID = payload.objectID;
      const activeDirectoryUploads = state.activeDirectoryUploads;
      if (activeDirectoryUploads[parentID]) {
        activeDirectoryUploads[parentID].push(objectID)
      } else {
        activeDirectoryUploads[parentID] = [objectID]
      }
      return {
        ...state,
        activeDirectoryUploads: {...activeDirectoryUploads}
      }
    }
    case REMOVE_ACTIVE_DIRECTORY_UPLOAD: {
      const payload = action.payload;
      const parentID = payload.parentID;
      const objectID = payload.objectID;
      var activeDirectoryUploads = state.activeDirectoryUploads;
      if (activeDirectoryUploads[parentID]) {
        var ids = activeDirectoryUploads[parentID]
        const index = ids.indexOf(objectID);
        if (index !== -1) {
          if (ids.length === 1) {
            delete activeDirectoryUploads[parentID]
          } else {
            ids.splice(index, 1);
            activeDirectoryUploads[parentID] = ids;
          }
        }
      }
      return {
        ...state,
        activeDirectoryUploads: {...activeDirectoryUploads}
      }
    }
    case CANCEL_ACTIVE_AXIOS_UPLOAD: {
      var axiosCancelTokens = state.axiosCancelTokens;
      if (axiosCancelTokens[action.payload.fileID]) {
        axiosCancelTokens[action.payload.fileID]();
        delete axiosCancelTokens[action.payload.fileID];
      }
      return {
        ...state,
        axiosCancelTokens: {...axiosCancelTokens}
      }
    }
    case ADD_CANCEL_TOKEN: {
      var axiosCancelTokens = state.axiosCancelTokens;
      axiosCancelTokens[action.payload.fileID] = action.payload.cancelToken
      return {
        ...state,
        axiosCancelTokens: {...axiosCancelTokens}
      }
    }
    case REMOVE_CANCEL_TOKEN: {
      var axiosCancelTokens = state.axiosCancelTokens;
      if (axiosCancelTokens[action.payload.fileID]) {
        delete axiosCancelTokens[action.payload.fileID];
      }
      return {
        ...state,
        axiosCancelTokens: {...axiosCancelTokens}
      }
    }
    case REMOVE_UPLOAD_ENTITY: {
      const entityID = action.payload.entityID;
      const entityType = action.payload.entityType;
      const mainRootID = action.payload.mainRootID;
      var axiosCancelTokens = state.axiosCancelTokens;
      var uploadList = state.uploadList;
      var activeUploads = state.activeUploads;
      var uploadStructure = state.uploadStructure;
      var rootUploadCounter = state.rootUploadCounter;
      var rootStructure = {};
      if (mainRootID && rootUploadCounter[mainRootID]) {
        rootStructure = uploadStructure[mainRootID];
        rootUploadCounter[mainRootID].counter += 1;
      }
      console.log("DDD: ", rootStructure, entityID, rootStructure, uploadStructure, mainRootID)
      if (entityType === 'file' && rootStructure?.files) {
        delete rootStructure?.files[entityID];
        uploadStructure[mainRootID] = rootStructure;
        if (uploadStructure[mainRootID] && Object.keys(uploadStructure[mainRootID]?.files)?.length === 0) {
          delete uploadStructure[mainRootID];
          delete rootUploadCounter[mainRootID];
        }
      } else if (uploadStructure[entityID]) {
        var files = uploadStructure[entityID].files;
        var keys = Object.keys(files);
        for (var i = 0; i < keys.length; i++) {
          const key = keys[i];
          const index = activeUploads.indexOf(key);
          if (index !== -1) {
            activeUploads = [...activeUploads.slice(0, index), ...activeUploads.slice(index+1, activeUploads.length)];
            if (TARGET_PLATFORM === "linux" || TARGET_PLATFORM === "macos" || TARGET_PLATFORM === "windows") {
              invoke("stop_active_uploading", {
                id: key
              })
            } else {
              if (axiosCancelTokens[key]) {
                axiosCancelTokens[key]();
                delete axiosCancelTokens[key];
              }
            }
          }
          for (var j = 0; j < uploadList.length; j++) {
            if (uploadList[j].id === key) {
              uploadList = [...uploadList.slice(0, j), ...uploadList.slice(j+1,uploadList.length)]
              break;
            }
          }
        }
        delete uploadStructure[entityID];
        delete rootUploadCounter[entityID];
      }
      return {
        ...state,
        activeUploads: [...activeUploads],
        uploadList: [...uploadList],
        rootUploadCounter: {...rootUploadCounter},
        uploadStructure: {...uploadStructure}
      }
    }
    case ADD_UPLOAD_STRUCTURE: {
      const structure = action.payload;
      const keys = Object.keys(structure);
      var rootUploadCounter = state.rootUploadCounter;
      for (var i = 0; i < keys.length; i++) {
        rootUploadCounter[keys[i]] = {counter: 0, total: Object.keys(structure[keys[i]].files).length};
      }
      
      return {
        ...state,
        rootUploadCounter: {...rootUploadCounter},
        uploadStructure: {...state.uploadStructure, ...structure}
      }
    }
    case SET_TMP_PATHS_FROM_RUST: {
      const files = action.payload.files;
      const folders = action.payload.folders;
      const meta = action.payload.meta;
      var tmpRustFilePaths = {
        files: files,
        folders: folders,
        meta: meta
      }
      return {
        ...state,
        tmpRustFilePaths: {...tmpRustFilePaths}
      }
    }
    case CLEAN_TMP_PATHS_FROM_RUST: {
      return {
        ...state,
        tmpRustFilePaths: {}
      }
    }
    case TMP_UPLOAD_FILE_OBJECTS: {
      var fileObject = action.payload;
      return {
        ...state,
        tmpFileObjects: [...state.tmpFileObjects, fileObject]
      }
    }
    case CLEAN_TMP_UPLOAD_FILE_OBJECTS: {
      return {
        ...state,
        tmpFileObjects: []
      }
    }
    case REMOVE_TMP_UPLOAD_FILE_OBJECT: {
      const id = action.payload;
      var res = [];
      for (var i = 0;i < state.tmpFileObjects.length; i++) {
        if (state.tmpFileObjects[i].id !== id) {
          res.push(state.tmpFileObjects[i])
        }
      }
      return {
        ...state,
        tmpFileObjects: [...res]
      }
    }
    case ADD_ACTIVE_UPLOADS: {
      const objectID = action.payload;
      var filesForUploadUpdate = state.filesForUploadUpdate;
      filesForUploadUpdate[objectID] = {id: objectID, progress: 0.001}
      return {
        ...state,
        activeUploads: [...state.activeUploads, objectID],
        filesForUploadUpdate: {...filesForUploadUpdate}
      }
    }
    case REMOVE_ACTIVE_UPLOADS: {
      const objectID = action.payload;
      const index = state.activeUploads.indexOf(objectID)
      return {
        ...state,
        activeUploads: [...state.activeUploads.slice(0, index), ...state.activeUploads.slice(index+1, state.activeUploads.length)]
      }
    }
    case SET_SEEN_UPLOADS: {
      var uploadList = state.uploadList;
      for (var i = 0; i < uploadList.length; i++) {
        uploadList[i].seen = true;
      }
      return {
        ...state,
        uploadList: [...uploadList]
      }
    }
    case ADD_UPLOADS: {
      const payload = action.payload;
      return {
        ...state,
        uploadList: [payload, ...state.uploadList]
      }
    }
    case UPDATE_UPLOADS_PROGRESS: {
      const payload = action.payload;
      var uploadList = state.uploadList;
      var filesForUploadUpdate = state.filesForUploadUpdate;
      for (var i = 0;i < uploadList.length; i++) {
        var obj = uploadList[i];
        if (obj.id === payload.id) {
          uploadList[i].progress = payload.progress
          uploadList[i].speed = payload.speed
          filesForUploadUpdate[obj.id] = {id: obj.id, progress: payload.progress}
          return {
            ...state,
            uploadList: [...uploadList],
            filesForUploadUpdate: {...filesForUploadUpdate}
          }
        }
      }
      return {
        ...state,
      }
    }
    case DELETE_UPLOAD_OBJECT: {
      const id = action.payload;
      const uploadList = state.uploadList;
      for (var i = 0; i < uploadList.length; i++) {
        if (uploadList[i].id === id) {
          return {
            ...state,
            uploadList: [...uploadList.slice(0, i), ...uploadList.slice(i+1,uploadList.length)]
          }
        }
      }
      return {
        ...state
      }
    }
    case DELETE_UPLOADS: {
      const index = action.payload;
      var uploadList = state.uploadList;
      uploadList.splice(index, 1)
      return {
        ...state,
        uploadList: [...uploadList]
      }
    }
    case CLEAN_UPLOADS: {
      return {
        ...state,
        uploadList: []
      }
    }
    default:
      return {
        ...state
      }
  }
}