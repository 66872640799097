import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PiUsersThree } from "react-icons/pi";
import { cutLongString, randomString } from "../../../utils/helper";
import { FILE_EXPLORER_PAGE } from "../../../utils/consts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteFileInList, DeleteNotification, OpenNotifications, RemoveObjectByIndex, SetFileList, SetUnsetSelectedRow } from "../../../redux_store/actions";
import { AiFillCloseCircle } from "react-icons/ai";


export function AccessChange(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box style={{width: '100%'}}
    onClick={(e)=>{
      var selection = window.getSelection();
      if(selection.type !== "Range") {
        dispatch(SetFileList([]))
        navigate(`${FILE_EXPLORER_PAGE}/${props.sourceDirID}/?selected=${props.objectID}&t=${randomString(10)}`)
        dispatch(OpenNotifications(false))
      }
    }}
    className={"clickable-notification"}
    >
      <Stack direction={"column"} style={{width: '92%'}} padding={1}>
        <Box  style={{width: '100%'}}>
          <Stack direction={"row"}>
            <PiUsersThree size={24} style={{color: "#4051DA"}}/>
            <Typography style={{fontWeight: 'bold', textAlign: 'center', marginRight: 10, width: '89%', marginTop: 3}}>{props.date}</Typography>
            <Tooltip title={"Close notification"} >
              <IconButton
                size="small"
                style={{position: 'absolute', right: 10, marginTop:1}}
                  onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation()
                    dispatch(DeleteFileInList('notification', props.id))
                  }}
                  aria-label="unfavorite"
                  id="unfavorite-btn"
                >
                <AiFillCloseCircle size={15}/>
              </IconButton>
            </Tooltip>
          </Stack>
          <Typography style={{width: '100%'}}>
            User <span style={{fontWeight: 'bold'}}>{cutLongString(props.srcUserEmail, 40)}</span> changed the access from 
            <span style={{fontWeight: 'bold'}}> {props.oldAccessName} </span> to <span style={{fontWeight: 'bold'}}>{props.newAccessName} </span> for  
            <span style={{fontWeight: 'bold'}}> {cutLongString(props.source, 40)}</span> {props.objectType} <span style={{fontWeight: 'bold'}}> {cutLongString(props.resource, 40)}</span>
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}