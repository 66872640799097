import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { savePublicLinkPassword } from 'src/utils/helper';
import { SetPublicLinkPassword } from 'src/redux_store/actions';

export default function PublicLinkPasswordDialog(props) {
  
  const {open, publicToken} = props;
  const [value, setValue] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenDialog(false);
    navigate('/')
  };

  const handleSave = () => {
    console.log("FROM HEREL ", publicToken, value)
    savePublicLinkPassword(publicToken, btoa(value));
    dispatch(SetPublicLinkPassword(publicToken, btoa(value)));
    navigate(0);
  }

  useEffect(() => {
    setOpenDialog(open);
  }, [open])

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        maxWidth={'md'}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Link password required</DialogTitle>
        <DialogContent style={{width: 400}}>
          <TextField
            autoFocus
            required
            margin="dense"
            label={'Enter link password'}
            value={value}
            type="password"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained"
            onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}