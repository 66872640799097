const initialState = {
  fileList: [],
  selectedRows: [],
}


export default function searchFileExplorerReducer(state = initialState, action) {
  switch (action.type) {
    default: {
      return {
        ...state
      }
    }
  }
}