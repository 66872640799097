import { Avatar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API, AlertType } from "../../../utils/consts";
import { AlertData } from "../../../objects/alert";
import { useDispatch } from "react-redux";
import { SetAlertData, UpdateFileInList, UpdateFileObjectAccesses } from "../../../redux_store/actions";
import { getAccesses, removeShare } from "src/utils/helper";


function updateShareAccess(objects, userToken) {
  return fetch(API+'/update-access/',  {
    method: 'PUT',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accesses: objects
    })
  }).then(resp =>{
    return resp.json()
  })
}


function PermissionSelect(props) {
  const [selectedAccess, setSelectedAccess] = useState([]);
  const [shareAccesses, setShareAccesses] = useState([]);
  const [disabledSelect, setDisabledSelect] = useState(props.disabled);


  useState(()=>{
    for (var i = 0; i < props.accesses.length; i++) {
      if (props.userAcess.id === props.accesses[i].id) {
        setSelectedAccess(props.accesses[i])
        if (props.accesses[i].name === "Owner") {
          setDisabledSelect(true)
        }
      }
    }
    
  }, [])

  useState(()=>{
    if (props.userInfo.share_permissions) {
      setShareAccesses([...props.userInfo.share_permissions, ...[{name:"Remove access"}]])
    }
  }, [props.userInfo.share_permissions])
  

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.name === "Remove access") {
      props.setOpenDeleteDialog(true)
      props.onAccessRemove(props.user)
    } else {
      props.onAccessChange(props.user.id, value.id)
      setSelectedAccess(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    }
    
  };

  return (
    <FormControl sx={{ m: 1, width: '100%', height: 23 }} className="permission-ctrl">
      <InputLabel id="user-permissions-label">Access</InputLabel>
      <Select
        size="small"
        label="Access"
        labelId="user-permissions-label"
        id="user-permissions"
        value={selectedAccess}
        onChange={handleChange}
        defaultValue={selectedAccess}
        disabled={disabledSelect}
      >
        {shareAccesses?.map((item, index) => {
            if (item.name === "Owner") {
              return (
                <MenuItem disabled key={index} value={item} style={{width: '100%', whiteSpace: 'normal'}}>{item.name}</MenuItem>
              )
            } else if (item.name === "Remove access") {
              return (
                <MenuItem key={index} value={item} style={{width: '100%', whiteSpace: 'normal', color: 'red', fontWeight: 'bold'}}>{item.name}</MenuItem>
              )
            } else 
            return (
              <MenuItem key={index} value={item} style={{width: '100%', whiteSpace: 'normal'}}>
                {item.name}
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}

export function ManageAccessDialog(props) {

  const [userAcceses, setUserAccesses] = useState([]);
  const [itemsForChangeAccess, setItemsForChangeAccess] = useState({})
  const [accessRemove, setAccessRemove] = useState([])
  const [userForAccessDelete, setUserForAccessDelete] = useState()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [disableSelect, setDisabledSelect] = useState(true)
  const {open, onCancel, manageAccessFileObject, userToken, userInfo} = props;
  const dispatch = useDispatch()

  function _onCancel() {
    onCancel();
    setItemsForChangeAccess({})
    setAccessRemove([])
    setUserForAccessDelete(null)
    setDisabledSelect(true)
  }

  const onAccessChange = (userID, accessID) => {
    var obj = {}
    obj[userID] = accessID
    setItemsForChangeAccess({...itemsForChangeAccess, ...obj})
  }

  const onAccessRemove = (user) => {
    setUserForAccessDelete(user)
  }

  const closeDeleteDialog =()=>{
    setOpenDeleteDialog(false)
  }

  useEffect(()=>{
    setUserAccesses([])
    if (manageAccessFileObject) {
      getAccesses(manageAccessFileObject?.id, userInfo?.userToken).then((resp) =>{
        setUserAccesses([...resp.accesses])
        for (var i = 0; i < resp.accesses?.length; i++) {
          const user = resp.accesses[i].user;
          const access = resp.accesses[i].access;
          if (access.name === "Owner" && user.id === userInfo?.userID) {
            setDisabledSelect(false);
          }
        }
      })
    }
  }, [manageAccessFileObject?.id])

  const onDeleteAccess = () => {
    setAccessRemove([...accessRemove, userForAccessDelete])
    setOpenDeleteDialog(false)
    
    var tmpUserAccesses = [];
    for (var i = 0; i < userAcceses.length;i++) {
      if (userForAccessDelete.id === userAcceses[i].user.id) {
        continue
      }
      tmpUserAccesses.push(userAcceses[i])
    }
    setUserAccesses([...tmpUserAccesses])
    setUserForAccessDelete(null)
  }

  const onSave = () => {
    var objects = [];
    var keys = Object.keys(itemsForChangeAccess);
    for (var i = 0; i < keys.length; i++) {
      objects.push({
        resource: manageAccessFileObject?.id,
        access_id: itemsForChangeAccess[keys[i]],
        target_user: keys[i]
      })
    }
    if (objects.length > 0) {
      updateShareAccess(objects, userToken).then(jsonData=>{
        var alertData = new AlertData();
        if (jsonData.msg || jsonData.detail) {
          alertData.type = AlertType.ERROR;
          alertData.message = alertData.message = jsonData.msg || jsonData.detail
          dispatch(SetAlertData(alertData))
        } else {
          alertData.type = AlertType.INFO;
          alertData.message = `Access to ${manageAccessFileObject?.original_name} has been changed`
          dispatch(SetAlertData(alertData))
          dispatch(UpdateFileObjectAccesses(jsonData.accesses))
          onCancel()
        }
      })
    }
    
    if (accessRemove.length > 0) {
      var forRemove = [];
      for (var i = 0; i < accessRemove.length; i++) {
        const obj = accessRemove[i];
        forRemove.push({
          target_user: obj.id,
          resource: manageAccessFileObject?.id
        })
      }
      removeShare(forRemove, userToken).then(jsonData =>{
        var alertData = new AlertData();
        if (jsonData.msg || jsonData.detail) {
          alertData.type = AlertType.ERROR;
          alertData.message = alertData.message = jsonData.msg || jsonData.detail
          dispatch(SetAlertData(alertData))
        } else {
          alertData.type = AlertType.INFO;
          alertData.message = `User has been removed from the share`
          dispatch(SetAlertData(alertData))
          const files = jsonData.files;
          for (var i = 0; i < files.length; i++) {
            var f = files[i];
            dispatch(UpdateFileInList(f))
          }
          onCancel()
        }
      })
    }
    
  }
  
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="manage-access-modal"
        maxWidth={"lg"}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            onCancel();
          },
        }}
        >
        <DialogTitle style={{fontWeight: 700}}>Manage accesses</DialogTitle>
        <DialogContent style={{width: 650}}>
          <Stack direction="column" spacing={0} style={{height: 400, width: '100%'}}>
            <List style={{width: '100%'}} >
              {userAcceses.map((item, index)=>{
                  return (
                <ListItem style={{width: '100%'}} key={index} disableGutters={true}>
                  <Grid 
                    container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    disableGutters={true}
                    >
                    <Grid item lg={1} md={1} alignItems={"flex-start"}>
                      <Avatar
                        sx={{ width: 30, height: 30, marginTop: 1}}
                        src={item.user.avatar}
                      >{item.user.email[0]}</Avatar>
                    </Grid>
                    <Grid item lg={8} md={8}>
                      {item.user.name?
                        <Stack direction={"column"}>
                          <Typography style={{fontSize: 14, fontWeight: 'bold'}}>{item.user.email || item.user.name}</Typography>
                          <Typography style={{fontSize: 14, marginTop:3}}>{item.user.name}</Typography>
                        </Stack>:
                        <Typography style={{fontSize: 14, fontWeight: 'bold', marginTop: 10}}>{item.user.email}</Typography>
                      }
                    </Grid>
                    <Grid item lg={3} md={3}>
                      <PermissionSelect 
                        userInfo={userInfo} 
                        user={item.user} 
                        userAcess={item.access} 
                        accesses={userInfo?.share_permissions} 
                        onAccessChange={onAccessChange}
                        setOpenDeleteDialog={setOpenDeleteDialog}
                        onAccessRemove={onAccessRemove}
                        disabled={disableSelect}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                )
              })}
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={_onCancel}>Close</Button>
          <Button variant="contained" onClick={onSave} disabled={!accessRemove.length && !Object.keys(itemsForChangeAccess).length} style={{alignSelf: 'end'}}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Remove user ${userForAccessDelete?.name||userForAccessDelete?.email}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            When a user is removed from the shared folder, that user will lose all access to the shared files!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button onClick={(userID)=>onDeleteAccess(userID)} autoFocus>
            Delete access
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}