import React, { useEffect, useRef, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { BsCheckLg } from "react-icons/bs";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { useAuth } from "react-oidc-context";
import { Alert, Avatar, Box, Button, Container, Divider, Fab, FormControl, 
  IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Grid, Tooltip, Autocomplete,
  TableContainer, Table, TableBody, TableHead, TableRow, TableCell, 
  Stack, TextField, Tabs, Tab, Paper, Typography, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { open  } from '@tauri-apps/api/dialog';
import { appDir } from '@tauri-apps/api/path';
import { API, AlertType, DEFAULT_FONT_FOR_PRODUCT_NAMES, FILE_EXPLORER_PAGE, PLANS_PAGE, TARGET_PLATFORM, langs } from "../utils/consts";
import config from "../managers/config";
import { useDispatch, useSelector } from "react-redux";
import { UserInfo } from "../objects/user";
import { SetAlertData, SetUserInfo } from "../redux_store/actions";
import { AlertData } from "../objects/alert";
import { extractMaxCreatedFolders, extractMaxDownloadsBytes, extractMaxShares, extractMaxUploadBytes, extractMaxUploads, extractUserCreatedFolders, extractUserDownloadsBytes, extractUserShares, extractUserSpaceUsage, extractUserTotalSpace, extractUserUploads, extractUserUploadsBytes, getUserInfoFromProfileData, valueBytesToString } from "../utils/helper";
import { lightStyle } from "../style/light_style";
import { TopComponent } from "./base/top_component";
import { DEFAULT_BUTTON_BACKGROUND_COLOR, DEFAULT_LIGHT_TEXT_COLOR } from "src/style/global";
import { PiMinusLight } from "react-icons/pi";
var hash = require('object-hash');


function CreateSnackBar(props) {
  if (props.alertData && props.type === 'info') {
    return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={props.openNotification}
      onClose={()=>{props.setOpenNotification(false)}}
      message={props.alertData?.message}
      autoHideDuration={3000}
      key={1}
    />)
  } else if (props.alertData) {
    return (
      <Snackbar 
        open={props.openNotification} 
        key={1}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000} 
        onClose={()=>{props.setOpenNotification(false)}}>
        <Alert onClose={()=>{props.setOpenNotification(false)}} severity={props.alertData.type} sx={{ width: '100%' }}>
          {props.alertData?.message}
        </Alert>
      </Snackbar>
    )
  }
}


function GeneralSettings(props) {
  const downloadDirRef = useRef(null) 
  const avatarSelect = useRef(null) 
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const [nameTextInput, setNameTextInput] = useState(userInfo.userName)
  const [secondNameInput, setSecondNameInput] = useState(userInfo.lastName)
  const [downloadDirInput, setDownloadDirInput] = useState("")
  const [applicationSyncDirectory, setApplicationSyncDirectory] = useState("")
  const [emailInput, setEmailInput] = useState(userInfo.userEmail)
  const alertData = useSelector(state => state.fileListReducer.alertData)
  const [userAvatarUrl, setUserAvatarUrl] = useState("")
  const [openNotification, setOpenNotification] = useState(false)
  const [showAddAvatar, setShowAddAvatar] = useState(0)
  const [tmpImage, setTmpImage] = useState("");
  const dispatch = useDispatch()
  const auth = useAuth();
  const navigate = useNavigate();
  const ref = useRef();
  ref.current = {userInfo}
  const onDirSelect = async () => {
    const selected = await open({
      directory: true,
      multiple: false,
      defaultPath: applicationSyncDirectory || await appDir(),
    });
    setApplicationSyncDirectory(selected)
  };

  useEffect(()=>{
    if (userInfo && userInfo.avatarSSoUrl) {
      setUserAvatarUrl(userInfo.avatarSSoUrl)
    } else if (userInfo && userInfo.avatarUrl) {
      setUserAvatarUrl(userInfo.avatarUrl)
    }
  }, [])

  useEffect(()=>{
    if (!alertData || !alertData?.message) {
      setOpenNotification(false)
    } else {
      setOpenNotification(true)
    }
  }, [alertData])


  useEffect(()=>{
    if (ref.current?.userInfo?.userToken) {
      const headers = { 
        'Content-Type': 'application/json', 
        'AuthToken': ref.current?.userInfo?.userToken }
      fetch(API+'/user-profile/', { headers })
        .then(response => response.json())
        .then(data => {
          data.access_token = ref.current?.userInfo?.userToken;
          const _userInfo = getUserInfoFromProfileData(data);
          dispatch(SetUserInfo(_userInfo));
          setSecondNameInput(data.last_name)
          setNameTextInput(data.first_name)
          sessionStorage.setItem("profile", JSON.stringify(data))
        }).catch(err => {
          console.log("ERR: ", err)
        });
    }
  }, [])

  useEffect(() => {
    if (TARGET_PLATFORM === "windows") {
      setDownloadDirInput(config?.configObject?.syncPath + "\\Downloads")
      setApplicationSyncDirectory(config?.configObject?.syncPath)
    } else if (TARGET_PLATFORM === "macos" || TARGET_PLATFORM === "linux") {
      setApplicationSyncDirectory(config?.configObject?.syncPath)
      setDownloadDirInput(config?.configObject?.syncPath + "/Downloads")
    }
  }, [config?.configObject?.syncPath])


  const handleClose = () => {
    setOpenNotification(false)
  };

  function updateProfile() {
    fetch(API+'/user-profile/',  {
      method: 'PUT',
      headers: {
        'AuthToken': userInfo.userToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        last_name: secondNameInput,
        first_name: nameTextInput
      })
    }).then(resp =>{
      return resp.json()
    })
    .catch((err) => {
      console.log("HOME PROFILE ERROR ", err)
    })
    
  }

  function onSave() {
    config?.updateParam('downloadPath', downloadDirInput)
    config?.updateParam('name', nameTextInput)
    config?.updateParam('secondName', secondNameInput)
    config?.updateParam('email', emailInput)
    config?.saveConfig()
    updateProfile()
    setOpenNotification(true);
  }

  useEffect(() => {
    if (!tmpImage) {
      return;
    }
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src =  tmpImage;
    image.onload = () => {
      var oc = document.createElement('canvas'),
      octx = oc.getContext('2d');
      const ctx = canvas.getContext('2d');
      canvas.width = 400;
      canvas.height = canvas.width * (image.height / image.width);
      oc.width = canvas.width;
      oc.height = canvas.height;
      octx.drawImage(image, 0, 0, oc.width, oc.height);
      ctx.drawImage(oc, 0, 0, oc.width, oc.height,
        0, 0, canvas.width, canvas.height);
      const croppedImageUrl = canvas.toDataURL('image/jpeg');
      fetch(croppedImageUrl)
      .then(res => res.blob())
      .then(bData => {
        var data = new FormData();
        data.append('file',  bData)
        fetch(API+'/save-avatar-image/',  {
          method: 'POST',
          headers: {
            'AuthToken': userInfo.userToken
          },
          body: data
        }).then(resp =>{
          return resp.json()
        }).then(respJson => {
          if (respJson.msg) {
            var alertData = new AlertData();
            alertData.type = AlertType.ERROR;
            alertData.message = respJson.msg;
            dispatch(SetAlertData(alertData))
          }
          var ui = userInfo;
          ui.avatarSSoUrl = ""
          ui.avatarUrl = API+"/avatar-image/"+respJson.avatar_id+'/?'+(Date.now())
          dispatch(SetUserInfo(ui));
          setUserAvatarUrl(ui.avatarUrl)
        })
      })
      };
  }, [tmpImage]);

  function processAvatarUpload(e) {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setTmpImage(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    
  }

  const { value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${props.index}`}
      aria-labelledby={`settings-tab-${props.index}`}
      style={{overflowY: "auto", height: '100%'}}
    >
      <input accept='image/*' type='file' id='file' ref={downloadDirRef} style={{display: 'none'}}/>
      <input type='file' id='file' ref={avatarSelect} style={{display: 'none'}} onChange={processAvatarUpload} />
      <Fab 
        onClick={()=>{
          onSave()
        }}
        size="small"
        color="primary" 
        style={{position: 'fixed', top: '15px', right: 20}}>
        <BsCheckLg size={lightStyle.btnSize.backButtonSize}/>
      </Fab>
      <Box >
        <Container maxWidth="sm">
          <Stack 
            direction="column" 
            style={{justifyContent: "center", width: '100%', alignItems: 'center'}}
            >
              <Stack direction={"column"} mt={10} style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <IconButton 
                  onMouseEnter={(e)=>{setShowAddAvatar(1)}}
                  onMouseLeave={(e)=>{setShowAddAvatar(0)}}
                  size="large"
                  style={{position: 'absolute', backgroundColor:'rgba(0, 0, 0, 0.3)', zIndex: 777,  width: 200, height: 200, opacity: showAddAvatar}}
                    onClick={()=>{
                      avatarSelect.current.click();
                    }}
                    aria-label="unfavorite"
                  >
                  <MdOutlinePhotoCamera 
                    className="settings-avatar-btn"

                    size={100} style={{zIndex: 999}} color="black" opacity={1}/>
                </IconButton>
                
                <Avatar 
                  sx={{ width: 200, height: 200, fontSize: 80}}
                  src={userInfo.avatarSSoUrl !== "" ? userInfo.avatarSSoUrl : userInfo.avatarUrl}
                  onMouseEnter={(e)=>{setShowAddAvatar(1)}}
                  onMouseLeave={(e)=>{setShowAddAvatar(0)}}
                >{ref.current?.userInfo.abr}</Avatar>
              </Stack>
              <Box mt={10} style={{width: 400}}>
                <Stack direction={"column"} style={{width: '100%', height: 50}}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Stack 
                        direction={"row"} 
                        alignItems={"start"} 
                        justifyContent={"start"}
                        pt={1}
                        >
                        <Typography style={{
                          fontWeight: 'bold', 
                          fontSize: 19, 
                          marginLeft: 5,
                          fontFamily: DEFAULT_FONT_FOR_PRODUCT_NAMES
                          }}>{userInfo?.userProduct?.product?.name}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack direction={"row"} alignItems={"end"} justifyContent={"end"}>
                        <Button
                          variant="contained"
                          className="get-more-space"
                          size={"medium"}
                          onClick={() => {
                            navigate(PLANS_PAGE)
                          }}
                        >
                          Get more space
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack direction={"row"}>
                    <Box style={{ width: '100%', marginBottom: 10 }}>
                      <LinearProgressWithLabel
                        value={userInfo && userInfo?.userUsedSpace && userInfo?.userTotalSpace ? 100 * userInfo.userUsedSpace / userInfo.userTotalSpace : 0}
                        actualSize={valueBytesToString(userInfo ? userInfo.userUsedSpace : 0)}
                        userSpaceSize={valueBytesToString(userInfo ? userInfo.userTotalSpace : 0)}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Box>
              <Box mt={10}>
                <TextField 
                  id="outlined-basic" 
                  label="Name" 
                  variant="outlined" 
                  value={nameTextInput}
                  onChange={(e)=>{setNameTextInput(e.target.value)}}
                  style={{width: 400}}/>
              </Box>
              <Box mt={1}>
                <TextField 
                  id="outlined-basic" 
                  label="Second Name" 
                  variant="outlined" 
                  value={secondNameInput}
                  onChange={(e)=>{setSecondNameInput(e.target.value)}}
                  style={{width: 400}}
                />
              </Box>
              <Box mt={1}>
                <TextField 
                  id="outlined-basic" 
                  label="Email" 
                  variant="outlined" 
                  value={emailInput}
                  disabled
                  onChange={(e)=>{setEmailInput(e.target.value)}}
                  style={{width: 400}}/>
              </Box>
              {TARGET_PLATFORM !== 'web' ? <>
              <Divider variant="middle" style={{width: 370, height: 1, background: '#778899', marginTop: '2vh'}} component={"div"}/>
              <Box style={{marginTop: '2vh'}}>
              <FormControl  variant="outlined">
                <InputLabel htmlFor="sync-directory-outline">Application sync directory</InputLabel>
                <OutlinedInput 
                  id="sync-directory-outline" 
                  style={{width: 400}}
                  label={"Application sync directory"}
                  value={applicationSyncDirectory}
                  onChange={(e)=>{setApplicationSyncDirectory(e.target.value)}}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        aria-label="open directory select"
                        onClick={onDirSelect}
                        style={{width: 80}}
                        variant="contained" 
                      >
                        Open
                      </Button>
                    </InputAdornment>
                  }
                />
                </FormControl>
              </Box>
              </> : null}
              {TARGET_PLATFORM !== 'web' ? <>
              <Divider variant="middle" style={{width: 370, height: 1, background: '#778899', marginTop: '2vh'}} component={"div"}/>

              <Box style={{marginTop: '2vh'}}>
              <FormControl  variant="outlined">
                <InputLabel htmlFor="directory-outline">Download directory</InputLabel>
                <OutlinedInput 
                  id="directory-outline" 
                  style={{width: 400}}
                  label={"Download directory"}
                  value={downloadDirInput}
                  //onChange={(e)=>{setDownloadDirInput(e.target.value)}}
                  disabled
                />
                </FormControl>
              </Box>
              </> : null}
              <Divider variant="middle" style={{width: 370, height: 1, background: '#778899', marginTop: '2vh'}} component={"div"} />
              <Stack direction={"column"} style={{width: 400}} pt={3.5}>
                <Autocomplete
                  id="langs"
                  sx={{ width: '100%' }}
                  options={langs}
                  autoHighlight
                  disabled
                  defaultValue={langs[0]}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a language"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
                <Grid container>
                  <Grid item xs={6}>
                    <Stack direction={"row"}
                      style={{width: '100%'}}
                      justifyContent={"left"}
                      alignItems={"left"}
                    >
                      <Typography 
                        pt={2}
                        style={{
                          fontSize: 17, 
                          textAlign: 'left'
                          }}>Active sessions {userInfo?.activeSessions + " / " + userInfo?.totalSessions}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack style={{width: '100%'}} justifyContent={"right"} alignItems={"right"} pt={1.5}>
                      <Button
                        variant="contained"
                        className="lougt-all-btn"
                        size={"small"}
                        onClick={() => {

                        }}
                      >
                        Logout all devices
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
          </Stack>
        </Container>
      </Box>
      <CreateSnackBar
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        alertData={alertData}
      />
    </div>
  )
}

function LinearProgressWithLabel(props) {

  const [backgroundColor, setBackgroundColor] = useState('rgba(25, 118, 210, 0.3)')
  const [progressColor, setProgressColor] = useState('rgb(25, 118, 210)')

  useEffect(() => {
    var defaultColor = [25, 118, 210];
    /* var r = props.value * 255 / 100 ;
    if (25 + r < 255) {
      r += 25;
    } else {
      r = 255;
    }
    defaultColor[0] = r;
    console.log("R: ", r , props.value) */
    if (props.value > 90) {
      defaultColor[0] = 255;
      defaultColor[1] = 60;
      defaultColor[2] = 56;
    }
    setBackgroundColor(`rgba(${defaultColor}, 0.3)`)
    setProgressColor(`rgba(${defaultColor})`)
  }, [])

  return (
    <Stack sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop:2 }}>
      <Box sx={{ minWidth: "30%" }}>
        <Typography variant="body2" color="text.secondary" style={{fontWeight: 'bold'}}>{props.label}</Typography>
      </Box>
      <Box sx={{ width: '98%'}}>
        <LinearProgress variant="determinate" value={props.value} sx={{height: 20, backgroundColor: backgroundColor,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: progressColor
                  }}} />
      </Box>
      <Box sx={{ minWidth: "30%" }}>
        <Typography variant="body2" color="text.secondary" style={{fontWeight: 'bold'}}>{props.actualSize +" of " + props.userSpaceSize}</Typography>
      </Box>
    </Stack>
  );
}

export function Statistics(props) {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dashboardWidth, setDashboardWidth] = useState(600)
  const { value, index } = props;

  const localRef = useRef();
  localRef.current = {windowWidth}

  function windowChangeWidth() {
    setWindowWidth(window.innerWidth);
    if (localRef.current.windowWidth < 700) {
      setDashboardWidth(400)
    } else {
      setDashboardWidth(600)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", windowChangeWidth);
  }, [])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${props.index}`}
      aria-labelledby={`settings-tab-${props.index}`}
    >
      <Box sx={{ height: '100vh'}}>
        <Stack direction={"column"} sx={{width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
          <Divider style={{width: 400, marginTop: '2vh', fontWeight: "bold"}}>Daily activities</Divider>
          <Stack direction={"column"} sx={{width: dashboardWidth}} padding={1}>
            <Stack direction={"row"} sx={{width: '100%'}} spacing={5}>
              <Box sx={{width: '50%'}}>
                <LinearProgressWithLabel 
                  value={userInfo && userInfo?.userMaxUploadBytes?100 * userInfo.userCurrentUploadBytes / userInfo.userMaxUploadBytes:0} 
                  actualSize={valueBytesToString(userInfo?userInfo.userCurrentUploadBytes:0)} 
                  userSpaceSize={valueBytesToString(userInfo?userInfo.userMaxUploadBytes:0)} 
                  label={"Bytes uploaded"}
                />
              </Box>
              <Box sx={{width: '50%'}}>
                <LinearProgressWithLabel 
                  value={userInfo  && userInfo?.userMaxDownloadBytes?100 * userInfo.userCurrentDownloadBytes / userInfo.userMaxDownloadBytes:0} 
                  actualSize={valueBytesToString(userInfo?userInfo.userCurrentDownloadBytes:0)} 
                  userSpaceSize={valueBytesToString(userInfo?userInfo.userMaxDownloadBytes:0)} 
                  label={"Bytes downloaded"}
                />
              </Box>
            </Stack>
            <Stack direction={"row"} sx={{width: '100%'}} spacing={5}>
              <Box sx={{width: '50%'}}>
                <LinearProgressWithLabel 
                  value={userInfo  && userInfo?.userMaxShares?100 * userInfo.userCurrentShares / userInfo.userMaxShares:0} 
                  actualSize={userInfo?userInfo.userCurrentShares:0} 
                  userSpaceSize={userInfo?userInfo.userMaxShares:0} 
                  label={"Shares"}/>
              </Box>
              <Box sx={{width: '50%'}}>
                <LinearProgressWithLabel value={30} actualSize={1} userSpaceSize={3} label={"Archivations"}/>
              </Box>
            </Stack>
            <Stack direction={"row"} sx={{width: '100%'}} spacing={5}>
              <Box sx={{width: '50%'}}>
                <LinearProgressWithLabel 
                  value={userInfo  && userInfo?.userMaxUploads?100 * userInfo.userCurrentUploads / userInfo.userMaxUploads:0} 
                  actualSize={userInfo?userInfo.userCurrentUploads:0} 
                  userSpaceSize={userInfo?userInfo.userMaxUploads:0} 
                  label={"Files uploaded"}
                />
              </Box>
              <Box sx={{width: '50%'}}>
                <LinearProgressWithLabel 
                  value={userInfo  && userInfo?.userMaxCretedFolders?100 * userInfo.userCurrentCretedFolders / userInfo.userMaxCretedFolders:0} 
                  actualSize={userInfo?userInfo.userCurrentCretedFolders:0} 
                  userSpaceSize={userInfo?userInfo.userMaxCretedFolders:0} 
                  label={"Folders created"}
                />
              </Box>
            </Stack>
          </Stack>
          <Divider style={{width: 400, marginTop: '2vh', fontWeight: "bold"}}>Login history</Divider>
          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} style={{width: 'auto'}} pt={2}>
            <TableContainer sx={{ overflowX: "initial", overflowY: 'auto', height: 500}}>
              <Table
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Application</TableCell>
                    <TableCell>OS</TableCell>
                    {windowWidth > 700 ? <TableCell>Location</TableCell> : null }
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userInfo?.loginHistory?.map((obj, index) => {
                    return <TableRow key={index}>
                            <TableCell>{obj.application}</TableCell>
                            <TableCell>{obj.operation_system}</TableCell>
                            {windowWidth > 700 ? <TableCell>{obj.location}</TableCell> : null}
                            <TableCell>{new Date(Date.parse(obj.created)).toLocaleString()}</TableCell>
                          </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          
        </Stack>
      </Box>
      
    </div>
  )
}

function Billing(props) {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { value, index } = props;

  function windowChangeWidth() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", windowChangeWidth);
  }, [])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${props.index}`}
      aria-labelledby={`settings-tab-${props.index}`}
    >
      <Box sx={{ height: '100vh'}}>
        <Stack direction={"column"} sx={{width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
          <Divider style={{width: 600, marginTop: '2vh', fontWeight: "bold"}}>Billing history</Divider>
          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} style={{width: 'auto'}} pt={2}>
            <TableContainer sx={{ overflowX: "initial", overflowY: 'auto', height: 500}}>
              <Table
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {windowWidth > 700 ? <TableCell>Created</TableCell> : null }
                    {windowWidth > 700 ? <TableCell>Paid</TableCell> : null }
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userInfo?.billing?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.invoice.product.name}</TableCell>
                        {windowWidth > 700 ? <TableCell>{new Date(Date.parse(item.invoice.created)).toLocaleString()}</TableCell> : null }
                        {windowWidth > 700 ? <TableCell>{!isNaN(item.invoice.paid) ? <PiMinusLight size={27} /> : new Date(Date.parse(item.invoice.paid)).toLocaleString()}</TableCell> : null }
                        <TableCell>{item.invoice.amount / 100}</TableCell>
                        <TableCell>{item.invoice.status !== 'paid' ? 'created' : 'paid'}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Box>
    </div>
  )
}


export function Settings(props) {

  const [value, setValue] = useState(0);
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  const navigate = useNavigate();

  function a11yProps(index) {
    return {
      id: `settings-tab-${index}`,
      'aria-controls': `settings-tabpanel-${index}`,
    };
  }

  const handleChangeIndex = (event, index) => {
    setValue(index);

  };

  return (
    <TopComponent>
      <Box
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        style={lightStyle.mainBackgroud}
      >
        <Box style={{width: '100%', height: '100%'}}>
          <Grid container padding={1} style={{height: 70}}>
            <Grid item xs={1} sm={3} md={3} lg={4} xl={4} alignItems={"flex-start"} justifyContent={"center"} padding={1}>
              <Stack style={{width: '100%', height: '100%'}} direction={"row"} justifyContent={"start"} alignItems={"start"}>
                <Tooltip title="Go back to files" >
                  <IconButton
                    aria-label="more"
                    onClick={() => {
                      if (backUrl) {
                        navigate(backUrl);
                      } else {
                        navigate(`${FILE_EXPLORER_PAGE}/${userInfo?.root}/`)
                      }
                    }}
                    style={{backgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR}}
                    id="back-btn">
                      <FiArrowLeft size={22} color={DEFAULT_LIGHT_TEXT_COLOR}/>
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={10} sm={6} md={6} lg={4} xl={4}>
              <Stack direction="row" style={{ width: '100%'}} pt={1.5} justifyContent={"center"} alignItems={"center"}>
                <Tabs value={value} onChange={handleChangeIndex} centered className="profile-tab">
                  <Tab label="General"  {...a11yProps(0)} style={{fontWeight: 'bold'}}/>
                  <Tab label="Statistics" {...a11yProps(1)} style={{fontWeight: 'bold'}}/>
                  <Tab label="Billing"  {...a11yProps(2)} style={{fontWeight: 'bold'}}/>
                </Tabs>
              </Stack>
            </Grid>
            <Grid item xs={1} sm={3} md={3} lg={4} xl={4}>
              <Stack direction="row" style={{width: '100%'}} justifyContent={"end"} alignItems={"end"} spacing={1}>
                
              </Stack>
            </Grid>
          </Grid>
          <Stack direction={"row"} padding={0} ml={-2}>
          </Stack>
          <Box style={{width: '100%',height: 'calc(100% - 75px)'}}>
            <GeneralSettings value={value} index={0} />
            <Statistics value={value} index={1} />
            <Billing value={value} index={2} />
          </Box>
        </Box>
      </Box>
    </TopComponent>
  )
}
/*
<Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openNotification}
        onClose={handleClose}
        autoHideDuration={2000}
        message="Configurations saved"
        key={1}
      />
*/