import { combineReducers } from '@reduxjs/toolkit'
import { createStore } from 'redux';
import fileListReducer from './file_list_reducer';
import userProfileReducer from './user_profile_reducer';
import downloadsReducer from './downloads';
import uploadsReducer from './uploads';
import binReducer from './bin_reducer';
import notificationsReducer from './notifications';
import searchFileExplorerReducer from './search_file_explorer_reducer';

// Automatically adds the thunk middleware and the Redux DevTools extension
export const rootReducer = combineReducers({
    fileListReducer: fileListReducer,
    userProfileReducer: userProfileReducer,
    downloadsReducer: downloadsReducer,
    uploadsReducer: uploadsReducer,
    binReducer: binReducer,
    notificationsReducer: notificationsReducer,
    searchFileExplorerReducer: searchFileExplorerReducer
})


const rootStore = () => {
  return createStore(rootReducer);
}
export const _store = rootStore()