import { Box, Divider, Drawer, IconButton, LinearProgress, List, ListItem, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tauri } from '@tauri-apps/api';
import { join } from '@tauri-apps/api/path';
import { AiFillCloseCircle, AiFillFolder } from "react-icons/ai";
import { AddDownloads, DeleteDownloads, SetNewContent, SetSeenDownloads, UpdateNotificationLastVisited } from "../../redux_store/actions";
import { cutLongString } from "../../utils/helper";
import { API } from "../../utils/consts";


function setLastVisistedNotification(userToken) {
  return fetch(API+"/set-last-visited-notification/", {
    method: 'POST',
    headers: {
      'AuthToken': userToken,
      'Content-Type': 'application/json',
    },
  }).then(data=>{
    return data.json()
  })
}

function NotificationListItem(props) {
  return (
    <>
    <ListItem key={props.id} disablePadding className="downloadsItem">
      {props.obj}
    </ListItem>
    <Divider />
    </>
  )
}


export function Notifications(props) {
  const {notificationList, open} = props;
  const [openNotifications, setOpenNotifications] = useState(false)
  const dispatch = useDispatch();
  useEffect(()=>{
    setOpenNotifications(open)
    /* setLastVisistedNotification(props.userToken).then(jsonData => {
      dispatch(UpdateNotificationLastVisited(jsonData.last_visited))
    }) */
    if (open) {
      dispatch(SetNewContent('notification', 0))
    }
  }, [open])

  function onClose() {
    props.onClose()
    setOpenNotifications(false)
  }

  function NotificationsList(props) {
    return (
      <Box
        sx={{width: 300, height: '100%'}}
        role="presentation"
        style={{backgroundColor: "#EAF3FC"}}
      >
        <Typography style={{width: '100%', fontWeight: 'bold', textAlign: 'center', fontSize: 23}}>Notifications</Typography>
      <List
        style={{width: '100%'}}
      >
        {notificationList?.map((obj, index)=>{
          return (<NotificationListItem obj={obj.obj} key={index} id={index} />)
        })}
      </List>
    </Box>
    )
  }

  return (
    <div>
      <React.Fragment key={'left'}>
        <Drawer
          anchor={'left'}
          open={openNotifications}
          onClose={onClose}
        >
          <NotificationsList _setOpen={setOpenNotifications} notificationList={notificationList}/>
        </Drawer>
      </React.Fragment>
  </div>
  )
}