import { Autocomplete, Stack, TextField, Typography, createFilterOptions } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { GetListObjectIcon, cutLongString, getFileIDS, getToken, randomString, reduxPreviewWrapper, search, statePreviewWrapper } from "../utils/helper";
import { API, FILE_EXPLORER_PAGE, SEARCH_FILELIST } from "../utils/consts";
import { useNavigate } from "react-router-dom";
import { ClearSelectedRows, SetBackURL, SetCurrentPgPage, SetFileList, SetSelectedItems } from "../redux_store/actions";
import { useDispatch, useSelector } from "react-redux";


const filter = createFilterOptions();

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  return (
    <Stack {...props} ref={ref} sx={{background: '#F9F9FA'}}/>
  )
})

function ShowAccess(props) {
  const {access, userID} = props;
  var sharedBy;
  for (var i = 0; i < access.length; i++) {
    if (access[i].user.id === userID && access[i].access.name === 'Owner') {
      return (<Typography style={{width: 55, fontSize: 13}}>Owner</Typography>)
    } else if (access[i].access.name === 'Owner') {
      sharedBy = access[i].user
    }
  }
  return (<Typography style={{width: 'auto', fontSize: 13}}>Shared by {sharedBy.name}</Typography>)
}

const BOUNCE_RATE = 1000;

export const useDebounce = () => {
  const busy = useRef(false);

  const debounce = async (callback) => {
    if (!busy.current) {
      busy.current = true;
    } else {
      return 
    }

    setTimeout(() => {
      callback();
      busy.current = false;
    }, BOUNCE_RATE);
  };

  return { debounce };
};

export function Search(searchProps) {
  const [founds, setFound] = useState([])
  const [value, setValue] = useState("")
  const userInfo = useSelector(state => state.userProfileReducer.userInfo);
  const [somethingChanged, setSomethingChange] = useState(false);
  const localRef = useRef();
  const {debounce} = useDebounce();
  var currentTimeOut;
  localRef.current = {currentTimeOut, value, founds}
  const dispatch = useDispatch();

  function previewsCb(previews) {
    var _founds = localRef.current.founds;
    for (var i = 0; i < _founds.length; i++) {
      var foundObject = _founds[i];
      var preview = previews[foundObject.id];
      if (preview) {
        _founds[i].small_preview = preview;
      }
    }
    setFound([..._founds])
  }

  function getSearch() {
    if (localRef.current.value && localRef.current.value.length > 2) {
      search(localRef.current.value, userInfo?.userToken).then(data=>{
        setFound(data.files)
        const fileIDS = getFileIDS(data.files);
        statePreviewWrapper(fileIDS, 'small', userInfo?.userToken, previewsCb)
      }).catch((err) => {
        console.log("ERR", err)
      })
    }
  }
  useEffect(() => {
    debounce(getSearch)
  }, [value])

  const navigate = useNavigate();
    return (
      <Autocomplete
        inputValue={value}
        ListboxComponent={ListboxComponent}
        onInputChange={(event, newValue) => {
          setValue(newValue)
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={founds}
        getOptionLabel={(option) => {
          // Add "xxx" option created dynamically
          if (option.original_name) {
            return option.original_name;
          }
          // Regular option
          return '';
        }}
        renderOption={(_props, option) => {
          if (option.original_name) {
            return (
              <Stack 
                key={_props['data-option-index']}
                onClick={()=>{
                  setValue(option.original_name)
                  navigate(`${FILE_EXPLORER_PAGE}/${option.parent_dir}/?selected=${option.id}`)
                  setFound([])
                }}
                direction="row" 
                style={{width: '100%', height: 55}} 
                padding={1} 
                className="search-item">
                <Stack style={{marginTop: 5}}>
                  <GetListObjectIcon props={option} folderSize={35} previewFormat={"small"}/>
                </Stack>
                <Stack direction="column" style={{flex: 1, marginLeft: 5}}>
                  <Typography style={{fontSize: 14, fontWeight: 'bold', width: '100%', textAlign: 'left'}}>{cutLongString(option.original_name, 50)}</Typography>
                  <Stack direction="row">
                    <ShowAccess access={option.user_permissions} userID={searchProps.userID}/>
                  </Stack>
                </Stack>
              </Stack>
            )
          } return null
          
        }}
        sx={{width: '100%'}}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} 
            sx={{background: '#F9F9FA', borderRadius: 10}}
            onKeyDown={(e)=>{
              if (e.key === 'Enter' && localRef.current.value && localRef.current.value.length > 2) {
                dispatch(SetFileList([], `${userInfo?.root}_search`), 20, false)
                dispatch(ClearSelectedRows())
                navigate(`${FILE_EXPLORER_PAGE}/?q=${localRef.current.value}`)
              }
            }}
            style={{
              minWidth: 100,
              maxWidth: 500,
            }
          } label="Search" size="small" />
        )}
      />
    )
}