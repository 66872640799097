import React, { useEffect, useState } from "react";
import { API, FILE_EXPLORER_PAGE } from "../utils/consts";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { HiMiniChevronRight } from "react-icons/hi2";
import { Box, Button, Card, CardActions, Tab, Tabs, Stack, IconButton, Grid, Tooltip } from "@mui/material";
import { PricingCard } from "./base/pricing_card";
import { lightStyle } from "src/style/light_style";
import { TopComponent } from "./base/top_component";
import { DEFAULT_BUTTON_BACKGROUND_COLOR, DEFAULT_LIGHT_TEXT_COLOR } from "src/style/global";
import { getProducts } from "src/utils/helper";


const tiers = [
  {
    id: 0,
    title: "Free",
    price: "0",
    description: [
      "10 users included",
      "8 GB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    id: 1,
    title: "Entry",
    subheader: "Most popular",
    price: "1.50",
    description: [
      "10 shared devices",
      "100 GB of storage",
      "Upload file size is 1GB",
      "Maximum downloads per link 100",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    id: 2,
    title: "Basic",
    price: "6",
    description: [
      "50 users included",
      "1.5 TB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    id: 3,
    title: "Standard",
    price: "11",
    description: [
      "10 users included",
      "2.5 TB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    id: 4,
    title: "Top",
    subheader: "Most popular",
    price: "14",
    description: [
      "10 shared devices",
      "4 TB of storage",
      "Upload file size is 1GB",
      "Maximum downloads per link 100",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    id: 5,
    title: "Best",
    price: "23",
    description: [
      "50 users included",
      "6 TB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
];

function Cards(props) {
  return (
  <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`settings-tabpanel-${props.index}`}
      aria-labelledby={`settings-tab-${props.index}`}
    >
      <Grid container spacing={3}>
        {props.orderedCards?.map((tier, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <PricingCard 
                key={tier.id}
                period={props.period}
                id={tier.external_id}
                title={tier.name}
                subheader={tier.subheader}
                price={tier.price / 100}
                description={tier.descriptions}
                buttonVariant={'contained'}
                buttonText={'Activate'}
                userInfo={props.userInfo}
                scu={tier.scu}
              />
            </Grid>
          ))}
        </Grid>
  </div>)
}

export function Plans(props) {
  const backUrl = useSelector(state => state.userProfileReducer.backUrl)
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const [value, setValue] = useState(0);
  const [orderedCardsMonthly, setOrderedCardsMonthly] = useState([])
  const [orderedCardsAnnually, setOrderedCardsAnnually] = useState([])
  const [freeCard, setFreeCard] = useState([])
  const navigate = useNavigate();
  const orderMonthly = ["eco_monthly_plan", "eco_pro_monthly_plan", 
  "standard_monthly_plan", "standard_pro_monthly_plan", "premium_monthly_plan"]
  const orderAnnually = ["eco_annually_plan", "eco_pro_annually_plan", 
  "standard_annually_plan", "standard_pro_annually_plan", "premium_annually_plan"]
  useEffect(() => {
    var groupped = {'annually': {}, 'monthly': {}};
    var freeCard;
    getProducts().then(data => {
      for (var i = 0; i < data.length; i++) {
        if (data[i].name === "Free") {
          freeCard = data[i];
        } else {
          groupped[data[i].subscription_period][data[i].scu] = data[i]
        }
      }
      setOrderedCardsMonthly([freeCard, ...orderMonthly.map((item, index) => (groupped['monthly'][item]))])
      setOrderedCardsAnnually([freeCard, ...orderAnnually.map((item, index) => (groupped['annually'][item]))])
    })
  }, [])

  function a11yProps(index) {
    return {
      id: `plan-tab-${index}`,
      'aria-controls': `plan-tabpanel-${index}`,
    };
  }

  const handleChangeIndex = (event, index) => {
    setValue(index);
  };

  return (
    <TopComponent>
      <Box
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        style={lightStyle.mainBackgroud}
      >
        <Box style={{width: '100%', height: '100%', overflowY: 'auto'}}>
          <Grid container padding={1} style={{height: 70}}>
            <Grid item 
              xs={1} sm={1} md={1} lg={4} xl={4} alignItems={"flex-start"} 
              justifyContent={"center"} padding={1}>
              <Stack style={{width: '100%', height: '100%'}} direction={"row"} justifyContent={"start"} alignItems={"start"}>
                <Tooltip title="Go back to files" >
                  <IconButton
                    aria-label="more"
                    onClick={() => {
                      if (backUrl) {
                        navigate(backUrl);
                      } else {
                        navigate(`${FILE_EXPLORER_PAGE}/${userInfo?.root}/`)
                      }
                    }}
                    style={{backgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR}}
                    id="back-btn">
                      <FiArrowLeft size={22} color={DEFAULT_LIGHT_TEXT_COLOR}/>
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
          <Stack direction={"row"} style={{flex: 1}} justifyContent={"center"} alignItems={"center"} >
            <Stack style={{maxWidth: 1500}}>
              
              <Tabs value={value} onChange={handleChangeIndex} centered className="profile-tab">
                <Tab label="Monthly"  {...a11yProps(0)} style={{fontWeight: 'bold'}}/>
                <Tab label="Annually" {...a11yProps(1)} style={{fontWeight: 'bold'}}/>
              </Tabs>
              <Box>
                <Cards 
                  period={"month"}
                  value={value}
                  index={0}
                  orderedCards={orderedCardsMonthly}
                  userInfo={userInfo }
                />
                <Cards 
                  period={"year"}
                  value={value}
                  index={1}
                  orderedCards={orderedCardsAnnually}
                  userInfo={userInfo} 
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
        
      </Box>
    </TopComponent>
  )
}