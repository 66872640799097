import { getIndexOfFoundObject } from 'src/utils/helper';
import { AlertData } from '../objects/alert';
import { ADD_BREAD_CRUMB_FOLDER, ADD_FILES_IN_LIST, ADD_FILE_IN_LIST, ADD_FOLDER_IN_LIST, ADD_FORCE_OBJECT_IN_LIST, ALERT_TYPE_ERROR, ALERT_TYPE_INFO, ALERT_TYPE_SUCCESS, AlertType, CLEAN_SYNCED_FOLDERS, CLEAR_SELECTED_ROWS, DECREMENT_NEW_CONTENT, DELETE_FILE_IN_LIST, DELETE_FILE_OBJECT, DELETE_FILE_OBJECTS, FILTER_FAVORITE_FILES, HIDE_SHARE_MODAL, INCREMENT_NEW_CONTENT, INIT_BREAD_CRUMB, INREMENT_NEW_CONTENT, OPEN_NOTIFICATIONS, PROCESS_MSG, REMOVE_OBJECT_BY_INDEX, REMOVE_OLD_FILE_STRUCT, RENAME_FILE_OBJECT, REPLACE_UPLOADED_FILE, SELECT_USING_SHIFT, SET_ALERT_DATA, SET_BREAD_CRUMB, SET_COPY_LINK_FILE_OBJECT, SET_CURRENT_FOLDER_ID, SET_CURRENT_PG_PAGE, SET_FILE_FAVORITE, SET_FILE_FAVORITES, SET_FILE_LIST, SET_FILE_LIST_BG_COLOR, SET_FILE_LIST_LOADED_STATUS, SET_FILE_STRUCT_ORDERING, SET_FILE_UPLOAD_IN_FOLDER, SET_HAS_MORE, SET_ITEMS_FOR_ACTION, SET_MANAGE_PERMISSION_FILE_OBJECT, SET_MODAL_FILE_COPY_DATA, SET_MODAL_FILE_MOVE_DATA, SET_NEW_CONTENT, SET_PREVIEW_SIZE_FORMAT, SET_SELECT_ITEMS, SET_SELETECTED_ALL_FILES, SET_SHARE_FILE_OBJECT, SET_SHOW_CONTAINER_HEADER_UPLOAD, SET_SYNCED_FOLDERS, SET_UNSET_SELECTED_ROW, SHOW_SHARE_MODAL, UPDATE_FILEOBJECT_ACCESS, UPDATE_FILE_IN_LIST, UPDATE_PREVIEW } from '../utils/consts'
import { uniqFast } from '../utils/helper';

const initialState = {
  fileStruct: {}, // key $app_id -> paginations, fileList, metaData object
  activeFilters: [],
  deleteFileObject: null,
  modalMoveFileObject: null,
  modalCopyFileObject: null,
  modalShareFileObject: null,
  modalManagePermissionFileObject: null,
  modalCopyLinkFileObject: null,
  itemsForAction: [],
  selectedRows: [], // list of file object's ids
  currentFolderID: '',
  mainListBreadCrumb: [],
  alertData: (new AlertData()),
  currentPgPage: 1,
  hasMore: true,
  selectedAllFiles: false,
  showContainerHeaderUpload: false,
  deleteFileObjectIDs: [],
  showShareModal: [],
  addedFiles: {},
  openNotifications: false,
  fileListLoadedStatus: 0,
  selectedAnchor: '',
  shiftSelected: '',
  inFolder: false,
  syncedFolders: {}
}

export default function fileListReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAN_SYNCED_FOLDERS: {
      return {
        ...state,
        syncedFolders: {}
      }
    }
    case SET_SYNCED_FOLDERS: {
      const payload = action.payload;
      const objectID = payload.objectID;
      const value = payload.value;
      var syncedFolders = state.syncedFolders;
      syncedFolders[objectID] = value;
      return {
        ...state,
        syncedFolders: {...syncedFolders}
      }
    }
    case SET_FILE_LIST_LOADED_STATUS: {
      const payload = action.payload;
      return {
        ...state,
        fileListLoadedStatus: payload
      }
    }
    case SET_MANAGE_PERMISSION_FILE_OBJECT: {
      const fileObject = action.payload;
      return {
        ...state,
        modalManagePermissionFileObject: fileObject
      }
    }
    case SHOW_SHARE_MODAL: {
      const fileObjectIDList = action.payload;
      return {
        ...state,
        showShareModal: [...fileObjectIDList]
      }
    }
    case HIDE_SHARE_MODAL: {
      return {
        ...state,
        showShareModal: false
      }
    }
    case SET_SHOW_CONTAINER_HEADER_UPLOAD: {
      const show = action.payload;
      return {
        ...state,
        showContainerHeaderUpload: show
      }
    }
    case SET_ITEMS_FOR_ACTION: {
      const itemsForAction = action.payload;
      if (itemsForAction.length === 0) {
        return {
          ...state,
          itemsForAction: [],
        }
      } else if (itemsForAction[0] === -1) {
        return {
          ...state,
          itemsForAction: [...state.selectedRows],
        }
      } else {
        return {
          ...state,
          itemsForAction: [...itemsForAction],
        }
      }
    }
    case SET_SELETECTED_ALL_FILES: {
      return {
        ...state,
        selectedAllFiles: !state.selectedAllFiles
      }
    }
    case FILTER_FAVORITE_FILES: {
      const index = state.activeFilters.indexOf(FILTER_FAVORITE_FILES);
      if ( index === -1 ) {
        return {
          ...state,
          activeFilters: [...state.activeFilters, FILTER_FAVORITE_FILES]
        }
      }
      return {
        ...state,
        activeFilters: [...state.activeFilters.slice(0, index), ...state.activeFilters.slice(index+1, state.activeFilters.length)]
      }
    }
    case SET_HAS_MORE: {
      const hasMore = action.payload;
      return {
        ...state,
        hasMore: hasMore
      }
    }
    case SET_CURRENT_PG_PAGE: {
      const currentPgPage = action.payload;
      return {
        ...state,
        currentPgPage: currentPgPage
      }
    }
    case REPLACE_UPLOADED_FILE: {
      const {source, parentID, dst} = action.payload;
      var fileStruct = state.fileStruct;
      var selectedRows = state.selectedRows;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var knownFolderIDS = fileStructObject.knownFolderIDS;
      var fileList = fileStructObject.fileList;
      for (var i = 0; i < fileList.length; i++) {
        var obj = fileList[i];
        if (obj.id === source.id) {
          knownFolderIDS[dst.id] = 1;
          var fileList = [...fileList.slice(0, i), dst, ...fileList.slice(i+1, fileList.length)];
          fileStructObject.fileList = fileList;
          fileStructObject.knownFolderIDS = knownFolderIDS
          fileStruct[parentID] = fileStructObject;
          return {
            ...state,
            fileStruct: {...fileStruct},
          }
        }
      }
      fileStructObject.fileList = [...fileList, dst];
      fileStruct[parentID] = fileStructObject;
      
      return {
        ...state,
        fileStruct: {...fileStruct},
      }
    }
    case SET_BREAD_CRUMB: {
      const breadCrumb = action.payload;
      return {
        ...state,
        mainListBreadCrumb: [...breadCrumb]
      }
    }
    case INIT_BREAD_CRUMB: {
      const breadcrumbObject = action.payload;
      return {
        ...state,
        mainListBreadCrumb: [breadcrumbObject]
      }
    }
    case SET_CURRENT_FOLDER_ID: {
      const currentFolderID = action.payload;
      return {
        ...state,
        currentFolderID: currentFolderID
      }
    }
    case ADD_BREAD_CRUMB_FOLDER: {
      const breadCrumbFileObject = action.payload;
      const mainListBreadCrumb = state.mainListBreadCrumb;
      if (breadCrumbFileObject === null ) {
        if (state.mainListBreadCrumb.length > 1) {
          return {
            ...state,
            mainListBreadCrumb: [...mainListBreadCrumb.slice(0, mainListBreadCrumb.length-1)]
          }
        } return {
          ...state
        }
      }
      
      for (var i = 0; i < mainListBreadCrumb.length; i++) {
        if (mainListBreadCrumb[i].id === breadCrumbFileObject.id) {
          return {
            ...state,
            mainListBreadCrumb: [...mainListBreadCrumb.slice(0, i+1)]
          }
        }
      }
      return {
        ...state,
        mainListBreadCrumb: [...mainListBreadCrumb, {original_name: breadCrumbFileObject.original_name, id: breadCrumbFileObject.id}]
      }
    }
    case CLEAR_SELECTED_ROWS: {
      return {
        ...state,
        selectedRows: [],
        selectedAllFiles: false,
      }
    }
    case SET_SELECT_ITEMS: {
      const {selectedRowIDS} = action.payload; 
      return {
        ...state,
        selectedRows: [...selectedRowIDS],
        selectedAllFiles: false,
      }
    }
    case SELECT_USING_SHIFT: {
      const {objectID, parentID} = action.payload;
      const selectedAnchor = state.selectedAnchor;
      const shiftSelected = state.shiftSelected;
      var fileStruct = state.fileStruct;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      if (!shiftSelected && !selectedAnchor) {
        return {
          ...state,
          shiftSelected: shiftSelected,
          selectedAnchor: objectID,
          selectedRows: [objectID]
        }
      }
      const fileList = fileStruct[parentID].fileList;
      const objectIndex = getIndexOfFoundObject(fileList, objectID);
      const anchorIndex = getIndexOfFoundObject(fileList, selectedAnchor);
      var startIndex = 0;
      var endIndex = 0;
      if (objectIndex > anchorIndex) {
        startIndex = anchorIndex;
        endIndex = objectIndex;
      } else if (objectIndex < anchorIndex) {
        startIndex = objectIndex;
        endIndex = anchorIndex;
      } else {
        startIndex = objectIndex;
        endIndex = objectIndex;
      }
      if (startIndex === -1) {
        return {
          ...state,
          shiftSelected: shiftSelected,
          selectedAnchor: objectID,
          selectedRows: [objectID]
        }
      }
      var idsForSelect = [];
      var idsForUnSelect = [];
      for (var i = startIndex; i < endIndex+1; i++) {
        idsForSelect.push(fileList[i].id);
      }
      if (shiftSelected) {
        const shiftSelectedIndex = getIndexOfFoundObject(fileList, shiftSelected);
        if (shiftSelectedIndex !== -1) {
          var startIndex = 0;
          var endIndex = 0;
          if (shiftSelectedIndex > anchorIndex) {
            startIndex = anchorIndex;
            endIndex = shiftSelectedIndex;
          } else if (shiftSelectedIndex < anchorIndex) {
            startIndex = shiftSelectedIndex;
            endIndex = anchorIndex;
          } else {
            startIndex = shiftSelectedIndex;
            endIndex = shiftSelectedIndex;
          }
          for (var i = startIndex; i < endIndex+1; i++) {
            idsForUnSelect.push(fileList[i].id);
          }
        }
      }
      var tmpSelected = []
      for (var i = 0; i < state.selectedRows.length; i++) {
        if (idsForUnSelect.indexOf(state.selectedRows[i]) === -1 || state.selectedRows[i] === selectedAnchor) {
          tmpSelected.push(state.selectedRows[i])
        }
      }
      var newList = uniqFast([...idsForSelect, ...tmpSelected])
      return {
        ...state,
        shiftSelected: objectID,
        selectedRows: [...newList],
      }
    }
    case SET_UNSET_SELECTED_ROW: {
      const {parentID, selectedRowID} = action.payload;
      var fileStruct = state.fileStruct;
      var selectedRows = state.selectedRows;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      const pos = selectedRows.indexOf(selectedRowID);
      if (pos !== -1) {
        return {
          ...state,
          selectedAllFiles: false,
          selectedRows: [...selectedRows.slice(0, pos), ...selectedRows.slice(pos+1, selectedRows.length)],
          selectedAnchor: selectedRowID,
          shiftSelected: ''
        }
      }
      var fileStructObject = fileStruct[parentID];
      var fileList = fileStructObject.fileList;
      if (fileList.length === selectedRows.length+1) {
        return {
          ...state,
          selectedAllFiles: true,
          selectedRows: [...selectedRows, selectedRowID],
          selectedAnchor: selectedRowID,
          shiftSelected: ''
        }
      }
      return {
        ...state,
        selectedAllFiles: false,
        selectedRows: [...selectedRows, selectedRowID],
        selectedAnchor: selectedRowID,
        shiftSelected: ''
      }
    }
    case PROCESS_MSG: {
      const payload = action.payload;
      var msg = "";
      if (payload.data.msg) {
        msg = payload.data.msg;
      } else if (payload.data.detail) {
        msg = payload.data.detail;
      }
      var alertData = new AlertData();
      switch(payload.alertType) {
        case ALERT_TYPE_ERROR: {
          alertData.type = AlertType.ERROR;
          alertData.message = msg;
          break;
        }
        case ALERT_TYPE_INFO: {
          alertData.type = AlertType.INFO;
          alertData.message = msg;
          break;
        }
        case ALERT_TYPE_SUCCESS: {
          alertData.type = AlertType.SUCCESS;
          alertData.message = msg;
          break;
        }
        default: {
          return {
            ...state
          }
        }
      }
      return {
        ...state,
        alertData: alertData
      }
    }
    case SET_ALERT_DATA: {
      const alertData = action.payload;
      return {
        ...state,
        alertData: alertData
      }
    }
    case SET_COPY_LINK_FILE_OBJECT: {
      const modalCopyLinkFileObject = action.payload;
      return {
        ...state,
        modalCopyLinkFileObject: modalCopyLinkFileObject
      }
    }
    case SET_SHARE_FILE_OBJECT: {
      const modalShareFileObject = action.payload;
      return {
        ...state,
        modalShareFileObject: modalShareFileObject
      }
    }
    case SET_MODAL_FILE_MOVE_DATA: {
      const modalFileMoveData = action.payload;
      return {
        ...state,
        modalMoveFileObject: modalFileMoveData
      }
    }
    case SET_MODAL_FILE_COPY_DATA: {
      const modalFileCopyData = action.payload;
      return {
        ...state,
        modalCopyFileObject: modalFileCopyData
      }
    }
    case SET_FILE_UPLOAD_IN_FOLDER: {
      const {inFolder} = action.payload;
      return {
        ...state,
        inFolder: inFolder
      }
    }
    case DELETE_FILE_IN_LIST: {
      const {parentID, fileObjectID} = action.payload;
      var fileStruct = state.fileStruct;
      var selectedRows = state.selectedRows;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var knownFolderIDS = fileStructObject.knownFolderIDS;
      if (knownFolderIDS[fileObjectID]) {
        delete knownFolderIDS[fileObjectID];
      }
      fileStructObject.knownFolderIDS = knownFolderIDS;
      var fileList = fileStructObject.fileList;
      for (var i = 0; i < fileList.length; i++) {
        var obj = fileList[i];
        if (obj.id === fileObjectID) {
          var index = selectedRows.indexOf(obj.id);
          if ( index !== -1) {
            fileStructObject.fileList = [...fileList.slice(0, i), ...fileList.slice(i+1, fileList.length)];
            fileStruct[parentID] = fileStructObject;
            return {
              ...state,
              fileStruct: {...fileStruct},
              selectedRows: [...selectedRows.slice(0, index), ...selectedRows.slice(index+1, selectedRows.length)]
            }
          }
          fileList.splice(i, 1);
          fileStructObject.fileList = [...fileList];
          fileStruct[parentID] = fileStructObject;
          return {
            ...state,
            fileStruct: {...fileStruct},
          }
        }
      }
      return {
        ...state
      }
    }
    case DELETE_FILE_OBJECT: {
      const deleteFileObject = action.payload;
      return {
        ...state,
        deleteFileObject: deleteFileObject
      }
    }
    case DELETE_FILE_OBJECTS: {
      const fileObjectIDs = action.payload;
      return {
        ...state,
        deleteFileObjectIDs: [...fileObjectIDs]
      }
    }
    case SET_FILE_FAVORITES: {
      const {parentID, fileObjectIDS, favorite} = action.payload;
      var fileStruct = state.fileStruct;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      var fileList = fileStructObject.fileList;
      for (var i = 0; i < fileList.length; i++) {
        var obj = fileList[i];
        if (fileObjectIDS.indexOf(obj.id) !== -1) {
          obj.favorite = favorite;
          fileList[i] = {...obj};
        }
      }
      fileStructObject.fileList = [...fileList];
      fileStruct[parentID] = {...fileStructObject};
      return {
        ...state,
        fileStruct: {...fileStruct},
      }
    }
    case INCREMENT_NEW_CONTENT: {
      var fileStruct = state.fileStruct;
      const {parentID} = action.payload;
      if (!fileStruct[parentID]) {
        fileStruct[parentID] = {newContent: 1}
      } else {
        var obj = fileStruct[parentID];
        if (obj.newContent) {
          obj.newContent ++;
        } else {
          obj.newContent = 1;
        }
        fileStruct[parentID] = obj;
      }
      return {
        ...state,
        fileStruct: {...fileStruct},
      };
    }
    case RENAME_FILE_OBJECT: {
      var fileStruct = state.fileStruct;
      const {newName, parentID, objectID} = action.payload;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }

      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var fileList = fileStructObject.fileList;
      for (var i = 0; i < fileList.length; i++) {
        var fielListObject = fileList[i];
        if (fielListObject.id === objectID) {
          fielListObject.original_name = newName;
          fileList[i] = {...fielListObject};
          fileStruct[parentID].fileList = [...fileList];
          return {
            ...state,
            fileStruct: {...fileStruct}
          }
        }
      }
      return {
        ...state
      }
    }
    case DECREMENT_NEW_CONTENT: {
      var fileStruct = state.fileStruct;
      const {parentID} = action.payload;
      if (!fileStruct[parentID]) {
        fileStruct[parentID] = {newContent: 0}
      } else {
        var obj = fileStruct[parentID];
        if (obj.newContent) {
          obj.newContent --;
        } else {
          obj.newContent = 0;
        }
        fileStruct[parentID] = obj;
      }
      return {
        ...state,
        fileStruct: {...fileStruct},
      };
    }
    case SET_NEW_CONTENT: {
      var fileStruct = state.fileStruct;
      const {parentID, newContent} = action.payload;
      if (!fileStruct[parentID]) {
        fileStruct[parentID] = {newContent: newContent, fileList: []}
      } else {
        var obj = fileStruct[parentID];
        obj.newContent = newContent;
        fileStruct[parentID] = obj;
      }
      return {
        ...state,
        fileStruct: {...fileStruct},
      };
    }
    case UPDATE_PREVIEW: {
      var fileStruct = state.fileStruct;
      const {parentID, previews, previewFormat, binPreview} = action.payload;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var fileList = fileStructObject.fileList;
      for (var i = 0; i < fileList.length; i++) {
        var fielListObject = fileList[i];
        var preview;
        if (binPreview) {
          preview = previews[fielListObject.object_id];
        } else {
          preview = previews[fielListObject.id];
        }
        
        if (preview) {
          if (previewFormat === 'small') {
            fielListObject.small_preview = preview;
          } else if (previewFormat === 'medium') {
            fielListObject.medium_preview = preview;
          }
        }
        fileList[i] = fielListObject;
      }
      fileStructObject.fileList = fileList;
      fileStruct[parentID] = fileStructObject;
      return {
        ...state,
        fileStruct: {...fileStruct},
      };
    }
    case UPDATE_FILE_IN_LIST: {
      var fileStruct = state.fileStruct;
      const {parentID, fileObject} = action.payload;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var fileList = fileStructObject.fileList;
      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].id === fileObject.id) {
          const smallPreview = fileList[i].small_preview;
          const mediumPreview = fileList[i].medium_preview;
          fileList[i] = fileObject;
          fileList[i].small_preview = smallPreview;
          fileList[i].medium_preview = mediumPreview;
          fileStructObject.fileList = [...fileList];
          fileStruct[parentID] = fileStructObject;
          return {
            ...state,
            fileStruct: {...fileStruct},
          };
        }
      }
      return {
        ...state,
      }
    }
    case SET_PREVIEW_SIZE_FORMAT: {
      const {previewFormat, parentID} = action.payload;
      var fileStruct = state.fileStruct;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      if (previewFormat === 'small') {
        fileStructObject.previewSmall = true;
      } else {
        fileStructObject.previewMedium = true;
      }
      fileStruct[parentID] = fileStructObject;
      return {
        ...state,
        fileStruct: {...fileStruct}
      }
    }
    case ADD_FILES_IN_LIST: {
      const {fileObjects, parentID, atTheEnd} = action.payload;
      var fileStruct = state.fileStruct;
      const index = 0
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var knownFolderIDS = fileStructObject.knownFolderIDS;
      var fileList = fileStructObject.fileList;
      for (var i = 0; i < fileObjects.length; i++) {
        var fileObject = fileObjects[i];
        if (knownFolderIDS[fileObject.id]) {
          continue
        }
        knownFolderIDS[fileObject.id] = 1
        if (atTheEnd) {
          fileList.push(fileObject);
        } else {
          fileList.splice(index, 0, fileObject);
        }
      }
      fileStructObject.fileList = [...fileList];
      if (fileObjects.length === fileStructObject.window) {
        fileStructObject.page += 1;
        fileStructObject.hasMore = true;
      } else {
        fileStructObject.hasMore = false;
      }
      fileStruct[parentID] = fileStructObject;
      fileStructObject.knownFolderIDS = knownFolderIDS;
      return {
        ...state,
        fileStruct: {...fileStruct}
      }
    }
    case OPEN_NOTIFICATIONS: {
      const open = action.payload;
      return {
        ...state,
        openNotifications: open
      }
    }
    case ADD_FILE_IN_LIST: {
      var fileStruct = state.fileStruct;
      const {fileObject, parentID, searchPage, first} = action.payload;
      var addedFiles = state.addedFiles;
      var fileList = state.fileList;
      const index = 0
      var key = parentID;
      if (!fileStruct[key]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[key];
      fileStructObject.lastActionDate = Date.now();
      var knownFolderIDS = fileStructObject.knownFolderIDS;
      if (knownFolderIDS[fileObject.id]) {
        return {
          ...state,
        }; 
      }
      knownFolderIDS[fileObject.id] = 1
      var fileList = fileStructObject.fileList;
      if (first) {
        fileList.splice(index, 0, fileObject)
        fileStructObject.fileList = [...fileList];
        fileStruct[key] = {...fileStructObject};
        fileStructObject.knownFolderIDS = knownFolderIDS;
        return {
          ...state,
        selectedAllFiles: false,
        fileStruct: {...fileStruct}
        }
      }
      fileList.push(fileObject)
      fileStructObject.fileList = [...fileList];
      fileStructObject.knownFolderIDS = knownFolderIDS;
      fileStruct[key] = {...fileStructObject};
      return {
        ...state,
        selectedAllFiles: false,
        fileStruct: {...fileStruct}
      }
    }
    case SET_FILE_STRUCT_ORDERING: {
      var fileStruct = state.fileStruct;
      const {groupBy, order} = action.payload;
      fileStruct.groupBy = groupBy;
      fileStruct.order = order;
      return {
        ...state,
        fileStruct: {...fileStruct}
      }
    }
    case ADD_FORCE_OBJECT_IN_LIST: {
      var fileStruct = state.fileStruct;
      const {parentID, object} = action.payload;
      if (!fileStruct[parentID]) {
        fileStruct[parentID] = {
          fileList: [],
          cursor: 0,
          window: window,
          page: 2,
          newContent: 0,
          hasMore: true
        }
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var fileList = fileStructObject.fileList;
      if (!fileStructObject.newContent) {
        fileStructObject.newContent = 1;
      } else {
        fileStructObject.newContent++;
      }
      fileList = [object, ...fileList];
      fileStructObject.fileList = fileList;
      fileStruct[parentID] = fileStructObject;
      return {
        ...state,
        fileStruct: {...fileStruct},
      };
    }
    case REMOVE_OBJECT_BY_INDEX: {
      var fileStruct = state.fileStruct;
      const {parentID, index} = action.payload;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var fileList = fileStructObject.fileList;
      if (fileList.length < index) {
        return {
          ...state,
        };
      }
      fileList.splice(index, 1);
      fileStructObject.fileList = fileList;
      fileStruct[parentID] = fileStructObject;
      return {
        ...state,
        fileStruct: {...fileStruct},
      };
    }
    case ADD_FOLDER_IN_LIST: {
      var fileStruct = state.fileStruct;
      const {parentID, fileObject} = action.payload;
      if (!fileStruct[parentID]) {
        return {
          ...state,
        };
      }
      var fileStructObject = fileStruct[parentID];
      fileStructObject.lastActionDate = Date.now();
      var knownFolderIDS = fileStructObject.knownFolderIDS;
      var fileList = fileStructObject.fileList;
      if (knownFolderIDS[fileObject.id]) {
        return {
          ...state,
        };
      } else {
        knownFolderIDS[fileObject.id] = 1;
      }
      fileList = [fileObject, ...fileList];
      fileStructObject.fileList = fileList;
      if (!fileStructObject.newContent) {
        fileStructObject.newContent = 1;
      } else {
        fileStructObject.newContent++;
      }
      fileStructObject.knownFolderIDS = knownFolderIDS;
      fileStruct[parentID] = fileStructObject;
      return {
        ...state,
        fileStruct: {...fileStruct},
      };
    }
    case SET_FILE_LIST: {
      const {fileList, parentID, window, updateContentCounter} = action.payload;
      var fileStruct = state.fileStruct;
      fileStruct[parentID] = {
        fileList: fileList,
        cursor: 0,
        window: window,
        page: fileList.length >= window ? 2 : 1,
        newContent: 0,
        hasMore: fileList.length >= window ? true : false
      };
      fileStruct[parentID].knownFolderIDS = {};
      fileStruct[parentID].lastActionDate = Date.now();
      var knownFolderIDS = fileStruct[parentID].knownFolderIDS;
      for (var i = 0; i < fileList.length; i++) {
        knownFolderIDS[fileList[i].id] = 1
      }
      if (updateContentCounter) {
        fileStruct[parentID].newContent = fileList.length;
      }
      fileStruct[parentID].knownFolderIDS = knownFolderIDS;
      return {
        ...state,
        fileStruct: {...fileStruct}
      };
    }
    case REMOVE_OLD_FILE_STRUCT: {
      var fileStruct = state.fileStruct;
      if ( !fileStruct) {
        return {
          ...state
        }
      }
      var keys = Object.keys(fileStruct);
      for (var i = 0; i < keys.length; i++) {
        const k = keys[i];
        var fsObject = fileStruct[k];
        if (Date.now() - fsObject.lastActionDate > 259200000) {
          // 259200000 is 3 days in milliseconds
          delete fileStruct[k];
        }
      }
      return {
        ...state,
        fileStruct: {...fileStruct}
      }
    }
    default:
      return state
  }
}