import React, { useEffect, useRef, useState } from "react";
import { Box, Typography} from "@mui/material";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { AlertData } from "src/objects/alert";
import { AddFolderInList, AddTmpUploadFileObject, AddUploadStructure, AddUploads, SetAlertData, SetTmpPathsFromRust } from "src/redux_store/actions";
import { AlertType, DEFAULT_COLOR } from "src/utils/consts";
import { covertDate, craeteUploadStructure, createDirTree, createFolders, getFileDirPath, getMainRootFromPath, getMainRootObject, randomString } from "src/utils/helper";

export function HeaderContainerUpload(props) {
  const fileStruct = useSelector(state => state.fileListReducer.fileStruct);
  const dispatch = useDispatch();
  const [values, setValues] = useState();
  const valueRef = useRef(null);
  valueRef.current = { values }

  useEffect(() => {
    var breadCrumbObj = props.value?.slice(-1)[0];
    setValues(breadCrumbObj);
  }, [props.value, props.show])


  const getAllFileEntries = async (dataTransferItemList) => {
    let fileEntries = [];
    // Use BFS to traverse entire directory/file structure
    let queue = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
      queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
      let entry = queue.shift();
      if (entry.isFile) {
        fileEntries.push(entry);
      } else if (entry.isDirectory) {
        fileEntries.push(entry);
        let reader = entry.createReader();
        queue.push(...(await readAllDirectoryEntries(reader)));
      }
    }
    // return fileEntries;
    return Promise.all(
      fileEntries.map((entry) => readEntryContentAsync(entry))
    );
  };

  // Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
  const readAllDirectoryEntries = async (directoryReader) => {
    let entries = [];
    let readEntries = await readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
      entries.push(...readEntries);
      readEntries = await readEntriesPromise(directoryReader);
    }
    return entries;
  };

  // Wrap readEntries in a promise to make working with readEntries easier
  const readEntriesPromise = async (directoryReader) => {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const readEntryContentAsync = async (entry) => {
    return new Promise((resolve, reject) => {
      let reading = 0;
      const contents = [];
      reading++;
      if (entry.isDirectory) {
        reading--;
        contents.push(entry);
        if (reading === 0) {
          resolve(contents);
        }
      } else {
        entry.file(async (file) => {
          reading--;
          const rawFile = file;
          rawFile.path = entry.fullPath;
          contents.push(rawFile);
          if (reading === 0) {
            resolve(contents);
          }
        });
      }
    });
  };


  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (item && item.dataTransfer) {
          var items = item.dataTransfer.items;

          getAllFileEntries(items).then(files => {

            const flattenFiles = files.reduce((acc, val) => acc.concat(val), []);
            var onlyDirs = [];
            var onlyFiles = [];
            var folders = []
            for (var i = 0; i < flattenFiles.length; i++) {

              if (flattenFiles[i].isDirectory) {
                onlyDirs.push(flattenFiles[i]);
                folders.push(flattenFiles[i].fullPath);
              } else if (flattenFiles[i].path) {
                onlyFiles.push(flattenFiles[i])
              }
            }
            if (onlyFiles.length > 2000) {
              dispatch(SetTmpPathsFromRust(onlyFiles, folders))
            } else {
              var parsed_files = [];
              for (let i = 0; i < onlyFiles.length; i++) {

                const f = onlyFiles[i];
                var lastIndexOfSlash = f.path.lastIndexOf("/");

                var filePath = "";
                if (lastIndexOfSlash === -1) {
                  continue
                }
                if (lastIndexOfSlash === 0) {
                  filePath = '/'
                } else {
                  filePath = f.path.slice(0, lastIndexOfSlash)
                }
                folders.push(getFileDirPath(filePath));
                parsed_files.push({
                  full_path: f.path,
                  relative_path: f.path,
                  id: randomString(15),
                  name: f.name,
                  size: f.size,
                  file_object: f,
                  relative_path_parent_dir: getFileDirPath(f.path)
                })
              }
              var dirTree = createDirTree(onlyDirs);

              createFolders(valueRef.current.values.id, dirTree, props.userInfo?.userToken).then(data => {
                if (data.msg || data.detail) {
                  var alertData = new AlertData();
                  alertData.type = AlertType.ERROR;
                  alertData.message = data.msg || data.detail;
                  dispatch(SetAlertData(alertData))
                  return;
                }
                var createdFolders = data.created_folders;
                if (!createdFolders) {
                  createdFolders[props.currentFolderID] = { files: [], folders: [] }
                }

                var upload_structure = craeteUploadStructure(parsed_files, createdFolders, data.main_roots);

                for (var i = 0; i < data.root_folders.length; i++) {
                  dispatch(AddFolderInList(data.root_folders[i]));
                }
                var tmp_main_root_object = getMainRootObject(data.main_roots);
                dispatch(AddUploadStructure(upload_structure))
                for (var i = 0; i < parsed_files.length; i++) {
                  var fileObject = parsed_files[i];
                  const relative_path = fileObject.relative_path;
                  const relative_path_parent_dir = fileObject.relative_path_parent_dir;
                  var parentID = data.created_folders[relative_path_parent_dir]
                  const main_root = getMainRootFromPath(relative_path_parent_dir);

                  if (parentID) {
                    var tmpObject = {
                      original_name: fileObject.name,
                      id: fileObject.id,
                      created: covertDate(new Date()),
                      updated: 0,
                      size: fileObject.size,
                      favorite: false,
                      object_type: 'file',
                      progress: 0.001,
                      upload: true,
                      parent_dir: parentID,
                      main_root_id: tmp_main_root_object[main_root],
                      file: fileObject.file_object
                    };
                    dispatch(AddTmpUploadFileObject(tmpObject))
                    dispatch(AddUploads(tmpObject))
                  }
                }
              })
            }
          });
        }
      },
      hover(item) {
        if (item && item.dataTransfer) {
          var items = item.dataTransfer.items;
          var length = items.length;
          if (length > 0) {
            var itemString = length > 1 ? length + " items in " : length + " item in "
            var alertData = new AlertData();
            alertData.type = AlertType.INFO;
            alertData.message = "Upload " + itemString + valueRef.current.values?.original_name
            dispatch(SetAlertData(alertData))
          }
        }
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    })
  )

  if (props.show) {
    return (
      <Box
        ref={dropRef}
        className="upload-header-container"
        style={{ background: DEFAULT_COLOR }}>
        <Box className="upload-header-inner"
        >
          <Typography style={{ width: '100%', textAlign: 'center', fontSize: 30, fontStyle: 'italic', fontWeight: 'bold' }}>Drop files here</Typography>
        </Box>
      </Box>
    )
  } else {
    return null
  }
}