import {downloadDir as dDT, join as jnT, appConfigDir as apcdT, resourceDir as rscT, appDir as appdT} from '@tauri-apps/api/path';
import { listen as lstT} from '@tauri-apps/api/event';
import { invoke as inkT } from '@tauri-apps/api';
import { open as opnT} from '@tauri-apps/api/dialog';
import { createDir as cdT, writeFile as wfT, BaseDirectory as bdT, exists as exT, readTextFile as rtfT } from '@tauri-apps/api/fs';
export const downloadDir = dDT;
export const join = jnT;
export const appConfigDir = apcdT;
export const listen = lstT;
export const createDir = cdT;
export const writeFile = wfT;
export const BaseDirectory = bdT;
export const exists = exT;
export const readTextFile = rtfT;
export const invoke = inkT;
export const open = opnT;
export const resourceDir = rscT;
export const appDir = appdT;