import React, { useEffect, useState } from "react";
import { API, FILE_EXPLORER_PAGE } from "../utils/consts";
import { useNavigate, useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";


import { Box, Stack, IconButton, Grid, Tooltip } from "@mui/material";
import { PricingCard } from "./base/pricing_card";
import { lightStyle } from "src/style/light_style";
import { TopComponent } from "./base/top_component";
import { DEFAULT_BUTTON_BACKGROUND_COLOR, DEFAULT_LIGHT_TEXT_COLOR } from "src/style/global";
import { getProduct } from "src/utils/helper";
import {CheckoutForm} from "./checkout";


export function Purchase(props) {
  const userInfo = useSelector(state => state.userProfileReducer.userInfo)
  const [selectedProduct, setSelectedProduct] = useState()
  const [localSecret, setLocalSecret] = useState()
  const navigate = useNavigate();
  let { id } = useParams();
  
  useEffect(() => {
    getProduct(id).then(data => {
      setSelectedProduct(data)
    })
  }, [])

  function onGetSecret(secret) {
    if (!secret) {
      navigate("/file-explorer")
    }
    setLocalSecret(secret)
    setSelectedProduct(null)
  }

  return (
    <TopComponent>
      
        <Box
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          style={lightStyle.mainBackgroud}
        >
          <Box style={{width: '100%', height: '100%', overflowY: 'auto'}}>
            <Grid container padding={1} style={{height: 70}}>
              <Grid item 
                xs={1} sm={1} md={1} lg={4} xl={4} alignItems={"flex-start"} 
                justifyContent={"center"} padding={1}>
                <Stack style={{width: '100%', height: '100%'}} direction={"row"} justifyContent={"start"} alignItems={"start"}>
                  <Tooltip title="Go to plans" >
                    <IconButton
                      aria-label="more"
                      onClick={() => {
                        navigate(-1)
                      }}
                      style={{backgroundColor: DEFAULT_BUTTON_BACKGROUND_COLOR}}
                      id="back-btn">
                        <FiArrowLeft size={22} color={DEFAULT_LIGHT_TEXT_COLOR}/>
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
            <Stack direction={"row"} style={{flex: 1}} justifyContent={"center"} alignItems={"center"} >
              <Stack style={{width: 400}}>
                {selectedProduct ? <PricingCard 
                  className={"purchase-card"}
                  period={selectedProduct?.subscription_period === 'monthly' ? 'month' : 'year'}
                  id={selectedProduct?.external_id}
                  title={selectedProduct?.name}
                  subheader={''}
                  price={selectedProduct?.price / 100}
                  description={selectedProduct?.descriptions}
                  buttonVariant={'contained'}
                  buttonText={'Purchase'}
                  userInfo={userInfo}
                  scu={selectedProduct?.scu}
                  productID={selectedProduct?.external_id}
                  onGetSecret={onGetSecret}
                  checkout
                /> : null}
                {localSecret ? 
                    <CheckoutForm secret={localSecret} />
                   : null}
              </Stack>
            </Stack>
          </Box>
        </Box>
    </TopComponent>
  )
}