import React, { useEffect, useRef, useState } from "react"
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Box, Drawer, Typography, List, Divider, ListItem, ListItemButton, ListItemText } from "@mui/material";
import FeatureCard from './base/feature_card'
import Question from './base/question'
import '../style/home.css'
import { lightStyle } from "src/style/light_style";
import { useAuth } from "react-oidc-context";
import { InitBreadCrumb, SetAccessToken, SetCurrentFolderID, SetUserInfo } from "src/redux_store/actions";
import { API, FILE_EXPLORER_PAGE } from "src/utils/consts";
import { UserInfo } from "src/objects/user";
import { extractUserSpaceUsage, extractUserTotalSpace, getUserInfoFromProfileData } from "src/utils/helper";


export function WebHome() {
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();
  const pricingRef = useRef();
  useEffect(()=>{ 
    if (auth.isAuthenticated) {
      const access_token = auth.user?.access_token;
      const refresh_token = auth.user?.refresh_token;
      var headers = { 
        'Content-Type': 'application/json', 
        'AuthToken': access_token }
      dispatch(SetAccessToken(access_token, refresh_token))
      fetch(API+'/user-profile/', { headers }).then(response => response.json()).then(data=>{
        data.access_token = access_token;
        const _userInfo = getUserInfoFromProfileData(data);
        dispatch(SetUserInfo(_userInfo));
        sessionStorage.setItem("profile", JSON.stringify(data))
        dispatch(SetUserInfo(_userInfo));
        navigate(`${FILE_EXPLORER_PAGE}/${data.root}/`)
      }).catch((err) => {
        console.log("HOME PROFILE ERROR ", err)
      })
      
    } else {
      navigate("/")
    }
  }, [auth.isAuthenticated])

  function initProfile(userToken) {
    const headers = { 
      'Content-Type': 'application/json', 
      'AuthToken': userToken }
    return fetch(API+'/user-profile/', { headers })
      .then(response => response.json())
      .then(data => {
        console.log("PROFILE: ", data)
        data.access_token = userToken;
        const _userInfo = getUserInfoFromProfileData(data);
        dispatch(SetUserInfo(_userInfo));
        sessionStorage.setItem("profile", JSON.stringify(data))
        dispatch(SetUserInfo(_userInfo));
        return data;
      }).catch((err) => {
        console.log("HOME PROFILE ERROR ", err)
      })
      ;
  }

  return (
    <Box
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      style={lightStyle.mainBackgroud}
    >
      <div className="home-container">
        <Helmet>
          <title>JarFlow</title>
        </Helmet>
        <div className="home-header">
          <header
            data-thq="thq-navbar"
            className="navbarContainer home-navbar-interactive"
          >
            <span className="logo">JarFlow</span>
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links">
                <span className="home-nav12 bodySmall">Home</span>
                <span className="home-nav2 bodySmall">Features</span>
                <span className="home-nav3 bodySmall">Pricing</span>
                <span className="home-nav4 bodySmall">About</span>
                <span className="home-nav5 bodySmall">Contact</span>
              </nav>
              <div className="home-buttons">
                <button className="home-login buttonFlat" onClick={() => {
                  navigate('/login')
                }}>Login</button>
                <button className="buttonFilled" onClick={() => navigate("/register")}>Register</button>
              </div>
            </div>
            <div data-thq="thq-burger-menu" className="home-burger-menu" key="right">
              <button onClick={() => setShowDrawer(true)}><svg viewBox="0 0 1024 1024" className="home-icon socialIcons">
                <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg></button>
            </div>
            <div
              data-thq="thq-mobile-menu"
              className="home-mobile-menu1 mobileMenu"
            >
              <div className="home-nav">
                <div className="home-top">
                  <span className="logo">JarFlow</span>
                  <div data-thq="thq-close-menu" className="home-close-menu">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="home-icon02 socialIcons"
                    >
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav className="home-links1">
                  <span>Home</span>
                  <span className="home-nav21 bodySmall">Features</span>
                  <span className="home-nav31 bodySmall">Pricing</span>
                  <span className="home-nav41 bodySmall">About</span>
                  <span className="home-nav51 bodySmall">Contact</span>
                </nav>
                <div className="home-buttons1">
                  <button className="buttonFlat" onClick={() => navigate("/login")}>Login</button>
                  <button className="buttonFilled" onClick={() => navigate("/register")}>Register</button>
                </div>
              </div>
              <div>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon04 socialIcons"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon06 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon08 socialIcons"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          </header>
        </div>
        <div className="home-hero">
          <div className="heroContainer home-hero1">
            <div className="home-container01">
              <h1 className="home-hero-heading heading1">
                Create Your Cloud Storage Portfolio
              </h1>
              <span className="home-hero-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      Store, Share, and Access Your Files Anytime, Anywhere
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <div className="home-btn-group">
                <button className="buttonFilled">Get Started</button>
                <button className="buttonFlat">Learn More →</button>
              </div>
            </div>
          </div>
        </div>
        <div className="home-features">
          <div className="featuresContainer">
            <div className="home-features1">
              <div className="home-container02">
                <span className="overline">
                  <span>features</span>
                  <br></br>
                </span>
                <h2 className="home-features-heading heading2">
                  Powerful Features For Your Secure Storage
                </h2>
                <span className="home-features-sub-heading bodyLarge">
                  <span>
                    <span>
                      <span>
                        Experience the convenience and security of our cloud
                        storage application
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div className="home-container03">
                <FeatureCard
                  heading="Secure Cloud Storage"
                  subHeading="Protect your files with advanced encryption"
                ></FeatureCard>
                <FeatureCard
                  heading="Easy File Sharing"
                  subHeading="Share files with a simple click of a button"
                ></FeatureCard>
                <FeatureCard
                  heading="Automatic Backup"
                  subHeading="Never worry about losing your data again"
                ></FeatureCard>
                <FeatureCard
                  heading="Cross-Platform Access"
                  subHeading="Access your files from any device, anywhere: MacOS, Windows, Android, IOS"
                ></FeatureCard>
                <FeatureCard
                  heading="Easy playback of files within the application"
                  subHeading="Playback of audio and video files, extensive 
                  support for various image and photo formats, 
                  PDF and office document support.
                  Automatic playlist generation for audio files."
                ></FeatureCard>
                <FeatureCard
                  heading="Always Free"
                  subHeading="Always free storage with a capacity of 5 Gigabytes."
                ></FeatureCard>
              </div>
            </div>
          </div>
        </div>
        <div className="home-pricing">
          <div className="pricingContainer">
            <div className="home-container04">
              <span className="overline">
                <span>Pricing</span>
                <br></br>
              </span>
              <h2 className="heading2">Choose the Right Plan for You</h2>
              <span className="home-pricing-sub-heading bodyLarge">
                <span>
                  <span>
                    No hidden fees and unnecessary services. Pay only for file storage.
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container05" ref={pricingRef}>
              <div className="freePricingCard home-pricing-card">
                <div className="home-container06">
                  <span className="home-text36 heading3">Free</span>
                  <span className="bodySmall">
                    A free plan with limited storage and basic features
                  </span>
                </div>
                <div className="home-container07">
                  <span className="home-text37">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-free-plan-price">0</span>
                </div>
                <div className="home-container08">
                  <div className="home-container09">
                    <span className="home-text40">✔</span>
                    <span className="bodySmall">5GB of storage</span>
                  </div>
                  <div className="home-container10">
                    <span className="home-text41">✔</span>
                    <span className="bodySmall">File sharing</span>
                  </div>
                  <div className="home-container11">
                    <span className="home-text42">✔</span>
                    <span className="bodySmall">Basic security measures</span>
                  </div>
                  <div className="home-container12">
                    <span className="home-text43">✔</span>
                    <span className="bodySmall">Limited customer support</span>
                  </div>
                </div>
                <button className="home-button buttonOutline" onClick={() => {
                  navigate("/login")
                }}>
                  Continue with Free
                </button>
              </div>
              <div className="basicPricingCard home-pricing-card1">
                <div className="home-container13">
                  <span className="home-text44 heading3">Eco</span>
                  <span className="bodySmall">
                    This pricing plan is suitable for storing and synchronizing small files.
                  </span>
                </div>
                <div className="home-container14">
                  <span className="home-text45">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-basic-plan-pricing">0.99</span>
                  <span className="home-text48">/ month</span>
                </div>
                <div className="home-container15">
                  <div className="home-container16">
                    <span className="home-text49">✔</span>
                    <span className="bodySmall">All features of FREE plan</span>
                  </div>
                  <div className="home-container17">
                    <span className="home-text51">✔</span>
                    <span className="bodySmall">60GB of storage</span>
                  </div>
                  <div className="home-container18">
                    <span className="home-text52">✔</span>
                    <span className="bodySmall">
                      Advanced file sharing options
                    </span>
                  </div>
                  <div className="home-container19">
                    <span className="home-text53">✔</span>
                    <span className="bodySmall">Enhanced security measures</span>
                  </div>
                  <div className="home-container20">
                    <span className="home-text54">✔</span>
                    <span className="bodySmall">Limited customer support</span>
                  </div>
                </div>
                <button className="home-button1 buttonFilledSecondary">
                  Try the ECO plan
                </button>
              </div>
              <div className="proPricingCard home-pricing-card2">
                <div className="home-container21">
                  <span className="home-text55 heading3">
                    <span>Eco Pro</span>
                    <br></br>
                  </span>
                  <span className="bodySmall">
                    An advanced plan for power users and large organizations
                  </span>
                </div>
                <div className="home-container22">
                  <span className="home-text58">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-pro-plan-pricing">1.69</span>
                  <span className="home-text61">/ month</span>
                </div>
                <div className="home-container23">
                  <div className="home-container24">
                    <span className="home-text62">✔</span>
                    <span className="bodySmall"> All features of BASIC plan</span>
                  </div>
                  <div className="home-container25">
                    <span className="home-text64">✔</span>
                    <span className="bodySmall">150GB of storage</span>
                  </div>
                  <div className="home-container26">
                    <span className="home-text65">✔</span>
                    <span className="bodySmall">
                      Advanced file sharing and collaboration tools
                    </span>
                  </div>
                  <div className="home-container27">
                    <span className="home-text66">✔</span>
                    <span className="bodySmall">Top-notch security measures</span>
                  </div>
                  <div className="home-container28">
                    <span className="home-text67">✔</span>
                    <span className="bodySmall">24/7 customer support</span>
                  </div>
                </div>
                <button className="home-button2 buttonFilledSecondary">
                  Try the ECO PRO plan
                </button>
              </div>
            </div>
            <div className="home-container05" ref={pricingRef}>
              <div className="basicPricingCard home-pricing-card1">
                <div className="home-container13">
                  <span className="home-text44 heading3">STANDARD</span>
                  <span className="bodySmall">
                    A plan suitable for individuals and small businesses
                  </span>
                </div>
                <div className="home-container14">
                  <span className="home-text45">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-basic-plan-pricing">2.59</span>
                  <span className="home-text48">/ month</span>
                </div>
                <div className="home-container15">
                  <div className="home-container16">
                    <span className="home-text49">✔</span>
                    <span className="bodySmall">All features of FREE plan</span>
                  </div>
                  <div className="home-container17">
                    <span className="home-text51">✔</span>
                    <span className="bodySmall">400GB of storage</span>
                  </div>
                  <div className="home-container18">
                    <span className="home-text52">✔</span>
                    <span className="bodySmall">
                      Advanced file sharing options
                    </span>
                  </div>
                  <div className="home-container19">
                    <span className="home-text53">✔</span>
                    <span className="bodySmall">Enhanced security measures</span>
                  </div>
                  <div className="home-container20">
                    <span className="home-text54">✔</span>
                    <span className="bodySmall">Priority customer support</span>
                  </div>
                </div>
                <button className="home-button1 buttonFilledSecondary">
                  Try the Basic plan
                </button>
              </div>
              <div className="basicPricingCard home-pricing-card1">
                <div className="home-container13">
                  <span className="home-text44 heading3">STANDARD PRO</span>
                  <span className="bodySmall">
                    A plan suitable for individuals and small businesses
                  </span>
                </div>
                <div className="home-container14">
                  <span className="home-text45">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-basic-plan-pricing">4.89</span>
                  <span className="home-text48">/ month</span>
                </div>
                <div className="home-container15">
                  <div className="home-container16">
                    <span className="home-text49">✔</span>
                    <span className="bodySmall">All features of FREE plan</span>
                  </div>
                  <div className="home-container17">
                    <span className="home-text51">✔</span>
                    <span className="bodySmall">1TB of storage</span>
                  </div>
                  <div className="home-container18">
                    <span className="home-text52">✔</span>
                    <span className="bodySmall">
                      Advanced file sharing options
                    </span>
                  </div>
                  <div className="home-container19">
                    <span className="home-text53">✔</span>
                    <span className="bodySmall">Enhanced security measures</span>
                  </div>
                  <div className="home-container20">
                    <span className="home-text54">✔</span>
                    <span className="bodySmall">Priority customer support</span>
                  </div>
                </div>
                <button className="home-button1 buttonFilledSecondary">
                  Try the Basic plan
                </button>
              </div>
              <div className="proPricingCard home-pricing-card2">
                <div className="home-container21">
                  <span className="home-text55 heading3">
                    <span>PREMIUM</span>
                    <br></br>
                  </span>
                  <span className="bodySmall">
                    An advanced plan for power users and large organizations
                  </span>
                </div>
                <div className="home-container22">
                  <span className="home-text58">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-pro-plan-pricing">8.39</span>
                  <span className="home-text61">/ month</span>
                </div>
                <div className="home-container23">
                  <div className="home-container24">
                    <span className="home-text62">✔</span>
                    <span className="bodySmall"> All features of BASIC plan</span>
                  </div>
                  <div className="home-container25">
                    <span className="home-text64">✔</span>
                    <span className="bodySmall">2TB of storage</span>
                  </div>
                  <div className="home-container26">
                    <span className="home-text65">✔</span>
                    <span className="bodySmall">
                      Advanced file sharing and collaboration tools
                    </span>
                  </div>
                  <div className="home-container27">
                    <span className="home-text66">✔</span>
                    <span className="bodySmall">Top-notch security measures</span>
                  </div>
                  <div className="home-container28">
                    <span className="home-text67">✔</span>
                    <span className="bodySmall">24/7 customer support</span>
                  </div>
                </div>
                <button className="home-button2 buttonFilledSecondary">
                  Try the PRO plan
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="home-banner">
          <div className="bannerContainer home-banner1">
            <h1 className="home-banner-heading heading2">
              Why Choose Our Cloud Storage Application?
            </h1>
            <span className="home-banner-sub-heading bodySmall">
              <span>
                <span>
                  <span>
                    Our cloud storage application offers a secure and convenient
                    way to store, share, and access your files. With advanced
                    features and reliable performance, it is the perfect solution
                    for individuals and businesses alike.
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
            <button className="buttonFilled">Learn More</button>
          </div>
        </div>
        <div className="home-faq">
          <div className="faqContainer">
            <div className="home-faq1">
              <div className="home-container29">
                <span className="overline">
                  <span>FAQ</span>
                  <br></br>
                </span>
                <h2 className="home-text85 heading2">Common questions</h2>
                <span className="home-text86 bodyLarge">
                  <span>
                    Here are some of the most common questions that we get.
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="home-container30">
                <Question
                  answer="A cloud storage application is a software that allows users to store and access their data and files on remote servers, which can be accessed through the internet."
                  question="What is a cloud storage application?"
                ></Question>
                <Question
                  answer="A cloud storage application works by securely storing user data on remote servers. Users can upload, download, and manage their files through the application's interface or API."
                  question="How does a cloud storage application work?"
                ></Question>
                <Question
                  answer="Yes, cloud storage applications employ various security measures to ensure the safety of user data. This includes encryption, access controls, and regular backups."
                  question="Is my data safe in a cloud storage application?"
                ></Question>
                <Question
                  answer="Yes, one of the main advantages of using a cloud storage application is that you can access your files from any device with an internet connection. This includes computers, smartphones, and tablets."
                  question="Can I access my files from any device with a cloud storage application?"
                ></Question>
                <Question
                  answer="Using a cloud storage application offers several benefits such as easy file sharing and collaboration, automatic backups, scalability, and the ability to free up local storage space on your devices."
                  question="What are the benefits of using a cloud storage application?"
                ></Question>
              </div>
            </div>
          </div>
        </div>
        <div className="home-footer">
          <footer className="footerContainer home-footer1">
            <div className="home-container31">
              <span className="logo">JarFlow</span>
              <nav className="home-nav1 bodySmall home-nav1">
                <span className="home-nav11 bodySmall">Home</span>
                <span className="home-nav22 bodySmall">Features</span>
                <span className="home-nav32 bodySmall">Pricing</span>
                <span className="home-nav42 bodySmall">About</span>
                <span className="home-nav52 bodySmall">Contact</span>
              </nav>
            </div>
            <div className="home-separator"></div>
            <div className="home-container32">
              <span className="bodySmall home-text89">
                © {new Date().getFullYear()} JarFlow, All Rights Reserved.
              </span>
              <div className="home-icon-group1">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon10 socialIcons"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon12 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon14 socialIcons"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          </footer>
        </div>
        <Drawer
          anchor={'right'}
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
        >
          <Box style={{alignItems: 'center', flex: 1}}>
            <List sx={{width: '90%', alignItems: 'center'}}>
              <ListItem key={'1'}>
                <button className="buttonFlat" onClick={() => {
                  setShowDrawer(false);
                  navigate("/");
                  }}>Home</button>
              </ListItem>
              <ListItem key={'2'}>
                <button className="buttonFlat" onClick={() => {
                  pricingRef.current.scrollIntoView();
                  setShowDrawer(false);
                  }}>Pricing</button>
              </ListItem>
              <ListItem key={'3'}>
                <button className="buttonFlat" onClick={() => navigate("/login")}>Login</button>
              </ListItem>
              <ListItem key={'4'}>
                <button className="buttonFilled" onClick={() => navigate("/register")}>Register</button>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </div>
    </Box>)
}